import { useAction } from "@/hooks/useAction";
import { IBlock } from "@/interfaces/block.interface";
import { IScope } from "@/interfaces/scope.interface";
import { getArrayFromArray, getValFromArray } from "@/utils";
import { Col, DatePicker, Row } from "antd";
import moment, { Moment } from "moment";
import { Plus, X } from "phosphor-react";
import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import short from "short-uuid";
import Button from "../ui/buttons/Button";
import { Action } from "./Action";
import { Card } from "./Card";
import { Loading } from "./Loading";

interface ITimeSheetTime {
  block: IBlock;
  formState: any;
  visible?: boolean;
  setFormState: any;
}

interface ITimesheetTime {
  id: string;
  checkin: Moment | null;
  checkout: Moment | null;
  editAction?: string;
  deleteAction?: string;
  disabled: boolean;
  saved?: boolean;
}

export const TimeSheetTime: FC<ITimeSheetTime> = ({
  block,
  visible = true,
}) => {
  const defaultOption: ITimesheetTime = {
    id: short.generate(),
    checkin: null,
    checkout: null,
    editAction: "",
    deleteAction: "",
    disabled: false,
    saved: false,
  };
  const config: IScope[] = block?.config || [];
  const [loading, setLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<ITimesheetTime[]>([defaultOption]);
  const form = useMemo(() => {
    return getArrayFromArray(config, "form") || [];
  }, [config]);

  const disabled = useMemo(() => {
    return getValFromArray(form, "disabled") === "1";
  }, [config]);

  const createAction = useMemo(() => {
    return getValFromArray(form, "create") || "";
  }, [config]);

  useMemo(() => {
    const it = getArrayFromArray(config, "items") || [];
    const itemRows: ITimesheetTime[] = [];
    it.forEach((one: any) => {
      const checkin = getArrayFromArray(one.array, "checkin");
      const checkout = getArrayFromArray(one.array, "checkout");
      const disabled = getValFromArray(one.array, "disabled") === "0";
      const editAction = getValFromArray(one.array, "edit");
      const deleteAction = getValFromArray(one.array, "delete");

      const checkinTime = getValFromArray(checkin, "default") || "";
      const checkoutTime = getValFromArray(checkout, "default") || "";

      itemRows.push({
        id: short.generate(),
        checkin: checkinTime ? moment(checkinTime, "HH:mm:ss") : null,
        checkout: checkoutTime ? moment(checkoutTime, "HH:mm:ss") : null,
        editAction,
        deleteAction,
        disabled,
        saved: true,
      });
    });
    setRows(form.length ? [...itemRows, defaultOption] : itemRows);
  }, [config, form]);

  const { t } = useTranslation();

  const enable_editing = !disabled;

  return visible ? (
    <>
      <Card title="" body="">
        {loading && <Loading />}
        {!loading && (
          <Row
            style={{
              gap: "10px",
              justifyContent: "space-between",
            }}
            align="middle"
          >
            <Col span={9}>
              <p>{t("checkin")}</p>
            </Col>
            <Col span={9}>
              <p>{t("checkout")}</p>
            </Col>
            <Col span={4}>
              <div className="w-100" />
            </Col>
          </Row>
        )}
        {!loading &&
          rows.map((one, index) => (
            <Row
              key={index}
              style={{ marginBottom: "10px", gap: "10px" }}
              align="middle"
            >
              <Col span={9}>
                <DatePicker
                  disabled={!enable_editing}
                  className="flex-1 w-100"
                  placeholder={t("checkin")}
                  picker="time"
                  showSecond={false}
                  value={
                    one?.checkin && moment(one?.checkin).isValid()
                      ? moment(one?.checkin)
                      : undefined
                  }
                  onChange={(event) => {
                    setRows(
                      rows.map((e) => {
                        if (e.id === one.id) {
                          e.checkin = event;
                        }
                        return e;
                      })
                    );
                  }}
                />
              </Col>
              <Col span={9}>
                <DatePicker
                  disabled={!enable_editing}
                  className="flex-1 w-100"
                  placeholder={t("checkout")}
                  picker="time"
                  showSecond={false}
                  value={
                    one?.checkout && moment(one?.checkout).isValid()
                      ? moment(one?.checkout)
                      : undefined
                  }
                  onChange={(event) => {
                    setRows(
                      rows.map((e) => {
                        if (e.id === one.id) {
                          e.checkout = event;
                        }
                        return e;
                      })
                    );
                  }}
                />
              </Col>
              <Col span={4} className="d-flex" style={{ gap: 10 }}>
                {!one.saved && (
                  <Action
                    disabled={!one.checkin || !one.checkout}
                    action={JSON.parse(createAction)}
                    item={{
                      checkin: one.checkin?.format("YYYY-MM-DD HH:mm:ss") || "",
                      checkout:
                        one.checkout?.format("YYYY-MM-DD HH:mm:ss") || "",
                    }}
                  />
                )}
                {one.editAction && (
                  <Action
                    disabled={!one.checkin || !one.checkout}
                    action={JSON.parse(one.editAction)}
                    item={{
                      checkin: one.checkin?.format("YYYY-MM-DD HH:mm:ss") || "",
                      checkout:
                        one.checkout?.format("YYYY-MM-DD HH:mm:ss") || "",
                    }}
                  />
                )}
                {one.deleteAction && (
                  <Action
                    action={JSON.parse(one.deleteAction)}
                    item={{
                      checkin: one.checkin?.format("YYYY-MM-DD HH:mm:ss") || "",
                      checkout:
                        one.checkout?.format("YYYY-MM-DD HH:mm:ss") || "",
                    }}
                  />
                )}
                {enable_editing && (
                  <>
                    {index === rows.length - 1 ? (
                      <Button
                        onClick={() => {
                          setRows((s) => [...s, defaultOption]);
                        }}
                        variant="primary"
                        type="link"
                        size="small"
                      >
                        <Plus />
                      </Button>
                    ) : one.deleteAction ? (
                      <span />
                    ) : (
                      <Button
                        onClick={() => {
                          setRows(rows.filter((e) => e.id !== one.id));
                        }}
                        variant="danger"
                        type="link"
                        size="small"
                      >
                        <X />
                      </Button>
                    )}
                  </>
                )}
              </Col>
            </Row>
          ))}
      </Card>
    </>
  ) : (
    <></>
  );
};
