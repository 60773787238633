import React from "react";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import { config } from "@/config";

type Props = { onChange?: (token: string) => void };

export const Captcha: React.FC<Props> = ({ onChange }) => {
  const onChangeCaptcha = (token: string) => {

    onChange && onChange(token);
  };
  

  return (
   null
    // <GoogleReCaptchaProvider reCaptchaKey={config.captchaKey}>
    //   <GoogleReCaptcha onVerify={onChangeCaptcha} />
    // </GoogleReCaptchaProvider>
  );

};
