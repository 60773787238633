import { IBlock } from "@/interfaces/block.interface";
import { IScope } from "@/interfaces/scope.interface";
import classNames from "classnames";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "./Card";
import { Loading } from "./Loading";
import { useAction } from "@/hooks/useAction";
import { Calendar, View, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Button from "../ui/buttons/Button";
import { AppLoading } from "@/pages/AppLoading";
import { getWeekNumber } from "@/utils";
import { Link } from "react-router-dom";

interface ILazyLoadCalendar {
  block: IBlock;
  formState: any;
  visible?: boolean;
}

interface ICalendarEvent {
  id: number;
  title: string;
  start_date: string;
  end_date: string;
  description: any;
  url: string;
  font_color: string;
  bg_color: string;
  __typename: string;
}

const localizer = momentLocalizer(moment);

export const LazyLoadCalendar: FC<ILazyLoadCalendar> = ({
  block,
  visible = true,
}) => {
  const config: IScope[] = block?.config || [];
  const expandable =
    config.find((e: any) => e.name === "expandable")?.value === "1";
  const expanded =
    config.find((e: any) => e.name === "expanded")?.value === "1";
  const graphql = config.find((e: any) => e.name === "graphql")?.value;
  const query_accessor = config.find(
    (e: any) => e.name === "query_accessor"
  )?.value;
  const mode = config.find((e: any) => e.name === "mode")?.value as View;
  const [calendarEvents, setCalendarEvents] = useState<ICalendarEvent[]>([]);

  const { doQuery } = useAction();
  const [currentDate, setCurrentDate] = useState<Date>(new Date());

  const [loading, setLoading] = useState<boolean>(false);
  const { t, i18n } = useTranslation();

  const [calendarMode, setCalendarMode] = useState<View>(mode || "month");

  const getData = useCallback(() => {
    if (graphql) {
      setLoading(true);
      const scope = [];
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1;
      const week = getWeekNumber(currentDate);
      const day = currentDate.getDate();

      if (calendarMode === "month") {
        scope.push({ name: "year", value: year.toString() });
        scope.push({ name: "month", value: month.toString() });
      }
      if (calendarMode === "week") {
        scope.push({ name: "year", value: year.toString() });
        scope.push({ name: "month", value: month.toString() });
        scope.push({ name: "week", value: week.toString() });
      }
      if (calendarMode === "day") {
        scope.push({
          name: "day",
          value: `${year}/${month}/${day}`,
        });
      }

      doQuery({
        query: graphql,
        scope,
        withQuate: true,
      })
        .then((data: any) => {
          if (data?.data) {
            setCalendarEvents(data?.data[query_accessor || ""].events || []);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [currentDate, graphql, query_accessor, calendarMode]);

  useEffect(() => {
    getData();
  }, [graphql, currentDate, calendarMode]);

  const events = useMemo(() => {
    return calendarEvents.map((e) => {
      return {
        id: e.id,
        title: e.title,
        start: new Date(e.start_date),
        end: new Date(e.end_date),
        resource: e,
      };
    });
  }, [calendarEvents]);

  return visible ? (
    <>
      <Card
        body={block.body}
        title={block?.title}
        expanded={expanded}
        classes={classNames(block?.classes)}
        expandable={expandable}
        config={config}
      >
        {loading && (
          <AppLoading
            style={{ background: "rgba(0,0,0, .4)" }}
            withLogo={false}
          />
        )}
        <Calendar
          localizer={localizer}
          rtl={i18n.language === "ar"}
          events={events}
          startAccessor="start"
          onView={(view) => {
            setCalendarMode(view);
          }}
          view={calendarMode}
          endAccessor="end"
          style={{ minHeight: 600 }}
          onNavigate={(date) => {
            setCurrentDate(date);
          }}
          components={{
            toolbar: (props) => {
              return (
                <div className="calendar-toolbar">
                  <div className="date-controls">
                    <Button
                      onClick={() => {
                        props.onNavigate("PREV", props.date);
                      }}
                    >
                      {t(`calendar.prev${props.view}`)}
                    </Button>
                    <Button
                      onClick={() => {
                        props.onNavigate("NEXT", props.date);
                      }}
                    >
                      {t(`calendar.next${props.view}`)}
                    </Button>
                    <Button
                      onClick={() => {
                        props.onNavigate("TODAY", props.date);
                      }}
                    >
                      {t("calendar.today")}
                    </Button>
                  </div>
                  {props.label}
                  <div className="calendar-controls">
                    <Button
                      variant={calendarMode === "month" ? "primary" : "light"}
                      onClick={() => {
                        setCalendarMode("month");
                      }}
                    >
                      {t("calendar.month")}
                    </Button>
                    <Button
                      variant={calendarMode === "week" ? "primary" : "light"}
                      onClick={() => {
                        setCalendarMode("week");
                      }}
                    >
                      {t("calendar.week")}
                    </Button>
                    <Button
                      variant={calendarMode === "day" ? "primary" : "light"}
                      onClick={() => {
                        setCalendarMode("day");
                      }}
                    >
                      {t("calendar.day")}
                    </Button>
                  </div>
                </div>
              );
            },
            event: (event) => {
              return (
                <div
                  style={{
                    background: event.event.resource.bg_color,
                    color: event.event.resource.font_color,
                  }}
                >
                  {event.event.resource?.url ? (
                    <>
                      {event.event.resource?.url?.includes("http") ? (
                        <a
                          href={event.event.resource.url}
                          style={{ color: event.event.resource.font_color }}
                        >
                          {event.event.title}
                        </a>
                      ) : (
                        <Link
                          to={event.event.resource.url}
                          style={{ color: event.event.resource.font_color }}
                        >
                          {event.event.title}
                        </Link>
                      )}
                    </>
                  ) : (
                    <span>{event.event.title}</span>
                  )}
                </div>
              );
            },
          }}
        />
      </Card>
    </>
  ) : (
    <></>
  );
};
