import { Badge, Tag } from "antd";
import React, { useMemo } from "react";
import { IScopeArray } from "@interfaces/scope.interface";
import { useMediaQuery } from "@/hooks/useMediaQuery";

export const BadgeItem: React.FC<{
  badge: IScopeArray[];
  style?: React.CSSProperties;
  customBadge?: number;
  type?: "badge" | "tag";
}> = ({ children, badge, style, type = "badge", customBadge }) => {
  const { badg_config } = useMemo(() => {
    const color = badge.find((e) => e.name === "color")?.value;
    const count = badge.find((e) => e.name === "count")?.value;
    const dot = badge.find((e) => e.name === "dot")?.value === "1";
    const offset = badge.find((e) => e.name === "offset")?.value;
    let overflowCount = badge.find((e) => e.name === "overflowCount")?.value
      ? parseInt(badge.find((e) => e.name === "overflowCount")?.value + "")
      : undefined;

    const showZero = badge.find((e) => e.name === "showZero")?.value === "1";
    const title = badge.find((e) => e.name === "title")?.value;
    const size = badge.find((e) => e.name === "size")?.value as
      | "default"
      | "small"
      | undefined;

    return {
      badg_config: {
        color,
        count,
        dot,
        offset,
        overflowCount,
        showZero,
        title,
        size,
      },
    };
  }, [badge]);

  const isMobile = useMediaQuery("(max-width: 1080px)");

  return type === "badge" ? (
    <Badge
      style={style}
      dot={badg_config.dot}
      color={badg_config.color}
      count={customBadge !== undefined ? customBadge : badg_config.count}
      overflowCount={badg_config.overflowCount}
      showZero={badg_config.showZero}
      title={badg_config.title}
      size={
        badg_config.size ? badg_config.size : isMobile ? "small" : "default"
      }
    >
      {children}
    </Badge>
  ) : (
    <Tag color={badg_config.color}>{badg_config.title}</Tag>
  );
};
