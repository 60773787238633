import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import { Checkbox, Form, FormInstance } from "antd";
import { Rule } from "antd/lib/form";
import classNames from "classnames";
import React, { useMemo } from "react";

const RegexParser = require("regex-parser");

interface IProps {
  block: IBlock;
  scope: IScope[];
  loading: boolean;
  form: FormInstance<any>;
  onChange?: (value: any, variable: string) => void;
  visible?: boolean;
  disabled?: string;
}

export const FieldCheckbox: React.FC<IProps> = ({
  block,
  scope = [],
  form,
  onChange,
  visible,
  disabled,
}) => {
  const variable =
    block?.config?.find((e: any) => e.name === "variable")?.value || "";

  const required =
    block?.config?.find((e: any) => e.name === "required")?.value === "1";
  const pattern = block?.config?.find((e: any) => e.name === "pattern")?.value;
  const validation_message = block?.config?.find(
    (e: any) => e.name === "validation_message"
  )?.value;

  const { rules } = useMemo(() => {
    const rules: Rule[] = [];

    if (pattern) {
      rules.push({
        pattern: new RegExp(RegexParser(pattern)),
        message: validation_message,
      });
    }
    if (required) {
      rules.push({
        required: required,
        message: `${block?.title} is required.`,
      });
    }

    return { rules };
  }, [required, pattern]);

  return (
    <Form.Item
      name={variable}
      rules={rules}
      className={classNames(visible === false && "d-none")}
    >
      <Checkbox
        disabled={disabled === "1"}
        checked={form.getFieldValue(variable) === "1"}
        onChange={({ target }) => {
          form.setFieldsValue({ [variable]: target.value === "0" ? "1" : "0" });
          onChange && onChange(target.value === "0" ? "1" : "0", variable);
        }}
      >
        {block?.title}
      </Checkbox>
    </Form.Item>
  );
};
