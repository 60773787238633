import { FileDoc, FilePdf, Image, Link } from "phosphor-react";
import DocumentItem from "./DocumentItem";
import { presetPrimaryColors } from "@ant-design/colors";
import SimpleBar from "simplebar-react";

const DocumentsTab = () => {
  return (
    <div className="tab-item documents-tab">
      <div className="tab-title">
        <span>Document name</span>
        <span>Size</span>
        <span>Clicks</span>
      </div>
      <div className="tab-content">
        <SimpleBar style={{ height: "100%" }}>
          <DocumentItem
            name="mathematic notes"
            icon={<FilePdf color={presetPrimaryColors.red} />}
            path="#"
            size="43 kb"
            clicks={550}
          />
          <DocumentItem
            name="mathematic notes"
            icon={<Image color={presetPrimaryColors.gold} />}
            path="#"
            size="30 mb"
            clicks={550}
          />
          <DocumentItem
            name="mathematic notes"
            icon={<FileDoc color={presetPrimaryColors.blue} />}
            path="#"
            size="10 mb"
            clicks={550}
          />
          <DocumentItem
            name="mathematic notes"
            icon={<Link color={presetPrimaryColors.black} />}
            path="#"
            size="30 mb"
            clicks={550}
          />
          <DocumentItem
            name="mathematic notes"
            icon={<FilePdf color={presetPrimaryColors.red} />}
            path="#"
            size="43 kb"
            clicks={550}
          />
          <DocumentItem
            name="mathematic notes"
            icon={<Image color={presetPrimaryColors.gold} />}
            path="#"
            size="30 mb"
            clicks={550}
          />
          <DocumentItem
            name="mathematic notes"
            icon={<FileDoc color={presetPrimaryColors.blue} />}
            path="#"
            size="10 mb"
            clicks={550}
          />
          <DocumentItem
            name="mathematic notes"
            icon={<Link color={presetPrimaryColors.black} />}
            path="#"
            size="30 mb"
            clicks={550}
          />
          <DocumentItem
            name="mathematic notes"
            icon={<FilePdf color={presetPrimaryColors.red} />}
            path="#"
            size="43 kb"
            clicks={550}
          />
          <DocumentItem
            name="mathematic notes"
            icon={<Image color={presetPrimaryColors.gold} />}
            path="#"
            size="30 mb"
            clicks={550}
          />
          <DocumentItem
            name="mathematic notes"
            icon={<FileDoc color={presetPrimaryColors.blue} />}
            path="#"
            size="10 mb"
            clicks={550}
          />
          <DocumentItem
            name="mathematic notes"
            icon={<Link color={presetPrimaryColors.black} />}
            path="#"
            size="30 mb"
            clicks={550}
          />
          <DocumentItem
            name="mathematic notes"
            icon={<FilePdf color={presetPrimaryColors.red} />}
            path="#"
            size="43 kb"
            clicks={550}
          />
          <DocumentItem
            name="mathematic notes"
            icon={<Image color={presetPrimaryColors.gold} />}
            path="#"
            size="30 mb"
            clicks={550}
          />
          <DocumentItem
            name="mathematic notes"
            icon={<FileDoc color={presetPrimaryColors.blue} />}
            path="#"
            size="10 mb"
            clicks={550}
          />
          <DocumentItem
            name="mathematic notes"
            icon={<Link color={presetPrimaryColors.black} />}
            path="#"
            size="30 mb"
            clicks={550}
          />
          <DocumentItem
            name="mathematic notes"
            icon={<FilePdf color={presetPrimaryColors.red} />}
            path="#"
            size="43 kb"
            clicks={550}
          />
          <DocumentItem
            name="mathematic notes"
            icon={<Image color={presetPrimaryColors.gold} />}
            path="#"
            size="30 mb"
            clicks={550}
          />
          <DocumentItem
            name="mathematic notes"
            icon={<FileDoc color={presetPrimaryColors.blue} />}
            path="#"
            size="10 mb"
            clicks={550}
          />
          <DocumentItem
            name="mathematic notes"
            icon={<Link color={presetPrimaryColors.black} />}
            path="#"
            size="30 mb"
            clicks={550}
          />
        </SimpleBar>
      </div>
    </div>
  );
};

export default DocumentsTab;
