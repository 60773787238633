import { IScope } from "@/interfaces/scope.interface";
import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { RootState } from "./root.reducer";

const NAME = "form";
type IState = {
  lastInput?: any;
  lastType: "modal" | "drawer" | "";
};
const initialState: IState = {
  lastInput: {},
  lastType: "",
};
export const FormSlice = createSlice({
  name: NAME,
  initialState: initialState,
  reducers: {
    saveInput: (state, action: PayloadAction<Omit<IState, "lastType">>) => {
      return { ...state, lastInput: action.payload.lastInput };
    },
    setLastType: (state, action) => {
      return { ...state, lastType: action.payload };
    },
  },
});

export const FormActions = FormSlice.actions;
export const formSelector = (state: RootState) => state.form;
