import { useRedirect } from "@/hooks/useRedirect";
import { FormActions } from "@/store/reducers/form.reducer";
import { getValFromArray } from "@/utils";
import { Card } from "@components/dynamic/Card";
import Button from "@components/ui/buttons/Button";
import { useAction } from "@hooks/useAction";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import { Col, Form, FormInstance, InputNumber, Row, Space } from "antd";
import { Rule } from "antd/lib/form";
import classNames from "classnames";
import { Check, NotePencil, X } from "phosphor-react";
import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const RegexParser = require("regex-parser");

interface IProps {
  block: IBlock;
  scope: IScope[];
  loading: boolean;
  form: FormInstance<any>;
  editInline?: boolean;

  formState: any;
  setFormState: any;
  onChange?: (value: any, variable: string) => void;
  visible?: boolean;
  disabled?: string;
}

export const FieldInputNumber: React.FC<IProps> = ({
  block,
  scope = [],
  form,
  setFormState,
  editInline,
  onChange,
  visible,
  disabled,
  formState,
}) => {
  const { checkRedirect } = useRedirect();
  const { t } = useTranslation();
  const config: IScope[] = block?.config || [];
  const dispatch = useDispatch();
  const variable = config.find((e) => e.name === "variable")?.value || "";
  const placeholder = config.find((e) => e.name === "placeholder")?.value || "";
  const required = config.find((e) => e.name === "required")?.value === "1";
  const pattern = config.find((e) => e.name === "pattern")?.value;
  const validation_message = config.find(
    (e) => e.name === "validation_message"
  )?.value;

  const isSaved = useRef(false);

  const graphql = config.find((e) => e.name === "graphql")?.value;
  const expanded = config.find((e) => e.name === "expanded")?.value === "1";
  const expandable = config.find((e) => e.name === "expandable")?.value === "1";

  const min = config.find((e) => e.name === "min")?.value;
  const max = config.find((e) => e.name === "max")?.value;
  const prefix = config.find((e) => e.name === "prefix")?.value;
  const addonAfter = config.find((e) => e.name === "addonAfter")?.value;
  const addonBefore = config.find((e) => e.name === "addonBefore")?.value;
  const step = config.find((e) => e.name === "step")?.value;
  const defaultValue = config.find((e) => e.name === "default")?.value;
  const can_do = config.find((e) => e.name === "can_do")?.value === "1";
  const nullable = config.find((e) => e.name === "nullable")?.value === "1";

  const auto_save = config.find((e) => e.name === "auto_save")?.value || "";
  const query = config.find((e) => e.name === "query")?.value;
  const timeout = config.find((e) => e.name === "timeout")?.value
    ? parseInt(
        config.find((e) => e.name === "timeout")?.value || ("0" as string)
      )
    : 1000;

  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const { doMutateQraphQl, replaceFromScope } = useAction();

  const [state, setState] = useState({
    loading: false,
    isValid: false,
    editMode: false,
    value: block?.body || "",
    editedValue: block?.body || "",
  });

  const { rules } = useMemo(() => {
    const rules: Rule[] = [];

    if (pattern) {
      rules.push({
        pattern: new RegExp(RegexParser(pattern)),
        message: validation_message,
      });
    }
    if (required) {
      rules.push({
        required: required,
        message: `${block?.title || block?.label} is required.`,
      });
    }
    if (!nullable && min) {
      rules.push({
        validator(rule, value) {
          if (value < min) {
            return Promise.reject(
              new Error(t("shouldBeMoreThan", { number: min }))
            );
          }
          return Promise.resolve();
        },
      });
    }
    if (!nullable && max) {
      rules.push({
        validator(rule, value) {
          if (value > max) {
            return Promise.reject(
              new Error(t("shouldBeLessThan", { number: max }))
            );
          }
          return Promise.resolve();
        },
      });
    }

    return { rules };
  }, [min, max, required, pattern]);

  const CardComponent = (
    <Card
      config={config}
      body={
        state.editedValue
          ? typeof state.editedValue === "number"
            ? (state?.editedValue as number)?.toFixed(2)?.toString()
            : state?.editedValue?.toString()
          : ""
      }
      title={block?.title}
      expanded={expanded}
      classes={block?.classes}
      expandable={expandable}
      visible={visible}
      editBodyButton={
        can_do ? (
          <Button
            key="card-edit-action-edit-inline"
            // type="button"
            loading={state.loading}
            variant="primary"
            onClick={() => setState((s) => ({ ...s, editMode: true }))}
            icon={<NotePencil />}
          />
        ) : undefined
      }
    />
  );

  const save = () => {
    let query = "";

    if (required && typeof state.editedValue === "undefined") return;

    if (pattern) {
      const expression = new RegExp(RegexParser(pattern));

      const isMatch = expression.test(state.editedValue);
      if (!isMatch) return;
    }

    let valid = true;

    if (!nullable && !state.editedValue) {
      valid = false;
    }

    if (nullable && state.editedValue && min && state.editedValue < min) {
      valid = false;
    }
    if (nullable && state.editedValue && max && state.editedValue > max) {
      valid = false;
    }

    if (!valid) return;

    query = replaceFromScope({
      scope: [{ name: variable, value: state.editedValue + "" }],
      str: graphql + "",
      withQuate: false,
    });

    setState((s) => ({ ...s, loading: true }));

    doMutateQraphQl(query)
      .then(() => {
        checkRedirect(config);
        setState((s) => ({
          ...s,
          loading: false,
          editMode: false,
          value: s.editedValue,
        }));
        isSaved.current = true;

        onChange && onChange(state.editedValue, variable);
      })
      .catch((e) => {
        setState((s) => ({ ...s, loading: false, editMode: false }));
      });
  };
  const cancel = () => {
    setState((s) => ({ ...s, editMode: false, editedValue: s.value }));
  };

  const Required = () => {
    return (
      typeof state.editedValue === "undefined" && (
        <div className="ant-form-item-explain ">
          <div role="alert" className="ant-form-item-explain-error">
            {variable} is required.
          </div>
        </div>
      )
    );
  };
  const Pattern = () => {
    if (nullable && !state.editedValue) {
      return null;
    }
    if (!nullable && !state.editedValue) {
      return (
        <div className="ant-form-item-explain ">
          <div role="alert" className="ant-form-item-explain-error">
            {t("pleaseEnterValue")}
          </div>
        </div>
      );
    }
    if (pattern) {
      const expression = new RegExp(RegexParser(pattern));

      const isMatch = expression.test(state.editedValue);

      return (
        !isMatch && (
          <div className="ant-form-item-explain ">
            <div role="alert" className="ant-form-item-explain-error">
              {validation_message}
            </div>
          </div>
        )
      );
    } else if (min && state.editedValue < min) {
      return (
        <div className="ant-form-item-explain ">
          <div role="alert" className="ant-form-item-explain-error">
            {t("shouldBeMoreThan", { number: min })}
          </div>
        </div>
      );
    } else if (max && state.editedValue > max) {
      return (
        <div className="ant-form-item-explain ">
          <div role="alert" className="ant-form-item-explain-error">
            {t("shouldBeLessThan", { number: max })}
          </div>
        </div>
      );
    }
  };

  const ref = useRef<any>(null);

  const onFieldChange = (val: string) => {
    if (auto_save && auto_save === "on_change") {
      if (required && !val) return;
      if (timer) {
        clearTimeout(timer);
      }

      const newTimer = setTimeout(async () => {
        let q = "";

        if (pattern) {
          const expression = new RegExp(RegexParser(pattern));

          const isMatch = expression.test(val);
          if (!isMatch) return;
        }

        q = replaceFromScope({
          scope: [{ name: variable, value: val }],
          str: query + "",
          withQuate: false,
        });

        doMutateQraphQl(q)
          .then(() => {
            checkRedirect(config);
          })
          .catch((err) => {
            console.log(err);
          });
      }, timeout);
      setTimer(newTimer);
    }
  };

  const onFieldBlur = (val: string) => {
    if (auto_save && auto_save === "on_blur") {
      if (required && !val) return;
      let q = "";
      if (pattern) {
        const expression = new RegExp(RegexParser(pattern));
        const isMatch = expression.test(val);
        if (!isMatch) return;
      }
      if (min && state.editedValue < min) return;
      if (max && state.editedValue > max) return;
      q = replaceFromScope({
        scope: [{ name: variable, value: val }],
        str: query + "",
        withQuate: false,
      });
      doMutateQraphQl(q)
        .then(() => {
          checkRedirect(config);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return editInline ? (
    state.editMode ? (
      <Card
        body={""}
        title={block?.title}
        expanded={expanded}
        classes={block?.classes}
        expandable={expandable}
        visible={visible}
        config={config}
      >
        <Row wrap={false} gutter={[16, 16]}>
          <Col xs="24" flex="auto">
            <InputNumber
              placeholder={placeholder}
              disabled={disabled === "1"}
              defaultValue={isSaved.current ? state.editedValue : defaultValue}
              step={step}
              ref={ref}
              addonAfter={addonAfter}
              addonBefore={addonBefore}
              prefix={prefix}
              value={state.editedValue}
              onChange={(value) => {
                setState((s) => ({ ...s, editedValue: value }));
              }}
              onFocus={() => {
                dispatch(FormActions.saveInput({ lastInput: ref.current }));
              }}
            />
          </Col>
          <Col xs="24" flex="none">
            <Space direction="horizontal" style={{ width: "20%" }}>
              <Button
                variant="primary"
                type="primary"
                icon={<Check />}
                // width={10}
                onClick={save}
                loading={state.loading}
              />
              <Button
                variant="danger"
                type="primary"
                icon={<X />}
                // width={10}
                disabled={state.loading}
                onClick={cancel}
              />
            </Space>
          </Col>
        </Row>

        {Required()}
        {Pattern()}
      </Card>
    ) : (
      CardComponent
    )
  ) : (
    <Form.Item
      label={block?.title || block?.label}
      name={variable}
      rules={rules}
      className={classNames(visible === false && "d-none")}
    >
      <InputNumber
        placeholder={placeholder}
        disabled={disabled === "1"}
        defaultValue={defaultValue}
        step={step}
        ref={ref}
        addonAfter={addonAfter}
        addonBefore={addonBefore}
        value={formState[variable]?.toString() || "0"}
        prefix={prefix}
        onChange={(value) => {
          form.setFieldsValue({ [variable]: value });
          setFormState((s: any) => ({ ...s, [variable]: value }));
          onFieldChange?.(value);
        }}
        onFocus={() => {
          dispatch(FormActions.saveInput({ lastInput: ref.current }));
        }}
        onBlur={({ target: { value } }) => {
          onFieldBlur?.(value);
        }}
      />
    </Form.Item>
  );
};
