import Button from "@/components/ui/buttons/Button";
import { url } from "@/config";
import { useKeyboardActions } from "@/hooks/useKeyboardActions";
import { AppLoading } from "@/pages/AppLoading";
import { Notification } from "@components/Notification";
import { RenderPage } from "@components/RenderPage";
import { AuthRouters } from "@routers/auth.routers";
import { PrivateRouters } from "@routers/private.routers";
import { AuthActions, authSelector } from "@store/reducers/auth.reducer";
import { ModalActions, modalSelector } from "@store/reducers/modal.reducer";
import { themeSelector } from "@store/reducers/theme.reducer";
import { ConfigProvider, Drawer, Modal } from "antd";
import { Locale } from "antd/lib/locale-provider";
import ar_EG from "antd/lib/locale/ar_EG";
import en_US from "antd/lib/locale/en_US";
import tr_TR from "antd/lib/locale/tr_TR";
import * as React from "react";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

export const Routes: React.FC = React.memo(({}) => {
  const { accessToken, sessionToken, loading } = useSelector(authSelector);
  const [authLoading, setAuthLoading] = React.useState(true);
  const {
    showModal,
    modalPageName,
    modalWidth,
    modalePageParams,
    previous_page_name,
    drawerPageName,
    drawerWidth,
    showDrawer,
    drawerPageParams,
    title,
  } = useSelector(modalSelector);

  const location = useLocation();

  const dispatch = useDispatch();
  React.useEffect(() => {
    authConfig();
  }, []);

  const authConfig = async () => {
    try {
      const json = await fetch(`${url}/api/v1/authconfig`);
      const res = await json.json();
      if (json.ok) {
        dispatch(AuthActions.setAuthConfig({ config: res }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAuthLoading(false);
      dispatch(AuthActions.setLoading(false));
    }
  };

  const theme = useSelector(themeSelector).theme;

  const { i18n } = useTranslation();

  const locale = React.useMemo(() => {
    let locale: Locale = en_US;
    switch (i18n.language) {
      case "ar":
        locale = ar_EG;
        break;
      case "en":
        locale = en_US;
        break;
      case "tr":
        locale = tr_TR;
        break;

      default:
        locale = en_US;
        break;
    }
    return locale;
  }, [i18n.language]);

  const closeModal = () => {
    dispatch(
      ModalActions.toggleModal({
        showModal: false,
        modalPageName: "",
        page_name: previous_page_name,
        title: "",
      })
    );
  };
  const closeDrawer = () => {
    dispatch(
      ModalActions.toggleModal({
        page_name: previous_page_name,
        showDrawer: false,
        drawerPageName: "",
      })
    );
  };

  React.useEffect(() => {
    document.documentElement.setAttribute("lang", i18n.language);
    if (i18n.language === "ar") {
      document.body.dir = "rtl";
    } else {
      document.body.dir = "ltr";
    }
  }, [locale]);

  React.useEffect(() => {
    if (!accessToken) {
      localStorage.setItem("unauthPage", location.pathname);
    }
  }, [accessToken]);

  useKeyboardActions();

  return (
    <ThemeSwitcherProvider
      defaultTheme={theme + ""}
      themeMap={{
        light: process.env.PUBLIC_URL + "/light-theme.css",
        dark: process.env.PUBLIC_URL + "/dark-theme.css",
      }}
    >
      <ConfigProvider
        locale={locale}
        direction={i18n.language === "ar" ? "rtl" : "ltr"}
      >
        {(authLoading || loading) && !accessToken ? (
          <AppLoading />
        ) : !!accessToken || !!sessionToken ? (
          <PrivateRouters />
        ) : (
          <AuthRouters />
        )}

        <Notification />

        {showModal && (
          <Modal
            data-page-name={modalPageName}
            title={title}
            closeIcon={null}
            visible={showModal}
            // onOk={showModal}
            onCancel={closeModal}
            centered
            width={`${modalWidth}vw`}
            footer={null}
          >
            {modalPageName && (
              <RenderPage
                className="no-side-menu"
                name={modalPageName}
                page_params={modalePageParams}
              />
            )}
          </Modal>
        )}

        <Drawer
          title={undefined}
          placement={i18n.language === "en" ? "right" : "left"}
          onClose={closeDrawer}
          visible={showDrawer}
          bodyStyle={{ padding: 0 }}
          // headerStyle={{ padding: 0 }}
          width={`${drawerWidth || "30"}vw`}
        >
          {drawerPageName && (
            <RenderPage
              className="no-side-menu"
              page_params={drawerPageParams}
              name={drawerPageName}
              style={{ margin: 0, padding: 0 }}
            />
          )}
        </Drawer>
      </ConfigProvider>
    </ThemeSwitcherProvider>
  );
});
