import { Statistic } from "antd";
import React, { memo } from "react";
import { useAction } from "@hooks/useAction";
import { useLazyloadQuery } from "@hooks/useLazyloadQuery";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";

import { Card } from "./Card";

export const LazyLoadKpi: React.FC<{
  block: IBlock;
  scope: IScope[];
  loading: boolean;
  visible?: boolean;
}> = memo(({ block, scope = [], visible }) => {
  const { replaceFromScope } = useAction();

  const config: IScope[] = block?.config ? block?.config : [];
  const unit = config.find((e: any) => e.name === "unit")?.value || "";
  const prefix = config.find((e: any) => e.name === "prefix")?.value || "";
  const suffix = config.find((e: any) => e.name === "suffix")?.value || "";
  const expandable =
    config.find((e: any) => e.name === "expandable")?.value === "1";
  const expanded =
    config.find((e: any) => e.name === "expanded")?.value === "1";

  const { result, loading } = useLazyloadQuery({
    query:
      (config.find((e: IScope) => e.name === "graphql")?.value
        ? config.find((e: IScope) => e.name === "graphql")?.value
        : "") + "",
    scope: config,
    variables: {},
  });

  return (
    <Card
      scope={scope}
      params={result}
      body={block?.body}
      title={block?.title}
      expanded={expanded}
      classes={block?.classes}
      expandable={expandable}
      loading={loading}
      visible={visible}
      config={config}
    >
      <Statistic
        // title={
        //   <p
        //     dangerouslySetInnerHTML={{
        //       __html: replaceFromScope({
        //         str: block?.body,
        //         scope,
        //       }),
        //     }}
        //   ></p>
        // }
        value={result}
        precision={2}
        valueStyle={{ color: "#3f8600" }}
        prefix={prefix}
        suffix={suffix}
      />

      <p
        dangerouslySetInnerHTML={{
          __html: replaceFromScope({
            str: unit,
            scope,
            withQuate: false,
          }),
        }}
      ></p>
    </Card>
  );
});
