import { Icon } from "@components/dynamic/Icon";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import { Breadcrumb as AntBreadcrumb, Space } from "antd";
import classNames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";

export const Breadcrumb: React.FC<{
  block: IBlock;
  scope: IScope[];
  loading: boolean;
  visible?: boolean;
}> = ({ block, scope = [], loading, visible }) => {
  const config = block?.config || [];
  const items = config.find((e) => e.name === "items")?.array || [];
  const navigate = useNavigate();

  return (
    <AntBreadcrumb className={classNames(visible === false && "d-none")}>
      {items.map((item: IScope, index: number) => {
        const name = item?.array?.find((e) => e.name === "name")?.value;
        const link = item?.array?.find((e) => e.name === "link")?.value;
        const icon = item?.array?.find((e) => e.name === "icon")?.value;

        return (
          <AntBreadcrumb.Item
            key={`Breadcrumb-${index}`}
            className="pointer"
            onClick={() => link && navigate(link)}
          >
            <Space>
              {!!icon && <Icon name={icon + ""} />}

              <span>{name}</span>
            </Space>
          </AntBreadcrumb.Item>
        );
      })}
    </AntBreadcrumb>
  );
};
