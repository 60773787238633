import { Divider as AntdDivider } from "antd";
import React from "react";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import classNames from "classnames";

export const Divider: React.FC<{
  block: IBlock;
  scope: IScope[];
  loading: boolean;
  visible?: boolean;
}> = ({ block, scope = [], loading, visible }) => {
  const config: IScope[] = block?.config || [];

  return <AntdDivider className={classNames(visible === false && "d-none")} />;
};
