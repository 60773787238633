import React, { ReactElement } from "react";
import { Icon } from "../dynamic/Icon";

export type DocumentItemProps = {
  name: string;
  path: string;
  icon?: ReactElement;
  size?: string;
  clicks?: number;
};
const DocumentItem: React.FC<DocumentItemProps> = ({
  name,
  path,
  icon,
  size,
  clicks,
}) => {
  return (
    <div className={"document-item"}>
      <div>
        {icon}
        <a href={path} download>
          {name}
        </a>
      </div>
      <span>{size}</span>
      <span>{clicks}</span>
    </div>
  );
};

export default DocumentItem;
