import { getValFromArray } from "@/utils";
import { Card } from "@components/dynamic/Card";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import { Timeline as AntTimeline } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

type TimelineMode = "left" | "alternate" | "right" | undefined;

export const Timeline: React.FC<{
  block: IBlock;
  visible?: boolean;
}> = ({ block, visible }) => {
  const { t, i18n } = useTranslation();
  const config: IScope[] = block?.config || [];
  const expandable =
    config.find((e: any) => e.name === "expandable")?.value === "1";
  const expanded =
    config.find((e: any) => e.name === "expanded")?.value === "1";

  const mode = config.find((e: any) => e.name === "mode")
    ?.value as TimelineMode;
  const items = config.find((e: any) => e.name === "items")?.array || [];

  console.log(items);

  const timeLineItems = items.map((item: any) => {
    return {
      label: getValFromArray(item.array, "label"),
      color: getValFromArray(item.array, "color"),
      children: getValFromArray(item.array, "children"),
      position: getValFromArray(item.array, "position"),
    };
  });

  return (
    <Card
      body={""}
      title={block?.title}
      expanded={expanded}
      classes={block?.classes}
      expandable={expandable}
      visible={visible}
      config={config}
    >
      <AntTimeline mode={mode}>
        {timeLineItems.map((item: any, index: number) => (
          <AntTimeline.Item
            color={item.color}
            key={`timeline-${index}`}
            position={item.position}
          >
            <p dangerouslySetInnerHTML={{ __html: item.label }} />
            <span dangerouslySetInnerHTML={{ __html: item.children }} />
          </AntTimeline.Item>
        ))}
      </AntTimeline>
    </Card>
  );
};
