import { useMounted } from "@/hooks/use-mounted";
import { useRedirect } from "@/hooks/useRedirect";
import { Card } from "@components/dynamic/Card";
import Button from "@components/ui/buttons/Button";
import { useAction } from "@hooks/useAction";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import {
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Space,
} from "antd";
import { Rule } from "antd/lib/form";
import classNames from "classnames";
import { Check, NotePencil, X } from "phosphor-react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { TreeSelect } from "antd";

const RegexParser = require("regex-parser");
const { Option } = Select;

interface IProps {
  block: IBlock;
  scope: IScope[];
  loading: boolean;
  form: FormInstance<any>;
  type?: "static" | "lazyload";

  formState: any;
  setFormState: any;
  editInline?: boolean;

  onChange?: (value: any, variable: string) => void;
  visible?: boolean;
  disabled?: string;
}

type Option = {
  name: string;
  title: string;
  disabled?: boolean;
  selected?: boolean;
  options?: Option[];
};

interface IState {
  search: string;
  loading: boolean;
  result: Option[];
  allResult: { name: string; title: string }[];

  isValid: boolean;
  editMode: boolean;
  value: string;
  editedValue: string;
}

export const FieldDropdown: React.FC<IProps> = ({
  block,
  scope = [],
  form,
  formState,
  setFormState,
  onChange,
  type = "static",
  editInline,
  visible,
  disabled,
}) => {
  const { checkRedirect } = useRedirect();
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const config: IScope[] = block?.config || [];

  const variable = config.find((e) => e.name === "variable")?.value || "";
  const placeholder = config.find((e) => e.name === "placeholder")?.value || "";
  const required = config.find((e) => e.name === "required")?.value === "1";
  const pattern = config.find((e) => e.name === "pattern")?.value;
  const validation_message = config.find(
    (e) => e.name === "validation_message"
  )?.value;

  const graphql = config.find((e) => e.name === "graphql")?.value || "";
  const expanded = config.find((e) => e.name === "expanded")?.value === "1";
  const expandable = config.find((e) => e.name === "expandable")?.value === "1";

  const allowClear = config.find((e) => e.name === "allowClear")?.value === "1";
  const mode = config.find((e) => e.name === "mode")?.value as
    | "multiple"
    | "tags"
    | undefined;
  const multi_level =
    config.find((e) => e.name === "multi_level")?.value === "1";

  const options = config.find((e) => e.name === "options")?.array || [];

  const query_accessor =
    config.find((e) => e.name === "query_accessor")?.value || "";
  const options_attribute =
    config.find((e) => e.name === "options_attribute")?.value || "";
  const selected_attribute =
    config.find((e) => e.name === "selected_attribute")?.value || "";
  const selected = useRef(
    mode === "multiple"
      ? config
          .find((e) => e.name === "selected")
          ?.array?.map((one) => one.value) || []
      : config.find((e) => e.name === "selected")?.value || ""
  );

  const showsearch = config.find((e) => e.name === "showsearch")?.value === "1";
  const searchtype = config.find((e) => e.name === "searchtype")?.value as
    | "graphql"
    | string;
  const searchkey = config.find((e) => e.name === "searchkey")?.value || "";
  const can_do = config.find((e) => e.name === "can_do")?.value === "1";
  const scope_variables =
    config.find((e) => e.name === "scope_variables")?.array || [];

  const vars: string[] = scope_variables.map((one) => one.name);
  const auto_save = config.find((e) => e.name === "auto_save")?.value || "";
  const query = config.find((e) => e.name === "query")?.value;
  const hint_query = config.find((e) => e.name === "hint_query")?.value;
  const hint_variable = config.find((e) => e.name === "hint_variable")?.value;
  const hint_accessor = config.find((e) => e.name === "hint_accessor")?.value;

  const set_graphql = config.find((e) => e.name === "set_graphql")?.value;
  const set_query_accessor = config.find(
    (e) => e.name === "set_query_accessor"
  )?.value;
  const fill_graphql = config.find((e) => e.name === "fill_graphql")?.value;
  const fill_query_accessor = config.find(
    (e) => e.name === "fill_query_accessor"
  )?.value;
  const fill_variables =
    config.find((e) => e.name === "fill_variables")?.array || [];

  const fill_vars: string[] = fill_variables.map((one) => one.name);

  const inline =
    config.find((e) => e.name === "inline")?.value === "1" || false;

  const { doQuery, doMutateQraphQl, replaceFromScope } = useAction();

  const lazyDataLoaded = useRef(false);

  const [state, setState] = useState<IState>({
    search: "",
    loading: false,
    result: [],
    allResult: [],

    isValid: false,
    editMode: false,
    value: block?.body || "",
    editedValue: block?.body || "",
  });

  const getData = (q?: string) => {
    lazyDataLoaded.current = true;
    const newVars: any = {};
    vars.forEach((one) => {
      if (formState[one]) {
        newVars[one] = formState[one];
      }
    });
    if (type === "lazyload") {
      setState((s) => ({ ...s, loading: true }));
      doQuery({
        query: graphql,
        scope: block?.config,
        item: { [searchkey]: q, ...newVars },
      })
        .then((data: any) => {
          if (data?.data) {
            if (data.data[query_accessor]) {
              if (
                data.data[query_accessor] &&
                data.data[query_accessor][selected_attribute]
              ) {
                form.setFieldsValue({
                  [variable]:
                    data.data[query_accessor][selected_attribute] + "",
                });
              }

              if (mounted) {
                const options = data.data[query_accessor][options_attribute];
                let sel = "";
                options.forEach((option: Option) => {
                  if (option.selected) {
                    sel = option.name;
                  }
                  if (option.options) {
                    option.options.forEach((o) => {
                      if (o.selected) {
                        sel = o.name;
                      }
                    });
                  }
                });
                setTimeout(() => {
                  form.setFieldsValue({
                    [variable]: sel,
                  });
                  setFormState((s: any) => ({
                    ...s,
                    [variable]: sel,
                  }));
                  onChange && onChange(sel, variable);
                }, 500);
                setState((s) => ({
                  ...s,
                  loading: false,
                  result: options,
                  allResult: options,
                }));
              }
            }
            form.setFieldsValue({ [variable]: undefined });
          }
        })
        .finally(() => {
          setState((s) => ({
            ...s,
            loading: false,
          }));
        });
    }
  };

  const mounted = useMounted();
  const selectedSet = useRef(false);
  const prevFormState = useRef<{ [key: string]: string | boolean | number }>();

  useEffect(() => {
    if (
      graphql
      //  && !editInline
    ) {
      if (vars.length === 0 && !formState[variable]) {
        if (!lazyDataLoaded.current) {
          getData();
        }
      } else {
        let get = false;
        vars.map((one) => {
          if (
            typeof formState[one] !== "undefined" &&
            !!formState[one] &&
            formState[one] !== prevFormState?.current?.[one]
          ) {
            get = true;
          }
        });
        prevFormState.current = formState;
        if (get) {
          getData();
        }
      }
    } else if (type === "lazyload" && searchtype === "local") {
      setState((s) => ({
        ...s,
        result: options.map((e: any) => ({
          name: e.value,
          title: e.name,
        })),
      }));
    } else if (type === "static") {
      if (mounted && state.result.length === 0) {
        setState((s) => ({
          ...s,
          result: options.map((e: any) => ({
            name: e.value,
            title: e.name,
          })),
        }));
      }
      if (!selectedSet.current && !setFormState[variable]) {
        selectedSet.current = true;
        setVal();
      }
    }
    if (editInline) {
      if (!selectedSet.current && !setFormState[variable]) {
        selectedSet.current = true;
        setState((s) => ({
          ...s,
          result: options.map((e: any) => ({
            name: e.value,
            title: e.name,
          })),
        }));
        setVal();
      }
    }
  }, [graphql, formState, setFormState, onChange]);

  const setVal = useCallback(() => {
    if (selected.current && !formState[variable]) {
      form.setFieldsValue({ [variable]: selected.current });
      setFormState((s: any) => ({ ...s, [variable]: selected.current }));
      onChange && onChange(selected.current, variable);
    }
  }, [formState, selected.current, onChange]);

  const { rules } = useMemo(() => {
    const rules: Rule[] = [];

    if (pattern) {
      rules.push({
        pattern: new RegExp(RegexParser(pattern)),
        message: validation_message,
      });
    }
    if (required) {
      rules.push({
        required: required,
        message: `${block?.title || block?.label} is required.`,
      });
    }

    return { rules };
  }, [required, pattern]);

  const onSearch = (e: any) => {
    setState((s) => ({ ...s, search: e.target.value }));
    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(async () => {
      if (e.target.value) {
        if (graphql && type === "lazyload" && searchtype === "graphql") {
          getData(e.target.value);
        } else if (type === "lazyload" && searchtype === "local") {
          setState((s) => ({
            ...s,
            result: s.allResult.filter((res) =>
              res.title?.toLowerCase()?.includes(e.target.value.toLowerCase())
            ),
          }));
        } else if (type === "static") {
          setState((s) => ({
            ...s,
            result: options
              .filter(
                (e: any) =>
                  e.name
                    ?.toLowerCase()
                    ?.includes(e.target.value.toLowerCase()) ||
                  e.value?.toLowerCase()?.includes(e.target.value.toLowerCase())
              )
              .map((e: any) => ({
                name: e.value,
                title: e.name,
              })),
          }));
        }
      } else {
        if (graphql && type === "lazyload" && searchtype === "graphql") {
          getData();
        }
      }
    }, 500);
    setTimer(newTimer);
  };

  const renderChilds = (data: any) => {
    return data?.options?.map((one: any) => {
      if (one?.options?.length) {
        return {
          title: one.title,
          value: one.name,
          label: one.title,
          options: renderChilds(one),
          children: renderChilds(one),
          disabled: one.disabled,
        };
      }
      return {
        title: one.title,
        label: one.title,
        value: one.name,
        disabled: one.disabled,
      };
    });
  };

  const selectOptions = useMemo(() => {
    if (options.length > 0) {
      return options.map((op: any) => {
        return op.options && op.options.length > 0
          ? {
              title: op.title || op.name,
              label: op.title || op.name,
              value: op.value,
              disabled: op.disabled,
              options: renderChilds(op),
              children: renderChilds(op),
            }
          : {
              title: op.title || op.name,
              label: op.title || op.name,
              value: op.value,
              disabled: op.disabled,
            };
      });
    }
    return state.result?.map((op, index: number) => {
      return op.options && op.options.length > 0
        ? {
            title: op.title,
            label: op.title,
            value: op.name,
            disabled: op.disabled,
            options: renderChilds(op),
            children: renderChilds(op),
          }
        : {
            title: op.title,
            label: op.title,
            value: op.name,
            disabled: op.disabled,
          };
    });
  }, [state.result]);

  const onSelect = (e: any) => {
    setState((s) => ({ ...s, search: "" }));
  };

  const Wrapper = inline ? "span" : Card;

  const CardComponent = (
    <Card
      body={
        state.result
          ? state.result.find((one) => one.name === state.value)?.title || ""
          : ""
      }
      title={block?.title}
      expanded={expanded}
      classes={block?.classes}
      expandable={expandable}
      visible={visible}
      config={config}
      editBodyButton={
        can_do ? (
          <Button
            key="card-edit-action-edit-inline"
            // type="button"
            loading={state.loading}
            variant="primary"
            onClick={() => setState((s) => ({ ...s, editMode: true }))}
            icon={<NotePencil />}
          />
        ) : undefined
      }
    />
  );

  const InlineCardComponent = (
    <Card
      body={
        <Space>
          <Form.Item
            label={block?.title}
            required={required}
            className="inline-form-item"
          >
            {state.result
              ? state.result.find((one) => one.name === state.value)?.title ||
                ""
              : ""}
          </Form.Item>
        </Space>
      }
      bordered={false}
      cardProps={{
        bodyStyle: { padding: 0 },
      }}
      expanded={expanded}
      classes={block?.classes}
      expandable={expandable}
      visible={visible}
      config={config}
      editBodyButton={
        can_do ? (
          <Button
            key="card-edit-action-edit-inline"
            // type="button"
            loading={state.loading}
            variant="primary"
            onClick={() => setState((s) => ({ ...s, editMode: true }))}
            icon={<NotePencil />}
          />
        ) : undefined
      }
    />
  );

  const save = () => {
    if (set_graphql) {
      let query = "";

      if (required && !state.editedValue) return;

      if (pattern) {
        const expression = new RegExp(RegexParser(pattern));

        const isMatch = expression.test(state.editedValue);
        if (!isMatch) return;
      }

      query = replaceFromScope({
        scope: [{ name: variable, value: state.editedValue }],
        str: set_graphql + "",
        withQuate: false,
      });

      setState((s) => ({ ...s, loading: true }));

      doMutateQraphQl(query)
        .then(() => {
          checkRedirect(config);
          setState((s) => ({
            ...s,
            loading: false,
            editMode: false,
            value: s.editedValue,
          }));

          onChange && onChange(state.editedValue, variable);
        })
        .catch((e) => {
          setState((s) => ({ ...s, loading: false, editMode: false }));
        });
      return;
    }
    let query = "";

    if (required && !state.editedValue) return;

    if (pattern) {
      const expression = new RegExp(RegexParser(pattern));

      const isMatch = expression.test(state.editedValue);
      if (!isMatch) return;
    }

    query = replaceFromScope({
      scope: [{ name: variable, value: state.editedValue }],
      str: graphql + "",
      withQuate: false,
    });

    setState((s) => ({ ...s, loading: true }));

    doMutateQraphQl(query)
      .then(() => {
        checkRedirect(config);
        setState((s) => ({
          ...s,
          loading: false,
          editMode: false,
          value: s.editedValue,
        }));

        onChange && onChange(state.editedValue, variable);
      })
      .catch((e) => {
        setState((s) => ({ ...s, loading: false, editMode: false }));
      });
  };
  const cancel = () => {
    setState((s) => ({ ...s, editMode: false, editedValue: s.value }));
  };

  const Required = () => {
    return (
      !state.editedValue && (
        <div className="ant-form-item-explain ">
          <div role="alert" className="ant-form-item-explain-error">
            {variable} is required.
          </div>
        </div>
      )
    );
  };
  const Pattern = () => {
    if (pattern) {
      const expression = new RegExp(RegexParser(pattern));

      const isMatch = expression.test(state.editedValue);

      return (
        !isMatch && (
          <div className="ant-form-item-explain ">
            <div role="alert" className="ant-form-item-explain-error">
              {validation_message}
            </div>
          </div>
        )
      );
    } else return null;
  };

  const onFieldChange = (val: string | null) => {
    if (!val) {
      return;
    }

    if (fill_graphql) {
      let q = "";
      const scopeVariables: any = [];

      Object.keys(formState).map((key) => {
        if (key !== variable) {
          scopeVariables.push({ name: key, value: formState[key] });
        }
      });

      q = replaceFromScope({
        scope: [...scopeVariables, { name: variable, value: val }],
        str: fill_graphql + "",
        withQuate: true,
      });

      doQuery({
        query: q,
      })
        .then((res: any) => {
          const data = res.data[fill_query_accessor!]?.variables as Array<{
            key: string;
            value: string;
          }>;
          data.forEach((one) => {
            if (fill_vars.includes(one.key)) {
              form.setFieldsValue({ [one.key]: one.value });
              setFormState((s: any) => ({ ...s, [one.key]: one.value }));
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (hint_query && hint_accessor) {
      let q = "";
      const scopeVariables: any = [];

      Object.keys(formState).map((key) => {
        if (key !== variable) {
          scopeVariables.push({ name: key, value: formState[key] });
        }
      });

      q = replaceFromScope({
        scope: [...scopeVariables, { name: variable, value: val }],
        str: hint_query + "",
        withQuate: true,
      });

      doQuery({
        query: q,
      })
        .then((res: any) => {
          setFormState((s: any) => ({
            ...s,
            [`${hint_variable}_hint`!]: res.data[hint_accessor].body,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (auto_save && auto_save === "on_change") {
      let q = "";

      if (pattern) {
        const expression = new RegExp(RegexParser(pattern));

        const isMatch = expression.test(val);
        if (!isMatch) return;
      }

      q = replaceFromScope({
        scope: [{ name: variable, value: val }],
        str: query + "",
        withQuate: false,
      });

      doMutateQraphQl(q)
        .then(() => {
          checkRedirect(config);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return editInline ? (
    state.editMode ? (
      <Wrapper
        body={""}
        title={inline ? block?.title : ""}
        expanded={expanded}
        classes={block?.classes}
        expandable={expandable}
        visible={visible}
        config={config}
      >
        <Row wrap={false} gutter={[16, 16]}>
          <Col flex="auto">
            <Form.Item
              label={block?.title || block?.label}
              name={variable}
              rules={rules}
              className={classNames(
                visible === false && "d-none",
                inline && "inline-form-item"
              )}
            >
              {multi_level ? (
                <TreeSelect
                  showSearch
                  style={{ width: "100%" }}
                  value={state.editedValue}
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  placeholder={placeholder}
                  allowClear
                  treeDefaultExpandAll
                  onChange={(value) => {
                    setState((s) => ({ ...s, editedValue: value }));
                  }}
                  treeData={selectOptions}
                />
              ) : (
                <Select
                  id={block?.name}
                  filterOption={false}
                  disabled={disabled === "1"}
                  loading={state.loading}
                  style={{ width: "100%" }}
                  mode={mode}
                  allowClear={allowClear}
                  placeholder={placeholder}
                  onSearch={onSearch}
                  onSelect={onSelect}
                  value={state.editedValue}
                  onChange={(value) => {
                    setState((s) => ({ ...s, editedValue: value }));
                  }}
                  options={selectOptions}
                  showSearch={false}
                  dropdownRender={(menu) => (
                    <React.Fragment>
                      {showsearch && (
                        <React.Fragment>
                          <Space
                            align="center"
                            style={{ padding: "0 8px 4px" }}
                          >
                            <Input
                              placeholder="Search ..."
                              onChange={onSearch}
                              value={state.search}
                            />
                          </Space>
                          <Divider style={{ margin: "8px 0" }} />
                        </React.Fragment>
                      )}
                      {menu}
                    </React.Fragment>
                  )}
                />
              )}
            </Form.Item>
          </Col>
          <Col flex="none">
            <Space direction="horizontal" style={{}}>
              <Button
                variant="primary"
                type="primary"
                icon={<Check />}
                width={10}
                onClick={save}
                loading={state.loading}
              ></Button>
              <Button
                variant="danger"
                type="primary"
                icon={<X />}
                width={10}
                disabled={state.loading}
                onClick={cancel}
              ></Button>
            </Space>
          </Col>
        </Row>

        {Required()}
        {Pattern()}
      </Wrapper>
    ) : inline ? (
      InlineCardComponent
    ) : (
      CardComponent
    )
  ) : (
    <Form.Item
      label={block?.title || block?.label}
      name={variable}
      rules={rules}
      required={required}
      className={classNames(
        visible === false && "d-none",
        inline && "inline-form-item"
      )}
    >
      {multi_level ? (
        <TreeSelect
          showSearch
          style={{ width: "100%" }}
          value={formState[variable] || ""}
          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
          placeholder={placeholder}
          allowClear
          treeDefaultExpandAll
          onChange={(value) => {
            const val = value === "undefined" ? null : value;

            form.setFieldsValue({ [variable]: val });
            setFormState((s: any) => ({ ...s, [variable]: val }));
            onFieldChange(val);
            onChange && onChange(val, variable);
          }}
          treeData={selectOptions}
        />
      ) : (
        <Select
          id={block?.name}
          filterOption={false}
          loading={state.loading}
          disabled={disabled === "1"}
          style={{ width: "100%" }}
          mode={mode}
          allowClear={allowClear}
          virtual={true}
          placeholder={placeholder}
          onSearch={onSearch}
          onSelect={onSelect}
          onChange={(value) => {
            const val = value === "undefined" ? null : value;

            form.setFieldsValue({ [variable]: val });
            setFormState((s: any) => ({ ...s, [variable]: val }));
            onFieldChange(val);
            onChange && onChange(val, variable);
          }}
          options={selectOptions}
          showSearch={false}
          dropdownRender={(menu) => (
            <React.Fragment>
              {showsearch && (
                <React.Fragment>
                  <Space align="center" style={{ padding: "0 8px 4px" }}>
                    <Input
                      placeholder="Search ..."
                      onChange={onSearch}
                      value={state.search}
                    />
                  </Space>
                  <Divider style={{ margin: "8px 0" }} />
                </React.Fragment>
              )}
              {menu}
            </React.Fragment>
          )}
          value={
            mode === "multiple"
              ? formState[variable] || []
              : formState[variable] || ""
          }
        />
      )}
    </Form.Item>
  );
};
