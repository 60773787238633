import { combineReducers } from "redux";
import { AuthReducer } from "./auth.reducer";
import { ConfigReducer } from "./config.reducer";

import { FormSlice } from "./form.reducer";
import { ModalSlice } from "./modal.reducer";
import { NotificationReducer } from "./notification.reducer";
import { SocketSlice } from "./socket.reducer";
import { ThemeReducer } from "./theme.reducer";
import { sideMenuReducer } from "./side-menu.reducer";

export const RootReducer = combineReducers({
  // root: Reducer,
  auth: AuthReducer,
  notification: NotificationReducer,
  theme: ThemeReducer,

  modal: ModalSlice.reducer,
  socket: SocketSlice.reducer,
  form: FormSlice.reducer,
  config: ConfigReducer,
  sideMenu: sideMenuReducer,
});

export type RootState = ReturnType<typeof RootReducer>;
