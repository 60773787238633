import { IScope } from "@/interfaces/scope.interface";
import { GET_PAGE } from "@/queries";
import { useLazyQuery } from "@apollo/client";
import { Loading } from "@components/dynamic/Loading";
import { Panel } from "@components/Panel";
import { Col, Row } from "antd";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

type Props = {
  name: string;
  style?: React.CSSProperties;
  page_params?: IScope[];
};

export const PageComponent: React.FC<Props> = ({
  name,
  style,
  page_params = [],
}) => {
  const [searchParams] = useSearchParams();

  const query = React.useMemo(() => {
    let q: any[] = [];

    for (const entry of searchParams.keys()) {
      q.push({
        name: entry,
        value: searchParams.get(entry),
      });
    }

    return q;
  }, [searchParams.keys()]);

  const [data, setData] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(true);

  const [selectQuery] = useLazyQuery(GET_PAGE, {
    variables: {
      name,
      params: [
        ...query,
        ...page_params.map((e) => ({ name: e.name, value: e.value })),
      ],
    },

    // context: { headers: { "x-extra-data": search } },
  });

  const getData = async () => {
    try {
      setLoading(true);
      const res = await selectQuery();
      if (res.data) {
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (name) {
      getData();
    }
  }, [name]);

  return (
    <div>
      {loading && <Loading />}
      <Row
        style={{ marginTop: 20, marginBottom: 20, ...style }}
        gutter={[16, 16]}
        className={"main-row"}
      >
        {data?.getPage?.panels?.map((panel: any, index: number) => {
          return (
            <Col
              span={24}
              key={`col-panel-${index}-${panel?.name}`}
              id={panel?.name}
            >
              <Panel
                loading={loading}
                scope={data?.getPage?.scope ? data?.getPage?.scope : []}
                panel={panel}
                pageName={name}
              />
            </Col>
          );
        })}
      </Row>
    </div>
  );
};
