import { Card } from "@components/dynamic/Card";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import React from "react";

export const VideoPlayer: React.FC<{
  block: IBlock;
  loading: boolean;
  visible?: boolean;
}> = ({ block, loading, visible }) => {
  const config: IScope[] = block?.config || [];
  const expandable =
    config.find((e: any) => e.name === "expandable")?.value === "1";
  const expanded =
    config.find((e: any) => e.name === "expanded")?.value === "1";
  const autoplay =
    config.find((e: any) => e.name === "autoplay")?.value === "1";
  const url = config.find((e: any) => e.name === "url")?.value;
  const allow_download =
    config.find((e: any) => e.name === "allow_download")?.value === "1";

  return (
    <Card
      body={""}
      title={block?.title}
      expanded={expanded}
      classes={block?.classes}
      expandable={expandable}
      loading={loading}
      visible={visible}
      config={config}
    >
      <video
        controlsList={!allow_download ? "nodownload" : ""}
        className="w-100"
        src={url}
        controls
        autoPlay={autoplay}
      />
    </Card>
  );
};
