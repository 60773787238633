import { Card } from "@components/dynamic/Card";
import { useLazyloadQuery } from "@hooks/useLazyloadQuery";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import { List } from "antd";
import React, { memo } from "react";

export const LazyloadIterator: React.FC<{
  block: IBlock;
  scope: IScope[];
  loading: boolean;
  visible?: boolean;
}> = memo(({ block, scope = [], visible }) => {
  const config: IScope[] = block?.config ? block?.config : [];

  const expandable =
    config.find((e: any) => e.name === "expandable")?.value === "1";
  const expanded =
    config.find((e: any) => e.name === "expanded")?.value === "1";

  const { allResult: result, loading } = useLazyloadQuery({
    query:
      (config.find((e: IScope) => e.name === "graphql")?.value
        ? config.find((e: IScope) => e.name === "graphql")?.value
        : "") + "",
    scope: config,
    variables: {},
  });

  return (
    <Card
      scope={scope}
      params={result}
      body={block.body}
      title={block?.title}
      expanded={expanded}
      classes={block?.classes}
      expandable={expandable}
      loading={loading}
      visible={visible}
      config={config}
    >
      <List
        itemLayout="horizontal"
        dataSource={result?.list_attr || []}
        renderItem={(item: any) => (
          <List.Item>
            <List.Item.Meta
              // avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
              title={<h6>{item?.name}</h6>}
              description={item?.description}
            />
          </List.Item>
        )}
      />
    </Card>
  );
});
