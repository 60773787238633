import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import { persistStore } from "redux-persist";
import { RootReducer } from "./reducers/root.reducer";

const Store = configureStore({
  reducer: RootReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({ serializableCheck: false }),
    // logger,
  ],
  devTools: true,
});

export type AppDispatch = typeof Store.dispatch;
export const persistor = persistStore(Store);

const { getState, dispatch } = Store;

export { Store, getState, dispatch };
