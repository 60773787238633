import { useRef, useState } from "react";
import PubSub from "pubsub-js";
type IConfig = {
  array?: [] | null;
  name: string;
  type: null | string;
  value: string;
};

export type KeyboardAction = {
  name: string;
  type: string;
  href: string;
  target: string;
  action_type: string;
  config: IConfig[];
};

type IPageForm = {
  [key: string]: any;
};

export const usePageTracker = () => {
  const [actions, setActions] = useState([]);

  const pages = useRef<IPageForm>({});
  const registerPage = (pageName: string, ref: any) => {
    pages.current[pageName] = ref;
    PubSub.publish("add_page", { pageName, ref });
  };

  const registerActions = (config: any) => {
    setActions(config);
    PubSub.publish("add_action", config);
  };

  const registerSubmit = (config: any) => {
    PubSub.publish("add_submit", config);
  };

  const registerFormState = (config: any) => {
    PubSub.publish("add_form_state", config);
  };

  return {
    registerActions,
    registerPage,
    registerSubmit,
    registerFormState,
    actions,
    pages,
  };
};
