import { LOGIN_OTP, SEND_LOGIN_OTP } from "@/queries";
import { useMutation } from "@apollo/client";
import { Captcha } from "@components/auth/Captcha";
import { FacebookButton } from "@components/auth/FacebookButton";
import { GoogleButton } from "@components/auth/GoogleButton";
import { Otp } from "@components/auth/Otp";
import { useUtiles } from "@hooks/useUtiles";
import { AuthActions } from "@store/reducers/auth.reducer";
import { Button, Checkbox, Col, Form, Modal, Row, Space, Spin } from "antd";
import MaskedInput from "antd-mask-input";
import { Phone } from "phosphor-react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { PortalPage } from "./PortalPage";

type Props = {};
export const SigninOtp: React.FC<Props> = ({}) => {
  const [state, setState] = useState({
    previewOtpModal: false,
    phone: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [sendOtp, sendOtpResults] = useMutation(SEND_LOGIN_OTP);
  const [loginOtp, loginOtpResults] = useMutation(LOGIN_OTP);
  const [form] = Form.useForm();
  const { consoleLog } = useUtiles();

  const onFinish = (values: any) => {
    const phone = `+9${values.phone}`;

    sendOtp({ variables: { phone } })
      .then((data) => {
        if (data.data.send_login_otp === "OK") {
          setState((s) => ({
            ...s,
            previewOtpModal: true,
            phone,
          }));
        }

        // dispatch(AuthActions.login({ accessToken :  data.data.login,user: {}}));
      })
      .catch((e) => {
        consoleLog({ e });
      });
  };

  const verifyOtp = (otp: string) => {
    loginOtp({
      variables: {
        otp,
        phone: state.phone,
      },
    })
      .then((data) => {
        dispatch(
          AuthActions.login({ accessToken: data.data.login_by_otp, user: {} })
        );
      })
      .catch((e) => {
        consoleLog({ e });
      });
  };

  const closeOtpModal = () => {
    setState((s) => ({ ...s, previewOtpModal: false }));
  };
  return (
    <PortalPage>
      <Space align="center" className="auth-form w-100">
        <Row className="w-100">
          <Col span={12} offset={6}>
            <Form
              form={form}
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
            >
              <Form.Item
                name="phone"
                rules={[
                  { required: true, message: "Please input your phone!" },
                  {
                    len: 11,
                    message: "Please input valid phone!",
                  },
                ]}
              >
                <MaskedInput
                  value={form.getFieldValue("phone")}
                  mask="+(90) 000-000-0000"
                  name="phone"
                  // size="20"
                  onChange={({ maskedValue, unmaskedValue }) => {
                    form.setFieldsValue({ phone: `${unmaskedValue}` });
                  }}
                  prefix={<Phone className="site-form-item-icon" />}
                  placeholder="phone"
                />
              </Form.Item>

              <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>

                <Link className="login-form-forgot" to="/forget-password">
                  Forgot password
                </Link>
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  size="small"
                  disabled={sendOtpResults.loading}
                  loading={sendOtpResults.loading}
                >
                  send otp
                </Button>
              </Form.Item>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Link to={"/signup"}> for new account register here </Link>
                </Col>
                <Col span={24}>
                  <Space>
                    <GoogleButton buttonText="login with google" />
                    <FacebookButton buttonText="login with facebooks" />
                  </Space>
                </Col>
              </Row>

              <Captcha />
            </Form>
          </Col>
        </Row>

        <Modal
          visible={state.previewOtpModal}
          title={"Verify Otp"}
          footer={null}
          onCancel={closeOtpModal}
        >
          <Otp value="" onChange={verifyOtp} />

          {loginOtpResults.loading && <Spin />}
          {/* <img alt="" style={{ width: "100%" }} src={state.previewVisible} /> */}
        </Modal>
      </Space>
    </PortalPage>
  );
};
