import { Card } from "@components/dynamic/Card";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import classNames from "classnames";
import React from "react";

export const IFrame: React.FC<{
  block: IBlock;
}> = ({ block }) => {
  const config: IScope[] = block?.config || [];

  const expandable =
    config.find((e: any) => e.name === "expandable")?.value === "1";
  const expanded =
    config.find((e: any) => e.name === "expanded")?.value === "1";
  const url = config.find((e: any) => e.name === "url")?.value;

  if (!url) {
    return null;
  }

  return (
    <Card
      body={block.body}
      title={block?.title}
      expanded={expanded}
      classes={classNames(block?.classes)}
      expandable={expandable}
      config={config}
    >
      <iframe
        src={url}
        style={{ width: "100%", height: "100%", border: "none" }}
      ></iframe>
    </Card>
  );
};
