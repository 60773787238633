import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import { Form, FormInstance, Radio, Space } from "antd";
import { Rule } from "antd/lib/form";
import classNames from "classnames";
import React, { useMemo } from "react";

const RegexParser = require("regex-parser");

interface IProps {
  block: IBlock;
  scope: IScope[];
  loading: boolean;
  form: FormInstance<any>;
  onChange?: (value: any, variable: string) => void;
  visible?: boolean;
}

export const RadioGroup: React.FC<IProps> = ({
  block,
  scope = [],
  form,
  onChange,
  visible,
}) => {
  const config = block?.config || [];

  const variable = config.find((e) => e.name === "variable")?.value || "";
  const direction = config.find((e) => e.name === "direction")?.value as
    | "vertical"
    | "horizontal"
    | undefined;
  const options = config.find((e) => e.name === "options")?.array || [];
  const required = config.find((e) => e.name === "required")?.value === "1";
  const pattern = config?.find((e) => e.name === "pattern")?.value;
  const validation_message = config?.find(
    (e) => e.name === "validation_message"
  )?.value;

  const { rules } = useMemo(() => {
    const rules: Rule[] = [];

    if (pattern) {
      rules.push({
        pattern: new RegExp(RegexParser(pattern)),
        message: validation_message,
      });
    }
    if (required) {
      rules.push({
        required: required,
        message: `${block?.title} is required.`,
      });
    }

    return { rules };
  }, [required, pattern]);

  return (
    <Form.Item
      name={variable}
      rules={rules}
      label={block?.title || block?.label}
      className={classNames(visible === false && "d-none")}
    >
      <Radio.Group
        id={block?.name}
        onChange={({ target }) => {
          form.setFieldsValue({ [variable]: target.value });
          onChange && onChange(target.value, variable);
        }}
        value={form.getFieldValue(variable)}
      >
        <Space direction={direction}>
          {options.map((op, index) => (
            <Radio key={`radio-op-${index}`} value={op.value}>
              {op.name}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </Form.Item>
  );
};
