import Table from "@components/ui/tables/Table";

export const Test = () => {
  return (
    <div>
      <h1>Test</h1>

      <Table />
    </div>
  );
};
