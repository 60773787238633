import { notification } from "antd";
import { NotificationPlacement } from "antd/lib/notification";

type NotificationType = "success" | "info" | "warning" | "error";

export const useUtiles = () => {
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    placement: NotificationPlacement = "bottomRight"
  ) => {
    notification[type]({
      message,
      description,
      placement,
    });
  };

  const copyToClipboard = (text: string) => navigator.clipboard.writeText(text);

  function isNumeric(str: string) {
    if (typeof str != "string") return false; // we only process strings!
    return !isNaN(parseFloat(str)); // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  }

  const consoleLog = (message?: any, ...optionalParams: any[]) => {
    // if (process.env.NODE_ENV !== "production") {
    if (true) {
      console.log(message, ...optionalParams);
    }
  };

  return {
    copyToClipboard,
    isNumeric,
    openNotificationWithIcon,
    consoleLog,
  };
};
