import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/reducers/root.reducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const NAME = "sideMenu";
const SIZES = ["sm", "md", "lg"];
type ISideMenuState = {
  hasSideMenu: boolean;
  hasSideMenuOpen: boolean;
  page_name: string;
  tab_page_name: string;
  tabs?: { page_name: string; side_page_name: string }[];
  size: "sm" | "md" | "lg";
};
const initialState: ISideMenuState = {
  hasSideMenu: false,
  hasSideMenuOpen: true,
  page_name: "",
  tab_page_name: "",
  tabs: [],
  size: "sm",
};
export const SideMenuSlice = createSlice({
  name: NAME,
  initialState: initialState,
  reducers: {
    hasSideMenu: (state, action) => {
      return { ...state, hasSideMenu: action.payload.hasSideMenu };
    },
    toggle: (state) => {
      return { ...state, hasSideMenuOpen: !state.hasSideMenuOpen };
    },
    setPageName: (state, action) => {
      return { ...state, page_name: action.payload };
    },
    setTabPageName: (state, action) => {
      return { ...state, tab_page_name: action.payload };
    },
    setTabs: (state, action) => {
      return {
        ...state,
        tabs: state.tabs
          ? [
              ...state.tabs,
              {
                page_name: action.payload.page_name,
                side_page_name: action.payload.side_page_name,
              },
            ]
          : [
              {
                page_name: action.payload.page_name,
                side_page_name: action.payload.side_page_name,
              },
            ],
      };
    },
    resetTabs: (state) => {
      return {
        ...state,
        tabs: [],
      };
    },
    toggleSize: (state) => {
      return {
        ...state,
        size: (SIZES.indexOf(state.size) === SIZES.length - 1
          ? SIZES[0]
          : SIZES[SIZES.indexOf(state.size) + 1]) as "sm" | "md" | "lg",
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const SideMenuActions = SideMenuSlice.actions;
export const sideMenuSelector = (state: RootState) => state.sideMenu;

export const sideMenuReducer = persistReducer(
  {
    storage: storage,
    key: NAME,
    debug: false,
    whitelist: ["user", "accessToken"],
  },
  SideMenuSlice.reducer
);
