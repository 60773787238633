import { RenderPortalPage } from "@/components/RenderPortalPage";
import { useLogoSrc } from "@/hooks/useLogoSrc";
import { PORTAL_GUI } from "@/queries";
import { authSelector } from "@/store/reducers/auth.reducer";
import { getValFromArray } from "@/utils";
import { useQuery } from "@apollo/client";
import { Col, Layout, Menu, Row } from "antd";
import { Content, Footer, Header } from "antd/lib/layout/layout";
import classNames from "classnames";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

export const PortalPage: FC = ({ children }) => {
  const { authConfig, accessToken, sessionToken } = useSelector(authSelector);
  const { loading, error, data } = useQuery(PORTAL_GUI, {
    skip: !accessToken || !sessionToken,
  });
  const logoSrc = useLogoSrc();
  const { name } = useParams();
  const { t } = useTranslation();
  const { getPortalGUI } = data || {};
  const navigate = useNavigate();
  const navMenu = useMemo(() => {
    return (
      getPortalGUI?.navbar?.items.map((item: any) => {
        const { label, key } = item;
        return {
          label,
          key,
          onClick: () => navigate(`/${item.route}`),
        };
      }) || []
    );
  }, [getPortalGUI]);

  const gui_data = getPortalGUI?.gui_data || [];
  const width = getValFromArray(gui_data, "width");

  const loadPage = children || (!!name && !loading);

  return loadPage ? (
    <Layout className={classNames("layout")}>
      <Header className="header">
        <Row align="middle" justify="space-between">
          <Col span={2}>
            <Link className="logo" to={`/${authConfig?.default_page}`}>
              <img src={logoSrc} />
            </Link>
          </Col>
          <Col span={22}>
            <div className="d-flex head-menu">
              <Menu
                mode="horizontal"
                items={navMenu.concat({
                  label: t("login"),
                  key: "login",
                  onClick: () => navigate("/signin"),
                })}
                inlineCollapsed={false}
              />
            </div>
          </Col>
        </Row>
      </Header>
      <Layout
        style={{
          maxWidth: `${width || 100}%`,
          minWidth: `${width || 100}%`,
          margin: "0 auto",
        }}
        className={"portal-content"}
      >
        <Content className="site-layout-background page-content">
          {children || <RenderPortalPage name={name!} />}
          {data?.getPortalGUI?.footer && (
            <Footer
              className="footer-app"
              style={
                {
                  "--gridCol": data?.getPortalGUI?.footer?.columns?.length,
                } as React.CSSProperties
              }
            >
              {data?.getPortalGUI?.footer?.columns?.map(
                (column: any, ind: number) => (
                  <div key={ind}>
                    <h3>{column.title}</h3>
                    <ul>
                      {column?.list?.map((item: any, index: number) => (
                        <li key={`${ind}-${index}`}>
                          <Link to={item.route}>{item.label}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )
              )}
            </Footer>
          )}
        </Content>
      </Layout>
    </Layout>
  ) : null;
};
