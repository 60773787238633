import { Card } from "@components/dynamic/Card";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import React, { useEffect } from "react";
import ReactAudioPlayer from "react-audio-player";

export const AudioPlayer: React.FC<{
  block: IBlock;
  loading: boolean;
  visible?: boolean;
}> = ({ block, loading, visible }) => {
  const config: IScope[] = block?.config || [];
  const expandable =
    config.find((e: any) => e.name === "expandable")?.value === "1";
  const expanded =
    config.find((e: any) => e.name === "expanded")?.value === "1";
  const autoplay =
    config.find((e: any) => e.name === "autoplay")?.value === "1";
  const loop = config.find((e: any) => e.name === "loop")?.value === "1";
  const allow_download =
    config.find((e: any) => e.name === "allow_download")?.value === "1";

  const url = config.find((e: any) => e.name === "url")?.value;

  const ref = React.useRef<HTMLAudioElement>(null);

  // useEffect(() => {
  //   document.addEventListener("keydown", (e) => {
  //     ref?.current?.play();
  //   });
  //   return () => {
  //     document.removeEventListener("keydown", (e) => {
  //       ref?.current?.play();
  //     });
  //   };
  // }, [ref]);

  const sliderRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (ref.current && sliderRef.current) {
      sliderRef.current.addEventListener("input", () => {
        if (ref.current && sliderRef.current) {
          const currentTime =
            (ref.current.duration / 100) * Number(sliderRef.current.value);
          ref.current.currentTime = currentTime;
        }
      });
      ref.current.addEventListener("timeupdate", () => {
        if (ref.current && sliderRef.current) {
          const progress =
            (ref.current.currentTime / ref.current.duration) * 100;
          sliderRef.current.value = progress.toString();
        }
      });
    }
  }, [ref.current, sliderRef.current]);

  return (
    <>
      <style>{`
/* Styling for WebKit (Chrome, Safari) */
input[type="range"]::-webkit-slider-runnable-track {
    background: #ff0000; /* Change this to your desired progress color */
    height: 4px; /* Adjust the height as needed */
}

/* Styling for Firefox */
input[type="range"]::-moz-range-progress {
    background: #ff0000; /* Change this to your desired progress color */
    height: 4px; /* Adjust the height as needed */
}

`}</style>
      <Card
        body={""}
        title={block?.title}
        expanded={expanded}
        classes={block?.classes}
        expandable={expandable}
        loading={loading}
        visible={visible}
        config={config}
      >
        <audio
          controls
          controlsList={!allow_download ? "nodownload" : ""}
          ref={ref}
          autoPlay={autoplay}
          loop={loop}
        >
          <source src={url} type="audio/mpeg" />
        </audio>
        {/* <input type="range" ref={sliderRef} /> */}
      </Card>
    </>
  );
};
