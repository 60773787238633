// create a custom hook to check if the component is mounted
// this is useful for async calls
import { useEffect, useRef } from "react";
export function useMounted() {
  var mounted = useRef(true);
  useEffect(function () {
    return function () {
      mounted.current = false;
    };
  }, []);
  return mounted;
}
