import { themeSelector } from "@/store/reducers/theme.reducer";
import { Card } from "@components/dynamic/Card";
import { useAction } from "@hooks/useAction";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import React from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";

export const StaticDonutChartBlock: React.FC<{
  block: IBlock;
  scope: IScope[];
  loading?: boolean;
}> = ({ block, scope = [], loading }) => {
  const { replaceFromScope } = useAction();

  const config: IScope[] = block?.config ? block?.config : [];

  const data = config.find((e: any) => e.name === "data")?.array || [];

  const expandable =
    config.find((e: any) => e.name === "expandable")?.value === "1";
  const expanded =
    config.find((e: any) => e.name === "expanded")?.value === "1";

  const theme = useSelector(themeSelector).theme;

  return (
    <Card
      scope={scope}
      body={block.body}
      title={block?.title}
      expanded={expanded}
      classes={block?.classes}
      expandable={expandable}
      loading={loading}
      config={config}
    >
      {!!block?.body && (
        <p
          dangerouslySetInnerHTML={{
            __html: replaceFromScope({
              str: block?.body,
              scope,
              withQuate: false,
            }),
          }}
        ></p>
      )}

      <Chart
        options={{
          labels: data.map((e: any) => e.name),
          series: data.map((e: any) => Number(e.value)),
          theme: {
            mode: theme,
          },
          chart: {
            background: "transparent",
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        }}
        type="donut"
        series={data.map((e: any) => Number(e.value))}
      />
    </Card>
  );
};
