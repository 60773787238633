import * as Phosphor from "phosphor-react";
import React from "react";

const phosphoreIcons: any = Phosphor;

// export const Icon: React.FC<{ name?: string }> = ({ name = "", ...props }) => {
export const Icon: React.FC<{ name?: string; className?: string }> = ({
  name = "",
  ...props
}) => {
  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  let iconName = "";
  name.split("-").forEach((str, index) => {
    iconName = iconName + capitalizeFirstLetter(str);
  });



  const IconComponent = phosphoreIcons[iconName];

  if (!IconComponent) return null;

  // return <Phosphor.Activity {...props} />
  return <IconComponent {...props} />;

};
