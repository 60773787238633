import React from "react";

export type TeacherCamProps = {
  instructorName?: string;
};
const TeacherCam: React.FC<TeacherCamProps> = ({ instructorName }) => {
  return (
    <div className="cam ratio ratio-16x9">
      <img src="https://media.istockphoto.com/id/1207225054/video/happy-indian-business-woman-making-video-conference-call-in-office.jpg?s=640x640&k=20&c=MOI5M23fM64715H0Tlcrwv4MgRqF9eL12dzIjiuGPGE=" />
      <span className="name">
        instrtor: <span>{instructorName}</span>
      </span>
    </div>
  );
};

export default TeacherCam;
