// DYNAMIC_LIST

import { DYNAMIC_LIST } from "@/queries";
import { useLazyQuery } from "@apollo/client";
import { Card } from "@components/dynamic/Card";
import { Loading } from "@components/dynamic/Loading";
import { Filters } from "@components/Filters";
import Button from "@components/ui/buttons/Button";
import { useRender } from "@hooks/useRender";
import { IBlock } from "@interfaces/block.interface";
import { IFilter } from "@interfaces/filter.interface";
import { IScope } from "@interfaces/scope.interface";
import {
  Col,
  FormInstance,
  PageHeader,
  Pagination,
  PaginationProps,
  Row,
} from "antd";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { config as Settings } from "@/config";
import classNames from "classnames";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { formSelector } from "@/store/reducers/form.reducer";
import { ModalActions, modalSelector } from "@/store/reducers/modal.reducer";

interface IProps {
  block: IBlock;
  scope: IScope[];
  form: FormInstance<any>;

  formState: any;
  setFormState: any;

  onChange?: (value: any, variable: string) => void;
  visible?: boolean;
  params?: {
    name: string;
    source: string | any;
  }[];
}

type IState = {
  list: any[];
  count: number;
  currentPage: number;
  hasMorePages: boolean;
  lastPage: number;
  perPage: number;
  total: number;
  loadMore: boolean;
  loading: boolean;
  filters: IFilter[];
  params?: any[];
};

export const DynamicList: React.FC<IProps> = ({
  block,
  scope = [],
  form,
  setFormState,
  formState,
  onChange,
  visible,
  params = [],
}) => {
  const { RenderBlocks } = useRender();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const config = (block?.config || []) as IScope[];
  const expandable = config.find((e) => e.name === "expandable")?.value === "1";
  const expanded = config.find((e) => e.name === "expanded")?.value === "1";
  const filter_expandable =
    config.find((e) => e.name === "filter_expandable")?.value === "1";
  const filter_expanded =
    config.find((e) => e.name === "filter_expanded")?.value === "1";
  const filters = (block?.filters || []) as IScope[];
  const pagination_type = config.find((e) => e.name === "pagination_type")
    ?.value as "see_more" | "pages";
  const current_page =
    parseInt(config.find((e) => e.name === "current_page")?.value || "") || 1;
  const per_page =
    parseInt(config.find((e) => e.name === "per_page")?.value || "") || 10;
  const page_size_options =
    config
      .find((e) => e.name === "page_size_options")
      ?.array?.map((e) => e.value) || Settings.page_size_options;

  scope = [...scope, ...(block.scope || [])];

  const getQuery = () => {
    const query: any[] = [];

    for (const entry of searchParams.keys()) {
      query.push({
        name: entry,
        value: searchParams.get(entry),
      });
    }

    return query;
  };

  const [state, setState] = useState<IState>({
    list: [],
    loadMore: false,
    count: 0,
    currentPage: current_page,
    hasMorePages: false,
    lastPage: 1,
    perPage: per_page,
    total: 0,
    loading: true,
    filters: [],
  });

  const [getData, { data, loading, error }] = useLazyQuery(DYNAMIC_LIST, {
    variables: {
      name: block.name,
      config: {
        page: 1,
        perPage: 10,
        filters: [] as IFilter[],
        params: [],
      },
      // name :'user_notifications'// block.name,
    },
  });

  const { lastInput } = useSelector(formSelector);
  const { reload_list } = useSelector(modalSelector);
  const lastInputRef = React.useRef<any>();

  React.useEffect(() => {
    if (reload_list) {
      dispatch(ModalActions.toggleModal({ reload_list: false }));
      setTimeout(() => {
        getAllList({});
      }, 100);
    }
  }, [reload_list]);

  React.useEffect(() => {
    lastInputRef.current = lastInput;
  }, [lastInput]);

  useEffect(() => {
    if (block.name) {
      getAllList({});
    }
  }, [block?.name]);

  const getAllList = ({
    page = state.currentPage,
    perPage = state.perPage,
    filters = state.filters,
  }: {
    page?: number;
    perPage?: number;
    filters?: IFilter[];
  }) => {
    getData({
      variables: {
        name: block.name,
        config: {
          page,
          perPage,
          filters,
          params: [...new Set([...getQuery(), ...params])] as any,
        },
      },
    }).then(({ data }) => {
      setState((s) => ({
        ...s,
        list: data?.listingProvider?.data || [],
        count: data?.listingProvider?.pagination?.count,
        currentPage: page,
        hasMorePages: data?.listingProvider?.pagination?.hasMorePages,
        lastPage: data?.listingProvider?.pagination?.lastPage,
        perPage: data?.listingProvider?.pagination?.perPage,
        total: data?.listingProvider?.pagination?.total,
        loading: false,
      }));
      if (lastInputRef.current && lastInputRef.current?.focus) {
        setTimeout(() => {
          lastInputRef.current?.focus();
        }, 200);
      }
    });
  };

  const loadMore = () => {
    let q: any[] = [];

    for (const entry of searchParams.keys()) {
      q.push({
        name: entry,
        value: searchParams.get(entry),
      });
    }
    setState({ ...state, loadMore: true });
    getData({
      variables: {
        name: block.name,
        config: {
          page: state.currentPage + 1,
          perPage: state.perPage,
          filters: state.filters,
          params: [...new Set([...getQuery(), ...params])] as any,
        },
      },
    }).then(({ data }) => {
      setState({
        ...state,
        list: [...state.list, ...(data?.listingProvider?.data || [])],
        count: data?.listingProvider?.pagination?.count,
        currentPage: state.currentPage + 1,
        hasMorePages: data?.listingProvider?.pagination?.hasMorePages,
        lastPage: data?.listingProvider?.pagination?.lastPage,
        perPage: data?.listingProvider?.pagination?.perPage,
        total: data?.listingProvider?.pagination?.total,
        loading: false,
        loadMore: false,
      });
    });
  };

  const onChangePagenation: PaginationProps["onChange"] = (page, perPage) => {
    if (state.loading) return;
    let q: any[] = [];

    for (const entry of searchParams.keys()) {
      q.push({
        name: entry,
        value: searchParams.get(entry),
      });
    }
    setState({ ...state, loading: true });
    getData({
      variables: {
        name: block.name,
        config: {
          page,
          perPage,
          filters: state.filters,
          params: [...new Set([...getQuery(), ...params])] as any,
        },
      },
    }).then(({ data }) => {
      setState({
        ...state,
        list: data?.listingProvider?.data || [],
        count: data?.listingProvider?.pagination?.count,
        currentPage: page,
        hasMorePages: data?.listingProvider?.pagination?.hasMorePages,
        lastPage: data?.listingProvider?.pagination?.lastPage,
        perPage,
        total: data?.listingProvider?.pagination?.total,
        loading: false,
        // loadMore: false,
      });
    });
  };

  const onChangeFilters = (filters: IFilter[]) => {
    if (state.loading) return;
    setState((s) => ({ ...s, loading: true, filters }));
    getAllList({ filters, page: 1, perPage: state.perPage });
  };

  return (
    <div className={classNames(visible === false && "d-none")}>
      <div>
        {!!block?.title && <PageHeader title={block?.title}></PageHeader>}

        {filters.length > 0 && (
          <Card
            scope={scope}
            body={block?.body}
            classes={block?.classes + " table-filters-collapse opened mb-2"}
            loading={false}
            expanded={filter_expanded}
            expandable={filter_expandable}
            className="page-card"
            cardProps={{ bordered: false }}
            config={config}
          >
            <div className={`table-filters-collapse opened `}>
              <div className="table-filters-collapse-container">
                <Filters
                  filters={filters}
                  scope={scope}
                  loading={state.loading}
                  onChangeFilters={onChangeFilters}
                />
              </div>
            </div>
          </Card>
        )}
        {state.loading && <Loading />}
        <div className="" hidden={state.loading}>
          <Card
            scope={scope}
            body={block?.body}
            classes={block?.classes}
            loading={false}
            expanded={expanded}
            expandable={expandable}
            className="page-card"
            cardProps={{ bordered: false }}
            config={config}
          >
            <Row gutter={[16, 16]}>
              {state.list?.map((block: any, index: number) => {
                const expandable =
                  block?.config?.find((e: any) => e.name === "expandable")
                    ?.value === "1";
                const expanded =
                  block?.config?.find((e: any) => e.name === "expanded")
                    ?.value === "1";

                const head_style =
                  block?.config?.find((e: any) => e.name === "head_style")
                    ?.array || [];
                let headStyle: { [key: string]: string | number } = {};
                if (head_style.length) {
                  head_style.forEach((e: any) => {
                    headStyle[e.name] = e.value;
                  });
                }
                const body_style =
                  block?.config?.find((e: any) => e.name === "body_style")
                    ?.array || [];
                let bodyStyle: { [key: string]: string | number } = {};
                if (body_style.length) {
                  body_style.forEach((e: any) => {
                    bodyStyle[e.name] = e.value;
                  });
                }

                return (
                  <Col
                    span={block?.width}
                    // span={24}
                    key={`col-block-${index}-${block?.name}`}
                    id={block?.name}
                  >
                    {block?.childs?.length > 0 ? (
                      <Card
                        scope={scope}
                        title={block?.title}
                        body={block?.body}
                        classes={block?.classes}
                        loading={false}
                        expanded={expanded}
                        expandable={expandable}
                        config={config}
                        cardProps={{
                          headStyle: headStyle,
                          bodyStyle: bodyStyle,
                        }}
                      >
                        <Row gutter={[16, 16]}>
                          {block.childs?.map((child: any, index: number) => {
                            return (
                              <Col
                                span={child?.width}
                                key={`col-block-3-${index}-${child?.name}`}
                                id={child?.name}
                              >
                                {RenderBlocks({
                                  block: child,
                                  scope,
                                  form,
                                  formState,
                                  setFormState,
                                  loading: loading,
                                })}
                              </Col>
                            );
                          })}
                        </Row>
                      </Card>
                    ) : (
                      RenderBlocks({
                        block,
                        scope,
                        form,
                        formState,
                        setFormState,
                        loading: loading,
                      })
                    )}
                  </Col>
                );
              })}
            </Row>
          </Card>
        </div>

        {state.currentPage < state.lastPage &&
          pagination_type === "see_more" && (
            <Button
              loading={state.loadMore}
              onClick={loadMore}
              className="mt-2"
            >
              {t("loadMore")}
            </Button>
          )}
      </div>

      <Row justify="center" className="mt-2">
        <Col>
          {state.lastPage > 1 && pagination_type === "pages" && (
            <Pagination
              disabled={state.loading}
              showSizeChanger
              pageSizeOptions={page_size_options}
              // onShowSizeChange={onShowSizeChange}
              // defaultCurrent={state.currentPage}
              current={state.currentPage}
              total={state.total}
              pageSize={state.perPage}
              onChange={onChangePagenation}
              // showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              defaultPageSize={20}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};
