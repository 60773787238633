import { Col, FormInstance, Popconfirm, Row, Switch } from "antd";
import { SwitchSize } from "antd/lib/switch";
import React, { useState } from "react";
import { useAction } from "@hooks/useAction";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import classNames from "classnames";
import { Card } from "./Card";

interface IProps {
  block: IBlock;
  scope: IScope[];
  loading: boolean;
  form: FormInstance<any>;
  onChange?: (value: any, variable: string) => void;
  visible?: boolean;
  body?: string;
  title?: string;
}

export const Switcher: React.FC<IProps> = ({
  block,
  scope = [],
  form,
  onChange,
  visible,
  body,
  title,
}) => {
  const checked = scope.find((e) => e.name === "value")?.value === "on";

  const size = scope.find((e) => e.name === "size")?.value as
    | SwitchSize
    | undefined;

  const disabled = scope.find((e) => e.name === "disabled")?.value === "1";
  const need_confirm =
    scope.find((e) => e.name === "need_confirm")?.value === "1";
  const confirm_message = scope.find(
    (e) => e.name === "confirm_message"
  )?.value;
  const confirm_button = scope.find((e) => e.name === "confirm_button")?.value;
  const cancelText = scope.find((e) => e.name === "cancelText")?.value;
  const on_query = scope.find((e) => e.name === "on_query")?.value || "";
  const off_query = scope.find((e) => e.name === "off_query")?.value || "";

  const [state, setState] = useState({
    checked: checked,
    loading: false,
    popVisible: false,
  });

  const { doQuery, replaceFromScope } = useAction();

  const onChangeValue = (checked: boolean) => {
    setState((s) => ({ ...s, checked }));
    doQueryByValue(checked);
  };

  const doQueryByValue = async (checked: boolean) => {
    setState((s) => ({ ...s, loading: true }));

    const query = replaceFromScope({
      str: checked ? on_query : off_query,
      scope,
      withQuate: true,
    });

    doQuery({ query, scope }).then((data) => {
      setState((s) => ({ ...s, loading: false }));
    });
  };

  const onConfirm = () => {
    setState((s) => ({ ...s, popVisible: false }));
  };

  return need_confirm ? (
    <React.Fragment>
      <Popconfirm
        disabled={disabled}
        title={confirm_message}
        visible={state.popVisible}
        onVisibleChange={() => setState((s) => ({ ...s, popVisible: true }))}
        onConfirm={onConfirm}
        onCancel={() => setState((s) => ({ ...s, popVisible: false }))}
        okText={confirm_button}
        cancelText={cancelText}
        className={classNames(visible === false && "d-none")}
      >
        <Card title={title} body={""}>
          <Row justify="space-between">
            <Col>{body}</Col>
            <Col>
              <Switch
                disabled={disabled}
                checked={state.checked}
                loading={state.loading}
                onChange={onChangeValue}
                size={size}
                className={classNames(
                  block?.classes,
                  visible === false && "d-none"
                )}
              />
            </Col>
          </Row>
        </Card>
      </Popconfirm>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Card title={title} body={""}>
        <Row justify="space-between">
          <Col>{body}</Col>
          <Col>
            <Switch
              disabled={disabled}
              checked={state.checked}
              loading={state.loading}
              onChange={onChangeValue}
              size={size}
              className={classNames(
                block?.classes,
                visible === false && "d-none"
              )}
            />
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  );
};
