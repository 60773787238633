import { useSocket } from "@/hooks/useSocket";
import { ISocketData } from "@/interfaces/socket.interface";
import { SocketActions, socketSelector } from "@/store/reducers/socket.reducer";
import { dispatch } from "@/store/store";
import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import io from "socket.io-client";

export const Socket: React.FC<{ socket_data: ISocketData[] }> = ({
  socket_data,
}) => {
  const { connect } = useSocket();
  const { isConnected, socket } = useSelector(socketSelector);

  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {
        dispatch(SocketActions.setSocketState(true));
      });

      socket.on("disconnect", () => {
        dispatch(SocketActions.setSocketState(true));
      });

      return () => {
        if (socket) {
          socket.off("connect");
          socket.off("disconnect");
        }
      };
    }
  }, [socket]);

  const { endpoint, token } = useMemo(() => {
    const token = socket_data.find((e) => e.name === "token")?.value || "";
    const endpoint =
      socket_data.find((e) => e.name === "endpoint")?.value || "";
    // const email = socket_data.find((e) => e.name === "email")?.value || "";
    return {
      token,
      endpoint,
    };
  }, [socket_data]);

  useEffect(() => {
    if (endpoint && token) {
      connect({ endpoint, token });
    }
  }, [endpoint, token]);

  return null;
};
