import { Steps, StepsProps } from "antd";
import React from "react";
import { useAction } from "@hooks/useAction";
import { useLazyloadQuery } from "@hooks/useLazyloadQuery";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import { Card } from "@components/dynamic/Card";

const { Step } = Steps;

export const LazyloadStepbar: React.FC<{
  block: IBlock;
  scope: IScope[];
  loading: boolean;
  visible?: boolean;
}> = ({ block, scope = [], visible }) => {
  const { replaceFromScope } = useAction();
  const config: IScope[] = block?.config ? block?.config : [];
  const expandable =
    config.find((e: any) => e.name === "expandable")?.value === "1";
  const expanded =
    config.find((e: any) => e.name === "expanded")?.value === "1";
  const size = (config.find((e: any) => e.name === "size")?.value ||
    "default") as StepsProps["size"];

  const { allResult: result, loading } = useLazyloadQuery({
    query:
      (config.find((e: IScope) => e.name === "graphql")?.value
        ? config.find((e: IScope) => e.name === "graphql")?.value
        : "") + "",
    scope: config,
    variables: {},
  });

  return (
    <Card
      scope={scope}
      params={result}
      body={block.body}
      title={block?.title}
      expanded={expanded}
      classes={block?.classes}
      expandable={expandable}
      loading={loading}
      visible={visible}
      config={config}
    >
      <Steps
        size={size}
        current={result?.current}
        direction={result?.direction}
      >
        {result?.steps?.map((step: any, index: number) => {
          return (
            <Step
              key={`step-${step.name}-${index}`}
              title={step.title}
              subTitle={step.subtitle}
              description={step.description}
            />
          );
        })}
      </Steps>
    </Card>
  );
};
