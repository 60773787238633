import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import { Form, FormInstance, Radio } from "antd";
import { Rule } from "antd/lib/form";
import classNames from "classnames";
import React, { useMemo } from "react";

const RegexParser = require("regex-parser");

interface IProps {
  block: IBlock;
  scope: IScope[];
  loading: boolean;
  form: FormInstance<any>;
  state: any;
  onChange: (variable: string, value: any) => void;
  visible?: boolean;
  disabled?: string;
}

export const FieldRadioButton: React.FC<IProps> = ({
  block,
  scope = [],
  form,
  state,
  onChange,
  visible,
  disabled,
}) => {
  const variable =
    block?.config?.find((e) => e.name === "variable")?.value || "";
  const value = block?.config?.find((e) => e.name === "value")?.value || "";

  const required =
    block?.config?.find((e: any) => e.name === "required")?.value === "1";
  const pattern = block?.config?.find((e: any) => e.name === "pattern")?.value;
  const validation_message = block?.config?.find(
    (e: any) => e.name === "validation_message"
  )?.value;

  const { rules } = useMemo(() => {
    const rules: Rule[] = [];

    if (pattern) {
      rules.push({
        pattern: new RegExp(RegexParser(pattern)),
        message: validation_message,
      });
    }
    if (required) {
      rules.push({
        required: required,
        message: `${block?.title} is required.`,
      });
    }

    return { rules };
  }, [required, pattern]);

  return (
    <Form.Item
      className={classNames(visible === false && "d-none")}
      name={variable}
      rules={rules}
      key={Math.random().toString()}
    >
      <Radio
        id={block?.name}
        value={block?.title}
        name={variable}
        checked={state[variable] === value}
        disabled={disabled === "1"}
        onChange={({ target }) => {
          form.setFieldsValue({ [variable]: value });

          onChange && onChange(variable, value);
        }}
      >
        {block?.title}
      </Radio>
    </Form.Item>
  );
};
