import React from "react";

import { makeParams } from "@/utils";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import { Row } from "antd";
import classNames from "classnames";
import {
  URLSearchParamsInit,
  createSearchParams,
  useNavigate,
} from "react-router-dom";
import Button from "../ui/buttons/Button";
import { Icon } from "./Icon";
import { Card } from "./Card";

export const PageNavigator: React.FC<{
  block: IBlock;
  scope: IScope[];
}> = ({ block, scope = [] }) => {
  const config: IScope[] = block?.config ? block?.config : [];
  const visible = config.find((e: any) => e.name === "visible")?.value === "1";
  const next_label = config.find((e: any) => e.name === "next_label")?.value;
  const next_icon = config.find((e: any) => e.name === "next_icon")?.value;
  const next_color = config.find((e: any) => e.name === "next_color")?.value;

  const next_page_name = config.find(
    (e: any) => e.name === "next_page_name"
  )?.value;
  const next_page_params = config.find(
    (e: any) => e.name === "next_page_params"
  )?.array;
  const prev_label = config.find((e: any) => e.name === "prev_label")?.value;
  const prev_icon = config.find((e: any) => e.name === "prev_icon")?.value;
  const prev_color = config.find((e: any) => e.name === "prev_color")?.value;
  const prev_page_name = config.find(
    (e: any) => e.name === "prev_page_name"
  )?.value;
  const prev_page_params = config.find(
    (e: any) => e.name === "prev_page_params"
  )?.array;

  const navigate = useNavigate();

  if (!visible) return null;

  return (
    <Card
      scope={scope}
      body={""}
      title={""}
      classes={block?.classes}
      cardProps={{
        bodyStyle: { padding: "0px" },
      }}
      visible={visible}
      config={config}
    >
      <Row justify="space-between" align="middle">
        {prev_label || prev_icon ? (
          <Button
            disabled={!prev_page_name}
            onClick={() => {
              const params =
                prev_page_params?.map((one) => ({
                  value: one.value,
                  name: one.name,
                })) || [];

              navigate({
                pathname: `/page/${prev_page_name}`,
                search: createSearchParams(
                  makeParams(params) as URLSearchParamsInit
                ).toString(),
              });
            }}
            className={classNames(
              "btn-transparent",
              !prev_page_name && "disabled"
            )}
            {...(prev_color && { style: { color: prev_color } })}
          >
            <Icon name={prev_icon} />
            {prev_label}
          </Button>
        ) : (
          <div style={{ minWidth: 100 }} />
        )}
        <span>{block.title}</span>
        {next_label || next_icon ? (
          <Button
            disabled={!next_page_name}
            onClick={() => {
              const params =
                next_page_params?.map((one) => ({
                  value: one.value,
                  name: one.name,
                })) || [];

              navigate({
                pathname: `/page/${next_page_name}`,
                search: createSearchParams(
                  makeParams(params) as URLSearchParamsInit
                ).toString(),
              });
            }}
            className={classNames(
              "btn-transparent",
              !next_page_name && "disabled"
            )}
            {...(next_color && { style: { color: next_color } })}
          >
            {next_label}
            <Icon name={next_icon} />
          </Button>
        ) : (
          <div style={{ minWidth: 100 }} />
        )}
      </Row>
    </Card>
  );
};
