import { ApolloProvider } from "@apollo/client";
import i18n from "i18next";
import { useEffect } from "react";
import detector from "i18next-browser-languagedetector";
import { initReactI18next, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { useApollo } from "./hooks/useApollo";
import { useServiceWorker } from "./hooks/useServiceWorker";
import ar from "./i18n/ar.json";
import en from "./i18n/en.json";
import "./less/themes/light-theme.less";
import "./less/v.css";
import { Routes } from "./routers/routers";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { AuthActions, authSelector } from "./store/reducers/auth.reducer";

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    fallbackLng: localStorage.getItem("i18nextLng") || "en",
    debug: false,
    resources: {
      en: {
        translation: en,
      },
      ar: {
        translation: ar,
      },
    },
  });

const App = () => {
  const { apolloDashboardClient, apolloPortalClient, isLocalhost } =
    useApollo();
  const { getToken } = useServiceWorker();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { accessToken, sessionToken } = useSelector(authSelector);

  useEffect(() => {
    if (!isLocalhost) {
      if (navigator.serviceWorker) {
        if (
          !navigator.serviceWorker?.controller ||
          !navigator.serviceWorker?.controller?.postMessage
        ) {
          // TODO
          dispatch(AuthActions.setLoading(false));
          return;
          // window.location.reload();
        }
        serviceWorkerRegistration
          .register({})
          .then((registration) => {
            console.log({ registration });
            if (!sessionToken) {
              getTokenFunc();
            } else {
              dispatch(AuthActions.setLoading(false));
            }
          })
          .catch((e) => {
            console.log({ e });
            dispatch(AuthActions.setLoading(false));
          })
          .finally(() => {
            dispatch(AuthActions.setLoading(false));
          });
      } else {
        dispatch(AuthActions.setLoading(false));
      }
    } else {
      dispatch(AuthActions.setLoading(false));
    }
  }, [
    navigator.serviceWorker,
    // navigator.serviceWorker?.controller,
    // navigator.serviceWorker?.controller?.postMessage,
  ]);

  const getTokenFunc = async () => {
    getToken()
      .then((token) => {
        dispatch(
          AuthActions.login({ accessToken: token, user: {}, remember: false })
        );
        dispatch(AuthActions.setLoading(false));
      })
      .catch((e) => {
        dispatch(AuthActions.setLoading(false));
      })
      .finally(() => {
        dispatch(AuthActions.setLoading(false));
      });
  };

  return (
    <ApolloProvider
      client={
        accessToken || sessionToken ? apolloDashboardClient : apolloPortalClient
      }
    >
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </ApolloProvider>
  );
};

export default App;
