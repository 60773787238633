import { usePageTracker } from "@/hooks/usePageTracker";
import { IBlock } from "@/interfaces/block.interface";
import { Card } from "@components/dynamic/Card";
import { useRender } from "@hooks/useRender";
import { useUtiles } from "@hooks/useUtiles";
import { IScope } from "@interfaces/scope.interface";
import { Col, Form, PageHeader, Row } from "antd";
import classNames from "classnames";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

interface IProps {
  scope: IScope[];
  panel?: any;
  loading: boolean;
  pageName?: string;
}

export const Panel: React.FC<IProps> = ({
  scope,
  panel,
  loading,
  pageName,
}) => {
  const [form] = Form.useForm();
  const [formState, setFormState] = useState<any>({});
  const { RenderBlocks } = useRender();
  const { consoleLog } = useUtiles();
  const dispatch = useDispatch();
  const config = (panel?.config || []) as IScope[];
  const expandable = useRef<boolean>(
    config.find((e) => e.name === "expandable")?.value === "1"
  ).current;
  const expanded = useRef(
    config.find((e) => e.name === "expanded")?.value === "1"
  ).current;

  let visible = config.find((e) => e.name === "visible")?.value || "1";
  const hide_if = config.find((e) => e.name === "hide_if")?.array || [];
  const hide_if_value: any = {};
  hide_if.forEach((e) => {
    if (e.name) {
      if (!Object(hide_if_value).hasOwnProperty(e.name)) {
        hide_if_value[e.name] = [];
      }
      e.array?.forEach((one) => {
        hide_if_value[e.name]?.push(one.value);
      });
    }
  });
  Object.keys(hide_if_value).forEach((e) => {
    if (hide_if_value[e].includes(formState[e])) {
      visible = "0";
    }
  });

  const show_if = config.find((e) => e.name === "show_if")?.array || [];
  const show_if_value: any = {};
  show_if.forEach((e) => {
    if (e.name) {
      if (!Object(show_if_value).hasOwnProperty(e.name)) {
        show_if_value[e.name] = [];
      }
      e.array?.forEach((one) => {
        show_if_value[e.name]?.push(one.value);
      });
    }
  });
  Object.keys(show_if_value).forEach((e) => {
    if (show_if_value[e].includes(formState[e])) {
      visible = "1";
    }
  });

  const formStateRef = useRef<any>({});

  const makeFormState = (blocks: IBlock[]) => {
    blocks.forEach((block) => {
      if (block.childs?.length) {
        makeFormState(block.childs);
      } else {
        if (
          block.type === "field_input" ||
          block.type === "field_input_number" ||
          block.type === "field_textarea" ||
          block.type === "field_dropdown"
        ) {
          if (block.config.find((e) => e.name === "default")?.value) {
            form.setFieldsValue({
              [block.config.find((e) => e.name === "variable")?.value || ""]:
                block.config.find((e) => e.name === "default")?.value,
            });
            formStateRef.current[
              block.config.find((e) => e.name === "variable")?.value || ""
            ] = block.config.find((e) => e.name === "default")?.value;
          }
        }
        if (block.type === "field_checkbox") {
          form.setFieldsValue({
            [block.config.find((e) => e.name === "variable")?.value || ""]:
              block.config.find((e) => e.name === "default")?.value,
          });
          formStateRef.current[
            block.config.find((e) => e.name === "variable")?.value || ""
          ] = block.config.find((e) => e.name === "default")?.value;
        }
        if (block.type === "field_radiobutton") {
          form.setFieldsValue({
            [block.config.find((e) => e.name === "variable")?.value || ""]:
              block.config.find((e) => e.name === "default")?.value === "1"
                ? block.config.find((e) => e.name === "value")?.value
                : "",
          });
          if (block.config.find((e) => e.name === "default")?.value === "1") {
            formStateRef.current[
              block.config.find((e) => e.name === "variable")?.value || ""
            ] =
              block.config.find((e) => e.name === "default")?.value === "1"
                ? block.config.find((e) => e.name === "value")?.value
                : "";
          }
        }
      }
    });
    return formStateRef.current;
  };

  useEffect(() => {
    if (panel?.blocks?.length) {
      setFormState(makeFormState(panel.blocks));
    }
  }, [panel]);

  const { registerPage, registerFormState } = usePageTracker();

  useEffect(() => {
    if (pageName && form) {
      registerPage(pageName, form);
    }
  }, [pageName, form]);

  useEffect(() => {
    registerFormState(formState);
  }, [formState]);

  // consoleLog("panel?.blocks", panel?.blocks);

  // return (
  //   <>
  //     <Select defaultValue="Zhejiang">
  //       <Select.Option value="Zhejiang">Zhejiang</Select.Option>
  //       <Select.Option value="Jiangsu">Jiangsu</Select.Option>
  //     </Select>

  //     <DatePicker />
  //     <RangePicker />
  //   </>
  // );
  // return <RenderBlocksComponent loading={loading} scope={scope} block={panel}  />
  return (
    <React.Fragment>
      {!!panel?.title && (
        <PageHeader
          title={panel?.title}
          // breadcrumb={{ routes }}
          extra={
            [
              // <Button variant="success" types="flush" key="123">
              //   <Plus />
              //   Add new
              // </Button>,
            ]
          }
        ></PageHeader>
      )}
      <Card
        scope={scope}
        body={panel?.body}
        classes={panel?.classes}
        loading={loading}
        expanded={expanded}
        expandable={expandable}
        className="page-card"
        visible={visible === "1"}
        cardProps={{ bordered: false }}
        config={config}
      >
        <Form autoComplete={"off"} layout="vertical" form={form}>
          <Row gutter={[16, 16]}>
            {panel?.blocks?.map((block: any, index: number) => {
              const expandable =
                block?.config?.find((e: any) => e.name === "expandable")
                  ?.value === "1";
              const expanded =
                block?.config?.find((e: any) => e.name === "expanded")
                  ?.value === "1";

              let visible =
                block?.config.find((e: any) => e.name === "visible")?.value ||
                "1";
              const hide_if =
                block?.config.find((e: any) => e.name === "hide_if")?.array ||
                [];
              const hide_if_value: any = {};
              hide_if.forEach((e: any) => {
                if (e.name) {
                  if (!Object(hide_if_value).hasOwnProperty(e.name)) {
                    hide_if_value[e.name] = [];
                  }
                  e.array?.forEach((one: any) => {
                    hide_if_value[e.name]?.push(one.value);
                  });
                }
              });
              Object.keys(hide_if_value).forEach((e) => {
                if (hide_if_value[e].includes(formState[e])) {
                  visible = "0";
                }
              });
              const show_if =
                block?.config.find((e: any) => e.name === "show_if")?.array ||
                [];
              const show_if_value: any = {};
              show_if.forEach((e: any) => {
                if (e.name) {
                  if (!Object(show_if_value).hasOwnProperty(e.name)) {
                    show_if_value[e.name] = [];
                  }
                  e.array?.forEach((one: any) => {
                    show_if_value[e.name]?.push(one.value);
                  });
                }
              });
              Object.keys(show_if_value).forEach((e) => {
                if (show_if_value[e].includes(formState[e])) {
                  visible = "1";
                }
              });

              const justifyContent =
                block?.config.find((e: any) => e.name === "justify_content")
                  ?.value || "";

              const Wrapper = justifyContent ? "div" : Fragment;

              return (
                <Col
                  span={block?.width}
                  key={`col-block-${index}-${block?.name}`}
                  id={block?.name}
                  className={classNames(visible === "0" && "d-none")}
                >
                  {block?.childs?.length > 0 ? (
                    <Card
                      scope={scope}
                      title={block?.title}
                      body={block?.body}
                      classes={block?.classes}
                      loading={loading}
                      expanded={expanded}
                      expandable={expandable}
                      config={config}
                    >
                      <Row gutter={[16, 16]}>
                        {block.childs?.map((child: any, index: number) => {
                          let visible = child?.config.find(
                            (e: any) => e.name === "visible"
                          )?.value;
                          const justifyContent =
                            child?.config.find(
                              (e: any) => e.name === "justify_content"
                            )?.value || "";
                          const hide_if =
                            child?.config.find((e: any) => e.name === "hide_if")
                              ?.array || [];
                          const hide_if_value: any = {};
                          hide_if.forEach((e: any) => {
                            if (e.name) {
                              if (
                                !Object(hide_if_value).hasOwnProperty(e.name)
                              ) {
                                hide_if_value[e.name] = [];
                              }
                              e.array?.forEach((one: any) => {
                                hide_if_value[e.name]?.push(one.value);
                              });
                            }
                          });
                          Object.keys(hide_if_value).forEach((e) => {
                            if (hide_if_value[e].includes(formState[e])) {
                              visible = "0";
                            }
                          });
                          const show_if =
                            child?.config.find((e: any) => e.name === "show_if")
                              ?.array || [];
                          const show_if_value: any = {};
                          show_if.forEach((e: any) => {
                            if (e.name) {
                              if (
                                !Object(show_if_value).hasOwnProperty(e.name)
                              ) {
                                show_if_value[e.name] = [];
                              }
                              e.array?.forEach((one: any) => {
                                show_if_value[e.name]?.push(one.value);
                              });
                            }
                          });

                          Object.keys(show_if_value).forEach((e) => {
                            if (show_if_value[e].includes(formState[e])) {
                              visible = "1";
                            }
                          });

                          const Wrapper = justifyContent ? "div" : Fragment;

                          return (
                            <Col
                              span={child?.width}
                              key={`col-block-3-${index}-${child?.name}`}
                              id={child?.name}
                              className={classNames(
                                visible === "0" && "d-none"
                              )}
                            >
                              <Wrapper
                                {...(justifyContent
                                  ? {
                                      style: {
                                        display: "flex",
                                        justifyContent,
                                      },
                                    }
                                  : {})}
                              >
                                {RenderBlocks({
                                  block: child,
                                  scope,
                                  form,
                                  formState,
                                  setFormState,
                                  loading: loading,
                                })}
                              </Wrapper>
                            </Col>
                          );
                        })}
                      </Row>
                    </Card>
                  ) : (
                    <Wrapper
                      {...(justifyContent
                        ? {
                            style: {
                              display: "flex",
                              justifyContent,
                            },
                          }
                        : {})}
                    >
                      {RenderBlocks({
                        block,
                        scope,
                        form,
                        formState,
                        setFormState,
                        loading: loading,
                      })}
                    </Wrapper>
                  )}
                </Col>
              );
            })}
          </Row>
        </Form>
      </Card>
    </React.Fragment>
  );
};
