import { QUERY_HI } from "@/queries";
import { useLazyQuery } from "@apollo/client";
import { Card } from "@components/dynamic/Card";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import { FormInstance, Rate as ARate } from "antd";
import gql from "graphql-tag";
import React, { useState } from "react";

interface IProps {
  block: IBlock;
  scope: IScope[];
  loading: boolean;
  form: FormInstance<any>;

  formState: any;
  setFormState: any;

  onChange?: (value: any, variable: string) => void;
  visible?: boolean;
}

export const Rate: React.FC<IProps> = ({
  block,
  scope = [],
  form,
  setFormState,
  formState,
  onChange,
  visible,
  ...props
}) => {
  const config: IScope[] = block?.config || [];

  const query = config.find((e) => e.name === "query")?.value || "";
  const count = config.find((e) => e.name === "count")?.value;
  const defaultValue = config.find((e) => e.name === "defaultValue")?.value;
  const allowClear = config.find((e) => e.name === "allowClear")?.value === "1";
  const allowHalf = config.find((e) => e.name === "allowHalf")?.value === "1";
  // const required =  config.find((e) => e.name === "required")?.value === "1";
  const disabled = config.find((e) => e.name === "disabled")?.value === "1";
  // const pattern = config.find((e) => e.name === "pattern")?.value;
  // const validation_message = config.find( (e) => e.name === "validation_message" )?.value;

  const [state, setState] = useState({
    value: defaultValue ? parseInt(defaultValue) : 0,
  });

  // const { doQuery } = useAction();

  // const { rules } = useMemo(() => {
  //   const rules: Rule[] = [];

  //   if (pattern) {
  //     rules.push({
  //       pattern: new RegExp(RegexParser(pattern)),
  //       message: validation_message,
  //     });
  //   }
  //   if (required) {
  //     rules.push({
  //       required: required,
  //       message: `${block?.title || block?.label} is required.`,
  //     });
  //   }

  //   return { rules };
  // }, [required, pattern]);

  const [saveChanges, { loading, data, error, refetch }] = useLazyQuery(
    query ? gql(query) : QUERY_HI
  );

  return (
    <Card
      body={block?.body}
      title={block?.title}
      // expanded={expanded}
      classes={block?.classes}
      visible={visible}
      config={config}
      // expandable={expandable}
    >
      <ARate
        count={count ? parseInt(count) : undefined}
        allowClear={allowClear}
        allowHalf={allowHalf}
        defaultValue={defaultValue ? parseInt(defaultValue) : undefined}
        disabled={disabled || loading}
        value={state.value}
        onChange={(value) => {
          setState((s) => ({ ...s, value }));
          saveChanges();
        }}
        {...props}
      />

      {/* <Form.Item
      label={block?.title || block?.label}
      name={variable}
      rules={rules}
    >
      <ARate
        allowClear={allowClear}
        allowHalf={allowHalf}
        defaultValue={defaultValue ? parseInt(defaultValue) : undefined}
        disabled={disabled}
        value={formState[variable]}

        onChange={(value) => {

          form.setFieldsValue({ [variable]: value });
          setFormState((s: any) => ({ ...s, [variable]: value }));

          onChange && onChange(value, variable);
        }}
        {...props}
      />
    </Form.Item> */}
    </Card>
  );
};
