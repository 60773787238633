import { IScope } from "@/interfaces/scope.interface";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { RootState } from "./root.reducer";

const NAME = "modal";
type IState = {
  showModal?: boolean;
  modalPageName?: string;
  modalWidth?: string;
  modalePageParams?: IScope[];
  reload?: boolean;
  refresh?: boolean;
  page_name?: string;
  previous_page_name?: string;
  reload_datatable?: boolean;
  tabs_opened?: boolean;
  current_tab?: string;
  stack?: string[];
  showDrawer?: boolean;
  drawerPageName?: string;
  drawerWidth?: string;
  drawerPageParams?: IScope[];
  refreshSelf?: boolean;
  refreshParent?: boolean;
  submitLoading?: boolean;
  btn_uuid?: string;
  title?: string;
  refreshCurrent?: boolean;
  currentPage?: string;
  reload_list?: boolean;
};
const initialState: IState = {
  showModal: false,
  modalPageName: "",
  modalWidth: "60",
  reload: false,
  refresh: false,
  page_name: "",
  previous_page_name: "",
  reload_datatable: false,
  tabs_opened: false,
  current_tab: "",
  stack: [],
  showDrawer: false,
  drawerPageName: "",
  drawerWidth: "60",
  refreshSelf: false,
  refreshParent: false,
  submitLoading: false,
  btn_uuid: "",
  title: "",
  refreshCurrent: false,
  currentPage: "",
  reload_list: false,
};

const handleStack = (state: IState, action: PayloadAction<IState>) => {
  const stack = state.stack ? [...state.stack] : [];
  if (action.payload.page_name) {
    if (
      (action.payload.showDrawer === false && state.showDrawer) ||
      (action.payload.showModal === false && state.showModal)
    ) {
      stack.pop();
    } else {
      stack.push(action.payload.page_name);
    }
  }
  return [...new Set(stack)];
};

export const ModalSlice = createSlice({
  name: NAME,
  initialState: initialState,
  reducers: {
    toggleModal: (state, action: PayloadAction<IState>) => {
      return {
        ...state,
        ...action.payload,
        stack: handleStack(state, action),
      };
    },
    removeStack: (state, action) => {
      return {
        ...state,
        ...action.payload,
        stack: state.stack
          ? state.stack.filter((one) => one !== action.payload)
          : [],
      };
    },
    endLoading: (state) => {
      return {
        ...state,
        btn_uuid: "",
        submitLoading: false,
      };
    },
    startLoading: (state, action) => {
      return {
        ...state,
        btn_uuid: action.payload,
        submitLoading: true,
      };
    },
    setTitle: (state, action) => {
      return {
        ...state,
        title: action.payload,
      };
    },
  },
});

export const ModalActions = ModalSlice.actions;
export const modalSelector = (state: RootState) => state.modal;

export const ModalReducer = persistReducer(
  {
    storage: storage,
    key: NAME,
    debug: false,
    whitelist: [],
  },
  ModalSlice.reducer
);
