import { IBlock } from "@/interfaces/block.interface";
import { IScope } from "@/interfaces/scope.interface";
import { arrayOfAlphabets, replaceJsonDoubleQuotes } from "@/utils";
import { Col, Input, Row, Select } from "antd";
import { Plus, X } from "phosphor-react";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import short from "short-uuid";
import Button from "../ui/buttons/Button";
import { Card } from "./Card";
import { useAction } from "@/hooks/useAction";
import { Loading } from "./Loading";

export interface IWeeklyRow {
  name: string;
  course: Course;
  day: string;
  hour: string;
  session_duration: number;
  instructor: Instructor;
  room: Room;
  __typename: string;
}

export interface Course {
  id: string;
  name: string;
  __typename: string;
}

export interface Instructor {
  id: string;
  fullname: string;
  __typename: string;
}

export interface Room {
  id: string;
  name: string;
  __typename: string;
}

interface IWeeklyScheduleLinesEditor {
  block: IBlock;
  formState: any;
  visible?: boolean;
  setFormState: any;
}

interface IRow {
  id?: string;
  hour?: string;
  day?: string;
  teacher?: string;
  session_duration?: string;
  room?: string;
  branch?: string;
}

export const WeeklyScheduleLinesEditor: FC<IWeeklyScheduleLinesEditor> = ({
  block,
  visible = true,
  setFormState,
}) => {
  const config: IScope[] = block?.config || [];

  const hours_options =
    config.find((e) => e.name === "hours_options")?.array || [];
  const hoursOptions = useMemo(() => {
    return hours_options.map((one) => ({
      label: one.value,
      value: one.name,
      option: one,
    }));
  }, [hours_options]);

  const days_options =
    config.find((e) => e.name === "days_options")?.array || [];
  const daysOptions = useMemo(() => {
    return days_options.map((one) => ({
      label: one.value,
      value: one.name,
      option: one,
    }));
  }, [days_options]);

  const defaultOption: IRow = {
    id: short.generate(),
    hour: "",
    day: "",
    teacher: "",
    room: "",
    branch: "",
  };
  const { doQuery } = useAction();

  const { t } = useTranslation();

  const [rows, setRows] = useState<IRow[]>([defaultOption]);
  const [loading, setLoading] = useState<boolean>(false);
  const variable = config.find((e) => e.name === "variable")?.value;
  const enable_editing =
    config.find((e) => e.name === "enable_editing")?.value === "1";
  const [teachers, setTeachers] = useState<any[]>([]);
  const [rooms, setRooms] = useState<any[]>([]);
  const items_query = config.find((e) => e.name === "items_query")?.value;
  const items_accessor = config.find((e) => e.name === "items_accessor")?.value;
  const teachers_query = config.find((e) => e.name === "teachers_query")?.value;
  const teachers_accessor = config.find(
    (e) => e.name === "teachers_accessor"
  )?.value;
  const rooms_query = config.find((e) => e.name === "rooms_query")?.value;
  const rooms_accessor = config.find((e) => e.name === "rooms_accessor")?.value;

  useEffect(() => {
    (async () => {
      if (!rooms_query && !rooms_accessor) {
        return;
      }
      if (!teachers_query && !teachers_accessor) {
        return;
      }
      if (!items_query && !items_accessor) {
        return;
      }
      try {
        setLoading(true);
        const [roomsData, teachersData, itemsData]: any = await Promise.all([
          doQuery({
            query: rooms_query!,
            scope: [{ name: "room_name", value: "" }],
            withQuate: false,
          }),
          doQuery({
            query: teachers_query!,
          }),
          doQuery({
            query: items_query!,
          }),
        ]);

        if (roomsData?.data) {
          setRooms([...roomsData?.data[rooms_accessor || ""].options] || []);
        }
        if (teachersData?.data) {
          setTeachers(
            [...teachersData?.data[teachers_accessor || ""].options] || []
          );
        }
        if (itemsData?.data) {
          const sessionsData = itemsData?.data[items_accessor || ""]
            .sessions as IWeeklyRow[];

          const sessions = sessionsData.map((one, index: number) => ({
            ...one,
            hour: hoursOptions.find((o) => o.label === one.hour)?.value || "",
            day: daysOptions.find((o) => o.label === one.day)?.value || "",
            room: one.room?.id || one.room || "",
            teacher: one?.instructor?.id || one?.instructor || "",
            id: short.generate(),
            branch: arrayOfAlphabets()[index],
          }));
          setRows(
            [
              ...(sessions as any),
              { ...defaultOption, branch: arrayOfAlphabets()[sessions.length] },
            ] || []
          );
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [
    rooms_query,
    rooms_accessor,
    teachers_query,
    teachers_accessor,
    items_query,
    items_accessor,
  ]);

  const teachersOptions = useMemo(() => {
    return teachers.map((one) => ({
      label: one.title,
      value: one.name,
      option: one,
    }));
  }, [teachers]);

  const roomsOptions = useMemo(() => {
    return rooms.map((one) => ({
      label: one.title,
      value: one.name,
      option: one,
    }));
  }, [rooms]);

  useEffect(() => {
    const newRows: IRow[] = JSON.parse(JSON.stringify(rows));
    setFormState((s: any) => ({
      ...s,
      [variable as string]: replaceJsonDoubleQuotes(
        JSON.stringify(
          newRows.map((one: any) => {
            one.instructor = one.teacher;
            return one;
          })
        )
      ),
    }));
  }, [rows, setFormState, variable]);

  return visible ? (
    <>
      <Card title="" body="">
        {loading ? (
          <Loading />
        ) : (
          <>
            <Row
              style={{
                gap: "10px",
              }}
              align="middle"
            >
              <Col span={4}>
                <p>{t("WeeklyScheduleLinesEditor.hour")}</p>
              </Col>
              <Col span={4}>
                <p>{t("WeeklyScheduleLinesEditor.day")}</p>
              </Col>
              <Col span={4}>
                <p>{t("WeeklyScheduleLinesEditor.teacher")}</p>
              </Col>
              <Col span={3}>
                <p>{t("WeeklyScheduleLinesEditor.room")}</p>
              </Col>
              <Col span={3}>
                <p>{t("WeeklyScheduleLinesEditor.branch")}</p>
              </Col>
              <Col span={2} />
            </Row>

            {rows.map((one, index) => (
              <Row
                key={index}
                style={{ marginBottom: "10px", gap: "10px" }}
                align="middle"
              >
                <Col className="d-flex" span={4}>
                  <Select
                    disabled={!enable_editing}
                    className="flex-1"
                    value={
                      one.hour
                        ? hoursOptions.find((e) => e.value == one.hour)?.value
                        : undefined
                    }
                    filterOption={false}
                    options={hoursOptions}
                    onChange={(value) => {
                      setRows(
                        rows.map((e) => {
                          if (e.id === one.id) {
                            e.hour = value;
                          }
                          return e;
                        })
                      );
                    }}
                    placeholder={t("WeeklyScheduleLinesEditor.hour")}
                  />
                </Col>
                <Col className="d-flex" span={4}>
                  <Select
                    disabled={!enable_editing}
                    className="flex-1"
                    value={
                      one.day
                        ? daysOptions.find((e) => e.value == one.day)?.value
                        : undefined
                    }
                    filterOption={false}
                    options={daysOptions}
                    onChange={(value) => {
                      setRows(
                        rows.map((e) => {
                          if (e.id === one.id) {
                            e.day = value;
                          }
                          return e;
                        })
                      );
                    }}
                    placeholder={t("WeeklyScheduleLinesEditor.day")}
                  />
                </Col>
                <Col className="d-flex" span={4}>
                  <Select
                    disabled={!enable_editing}
                    className="flex-1"
                    value={
                      one.teacher
                        ? teachersOptions.find((e) => e.value == one.teacher)
                            ?.value
                        : undefined
                    }
                    filterOption={false}
                    options={teachersOptions}
                    onChange={(value) => {
                      setRows(
                        rows.map((e) => {
                          if (e.id === one.id) {
                            e.teacher = value;
                          }
                          return e;
                        })
                      );
                    }}
                    placeholder={t("WeeklyScheduleLinesEditor.teacher")}
                  />
                </Col>
                <Col className="d-flex" span={4}>
                  <Select
                    disabled={!enable_editing}
                    className="flex-1"
                    value={
                      one.room
                        ? roomsOptions.find((e) => e.value == one.room)?.value
                        : undefined
                    }
                    filterOption={false}
                    options={roomsOptions}
                    onChange={(value) => {
                      setRows(
                        rows.map((e) => {
                          if (e.id === one.id) {
                            e.room = value;
                          }
                          return e;
                        })
                      );
                    }}
                    placeholder={t("WeeklyScheduleLinesEditor.room")}
                  />
                </Col>
                <Col className="d-flex" span={2}>
                  <Input
                    disabled={!enable_editing}
                    className="flex-1"
                    readOnly
                    placeholder={t("WeeklyScheduleLinesEditor.branch")}
                    value={one.branch}
                    onChange={(event) => {
                      setRows(
                        rows.map((e) => {
                          if (e.id === one.id) {
                            e.branch = event.target.value;
                          }
                          return e;
                        })
                      );
                    }}
                  />
                </Col>
                {enable_editing && (
                  <Col span={1}>
                    {index === rows.length - 1 ? (
                      <Button
                        onClick={() => {
                          setRows((s) => [
                            ...s,
                            {
                              ...defaultOption,
                              branch: arrayOfAlphabets()[s.length],
                            },
                          ]);
                        }}
                        variant="primary"
                        type="link"
                        size="small"
                      >
                        <Plus />
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          setRows(rows.filter((e) => e.id !== one.id));
                          setRows((prev) =>
                            prev.map((e, i) => ({
                              ...e,
                              branch: arrayOfAlphabets()[i],
                            }))
                          );
                        }}
                        variant="danger"
                        type="link"
                        size="small"
                      >
                        <X />
                      </Button>
                    )}
                  </Col>
                )}
              </Row>
            ))}
          </>
        )}
      </Card>
    </>
  ) : (
    <></>
  );
};
