import AuthLogo from "@/components/AuthLogo";
import { RESET_PASSWORD } from "@/queries";
import { authSelector } from "@/store/reducers/auth.reducer";
import { useMutation } from "@apollo/client";
import Button from "@components/ui/buttons/Button";
import { useUtiles } from "@hooks/useUtiles";
import { themeSelector } from "@store/reducers/theme.reducer";
import { Form, Input } from "antd";
import { Key } from "phosphor-react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

type Props = {};
export const ResetPasssword: React.FC<Props> = ({}) => {
  const { t } = useTranslation();
  const theme = useSelector(themeSelector).theme;
  const { authConfig } = useSelector(authSelector);
  const { reset_token } = useParams();

  const navigate = useNavigate();

  const [reset, { data, error, loading }] = useMutation(RESET_PASSWORD);
  const { openNotificationWithIcon, consoleLog } = useUtiles();

  const onFinish = (values: any) => {
    reset({ variables: { ...values, reset_token } })
      .then((data: any) => {
        if (data.status) {
          navigate("/signin");
        } else {
          navigate("/");
        }
      })
      .catch((e) => {
        consoleLog("error on reset password", { e });
      });
  };

  useEffect(() => {
    document.title = t("resetPassword");
  });

  return (
    <div className="auth-form">
      <div
        className="logo text-center mb-4 w-100"
        style={{ maxWidth: "350px" }}
      >
        <AuthLogo />
      </div>

      <Form
        name="normal_login"
        className="login-form w-100"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        style={{ maxWidth: "350px" }}
        layout="vertical"
      >
        <Form.Item
          name="password1"
          rules={[
            {
              required: true,
              message: t("thisFieldRequired", { name: t("password") }),
            },
          ]}
          label={t("pleaseEnterNewPassword")}
        >
          <Input.Password
            prefix={<Key className="site-form-item-icon" />}
            placeholder={t("password")}
          />
        </Form.Item>

        <Form.Item
          name="password2"
          dependencies={["password1"]}
          hasFeedback
          label={t("pleaseEnterConfirmPassword")}
          rules={[
            {
              required: true,
              message: t("thisFieldRequired", { name: t("confirmPassword") }),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password1") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t("passwordsNotMatch")));
              },
            }),
          ]}
        >
          <Input.Password
            prefix={<Key className="site-form-item-icon" />}
            placeholder={t("confirmPassword")}
          />
        </Form.Item>

        <Form.Item>
          <Button
            // type="secondary"
            htmlType="submit"
            className="login-form-button"
            disabled={loading}
            loading={loading}
            block
          >
            {t("resetPassword")}
          </Button>
        </Form.Item>
        {authConfig?.signup?.enabled && (
          <p className="text-center">
            {t("dontHaveAaccount")}{" "}
            <Link to={"/signup"}>{t("createAccount")}</Link>
          </p>
        )}
        <p className="text-center">
          <Link to={"/"}>{t("backToHome")}</Link>
        </p>
      </Form>
    </div>
    // <Space align="center" className="auth-form w-100">
    //   <Row className="w-100">
    //     <Col span={12} offset={6}>
    //       <Form
    //         name="normal_login"
    //         className="login-form"
    //         initialValues={{ remember: true }}
    //         onFinish={onFinish}
    //       >
    //         <Form.Item
    //           name="password1"
    //           rules={[
    //             { required: true, message: "Please input your password1!" },
    //           ]}
    //         >
    //           <Input
    //             prefix={<UserOutlined className="site-form-item-icon" />}
    //             placeholder="password1"
    //           />
    //         </Form.Item>

    //         <Form.Item
    //           name="password2"
    //           rules={[
    //             { required: true, message: "Please input your password2!" },
    //           ]}
    //         >
    //           <Input
    //             prefix={<UserOutlined className="site-form-item-icon" />}
    //             placeholder="password2"
    //           />
    //         </Form.Item>

    //         <Space>
    //           <Captcha />
    //         </Space>
    //         <Form.Item>
    //           <Space>
    //             <Button
    //               type="primary"
    //               htmlType="submit"
    //               className="login-form-button"
    //               size="small"
    //               disabled={loading}
    //               loading={loading}
    //             >
    //               reset password
    //             </Button>
    //           </Space>
    //         </Form.Item>
    //         <Link to={"/signin"}>back to login from here </Link>
    //       </Form>
    //     </Col>
    //   </Row>
    // </Space>
  );
};
