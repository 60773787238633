import { Card } from "@components/dynamic/Card";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import React, { memo } from "react";

const mapColor = (index: number) => {
  switch (index) {
    case 0:
      return "#67C23A";
    case 1:
      return "#409EFF";
    default:
      return "#d5d5d5";
  }
};

export const StaticProgressbar2: React.FC<{
  block: IBlock;
  scope: IScope[];
  loading: boolean;
}> = memo(({ block, scope = [], loading }) => {
  const config: IScope[] = block?.config ? block?.config : [];
  const visible = config.find((e: any) => e.name === "visible")?.value === "1";

  const expandable =
    config.find((e: any) => e.name === "expandable")?.value === "1";
  const expanded =
    config.find((e: any) => e.name === "expanded")?.value === "1";

  const progress = config.find((e: any) => e.name === "progress")?.array || [];
  const text_inside =
    config.find((e: any) => e.name === "text_inside")?.value === "1";

  return (
    <Card
      scope={scope}
      body={block.body}
      title={block?.title}
      expanded={expanded}
      classes={block?.classes}
      expandable={expandable}
      loading={loading}
      visible={visible}
      config={config}
    >
      <div className="c-progress">
        {text_inside &&
          progress.map((e: any, i: number) => (
            <div
              key={i}
              style={{
                width: `${e.value}%`,
                background: mapColor(i),
                borderRadius: "5px",
                padding: "0 5px",
              }}
            >
              {e.name}
            </div>
          ))}
        {!text_inside &&
          progress.map((e: any, i: number) => (
            <div
              key={i}
              style={{
                width: `${e.value}%`,
                overflow: "hidden",
              }}
              className="c-progress__item"
            >
              <span>{e.name}</span>
              <div
                className="c-progress__bar"
                style={{
                  background: mapColor(i),
                  height: "10px",
                }}
              />
            </div>
          ))}
      </div>
    </Card>
  );
});
