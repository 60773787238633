import { Card } from "@components/dynamic/Card";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useAction } from "@hooks/useAction";
import { DatePicker, Row, Tooltip } from "antd";
import {
  calcSecondsBetweenTwoTimes,
  getMinutesDiff,
  timeDifference,
} from "@/utils";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { IAction } from "@/interfaces/action.interface";
import { Action } from "./Action";
import moment from "moment";

interface ITime {
  checkin: string;
  checkout: string;
  time_sheet_day_id: number;
}

interface IDay {
  created_at: string;
  date: string;
  holiday_name: string;
  hours_count: number;
  id: string;
  leave: string;
  leave_id: string;
  over_time_count: number;
  paid_count: string;
  status: string;
  time_sheet_id: string;
  times: ITime[];
  is_holiday?: boolean;
}

const MINUTE_TO_PIXEL = 0.7;

export const TimesheetLazy: React.FC<{
  block: IBlock;
  visible?: boolean;
  formState: any;
}> = ({ block, visible, formState }) => {
  const { t, i18n } = useTranslation();
  const config: IScope[] = block?.config || [];

  const expandable =
    config.find((e: any) => e.name === "expandable")?.value === "1";
  const expanded =
    config.find((e: any) => e.name === "expanded")?.value === "1";
  const default_start_time = config.find(
    (e: any) => e.name === "default_start_time"
  )?.value;
  const default_end_time = config.find(
    (e: any) => e.name === "default_end_time"
  )?.value;

  const graphql = useMemo(() => {
    return config.find((e) => e.name === "graphql")?.value || "";
  }, []);

  const query_accessor = useMemo(() => {
    return config.find((e) => e.name === "query_accessor")?.value || "";
  }, []);

  const scope_variables =
    config.find((e) => e.name === "scope_variables")?.array || [];

  const vars: string[] = useMemo(() => {
    return scope_variables.map((one) => one.name);
  }, []);

  const year = config.find((e) => e.name === "year")?.value;
  const month = config.find((e) => e.name === "month")?.value;

  const [days, setDays] = useState<IDay[]>([]);
  const [loading, setLoading] = useState(false);

  const [currentDate, setCurrentDate] = useState<moment.Moment>(
    moment()
      .set("year", Number(year))
      .set("month", Number(month) - 1)
  );

  const { doQuery } = useAction();

  const diffSeconds = useMemo(() => {
    return calcSecondsBetweenTwoTimes(default_end_time!, default_start_time!);
  }, [default_end_time, default_start_time]);

  const width = useMemo(
    () => (diffSeconds / 60) * MINUTE_TO_PIXEL,
    [diffSeconds]
  );

  const [currentValue, setCurrentValue] = useState<any>(null);

  useEffect(() => {
    // console.log(graphql, vars, formState, currentValue);
    if (graphql) {
      let get = true;
      if (loading) {
        get = false;
      }
      vars.map((one) => {
        if (!formState[one] || currentValue == formState[one]) {
          get = false;
        } else {
          setCurrentValue(formState[one]);
        }
      });
      if (get) {
        getData();
      }
    }
  }, [graphql, vars, formState, currentValue]);

  const getData = useCallback(async () => {
    const newVars: any = {};
    vars.forEach((one) => {
      if (formState[one]) {
        newVars[one] = formState[one];
      }
    });

    setLoading(true);

    doQuery({
      query: graphql,
      scope: [
        ...block?.config.filter(
          (one) => one.name !== "year" && one.name !== "month"
        ),
        { name: "year", value: currentDate.year().toString() },
        { name: "month", value: (currentDate.month() + 1).toString() },
      ],
      item: { ...newVars },
    })
      .then((data: any) => {
        const res = data.data[query_accessor];
        setDays(res.days);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }, [currentDate]);

  useEffect(() => {
    getData();
  }, [currentDate, getData]);

  return (
    <Card
      body={""}
      title={block?.title}
      expanded={expanded}
      classes={block?.classes}
      expandable={expandable}
      loading={loading}
      visible={visible}
      config={config}
    >
      <div>
        <Row justify="center" className="mb-1">
          <DatePicker
            picker="month"
            value={currentDate}
            disabledDate={(current) => {
              return current && current > moment();
            }}
            onChange={(val) => val && setCurrentDate(val)}
          />
        </Row>

        <div className="timesheet">
          <div className="flex align-items-center">
            <span className="start-header"></span>
            <div
              className="session-wrapper top-session"
              style={{
                height: "50px",
              }}
            >
              <span
                className={classNames("timesheet-slot", "first-session")}
                style={{
                  width,
                  minWidth: width,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span className="flex-col">
                  <span>{t("timesheet.timeFrom")}</span>
                  <span>{default_start_time}</span>
                </span>
                <span className="flex-col">
                  <span>{t("timesheet.timeTo")}</span>
                  <span>{default_end_time}</span>
                </span>
              </span>
              <span className={classNames("timesheet-slot")}>
                {t("timesheet.totalHours")}
              </span>
              <span className={classNames("timesheet-slot")}>
                {t("timesheet.workingHours")}
              </span>
              <span className={classNames("timesheet-slot")}>
                {t("timesheet.overtime")}
              </span>
              <span className={classNames("timesheet-slot")}>
                {t("timesheet.holiday")}
              </span>
            </div>
          </div>

          {days.map((one: IDay, index: number) => (
            <div
              key={`${one.id}-${index}`}
              className={`flex align-items-center`}
            >
              <span className="start-header">{one.date}</span>
              {one.is_holiday ? (
                <div className="holiday-day session-wrapper">
                  <span
                    className={classNames("timesheet-slot", "first-session")}
                    style={{
                      width,
                      minWidth: width,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    {one.holiday_name}
                  </span>
                  <span className={classNames("timesheet-slot")}></span>
                  <span className={classNames("timesheet-slot")}></span>
                  <span className={classNames("timesheet-slot")}></span>
                  <span className={classNames("timesheet-slot")}></span>
                </div>
              ) : (
                <div
                  className="session-wrapper"
                  style={{
                    minHeight: "50px",
                  }}
                >
                  <span
                    className={classNames("timesheet-slot", "first-session")}
                    style={{
                      width,
                      minWidth: width,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    {one?.times?.map((time: ITime, ind: number) => (
                      <Tooltip
                        title={`${time.checkin}-${time.checkout}`}
                        key={`time-${ind}`}
                      >
                        <span
                          style={{
                            height: 5,
                            width:
                              (calcSecondsBetweenTwoTimes(
                                time.checkout,
                                time.checkin
                              ) /
                                60) *
                              MINUTE_TO_PIXEL,
                            position: "absolute",
                            right:
                              (calcSecondsBetweenTwoTimes(
                                time.checkin,
                                default_start_time!
                              ) /
                                60) *
                              MINUTE_TO_PIXEL,
                            backgroundColor: "green",
                            borderRadius: 5,
                          }}
                          className={classNames("timesheet-checkin")}
                        />
                      </Tooltip>
                    ))}
                  </span>
                  <span className={classNames("timesheet-slot")}>
                    {one.hours_count}
                  </span>
                  <span className={classNames("timesheet-slot")}>
                    {one.paid_count}
                  </span>
                  <span className={classNames("timesheet-slot")}>
                    {one.over_time_count}
                  </span>
                  <span className={classNames("timesheet-slot")}>
                    {one.holiday_name}
                  </span>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};
