import { config } from "@/config";
import Button from "@components/ui/buttons/Button";
import { GoogleLogo } from "phosphor-react";
import React from "react";
import {
  GoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";

type Props = {
  buttonText: string;
};
export const GoogleButton: React.FC<Props> = ({ buttonText }) => {
  const responseGoogle = (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {};

  return (
    <GoogleLogin
      render={(renderProps) => (
        <Button
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
          icon={<GoogleLogo />}
          variant="danger"
          types="flush"
          block
        >
          {buttonText}
        </Button>
      )}
      clientId={config.googleClientId}
      buttonText={buttonText}
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      cookiePolicy={"single_host_origin"}
    />
  );
};
