import { INotification } from "@interfaces/notification.interface";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/reducers/root.reducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const NAME = "notification";
type INotificationState = {
  notifications: INotification[];
};
const initialState: INotificationState = {
  notifications: [],
};
export const NotificationSlice = createSlice({
  name: NAME,
  initialState: initialState,
  reducers: {
    setNotifications: (state, action) => {
      const notifications = action.payload as INotification[];
      return { ...state, notifications };
    },
  },
});

// Action creators are generated for each case reducer function
export const NotificationActions = NotificationSlice.actions;
export const notificationSelector = (state: RootState) => state.notification;

export const NotificationReducer = persistReducer(
  {
    storage: storage,
    key: NAME,
    debug: false,
    whitelist: [],
  },
  NotificationSlice.reducer
);
