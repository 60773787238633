import { Card } from "@components/dynamic/Card";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import React, { useState, useEffect, useMemo } from "react";
import { useAction } from "@hooks/useAction";
import { Tooltip } from "antd";
import { getMinutesDiff, timeDifference } from "@/utils";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { IAction } from "@/interfaces/action.interface";
import { Action } from "./Action";

export const WeeklyScheduleLazy: React.FC<{
  block: IBlock;
  visible?: boolean;
  formState: any;
}> = ({ block, visible, formState }) => {
  const { t, i18n } = useTranslation();
  const config: IScope[] = block?.config || [];
  const expandable =
    config.find((e: any) => e.name === "expandable")?.value === "1";
  const expanded =
    config.find((e: any) => e.name === "expanded")?.value === "1";

  const graphql = useMemo(() => {
    return config.find((e) => e.name === "graphql")?.value || "";
  }, []);

  const query_accessor = useMemo(() => {
    return config.find((e) => e.name === "query_accessor")?.value || "";
  }, []);

  const scope_variables =
    config.find((e) => e.name === "scope_variables")?.array || [];
  const variable = config.find((e) => e.name === "variable")?.value || "";
  const start_time =
    config.find((e) => e.name === "start_time")?.value || "08:00";
  const end_time = config.find((e) => e.name === "end_time")?.value || "";
  const days = config.find((e) => e.name === "days")?.array || [];

  const actions: IAction[] = block?.actions ? block?.actions : [];
  const general_actions: IAction[] = block?.general_actions
    ? block?.general_actions
    : [];

  const slots = parseInt(end_time) - parseInt(start_time);

  const vars: string[] = useMemo(() => {
    return scope_variables.map((one) => one.name);
  }, []);

  const [loading, setLoading] = useState(false);

  const [sessions, setSessions] = useState([]);
  const [daysState, setDaysState] = useState([]);

  const { doQuery } = useAction();

  const [currentValue, setCurrentValue] = useState<any>(null);

  useEffect(() => {
    // console.log(graphql, vars, formState, currentValue);
    if (graphql) {
      let get = true;
      if (loading) {
        get = false;
      }
      if (daysState.length > 0) {
        get = false;
      }
      vars.map((one) => {
        if (!formState[one] || currentValue == formState[one]) {
          get = false;
        } else {
          setCurrentValue(formState[one]);
        }
      });
      if (get) {
        getData();
      }
    }
  }, [graphql, vars, formState, currentValue]);

  const getData = async () => {
    const newVars: any = {};
    vars.forEach((one) => {
      if (formState[one]) {
        newVars[one] = formState[one];
      }
    });

    setLoading(true);
    doQuery({
      query: graphql,
      scope: block?.config,
      item: { ...newVars },
    })
      .then((data: any) => {
        const a: any = [];
        const dt = data?.data[query_accessor];
        days.forEach((one: any) => {
          const obj: any = {};
          obj.day = one.value;
          obj.sessions = [];
          dt.sessions?.forEach((sess: any) => {
            if (sess.day === one.value) {
              obj.sessions.push(sess);
            }
          });
          a.push(obj);
        });
        setSessions(dt.sessions);
        setDaysState(a);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Card
      body={""}
      title={block?.title}
      expanded={expanded}
      classes={block?.classes}
      expandable={expandable}
      loading={loading}
      visible={visible}
      config={config}
      cardProps={{
        extra: general_actions.map((action, index) => (
          <Action
            key={`general-actions-weekly-${index}`}
            item={undefined}
            action={action}
            // type="general"
          />
        )),
      }}
    >
      <div style={{ overflowX: "auto" }}>
        <div className="flex align-items-center">
          <span className="start-header">اليوم</span>
          <div
            className="session-wrapper top-session"
            style={{
              minWidth: `${[...Array(slots + 1).keys()].length * 60}px`,
              height: "50px",
            }}
          >
            {[...Array(slots + 1).keys()].map((one, index: number) => (
              <span
                className={classNames(
                  "time-slot empty-time-slot",
                  index === 0 && "first-session"
                )}
                key={index}
              >{`${parseInt(start_time) + index}:00`}</span>
            ))}
          </div>
        </div>
        {daysState.map((one: any, index: number) => (
          <div key={index} className={`flex align-items-center`}>
            <span className="start-header">{one.day}</span>
            <div
              className="session-wrapper"
              style={{
                minWidth: `${[...Array(slots + 1).keys()].length * 60}px`,
                minHeight: "50px",
                height: one.sessions.length * 10 + "px",
              }}
            >
              {[...Array(slots + 1).keys()].map((one, index: number) => (
                <span
                  className={classNames(
                    "empty-time-slot",
                    index === 0 && "first-session"
                  )}
                  key={index}
                />
              ))}
              {one.sessions.map((one: any, index: number) => (
                <div
                  key={index}
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    border: 1,
                    position: "absolute",
                    right:
                      i18n.language === "ar"
                        ? `${
                            timeDifference(one.session_start_at, start_time) *
                            60
                          }px`
                        : "auto",
                    left:
                      i18n.language !== "ar"
                        ? `${
                            timeDifference(one.session_start_at, start_time) *
                            60
                          }px`
                        : "auto",
                    bottom: `${5 + index * 10}px`,
                  }}
                >
                  <Tooltip
                    color={one.bg_color}
                    title={
                      <div className="flex-column tooltip-session">
                        <span style={{ color: one.font_color }}>
                          {one.name}
                        </span>
                        <b style={{ color: one.font_color }}>{one.location}</b>
                        <span style={{ color: one.font_color }}>
                          {one.classroom}
                        </span>
                        <span style={{ color: one.font_color }}>
                          {one.session_start_at} - {one.session_end_at}
                        </span>
                        <div className="flex">
                          {actions.map((action, index) => (
                            <Action
                              key={`actions-weekly-edit-${index}`}
                              item={{
                                id: one.id.toString(),
                              }}
                              action={action}
                              withTooltip={false}
                              // type="general"
                            />
                          ))}
                        </div>
                      </div>
                    }
                  >
                    <span
                      style={{
                        height: "5px",
                        backgroundColor: one.font_color,
                        borderRadius: "5px",
                        width: `${getMinutesDiff(
                          new Date(`2029-02-25 ${one.session_end_at}`),
                          new Date(`2029-02-25 ${one.session_start_at}`)
                        )}px`,
                      }}
                    />
                  </Tooltip>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
};
