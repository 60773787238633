import { IUser } from "@interfaces/user.interface";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/reducers/root.reducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const NAME = "auth";
type IAuthState = {
  user?: IUser;
  accessToken?: string;
  sessionToken?: string;

  remember?: boolean;
  loading?: boolean;
  defaultRoute?: string;
  google_maps_api_key?: string;
  authConfig?: {
    login?: {
      enabled: boolean;
      message: string;
    };
    signup?: {
      enabled?: boolean;
      message?: string;
    };
    default_page?: string;
  };
};
const initialState: IAuthState = {
  user: undefined,
  accessToken: undefined,
  sessionToken: undefined,
  remember: true,
  loading: false,
  defaultRoute: "mingo",
  google_maps_api_key: "",
  authConfig: {},
};
export const AuthSlice = createSlice({
  name: NAME,
  initialState: initialState,
  reducers: {
    login: (state, action) => {
      const accessToken = action.payload?.accessToken;
      const user = action.payload?.user;
      const remember = action.payload?.remember;

      if (remember) {
        return { ...state, accessToken: accessToken, user: user };
      } else {
        return { ...state, sessionToken: accessToken, user: user };
      }
    },

    signup: (state, action) => {
      const accessToken = action.payload?.accessToken;
      const user = action.payload?.user;
      return { ...state, accessToken, user };
    },
    logout: (state) => {
      return {
        ...state,
        user: undefined,
        accessToken: undefined,
        sessionToken: undefined,
      };
    },

    setUser: (state, action) => {
      const user = action.payload?.user;

      return { ...state, user: user };
    },

    setLoading: (state, action) => {
      const loading = action.payload?.loading;
      return { ...state, loading };
    },
    setRoute: (state, action) => {
      return { ...state, route: action.payload?.route };
    },
    setGoogleMapsKey: (state, action) => {
      return { ...state, google_maps_api_key: action.payload?.key };
    },
    setAuthConfig: (state, action) => {
      return { ...state, authConfig: action.payload?.config };
    },
  },
});

// Action creators are generated for each case reducer function
export const AuthActions = AuthSlice.actions;
export const authSelector = (state: RootState) => state.auth;

export const AuthReducer = persistReducer(
  {
    storage: storage,
    key: NAME,
    debug: false,
    whitelist: ["user", "accessToken"],
  },
  AuthSlice.reducer
);
