// https://erp2.inlinez.net/ajax/login

import gql from "graphql-tag";

const action = `
label  name  type  icon variant toolTip
block   disabled   href  loading  shape size target action_type  label classes
config {   name  value type
  array {  name  value  type }
}

`;

export const get_all_users = gql`
  query getall($page: Int, $first: Int) {
    users(page: $page, first: $first) {
      paginatorInfo {
        perPage
        count
        currentPage
        total
      }
      data {
        id
        username
        email
        firstname
        lastname
        created_at

        actions {
          ${action}
        }
      }
    }
  }
`;

export const get_one_user = gql`
  query getone($id: ID) {
    user(id: $id) {
      id
      username
      email
      email
      lastname
      firstname
    }
  }
`;

export const delete_one_user = gql`
  mutation deleteOne($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`;

const blockContain = `

  name
  type
  title
  body
  width
  classes
  react_component

  onClickAction {
    ${action}
  }
  actions {
    ${action}
  }
  general_actions {
    ${action}
  }

  filters {
    name
    type
    label
    width
    config{
      name
      value
      type
      array {
        name
        value
      }
    }
  }


  config {
    name
    type
    value
    array {
      name
      value
      type
      array {
        name
        value
        type
        array {
          name
          value
        }
      }
    }
    pairs {
      a
      b
    }
  }
  childs {
    name
    type
    title
    body
    width
    classes
    onClickAction {
      ${action}
    }

    actions {
      ${action}
  }
    general_actions {
      ${action}
  }

    react_component
    config {
      name
      type
      value
      array {
        name
        value
        type
        array {
          name
          value
        }
      }
      pairs {
        a
        b
      }
    }
  }


`;

export const GET_PAGE = gql`
query getPage($name: String!, $params: [PageParam!] ) {
  getPage(name: $name, params : $params) {
    side_menu
    name
    title
    scope {
      name
      type
      value
      array {
        name
        value
      }

      pairs {
        a
        b
      }
    }
    react_component
    panels {
      name
      scope {
        name
        type
        value
        array {
          name
          value
        }

        pairs {
          a
          b
        }

      }

      react_component
      blocks {
        ${blockContain}
      }
    }
    kb_actions {
      name
      type
      href
      target
      action_type
      config {
        name
        type
        value
        array {
          name
          value
          type
          array {
            name
            value
          }
        }
      }
    }
  }
}
`;
export const edit_user = gql`
  mutation update(
    $id: ID!
    $username: String!
    $firstname: String!
    $lastname: String!
  ) {
    updateUser(
      id: $id
      username: $username
      firstname: $firstname
      lastname: $lastname
    ) {
      id
      username
      firstname
      lastname
      email
    }
  }
`;

export const ADD_USER = gql`
  mutation create(
    $username: String!
    $email: String!
    $firstname: String!
    $lastname: String!
  ) {
    createUser(
      username: $username
      email: $email
      firstname: $firstname
      lastname: $lastname
    ) {
      id
      username
    }
  }
`;

export const GET_COLS = `
query getCols($name: String!, $userID: Int!, $dtinput: dtInput, $params: [generalParam]) {
  dtConfig(name: $name, userID: $userID, dtinput: $dtinput, params : $params) {
    name
    pagination
    general_search
    sort_columns
    data_query
    columns {
      dataIndex
      visible
      key
      weight
      sorter
      label
    }
    general_actions {
      ${action}
    }
    bulk_actions {
      ${action}
    }
    actions {
      ${action}
    }
    filters {
      name
      label
      type
      config {
        name
        type
        value
        array {
          name
          value
          type
          array {
            name
            value
          }
        }
      }
    }
  }
}
`;
export const GET_COLS_GQL = gql`
  ${GET_COLS}
`;

export const GET_FORM = gql`
  query getForm($name: String!, $userID: Int!) {
    getForm(name: $name, userID: $userID) {
      columns {
        key
        dataIndex
        visible
        sorter
        weight
      }
      actions {
        ${action}
      }
    }
  }
`;

export const SIDE_MENU = gql`
  {
    getGUI {
      has_page_side_menu
      modules {
        name
        label
        icon
        default_menuitem

        menu {
          name
          label
          icon
          route
          childs {
            name
            label
            icon
            route
            shortcut_tooltip
            shortcut_icon
            shortcut_route
            __typename
          }
          __typename
        }
        __typename
      }
      general_actions {
       ${action}
      }

      socket_data {
        name
        value
        type
        array {
          name
          value
        }
      }

      user {
        id
        firstname
        fullname
        lastname
        email
        username
        avatar
        preferences {
          id
          key
          value
          name
          status
        }
        __typename
      }
      gui_data {
        name
        value
        type
        array {
          name
          value
        }
      }
      default_page
      site_name
      __typename
    }
  }
`;

export const MUTATION_HI = gql`
  mutation {
    hi
  }
`;

export const CHANGE_LANGUAGE = gql`
  mutation changeLanguage($user_id: ID!, $lang: String!) {
    changeLanguage(user_id: $user_id, lang: $lang) {
      id
    }
  }
`;

export const CHANGE_MODE = gql`
  mutation changeMode($user_id: ID!, $mode: String!) {
    changeMode(user_id: $user_id, mode: $mode) {
      id
    }
  }
`;

export const QUERY_HI = gql`
  query {
    hi
  }
`;

export const get_dynamic_table = gql`
  query dynamicTable($name: String!, $userID: Int!, $dtinput: dtInput) {
    dtConfig(name: $name, userID: $userID, dtinput: $dtinput) {
      name
      pagination
      data_query
      columns {
        dataIndex
        visible
        key
        weight
        sorter
        label
      }
      general_actions {
       ${action}
      }
      actions {
        ${action}
      }
      filters {
        name
        label
        type
        width
        config {
          name
          type
          value
          array {
            name
            value
            type
            array {
              name
              value
            }
          }
        }
      }
    }
  }
`;

export const SIGNIN = gql`
  mutation signinUser($username: String!, $password: String!) {
    login(username: $username, password: $password)
  }
`;

export const SIGNUP = gql`
  mutation signupUser(
    $username: String!
    $password: String!
    $phone: String!
    $firstname: String
    $lastname: String
  ) {
    signup(
      username: $username
      password: $password
      phone: $phone
      firstname: $firstname
      lastname: $lastname
    )
  }
`;

export const RESET_PASSWORD = gql`
  mutation ressetPasswordUser(
    $reset_token: String!
    $password1: String!
    $password2: String!
  ) {
    reset_password(
      reset_token: $reset_token
      password1: $password1
      password2: $password2
    )
  }
`;

export const FORGET_PASSWORD = gql`
  mutation forgetPasswordUser($username: String!) {
    forget_password(username: $username)
  }
`;

export const SEND_LOGIN_OTP = gql`
  mutation send_login_otp($phone: String!) {
    send_login_otp(phone: $phone)
  }
`;

export const LOGIN_OTP = gql`
  mutation login_by_otp($phone: String!, $otp: String!) {
    login_by_otp(phone: $phone, otp: $otp)
  }
`;

export const DYNAMIC_LIST = gql`
  query dynamicList($name : String!, $config: DataProviderConfig) {
    listingProvider(name: $name , config: $config) {
      name
      pagination {
        count
        currentPage
        hasMorePages
        lastPage
        total
        perPage
      }
      data {
        ${blockContain}
      }

    }
  }
`;

export const DATA_SELECTOR = gql`
  query dataSelector($name: String, $config: DataSelectorConfig) {
    dataSelector(name: $name, config: $config)
  }
`;

export const AUTH_CONFIG = gql`
  query {
    getAuthConfig {
      login {
        name
        value
      }
      signup {
        name
        value
      }
    }
  }
`;

export const GET_PORTAL_PAGE = gql`
query getPage($name: String!, $params: [PageParam!] ) {
  getPortalPage(name: $name, params : $params) {
    side_menu
    name
    title
    scope {
      name
      type
      value
      array {
        name
        value
      }

      pairs {
        a
        b
      }
    }
    react_component
    panels {
      name
      scope {
        name
        type
        value
        array {
          name
          value
        }

        pairs {
          a
          b
        }

      }

      react_component
      blocks {
        ${blockContain}
      }
    }
    kb_actions {
      name
      type
      href
      target
      action_type
      config {
        name
        type
        value
        array {
          name
          value
          type
          array {
            name
            value
          }
        }
      }
    }
  }
}
`;

export const LOGOUT = gql`
  mutation {
    logout
  }
`;

export const PORTAL_GUI = gql`
  {
    getPortalGUI {
      navbar {
        logo
        items {
          name
          label
          route
          icon
          childs {
            name
            label
            route
            icon
          }
        }
      }
      footer {
        dynamicItem {
          content
        }
        columns {
          title
          list {
            name
            label
            route
            icon
          }
        }
      }
      gui_data {
        name
        value
        type
        array {
          name
          value
          __typename
        }
        __typename
      }
    }
  }
`;
