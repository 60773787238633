import { AudioPlayer } from "@/components/dynamic/AudioPlayer";
import { CheckboxGroup } from "@/components/dynamic/CheckboxGroup";
import { Map } from "@/components/dynamic/Map";
import { PrintPreview } from "@/components/dynamic/PrintPreview";
import { RadioGroup } from "@/components/dynamic/RadioGroup";
import { config as Settings } from "@/config";
import DynamicTable from "@components/DynamicTable";
import { Breadcrumb } from "@components/dynamic/Breadcrumb";
import { Divider } from "@components/dynamic/Divider";
import { DynamicList } from "@components/dynamic/DynamicList";
import { EditInlineField } from "@components/dynamic/EditInlineField";
import { FieldCheckbox } from "@components/dynamic/FieldCheckbox";
import { FieldDatePicker } from "@components/dynamic/FieldDatePicker";
import { FieldDateRangePicker } from "@components/dynamic/FieldDateRangePicker";
import { FieldDropdown } from "@components/dynamic/FieldDropdown";
import { FieldFileUploader } from "@components/dynamic/FieldFileUploader";
import { FieldInput } from "@components/dynamic/FieldInput";
import { FieldInputNumber } from "@components/dynamic/FieldInputNumber";
import { FieldRadioButton } from "@components/dynamic/FieldRadioButton";
import { FieldSubmit } from "@components/dynamic/FieldSubmit";
import { GridItem } from "@components/dynamic/GridItem";
import { LazyLoadKpi } from "@components/dynamic/LazyLoadKpi";
import { Lazyload } from "@components/dynamic/Lazyload";
import { LazyloadIterator } from "@components/dynamic/LazyloadIterator";
import { LazyloadStepbar } from "@components/dynamic/LazyloadStepbar";
import { Notification } from "@components/dynamic/Notification";
import { Rate } from "@components/dynamic/Rate";
import { Simple } from "@components/dynamic/Simple";
import { StaticImage } from "@components/dynamic/StaticImage";
import { StaticKpi } from "@components/dynamic/StaticKpi";
import { StaticLineChart } from "@components/dynamic/StaticLineChart";
import { StaticProgressbar } from "@components/dynamic/StaticProgressbar";
import { StaticSlider } from "@components/dynamic/StaticSlider";
import { StaticStepbar } from "@components/dynamic/StaticStepbar";
import { Switcher } from "@components/dynamic/Switcher";
import { Tabs } from "@components/dynamic/Tabs";
import { FieldTextarea } from "@components/dynamic/fieldTextarea";
import { useUtiles } from "@hooks/useUtiles";
import { IBlock } from "@interfaces/block.interface";
import { IFilter } from "@interfaces/filter.interface";
import { IScope } from "@interfaces/scope.interface";
import { FormInstance } from "antd";
import React from "react";
import { VideoPlayer } from "../components/dynamic/VideoPlayer";
import { WeeklyScheduleLazy } from "../components/dynamic/WeeklyScheduleLazy";
import { getValFromArray } from "../utils";
import { JournalEditor } from "@/components/dynamic/JournalEditor";
import { InvoiceItemEditor } from "@/components/dynamic/InvoiceItemEditor";
import { ExpenseItemEditor } from "@/components/dynamic/ExpenseItemEditor";
import { StaticLineChartBlock } from "@/components/dynamic/StaticLineChartBlock";
import { StaticDonutChartBlock } from "@/components/dynamic/StaticDonutChartBlock";
import { StaticPercentageBlock } from "@/components/dynamic/StaticPercentageBlock";
import { StaticPieChartBlock } from "@/components/dynamic/StaticPieChartBlock";
import { StaticProgressbar2 } from "@/components/dynamic/StaticProgressbar2";
import { CourseSelector } from "@/components/dynamic/CourseSelector";
import { FieldRichTextarea } from "@/components/dynamic/fieldRichTextarea";
import { DueTermsEditor } from "@/components/dynamic/DueTermsEditor";
import { InstallmentEditor } from "@/components/dynamic/InstallmentEditor";
import { IFrame } from "@/components/dynamic/IFrame";
import { ProductAmountEditor } from "@/components/dynamic/ProductAmount";
import { TimesheetLazy } from "@/components/dynamic/TimesheetLazy";
import { Timeline } from "@/components/dynamic/Timeline";
import { TimeSheetTime } from "@/components/dynamic/TimesheetTime";
import { PageNavigator } from "@/components/dynamic/PageNavigator";
import { LazyLoadCalendar } from "@/components/dynamic/LazyLoadCalendar";
import { WeeklyScheduleLinesEditor } from "@/components/dynamic/WeeklyScheduleLinesEditor";

interface IRenderBlocks {
  block: IBlock;
  scope: IScope[];
  form: FormInstance<any>;
  formState: any;
  setFormState: any;
  loading: boolean;
  setFilterState?: React.Dispatch<React.SetStateAction<IFilter[]>>;
  // setFilterState?: any;
  filterState?: IFilter[];
  ref?: any;
}

export const useRender = () => {
  const { consoleLog } = useUtiles();

  const editFilterState = (
    variable: string,
    value: any,
    filterState?: IFilter[],
    setFilterState?: React.Dispatch<React.SetStateAction<IFilter[]>>,
    type: "one" | "two" | "multi" = "one",
    ref?: any
  ) => {
    setFilterState &&
      filterState &&
      setFilterState((s) => {
        let filterObject: IFilter = {
          name: variable,
          op: "",
          val: "",
          val1: "",
          val2: "",
          vals: [],
          // ref,
        };

        switch (type) {
          case "multi": {
            filterObject.vals = value;

            break;
          }
          case "two": {
            if (value?.length === 2) {
              filterObject.val1 = value[0];
              filterObject.val2 = value[1];
            }
            break;
          }
          default: {
            filterObject.val = value;
            break;
          }
        }

        if ((Array.isArray(value) && value.length === 0) || !value) {
          return s.filter((e) => e.name !== variable);
        }

        return !s.find((e) => e.name === variable)
          ? [...s, filterObject]
          : s.map((e) => {
              if (e.name === variable) {
                e = filterObject;
              }
              return e;
            });
      });
  };
  const RenderBlocks = ({
    block,
    scope,
    form,
    formState,
    setFormState,
    setFilterState,
    loading = false,
    filterState = [],
  }: IRenderBlocks) => {
    let component: any;

    scope = [...scope, ...block.config];

    let disabled =
      block.config.find((e) => e.name === "disabled")?.value || "0";
    const disable_if =
      block.config.find((e) => e.name === "disable_if")?.array || [];
    const disable_if_value: any = {};
    disable_if.forEach((e) => {
      e.array?.forEach((one) => {
        if (!Object(disable_if_value).hasOwnProperty(one.name)) {
          disable_if_value[one.name] = [];
        }
        disable_if_value[one.name]?.push(one.value);
      });
    });

    Object.keys(disable_if_value).forEach((e) => {
      if (disable_if_value[e].includes(formState[e] || "undefined")) {
        disabled = "1";
      }
    });

    const enable_if =
      block.config.find((e) => e.name === "enable_if")?.array || [];
    const enable_if_value: any = {};
    enable_if.forEach((e) => {
      if (e.name) {
        e.array?.forEach((one) => {
          if (!Object(enable_if_value).hasOwnProperty(one.name)) {
            enable_if_value[one.name] = [];
          }
          enable_if_value[one.name]?.push(one.value);
        });
      }
    });
    Object.keys(enable_if_value).forEach((e) => {
      if (enable_if_value[e].includes(formState[e])) {
        disabled = "0";
      }
    });
    switch (block?.type) {
      case "simple": {
        component = (
          <Simple
            form={form}
            formState={formState}
            setFormState={setFormState}
            block={block}
            scope={scope}
            loading={false}
          />
        );
        break;
      }
      case "breadcrumb": {
        component = <Breadcrumb block={block} scope={scope} loading={false} />;
        break;
      }
      case "divider": {
        component = <Divider block={block} scope={scope} loading={false} />;
        break;
      }
      case "static_kpi": {
        component = <StaticKpi block={block} scope={scope} loading={false} />;
        break;
      }
      case "lazyload_kpi": {
        component = <LazyLoadKpi block={block} scope={scope} loading={false} />;
        break;
      }
      case "static_line_chart": {
        component = (
          <StaticLineChart block={block} scope={scope} loading={false} />
        );
        break;
      }
      case "static_image": {
        component = <StaticImage block={block} scope={scope} loading={false} />;
        break;
      }
      case "lazyload_iterator": {
        component = (
          <LazyloadIterator block={block} scope={scope} loading={false} />
        );
        break;
      }
      case "static_stepbar": {
        component = (
          <StaticStepbar block={block} scope={scope} loading={false} />
        );
        break;
      }
      case "static_progressbar": {
        component = (
          <StaticProgressbar block={block} scope={scope} loading={false} />
        );
        break;
      }
      case "lazyload_stepbar": {
        component = (
          <LazyloadStepbar block={block} scope={scope} loading={false} />
        );
        break;
      }
      case "static_slider": {
        component = (
          <StaticSlider block={block} scope={scope} loading={false} />
        );
        break;
      }
      case "lazyload": {
        component = <Lazyload block={block} scope={scope} loading={false} />;
        break;
      }
      case "lazyload_datatable": {
        const config: IScope[] = block?.config || [];
        const name = config.find((e) => e.name === "name")?.value + "";
        const params = config.find((e) => e.name === "params")?.array || [];
        const perPage = config.find((e) => e.name === "perPage")?.array || [];
        let userID = config.find((e) => e.name === "userID")?.value + "";

        if (
          userID?.includes(Settings.specialChar) &&
          userID?.includes(Settings.specialChar)
        ) {
          userID = userID.replaceAll(Settings.specialChar, "") + "";
          userID = scope?.find((e: any) => e.name === userID)?.value + "";
        } else {
          // userID = userID.replaceAll(config.specialChar, "") + "";
        }

        const paramsAll: any = [];

        params.forEach((one) => {
          paramsAll.push({
            name: getValFromArray(one.array as any, "name"),
            value: getValFromArray(one.array as any, "value"),
          });
        });

        component = name && userID && (
          <DynamicTable
            name={name}
            userID={parseInt(userID)}
            params={[
              // { name: "filters", source: [] },
              ...paramsAll,
            ]}
            scope={[...config, ...scope]}
            perPageData={perPage.map((e) => parseInt(e.value))}
          />
        );
        break;
      }

      // //// forms items

      case "field_input":
      case "edit_inline_input":
      case "input": {
        component = (
          <FieldInput
            disabled={disabled}
            editInline={block.type === "edit_inline_input"}
            form={form}
            block={block}
            scope={scope}
            loading={loading}
            formState={formState}
            setFormState={setFormState}
            onChange={(value, variable, ref) => {
              editFilterState(
                variable,
                value,
                filterState,
                setFilterState,
                "one",
                ref
              );
            }}
          />
        );
        break;
      }

      case "field_input_number":
      case "edit_inline_input_number": {
        component = (
          <FieldInputNumber
            disabled={disabled}
            editInline={block.type === "edit_inline_input_number"}
            form={form}
            block={block}
            scope={scope}
            loading={loading}
            formState={formState}
            setFormState={setFormState}
            onChange={(value, variable) => {
              editFilterState(variable, value, filterState, setFilterState);
            }}
          />
        );
        break;
      }

      case "field_date_picker":
      case "edit_inline_date_picker":
      case "date_picker": {
        component = (
          <FieldDatePicker
            form={form}
            editInline={block.type === "edit_inline_date_picker"}
            block={block}
            scope={scope}
            loading={loading}
            formState={formState}
            setFormState={setFormState}
            onChange={(value, variable) => {
              editFilterState(variable, value, filterState, setFilterState);
            }}
          />
        );
        break;
      }

      case "date_range": {
        component = (
          <FieldDateRangePicker
            form={form}
            block={block}
            scope={scope}
            loading={loading}
            formState={formState}
            setFormState={setFormState}
            onChange={(value, variable) => {
              editFilterState(
                variable,
                value,
                filterState,
                setFilterState,
                "two"
              );
            }}
          />
        );
        break;
      }

      case "field_textarea":
      case "edit_inline_textarea": {
        component = (
          <FieldTextarea
            editInline={block.type === "edit_inline_textarea"}
            form={form}
            block={block}
            scope={scope}
            loading={loading}
            formState={formState}
            setFormState={setFormState}
            onChange={(value, variable) => {
              editFilterState(variable, value, filterState, setFilterState);
            }}
          />
        );
        break;
      }

      case "field_checkbox": {
        component = (
          <FieldCheckbox
            form={form}
            block={block}
            disabled={disabled}
            scope={scope}
            loading={loading}
            onChange={(value, variable) => {
              setFormState({ ...formState, [variable]: value });

              editFilterState(
                variable,
                value,
                filterState,
                setFilterState,
                "multi"
              );
            }}
          />
        );
        break;
      }
      case "checkbox": {
        component = (
          <CheckboxGroup
            form={form}
            block={block}
            scope={scope}
            formState={formState}
            setFormState={setFormState}
            loading={loading}
            onChange={(value, variable) => {
              editFilterState(
                variable,
                value,
                filterState,
                setFilterState,
                "multi"
              );
            }}
          />
        );
        break;
      }
      case "radiobutton": {
        component = (
          <RadioGroup
            form={form}
            block={block}
            scope={scope}
            loading={loading}
            onChange={(value, variable) => {
              editFilterState(variable, value, filterState, setFilterState);
            }}
          />
        );
        break;
      }
      case "switcher": {
        component = (
          <Switcher
            form={form}
            body={block.body}
            title={block.title}
            block={block}
            scope={scope}
            loading={loading}
            onChange={(value, variable) => {
              editFilterState(variable, value, filterState, setFilterState);
            }}
          />
        );
        break;
      }

      case "field_radiobutton": {
        component = (
          <FieldRadioButton
            form={form}
            block={block}
            disabled={disabled}
            scope={scope}
            loading={loading}
            state={formState}
            onChange={(variable, value) => {
              setFormState({ ...formState, [variable]: value });

              editFilterState(variable, value, filterState, setFilterState);
            }}
          />
        );
        break;
      }

      case "field_dropdown":
      case "edit_inline_dropdown":
      case "dropdown": {
        component = (
          <FieldDropdown
            editInline={block.type === "edit_inline_dropdown"}
            form={form}
            block={block}
            scope={scope}
            loading={loading}
            disabled={disabled}
            type="static"
            formState={formState}
            setFormState={setFormState}
            onChange={(value, variable) => {
              editFilterState(
                variable,
                value,
                filterState,
                setFilterState,
                Array.isArray(value) ? "multi" : "one"
              );
            }}
          />
        );
        break;
      }

      case "field_file_uploader":
      case "edit_inline_file_uploader": {
        component = (
          <FieldFileUploader
            editInline={block.type === "edit_inline_file_uploader"}
            form={form}
            block={block}
            scope={scope}
            loading={loading}
            formState={formState}
            setFormState={setFormState}
            onChange={(value, variable) => {
              editFilterState(
                variable,
                value,
                filterState,
                setFilterState,
                Array.isArray(value) ? "multi" : "one"
              );
            }}
            disabled={disabled}
          />
        );
        break;
      }

      case "field_lazyload_dropdown":
      case "edit_inline_lazyload_dropdown": {
        component = (
          <FieldDropdown
            editInline={block.type === "edit_inline_lazyload_dropdown"}
            form={form}
            block={block}
            scope={scope}
            loading={loading}
            type="lazyload"
            formState={formState}
            setFormState={setFormState}
            onChange={(value, variable) => {
              editFilterState(
                variable,
                value,
                filterState,
                setFilterState,
                Array.isArray(value) ? "multi" : "one"
              );
            }}
            disabled={disabled}
          />
        );
        break;
      }

      case "form_submit":
      case "form_cancel":
      case "form_reset": {
        component = (
          <FieldSubmit
            form={form}
            block={block}
            scope={scope}
            loading={loading}
            formState={formState}
            setFormState={setFormState}
          />
        );
        break;
      }

      case "edit_inline": {
        component = (
          <EditInlineField
            form={form}
            block={block}
            scope={scope}
            loading={loading}
            formState={formState}
            setFormState={setFormState}
            onChange={(value, variable) => {
              editFilterState(variable, value, filterState, setFilterState);
            }}
          />
        );
        break;
      }
      case "rate": {
        component = (
          <Rate
            form={form}
            block={block}
            scope={scope}
            loading={loading}
            formState={formState}
            setFormState={setFormState}
            onChange={(value, variable) => {
              editFilterState(variable, value, filterState, setFilterState);
            }}
          />
        );
        break;
      }
      case "notification": {
        component = (
          <Notification
            form={form}
            block={block}
            scope={scope}
            loading={loading}
            formState={formState}
            setFormState={setFormState}
            onChange={(value, variable) => {}}
          />
        );
        break;
      }

      case "tabs": {
        component = (
          <Tabs
            form={form}
            block={block}
            scope={scope}
            loading={loading}
            formState={formState}
            setFormState={setFormState}
          />
        );
        break;
      }
      case "listing_block": {
        const config: IScope[] = block?.config || [];
        const params = config.find((e) => e.name === "params")?.array || [];
        const paramsAll: any = [];

        params.forEach((one) => {
          paramsAll.push({
            name: getValFromArray(one.array as any, "name"),
            value: getValFromArray(one.array as any, "value"),
          });
        });
        component = (
          <DynamicList
            form={form}
            block={block}
            scope={scope}
            formState={formState}
            setFormState={setFormState}
            params={paramsAll}
          />
        );
        break;
      }
      case "grid": {
        component = (
          <GridItem
            // <ListItem

            show="grid"
            form={form}
            formState={formState}
            setFormState={setFormState}
            block={block}
            scope={scope}
            loading={false}
          />
        );
        break;
      }
      case "list": {
        component = (
          // <GridItem
          <GridItem
            show="list"
            form={form}
            formState={formState}
            setFormState={setFormState}
            block={block}
            scope={scope}
            loading={false}
          />
        );
        break;
      }
      case "print_preview": {
        component = <PrintPreview block={block} scope={scope} />;
        break;
      }
      case "audio_player": {
        component = <AudioPlayer block={block} loading={false} />;
        break;
      }
      case "video_player": {
        component = <VideoPlayer block={block} loading={false} />;
        break;
      }
      case "lazyload_weekly_schedule": {
        component = <WeeklyScheduleLazy block={block} formState={formState} />;
        break;
      }

      case "map": {
        component = <Map block={block} formState={formState} />;
        break;
      }

      case "journal_editor": {
        component = (
          <JournalEditor
            block={block}
            formState={formState}
            setFormState={setFormState}
          />
        );
        break;
      }

      case "invoice_items_editor": {
        component = (
          <InvoiceItemEditor
            block={block}
            formState={formState}
            setFormState={setFormState}
          />
        );
        break;
      }

      case "expense_items_editor": {
        component = (
          <ExpenseItemEditor
            block={block}
            formState={formState}
            setFormState={setFormState}
          />
        );
        break;
      }

      case "due_items_editor": {
        component = (
          <DueTermsEditor
            block={block}
            formState={formState}
            setFormState={setFormState}
          />
        );
        break;
      }
      case "installment_editor": {
        component = (
          <InstallmentEditor
            block={block}
            formState={formState}
            setFormState={setFormState}
          />
        );
        break;
      }

      case "product_mounts": {
        component = (
          <ProductAmountEditor
            block={block}
            formState={formState}
            setFormState={setFormState}
          />
        );
        break;
      }

      case "StaticLineChartBlock": {
        component = <StaticLineChartBlock scope={scope} block={block} />;
        break;
      }
      case "StaticDonutChartBlock": {
        component = <StaticDonutChartBlock scope={scope} block={block} />;
        break;
      }
      case "StaticPercentageBlock": {
        component = <StaticPercentageBlock scope={scope} block={block} />;
        break;
      }
      case "StaticPieChartBlock": {
        component = <StaticPieChartBlock scope={scope} block={block} />;
        break;
      }
      case "static_progressbar2": {
        component = (
          <StaticProgressbar2 block={block} scope={scope} loading={false} />
        );
        break;
      }
      case "course_selector": {
        component = (
          <CourseSelector
            block={block}
            scope={scope}
            loading={false}
            setFormState={setFormState}
          />
        );
        break;
      }
      case "field_rich_textarea": {
        component = (
          <FieldRichTextarea
            form={form}
            block={block}
            scope={scope}
            loading={loading}
            formState={formState}
            setFormState={setFormState}
            onChange={(value, variable) => {
              editFilterState(variable, value, filterState, setFilterState);
            }}
          />
        );
        break;
      }

      case "lazyload_timesheet": {
        component = <TimesheetLazy block={block} formState={formState} />;
        break;
      }

      case "time_sheet_time": {
        component = (
          <TimeSheetTime
            setFormState={setFormState}
            block={block}
            formState={formState}
          />
        );
        break;
      }

      case "iframe": {
        component = <IFrame block={block} />;
        break;
      }
      case "page_navigator": {
        component = <PageNavigator scope={scope} block={block} />;
        break;
      }

      case "timeline": {
        component = <Timeline block={block} />;
        break;
      }
      case "lazyload_calendar": {
        component = <LazyLoadCalendar formState={formState} block={block} />;
        break;
      }

      case "weekly_schedule_lines_editor": {
        component = (
          <WeeklyScheduleLinesEditor
            block={block}
            formState={formState}
            setFormState={setFormState}
          />
        );
        break;
      }

      default: {
        consoleLog("not found type ", block?.type);

        break;
      }
    }

    return component;
  };

  return { RenderBlocks };
};
