import { useAction } from "@/hooks/useAction";
import { IBlock } from "@/interfaces/block.interface";
import { IScope } from "@/interfaces/scope.interface";
import { Col, Input, Row, Select, Space } from "antd";
import debounce from "lodash/debounce";
import { Plus, X } from "phosphor-react";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import short from "short-uuid";
import Button from "../ui/buttons/Button";
import { Card } from "./Card";
import { Loading } from "./Loading";
import { replaceJsonDoubleQuotes } from "@/utils";

interface IInvoiceItemEditor {
  block: IBlock;
  formState: any;
  visible?: boolean;
  setFormState: any;
}

interface InvoiceRow {
  product: IOption | string;
  price: number;
  qty: number;
  tax: IOption | string;
  total: number;
  description: string;
  account: IOption | string;
  id: string;
  fetchProducts: boolean;
  productQuery: string;
  productsOptions: IOption[];
  fetchTaxs: boolean;
  taxQuery: string;
  taxsOptions: IOption[];
  productOption?: IOption;
  taxOption?: IOption;
  accountOption?: IOption;
  fetchAccounts?: boolean;
  accountQuery?: string;
  accountsOptions?: IOption[];
}

interface IOption {
  label: string;
  value: string;
  id?: string;
  option?: any;
}

export const InvoiceItemEditor: FC<IInvoiceItemEditor> = ({
  block,
  visible = true,
  formState,
  setFormState,
}) => {
  const defaultOption: InvoiceRow = {
    product: "",
    price: 0,
    qty: 0,
    tax: "",
    total: 0,
    account: "",
    description: "",
    id: short.generate(),
    fetchProducts: false,
    productQuery: "",
    productsOptions: [],
    fetchTaxs: false,
    taxQuery: "",
    taxsOptions: [],
  };
  const config: IScope[] = block?.config || [];

  const { doQuery } = useAction();

  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<IOption[]>([]);
  const [accounts, setAccounts] = useState<IOption[]>([]);
  const [taxs, setTaxes] = useState<IOption[]>([]);
  const [rows, setRows] = useState<InvoiceRow[]>([defaultOption]);

  const products_query = config.find((e) => e.name === "products_query")?.value;
  const products_accessor = config.find(
    (e) => e.name === "products_accessor"
  )?.value;
  const items_query = config.find((e) => e.name === "items_query")?.value;
  const items_accessor = config.find((e) => e.name === "items_accessor")?.value;
  const tax_query = config.find((e) => e.name === "taxes_query")?.value;
  const tax_accessor = config.find((e) => e.name === "taxes_accessor")?.value;
  const accounts_query = config.find((e) => e.name === "accounts_query")?.value;
  const accounts_accessor = config.find(
    (e) => e.name === "accounts_accessor"
  )?.value;
  const variable = config.find((e) => e.name === "variable")?.value;
  const enable_editing =
    config.find((e) => e.name === "enable_editing")?.value === "1";
  const disable_adding_items =
    config.find((e) => e.name === "disable_adding_items")?.value === "1";
  const disable_tax =
    config.find((e) => e.name === "disable_tax")?.value === "1";

  const getData = () => {
    if (products_query) {
      return doQuery({
        query: products_query,
        scope: [{ name: "product_name", value: "" }],
        withQuate: false,
      });
    }
  };

  const getTaxes = () => {
    if (tax_query) {
      return doQuery({
        query: tax_query,
      });
      // .then((data: any) => {
      //   if (data?.data) {
      // const taxs: IOption[] =
      //   data?.data[tax_accessor || ""].map((one: any) => ({
      //     label: one.title,
      //     value: one.name,
      //     option: one,
      //   })) || [];
      //     setTaxes(taxs);
      //     // setRows((s) =>
      //     //   s.map((e) => {
      //     //     e.taxOption = taxs.find((tx) => {
      //     //       if (typeof e.tax !== "string") {
      //     //         return tx.option.id === e.tax.id;
      //     //       } else {
      //     //         return tx.value === e.tax;
      //     //       }
      //     //     });
      //     //     return e;
      //     //   })
      //     // );
      //   }
      // });
    }
  };

  useEffect(() => {
    const newRows: InvoiceRow[] = JSON.parse(JSON.stringify(rows));
    setFormState((s: any) => ({
      ...s,
      [variable as string]: replaceJsonDoubleQuotes(
        JSON.stringify(
          newRows.map((one) => {
            return one;
          })
        )
      ),
    }));
  }, [rows, setFormState, variable]);

  const searchProducts = useMemo(() => {
    if (!products_query) return;
    const loadOptions = (val: string, one: InvoiceRow) => {
      if (val === "") {
        return;
      }
      setRows((s) =>
        s.map((e) => {
          if (e.id === one.id) {
            e.fetchProducts = true;
            e.productQuery = val;
          }
          return e;
        })
      );
      doQuery({
        query: products_query,
        scope: [{ name: "product_name", value: val.toString() }],
        withQuate: false,
      })
        .then((data: any) => {
          if (data?.data) {
            setRows((s) =>
              s.map((e) => {
                if (e.id === one.id) {
                  e.productsOptions =
                    data?.data[products_accessor || ""].options.map(
                      (one: any) => ({
                        label: one.title,
                        value: one.name,
                      })
                    ) || [];
                }
                return e;
              })
            );
          }
        })
        .finally(() => {
          setRows((s) =>
            s.map((e) => {
              if (e.id === one.id) {
                e.fetchProducts = false;
              }
              return e;
            })
          );
        });
    };

    return debounce(loadOptions, 1000);
  }, [products_query, rows, products_accessor]);

  const getItems = () => {
    if (items_query) {
      return doQuery({
        query: items_query,
      });
    }
  };

  const getAccounts = () => {
    if (accounts_query && accounts_accessor) {
      return doQuery({
        query: accounts_query,
      });
    }
  };

  const getAllData = useCallback(async () => {
    try {
      let productsOptions: any,
        taxsOptions: any,
        itemOptions: any,
        accountsOptions: any = [];
      const res: any = await Promise.all([
        getData(),
        getTaxes(),
        getItems(),
        getAccounts(),
      ]);

      const productsResponse = res[0];
      const taxsResponse = res[1];
      const itemsResponse = res[2];
      const accountsResponse = res[3];

      if (productsResponse?.data) {
        productsOptions =
          productsResponse?.data[products_accessor || ""].options.map(
            (one: any) => ({
              label: one.title,
              value: one.name,
              option: one,
            })
          ) || [];
      }
      if (taxsResponse?.data) {
        taxsOptions =
          taxsResponse?.data[tax_accessor || ""].map((one: any) => ({
            label: one.title,
            value: one.name,
            option: one,
          })) || [];
      }
      if (accountsResponse?.data) {
        accountsOptions =
          accountsResponse?.data[accounts_accessor || ""].options.map(
            (one: any) => ({
              label: one.title,
              value: one.name,
              option: one,
            })
          ) || [];
      }

      if (itemsResponse?.data) {
        itemOptions = [...(itemsResponse?.data[items_accessor || ""] || [])];
      }

      itemOptions =
        itemOptions &&
        itemOptions.map((one: any) => {
          if (one.tax.id) {
            one.taxOption = taxsOptions.find((tx: any) => {
              if (typeof one.tax !== "string") {
                return tx.option.id === one.tax.id;
              } else {
                return tx.value === one.tax;
              }
            });
            one.tax = one.taxOption.value;
            one.total = one.price * one.qty;
            if (one.taxOption) {
              if (one.taxOption?.option?.type === "fixed") {
                one.total = one.total + one.taxOption?.option?.value;
              }
              if (one.taxOption?.option?.type === "percent") {
                one.total =
                  one.total + (one.total * one.taxOption?.option?.value) / 100;
              }
            }
            return one;
          }
        });

      setTaxes(taxsOptions);
      setOptions(productsOptions);
      setAccounts(accountsOptions);
      setRows(itemOptions ? [...itemOptions, defaultOption] : [defaultOption]);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    getAllData();
  }, []);

  const calcTotal = () => {
    return rows.reduce((a, b) => {
      return a + b.price * b.qty;
    }, 0);
  };

  const calcTax = () => {
    return rows.reduce((a, b) => {
      return (
        a +
        (b.taxOption
          ? b.taxOption?.option?.type === "percent"
            ? (b.qty * b.price * b.taxOption?.option?.value) / 100
            : b.taxOption?.option?.value
          : 0)
      );
    }, 0);
  };

  const calcTotalWithTax = () => {
    return rows.reduce((a, b) => {
      return a + b.total;
    }, 0);
  };

  const updateRows = useCallback(() => {
    setRows((s) =>
      s.map((one) => {
        one.total = one.price * one.qty;
        if (one.taxOption) {
          if (one.taxOption?.option?.type === "fixed") {
            one.total = one.total + one.taxOption?.option?.value;
          }
          if (one.taxOption?.option?.type === "percent") {
            one.total =
              one.total + (one.total * one.taxOption?.option?.value) / 100;
          }
        }
        return one;
      })
    );
  }, [rows]);

  return visible ? (
    <>
      <Card title="" body="">
        {loading && <Loading />}
        {!loading && (
          <Row
            style={{
              gap: "10px",
              justifyContent: "space-between",
            }}
            align="middle"
          >
            <Col>
              <p>{t("product")}</p>
            </Col>
            <Col>
              <p>{t("price")}</p>
            </Col>
            <Col>
              <p>{t("quantity")}</p>
            </Col>
            {!!accounts_accessor && (
              <Col>
                <p>{t("account")}</p>
              </Col>
            )}
            <Col>
              <p>{t("tax")}</p>
            </Col>
            <Col>
              <p>{t("total")}</p>
            </Col>
            <Col>
              <p>{t("description")}</p>
            </Col>
            <Col style={{ minWidth: 65 }} />
          </Row>
        )}
        {!loading &&
          rows.map((one, index) => (
            <Row
              key={index}
              style={{ marginBottom: "10px", gap: "10px" }}
              align="middle"
            >
              <Select
                disabled={!enable_editing}
                className="flex-1"
                showSearch
                onSearch={(val) => searchProducts?.(val, one)}
                value={
                  typeof one.product !== "string"
                    ? one.product.id
                    : one.product || undefined
                }
                filterOption={false}
                onBlur={() => {
                  setRows(
                    rows.map((e) => {
                      if (e.id === one.id) {
                        e.fetchProducts = false;
                        e.productQuery = "";
                      }
                      return e;
                    })
                  );
                }}
                options={one.productQuery ? one.productsOptions : options}
                loading={one.fetchProducts}
                onChange={(value) => {
                  setRows(
                    rows.map((e) => {
                      if (e.id === one.id) {
                        const dt = options || one.productsOptions;
                        const productOption: any = dt.find(
                          (e) => e.value === value
                        );
                        e.productOption = productOption;
                        e.product = value;
                        e.price =
                          e.productOption?.option?.object.sale_price || 0;

                        const inventory_account_id =
                          productOption.option?.object?.inventory_account_id;
                        e.account = inventory_account_id.toString();

                        e.accountOption = accounts.find(
                          (e) => e.value == inventory_account_id
                        );
                      }
                      return e;
                    })
                  );
                  updateRows();
                }}
                placeholder={t("selectProduct")}
              />
              <Input
                disabled={!enable_editing}
                className="flex-1"
                placeholder={t("price")}
                type="number"
                value={one.price}
                onChange={(event) => {
                  setRows(
                    rows.map((e) => {
                      if (e.id === one.id) {
                        e.price = Number(event.target.value);
                      }
                      return e;
                    })
                  );
                  updateRows();
                }}
              />
              <Input
                disabled={!enable_editing}
                className="flex-1"
                placeholder={t("quantity")}
                type="number"
                value={one.qty}
                onChange={(event) => {
                  setRows(
                    rows.map((e) => {
                      if (e.id === one.id) {
                        e.qty = Number(event.target.value);
                      }
                      return e;
                    })
                  );
                  updateRows();
                }}
              />
              {!!accounts_accessor && (
                <Select
                  disabled={!enable_editing}
                  className="flex-1"
                  value={
                    typeof one.account !== "string"
                      ? one?.account?.id || one?.account?.value
                      : one.account || undefined
                  }
                  filterOption={false}
                  onBlur={() => {
                    setRows(
                      rows.map((e) => {
                        if (e.id === one.id) {
                          e.fetchAccounts = false;
                          e.accountQuery = "";
                        }
                        return e;
                      })
                    );
                  }}
                  options={one.accountQuery ? one.accountsOptions : accounts}
                  loading={one.fetchAccounts}
                  onChange={(value) => {
                    setRows(
                      rows.map((e) => {
                        if (e.id === one.id) {
                          e.accountOption = accounts.find(
                            (e) => e.value === value
                          );
                          e.account = value;
                        }
                        return e;
                      })
                    );
                    updateRows();
                  }}
                  placeholder={t("selectAccount")}
                />
              )}
              <Select
                disabled={!enable_editing || disable_tax}
                className="flex-1"
                value={
                  typeof one.tax !== "string"
                    ? one.tax?.id
                    : one.tax || undefined
                }
                filterOption={false}
                onBlur={() => {
                  setRows(
                    rows.map((e) => {
                      if (e.id === one.id) {
                        e.fetchTaxs = false;
                        e.taxQuery = "";
                      }
                      return e;
                    })
                  );
                }}
                options={one.taxQuery ? one.taxsOptions : taxs}
                loading={one.fetchTaxs}
                onChange={(value) => {
                  setRows(
                    rows.map((e) => {
                      if (e.id === one.id) {
                        e.taxOption = taxs.find((e) => e.value === value);
                        e.tax = value;
                      }
                      return e;
                    })
                  );
                  updateRows();
                }}
                placeholder={t("selectTax")}
              />
              <Input
                disabled
                className="flex-1"
                placeholder={t("total")}
                type="number"
                value={one.total}
                onChange={(event) => {
                  setRows(
                    rows.map((e) => {
                      if (e.id === one.id) {
                        e.total = Number(event.target.value);
                      }
                      return e;
                    })
                  );
                }}
              />
              <Input
                disabled={!enable_editing}
                className="flex-1"
                placeholder={t("description")}
                value={one.description}
                onChange={(event) => {
                  setRows(
                    rows.map((e) => {
                      if (e.id === one.id) {
                        e.description = event.target.value;
                      }
                      return e;
                    })
                  );
                }}
              />

              {enable_editing && (
                <>
                  {index === rows.length - 1 ? (
                    <>
                      {!disable_adding_items && (
                        <Button
                          onClick={() => {
                            setRows((s) => [...s, defaultOption]);
                          }}
                          variant="primary"
                          type="link"
                          size="small"
                        >
                          <Plus />
                        </Button>
                      )}
                    </>
                  ) : (
                    <Button
                      onClick={() => {
                        setRows(rows.filter((e) => e.id !== one.id));
                      }}
                      variant="danger"
                      type="link"
                      size="small"
                    >
                      <X />
                    </Button>
                  )}
                </>
              )}
            </Row>
          ))}

        <Space direction="vertical" className="invoice-item-footer">
          <div className="d-flex invoice-total-result">
            <p>{t("total")}</p>
            <span>{isNaN(calcTotal()) ? 0 : calcTotal()}</span>
          </div>
          <div className="d-flex invoice-total-result">
            <p>{t("taxTotal")}</p>
            <span>{isNaN(calcTax()) ? 0 : calcTax().toFixed(4)}</span>
          </div>
          <div className="d-flex invoice-total-result">
            <p>{t("totalWithTax")}</p>
            <span>
              {isNaN(calcTotalWithTax()) ? 0 : calcTotalWithTax().toFixed(4)}
            </span>
          </div>
        </Space>
      </Card>
    </>
  ) : (
    <></>
  );
};
