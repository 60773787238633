import { Card } from "@components/dynamic/Card";
import { useAction } from "@hooks/useAction";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import { Statistic } from "antd";
import React from "react";

export const StaticKpi: React.FC<{
  block: IBlock;
  scope: IScope[];
  loading: boolean;
  visible?: boolean;
}> = ({ block, scope = [], loading, visible }) => {
  const { replaceFromScope } = useAction();

  const config: IScope[] = block?.config ? block?.config : [];
  const value = config.find((e: any) => e.name === "value");
  const unit = config.find((e: any) => e.name === "unit")?.value || "";
  const prefix = config.find((e: any) => e.name === "prefix")?.value || "";
  const suffix = config.find((e: any) => e.name === "suffix")?.value || "";
  const precision = config.find((e: any) => e.name === "precision")?.value || 0;

  const expandable =
    config.find((e: any) => e.name === "expandable")?.value === "1";
  const expanded =
    config.find((e: any) => e.name === "expanded")?.value === "1";

  return (
    <Card
      scope={scope}
      body={block?.body}
      title={block?.title}
      expanded={expanded}
      classes={block?.classes}
      expandable={expandable}
      loading={loading}
      visible={visible}
      config={config}
    >
      <Statistic
        value={value?.value}
        precision={Number(precision)}
        valueStyle={{ color: "#3f8600" }}
        prefix={prefix}
        suffix={suffix}
      />

      <p
        dangerouslySetInnerHTML={{
          __html: replaceFromScope({
            str: unit,
            scope,
            withQuate: false,
          }),
        }}
      ></p>
    </Card>
  );
};
