import { BadgeItem } from "@components/dynamic/Badge";
import { Icon } from "@components/dynamic/Icon";
import Button, { CustomButtonProps } from "@components/ui/buttons/Button";
import { useAction } from "@hooks/useAction";
import { IAction } from "@interfaces/action.interface";
import { IScope } from "@interfaces/scope.interface";
import { Popconfirm, Tooltip } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  item: any;
  action: IAction;
  scope?: IScope[];
  extraParams?: IScope["array"];

  // type?: "action" | "general" | "general_header";
  actionClicked?: (res: any) => void;
  style?: React.CSSProperties;
  customBadge?: number;
  disabled?: boolean;
  filters?: any;
  withTooltip?: boolean;
}

export interface IActionState {
  query: string;
}
export const Action = React.forwardRef<CustomButtonProps, IProps>(
  (
    {
      item,
      action,
      scope = [],
      style = {},
      actionClicked,
      extraParams = [],
      customBadge,
      disabled,
      filters,
      withTooltip,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { clickAction, replaceFromScope, convertItemToScope } = useAction();

    const [state, setState] = useState<IActionState>({
      query: "",
    });

    const config = (action.config || []) as IScope[];
    const need_confirm =
      config.find((e) => e.name === "need_confirm")?.value === "1";
    const append_to_block = config.find(
      (e) => e.name === "append_to_block"
    )?.value;
    const can_do_key = config.find((e) => e.name === "can_do_key")?.value;
    const confirm_message = config.find(
      (e) => e.name === "confirm_message"
    )?.value;
    const confirm_button = config.find(
      (e) => e.name === "confirm_button"
    )?.value;
    const page_config =
      config.find((e) => e.name === "page_config")?.array || [];
    const enable_action = can_do_key
      ? item?.meta?.find((e: any) => e.name === can_do_key)?.value === "1"
      : true;
    const badge = config.find((e) => e.name === "badge")?.array || [];

    const onClickAction = (action: IAction, _item?: any) => {
      clickAction({
        action,
        setState,
        item: {
          ...item,
          ..._item,
        },
        scope,
        actionClicked,
        extraParams,
        filters,
      });
    };

    if (withTooltip === false) {
      return (
        <Button
          ref={ref}
          size={action.size}
          variant={action.variant}
          types={action.action_type}
          onClick={(e) => {
            onClickAction(action);
            e.stopPropagation();
          }}
          icon={action.icon && <Icon name={action.icon + ""}></Icon>}
          block={action.block}
          // className={action.classes}
          disabled={disabled === true ? true : action.disabled}
          loading={action.loading}
          shape={action.shape}
          style={style}
        >
          {action.label}
        </Button>
      );
    }

    return (
      <React.Fragment>
        {enable_action &&
          (need_confirm ? (
            <BadgeItem style={style} badge={badge} customBadge={customBadge}>
              <Tooltip title={action.toolTip} style={style} placement="bottom">
                <Popconfirm
                  title={replaceFromScope({
                    str: confirm_message || "",
                    scope: [...scope, ...convertItemToScope(item || {})],
                    withQuate: false,
                  })}
                  okText={replaceFromScope({
                    str: confirm_button || "",
                    scope,
                    withQuate: false,
                  })}
                  cancelText={t("cancel")}
                  onConfirm={(e) => {
                    // e.stopPropagation();
                    onClickAction(action);
                  }}
                >
                  <Button
                    ref={ref}
                    // size={action.size}
                    variant={action.variant}
                    types={action.action_type}
                    icon={action.icon && <Icon name={action.icon + ""}></Icon>}
                    block={action.block}
                    // className={action.classes}
                    disabled={disabled === true ? true : action.disabled}
                    loading={action.loading}
                    shape={action.shape}
                    style={style}
                  >
                    {action.label}
                  </Button>
                </Popconfirm>
              </Tooltip>
            </BadgeItem>
          ) : (
            <BadgeItem style={style} badge={badge} customBadge={customBadge}>
              <Tooltip title={action.toolTip} style={style} placement="bottom">
                <Button
                  ref={ref}
                  // size={action.size}
                  variant={action.variant}
                  types={action.action_type}
                  onClick={(e) => {
                    onClickAction(action);
                    e.stopPropagation();
                  }}
                  icon={action.icon && <Icon name={action.icon + ""}></Icon>}
                  block={action.block}
                  // className={action.classes}
                  disabled={disabled === true ? true : action.disabled}
                  loading={action.loading}
                  shape={action.shape}
                  style={style}
                >
                  {action.label}
                </Button>
              </Tooltip>
            </BadgeItem>
          ))}
      </React.Fragment>
    );
  }
);
