import { ModalActions, modalSelector } from "@/store/reducers/modal.reducer";
import {
  SideMenuActions,
  sideMenuSelector,
} from "@/store/reducers/side-menu.reducer";
import { removefirstAndLastTwoCharachters } from "@/utils";
import { Card } from "@components/dynamic/Card";
import { RenderPage } from "@components/RenderPage";
import { IBlock } from "@interfaces/block.interface";
import { IScope, IScopeArray } from "@interfaces/scope.interface";
import { FormInstance, Tabs as AntTabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

interface IProps {
  block: IBlock;
  scope: IScope[];
  loading: boolean;
  form: FormInstance<any>;

  formState: any;
  setFormState: any;
  editInline?: boolean;

  onChange?: (value: any, variable: string) => void;
  visible?: boolean;
}

export const Tabs: React.FC<IProps> = ({
  block,
  scope = [],
  form,
  setFormState,
  formState,
  onChange,
  editInline = false,
  visible,
  ...props
}) => {
  const [tabsState, setTabsState] = useState<IScopeArray[]>([]);
  const config: IScope[] = block?.config || [];
  const { tabs: storeTabs } = useSelector(sideMenuSelector);
  const tabs = config.find((e) => e.name === "tabs")?.array || [];
  const [currentIndex, setCurrentIndex] = useState(0);
  const expanded = config.find((e) => e.name === "expanded")?.value === "1";
  const expandable = config.find((e) => e.name === "expandable")?.value === "1";

  const scope_variables =
    scope.find((e) => e.name === "panel_data")?.array || [];

  const { page_name } = useSelector(modalSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setTabsState(tabs);
    }, 200);
  }, [tabs]);

  useEffect(() => {
    dispatch(
      ModalActions.toggleModal({
        tabs_opened: true,
      })
    );
    return () => {
      tabs.forEach((one) => {
        one.array?.forEach((one) => {
          if (one.name === "page_name") {
            dispatch(ModalActions.removeStack(one.value));
          }
        });
      });
      dispatch(
        ModalActions.toggleModal({
          tabs_opened: false,
        })
      );
      dispatch(SideMenuActions.setTabPageName(""));
      dispatch(SideMenuActions.resetTabs());
    };
  }, []);

  return (
    <Card
      body={""}
      title={block?.title}
      expanded={expanded}
      classes={block?.classes}
      expandable={expandable}
      visible={visible}
      config={config}
    >
      <AntTabs
        onTabClick={(e) => {
          const index: number = e.split("-")[1] as any;
          setCurrentIndex(index * 1);
          const tab = tabs[index];
          const name =
            tab.array?.find((e) => e.name === "page_name")?.value || "";

          dispatch(
            ModalActions.toggleModal({
              page_name: name,
              previous_page_name: page_name,
              tabs_opened: true,
              current_tab: name,
            })
          );

          const currentTabStore = storeTabs?.find((e) => e.page_name === name);

          if (currentTabStore) {
            dispatch(
              SideMenuActions.setTabPageName(currentTabStore.side_page_name)
            );
          }
        }}
      >
        {tabsState.map((tab, index) => {
          const label = tab.array?.find((e) => e.name === "label")?.value || "";
          const panel = tab.array?.find((e) => e.name === "panel")?.value || "";
          const page_name =
            tab.array?.find((e) => e.name === "page_name")?.value || "";
          const disabled =
            tab.array?.find((e) => e.name === "disabled")?.value === "1";
          const page_params =
            tab.array?.find((e) => e.name === "page_params")?.array || [];
          const cached =
            tab.array?.find((e) => e.name === "cached")?.value === "1";
          return (
            <AntTabs.TabPane
              disabled={disabled}
              tab={<span>{label}</span>}
              key={`tab-${index}`}
            >
              {!!page_name && (
                <>
                  {cached ? (
                    <RenderPage
                      className="no-side-menu"
                      name={page_name}
                      page_params={page_params?.map((one: any) => ({
                        name: one.name,
                        value:
                          one.value.indexOf("@@") > -1
                            ? scope_variables.find(
                                (o) =>
                                  o.name ===
                                  removefirstAndLastTwoCharachters(one.value)
                              )?.value
                            : one.value,
                      }))}
                    />
                  ) : index === currentIndex ? (
                    <RenderPage
                      className="no-side-menu"
                      name={page_name}
                      page_params={page_params?.map((one: any) => ({
                        name: one.name,
                        value:
                          one.value.indexOf("@@") > -1
                            ? scope_variables.find(
                                (o) =>
                                  o.name ===
                                  removefirstAndLastTwoCharachters(one.value)
                              )?.value
                            : one.value,
                      }))}
                    />
                  ) : null}
                </>
              )}
            </AntTabs.TabPane>
          );
        })}
      </AntTabs>
    </Card>
  );
};
