import { Icon } from "@components/dynamic/Icon";
import Button from "@components/ui/buttons/Button";
import { useAction } from "@hooks/useAction";
import { useUtiles } from "@hooks/useUtiles";
import { IAction } from "@interfaces/action.interface";
import { IScope } from "@interfaces/scope.interface";
import { notificationSelector } from "@store/reducers/notification.reducer";
import { notification, Space } from "antd";
import { NotificationPlacement } from "antd/lib/notification";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

type NotificationType = "success" | "info" | "warning" | "error";

interface IProps {
  item?: any;
  action?: IAction;
  scope?: IScope[];
  type?: "action" | "general";
  actionClicked?: (res: any) => void;
}

interface IState {
  visibleModal: boolean;
  visibleDrawer: boolean;

  target: string;
  url: string;
  page_name: string;
  page_params: any[];
  drawerWidth: string;
  modalWidth: string;
  query: string;
  scope: IScope[];
}

const Context = React.createContext({ name: "Default" });

export const Notification: React.FC<IProps> = ({}) => {
  const { consoleLog } = useUtiles();
  const [r, setR] = useState(true);
  const [api, contextHolder] = notification.useNotification();

  const notifications = useSelector(notificationSelector).notifications;

  useEffect(() => {
    notifications.forEach((notificationItem) => {
      const actions = notificationItem.actions || [];
      const config = notificationItem.config || [];
      const description = notificationItem.description || "";
      const key = notificationItem.key || "";
      const message = notificationItem.message || "";
      const name = notificationItem.name || "";
      const onClickAction = notificationItem.onClickAction || [];
      const onCloseAction = notificationItem.onCloseAction || [];
      const type = (config.find((e) => e.name === "type")?.value ||
        "success") as NotificationType;
      const bottom = config.find((e) => e.name === "bottom")?.value;
      const top = config.find((e) => e.name === "top")?.value;
      const icon = config.find((e) => e.name === "icon")?.value;
      const duration = config.find((e) => e.name === "duration")?.value;
      const rtl = config.find((e) => e.name === "rtl")?.value === "1";
      const placement = (config.find((e) => e.name === "placement")?.value ||
        "bottomRight") as NotificationPlacement;
      const classes = (config.find((e) => e.name === "classes")?.array || [])
        .map((e) => e.value)
        .join(" ");
      let style = {};

      const doStyle = (config.find((e) => e.name === "style")?.array || []).map(
        (e) => {
          style = { ...style, [e.name]: e.value };
          return {};
        }
      );

      notification.config({
        top: top ? parseInt(top) : undefined,
        bottom: bottom ? parseInt(bottom) : undefined,
        duration: duration ? parseInt(duration) : undefined,
        rtl: r,
      });
      setR(!r);
      notification[type]({
        message,
        key,
        description: (
          <div
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          ></div>
        ),
        placement,
        style: { ...style },
        icon: icon ? (
          <Icon
            name={icon}
            className={`anticon ant-notification-notice-icon-${type}`}
          /> // className="ant-notification-notice-icon-error"
        ) : undefined,
        className: classes,
        btn: (
          <Space>
            {actions.map((action, index) => {
              return (
                <Button
                  key={`not-${index}`}
                  //   shape="circle"
                  type="primary"
                  size="small"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    clickActionEvent(action, undefined);
                  }}
                  icon={action.icon && <Icon name={action.icon + ""} />}
                >
                  {action.label || ""}
                </Button>
              );
            })}
          </Space>
        ),

        onClick: () =>
          onClickAction.length > 0 &&
          clickActionEvent(onClickAction[0], undefined),
        onClose: () =>
          onCloseAction.length > 0 &&
          clickActionEvent(onCloseAction[0], undefined),
        //
      });
    });

    // dispatch(Actions.setNotifications([]));
  }, [notifications]);

  const [state, setState] = useState<IState>({
    visibleModal: false,
    visibleDrawer: false,
    target: "",
    url: "",
    page_name: "",
    page_params: [],
    drawerWidth: "600",
    modalWidth: "600",
    query: "",
    scope: [],
  });

  const { clickAction } = useAction();
  const { doQuery } = useAction();
  React.useEffect(() => {
    if (state.query) {
      doQuery({ query: state.query, scope: state.scope }).then((res) => {
        // actionClicked && actionClicked(res);
      });
    }
  }, [state.query]);

  const clickActionEvent = (action: IAction, item?: any) => {
    clickAction({ action, item, scope: [] });
  };

  return (
    <React.Fragment>
      <Context.Provider value={{ name: "Ant Design" }}>
        {contextHolder}
      </Context.Provider>
    </React.Fragment>
  );
};
