import { SocketActions, socketSelector } from "@/store/reducers/socket.reducer";
import { dispatch } from "@/store/store";
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import io, { Socket } from "socket.io-client";

interface IState {
  isConnected: boolean;
}

type ConnectType = {
  token: string;
  endpoint: string;
};

export const useSocket = () => {
  const { isConnected, socket } = useSelector(socketSelector);

  // socket?.emit("join", {
  //   room: "general",
  // });

  // socket?.on("general", (data) => {
  //   console.log("general", data);
  // });

  // setTimeout(() => {
  //   console.log("send message");

  //   sendMessage("send_message", {
  //     room: "general",
  //     message: "hello",
  //   });
  // }, 3000);

  const [state, setState] = useState<IState>({
    isConnected: false,
  });

  const sendMessage = <T extends any>(eventName: string, data?: T) => {
    socket?.emit(eventName, data);
  };

  const listenOnRoom = (
    room: string,
    callback?: <T extends any>(data: T) => void
  ) => {
    socket?.on(room, (data) => {
      callback && callback(data);
    });
  };

  const removeListner = (room: string) => {
    socket?.off(room);
  };

  const joinRoom = (room: string) => {
    socket?.emit("join", { room });
  };

  const leaveRoom = (room: string) => {
    socket?.emit("leave", { room });
  };

  const connect = ({ token, endpoint }: ConnectType) => {
    dispatch(SocketActions.connectSocket({ token, endpoint }));
  };

  //   useEffect(() => {
  //     socket.current.on("connect", () => {
  //       console.log("connected");

  //       setState((s) => ({ ...s, isConnected: true }));
  //     });

  //     socket.current.on("disconnect", () => {
  //       console.log("disconnected");
  //       setState((s) => ({ ...s, isConnected: false }));
  //     });

  //     socket.current.emit("join", {
  //       room: "general",
  //     });

  //     socket.current.on("general", (data) => {
  //       console.log("general", data);
  //     });

  //     setTimeout(() => {
  //       console.log("send message");

  //       sendMessage("send_message", {
  //         room: "general",
  //         message: "hello",
  //       });
  //     }, 3000);

  //     return () => {
  //       socket.current.off("connect");
  //       socket.current.off("disconnect");
  //       socket.current.off("general");
  //     };
  //   }, [socket.current]);

  return {
    sendMessage,
    listenOnRoom,
    connect,
    joinRoom,
    leaveRoom,
    removeListner,
  };
};
