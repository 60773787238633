import React, { Component, useState } from "react";
import OtpInput from "react-otp-input";

type Props = {
  value: string;
  onChange?: (otp: string) => void;
};

export const Otp: React.FC<Props> = ({ onChange }) => {
  const [value, setValue] = useState("");
  const handleChange = (otp: string) => {
    setValue(otp);


    if (otp.length === 6) {
      onChange && onChange(otp);
    }
  };
  return (
    <OtpInput
      value={value}
      containerStyle={{
        justifyContent: "center",
      }}
      inputStyle={{
        width: 40,
        height: 40,
        margin: 10,
        padding: 0,

        outline: "none",

        boxSizing: "border-box",

        fontVariant: "tabular-nums",
        listStyle: "none",
        fontFeatureSettings: " tnum",
        position: "relative",
        display: "inline-block",

        minWidth: "0px",

        color: "rgb(0, 0, 0)",
        fontSize: "14px",
        lineHeight: "1.5715",
        backgroundColor: " rgb(245, 248, 255)",
        backgroundImage: " none",
        border: "1px solid ",
        borderRadius: "4px",
        transition: "all 0.3s ease 0s",
      }}
      onChange={handleChange}
      numInputs={6}
      isInputNum={false}
      separator={<span> </span>}
    />
  );
};
