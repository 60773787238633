import { formSelector } from "@/store/reducers/form.reducer";
import { ModalActions, modalSelector } from "@/store/reducers/modal.reducer";
import { getValFromArray } from "@/utils";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const useRedirect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { showModal, showDrawer } = useSelector(modalSelector);
  const { lastType } = useSelector(formSelector);
  const checkRedirect = (
    config: any,
    res?: any,
    form?: any,
    setFormState?: any
  ) => {
    if (res) {
      const new_page_name = res.page_name;
      const new_page_params = res.page_params || [];
      if (new_page_name) {
        dispatch(
          ModalActions.toggleModal({
            modalPageName: new_page_name,
            modalePageParams: new_page_params,
          })
        );
      }
    }

    const success_self_event =
      config.find((e: any) => e.name === "success_self_event")?.value || "";
    const success_parent_event =
      config.find((e: any) => e.name === "success_parent_event")?.value || "";

    const success_redirect = config.find(
      (e: any) => e.name === "success_redirect"
    )?.value;
    const self_event = config.find((e: any) => e.name === "self_event")?.value;
    const page_name = config.find((e: any) => e.name === "page_name")?.value;
    const page_params = config.find(
      (e: any) => e.name === "page_params"
    )?.array;

    if (success_self_event === "close") {
      if (lastType === "modal" && showModal) {
        dispatch(
          ModalActions.toggleModal({
            showModal: false,
            modalPageName: "",
            title: "",
          })
        );
      }
      if (lastType === "drawer" && showDrawer) {
        dispatch(
          ModalActions.toggleModal({
            showDrawer: false,
            drawerPageName: "",
          })
        );
      }
    }
    if (success_self_event === "refresh") {
      form.resetFields();
      setFormState({});
      dispatch(ModalActions.toggleModal({ refreshSelf: true }));
    }

    if (success_parent_event) {
      if (success_parent_event === "strict_refresh") {
        const parent_page_refresh = getValFromArray(
          config,
          "parent_page_refresh"
        );
        dispatch(
          ModalActions.toggleModal({
            refreshCurrent: true,
            currentPage: parent_page_refresh,
          })
        );
        return;
      }
      if (success_parent_event === "refresh") {
        form.resetFields();
        setFormState({});
        dispatch(ModalActions.toggleModal({ refreshParent: true }));
        return;
      } else if (success_parent_event === "update_modal") {
        dispatch(ModalActions.toggleModal({ reload_datatable: true }));
        return;
      } else if (success_parent_event === "refresh_datatable") {
        dispatch(ModalActions.toggleModal({ reload_datatable: true }));
        return;
      } else if (success_parent_event === "refresh_list") {
        dispatch(ModalActions.toggleModal({ reload_list: true }));
        return;
      } else if (success_parent_event === "refresh_page") {
        window.location.reload();
        return;
      } else {
        if (lastType === "modal" && showModal) {
          dispatch(
            ModalActions.toggleModal({
              showModal: false,
              modalPageName: "",
              title: "",
            })
          );
          return;
        }
        if (lastType === "drawer" && showDrawer) {
          dispatch(
            ModalActions.toggleModal({
              showDrawer: false,
              drawerPageName: "",
            })
          );
          return;
        }
      }
      navigate(success_parent_event);
    }
  };

  return {
    checkRedirect,
  };
};
