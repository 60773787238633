import { config } from "@/config";
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { INotification } from "@interfaces/notification.interface";
import { AuthActions, authSelector } from "@store/reducers/auth.reducer";
import { NotificationActions } from "@store/reducers/notification.reducer";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useServiceWorker } from "./useServiceWorker";

export const useApollo = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { setToken } = useServiceWorker();

  const { accessToken, sessionToken } = useSelector(authSelector);

  const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.0/8 are considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );

  const apolloOnError = onError(
    ({ graphQLErrors, networkError, forward, operation, response }) => {
      // @ts-ignore
      if (networkError?.statusCode === 401) {
        setToken?.("");
        dispatch(AuthActions.logout());
      }

      if (graphQLErrors && (graphQLErrors as any).notifications) {
        const notifications = ((graphQLErrors as any).notifications ||
          []) as INotification[];

        dispatch(NotificationActions.setNotifications(notifications));
      }

      // return forward(operation);
    }
  );

  const dashboardHttpLink = new HttpLink({
    uri: config.dashboardUrlApi,
    fetchOptions: { params: { mingo: "mingo" } },
  });
  const portalHttpLink = new HttpLink({
    uri: config.portalUrlApi,
    fetchOptions: { params: { mingo: "mingo" } },
  });

  const apolloCache = new InMemoryCache();

  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => {
      return {
        headers: {
          ...headers,
          // "x-extra-data": JSON.stringify({}),
          "x-lang": i18n.language,
          authorization: `Bearer ${accessToken || sessionToken}`,
        },
        params: {
          mingo: "mingo",
        },
      };
    });

    return forward(operation).map((data) => {
      const notifications = data.data?.notifications as INotification[];
      if (notifications && Array.isArray(notifications)) {
        dispatch(NotificationActions.setNotifications(notifications));
      }

      // console.timeEnd(operation.operationName);
      return data;
    });

    return forward(operation);
  });

  const apolloDashboardClient = new ApolloClient({
    cache: apolloCache,
    link: ApolloLink.from([
      apolloOnError,
      authMiddleware,
      // authMiddleware({ queryType: "dashbaord" }),
      dashboardHttpLink,
    ]),
  });
  const apolloPortalClient = new ApolloClient({
    cache: apolloCache,
    link: ApolloLink.from([
      apolloOnError,
      authMiddleware,
      // authMiddleware({ queryType: "portal" }),
      portalHttpLink,
    ]),
  });

  return {
    apolloDashboardClient,
    apolloPortalClient,
    isLocalhost,
  };
};
