import { Layout } from "antd";
import React from "react";
import { Badge, Space } from "antd";
import "simplebar-react/dist/simplebar.min.css";
import {
  Chalkboard,
  Monitor,
  VideoCamera,
  VideoCameraSlash,
  Microphone,
  MicrophoneSlash,
  Record,
  Play,
  Pause,
  Stop,
  Users,
  Link,
  Chats,
  FadersHorizontal,
  LinkSimpleHorizontal,
  ArrowArcLeft,
  ArrowArcRight,
  ArrowUpLeft,
  PencilSimple,
  PaintBucket,
  TextT,
  Rectangle,
  Image,
  Plus,
  Trash,
  CaretRight,
  CaretLeft,
  Folders,
} from "phosphor-react";
import { Content, Footer, Header } from "antd/lib/layout/layout";
import { useState } from "react";
import Button from "@/components/ui/buttons/Button";
import { Icon } from "@/components/dynamic/Icon";
import Sider from "antd/lib/layout/Sider";
import TeacherCam from "@/components/classroom/TeacherCam";
import AttendanceTab from "@/components/classroom/AttendanceTab";
import ChatTab from "@/components/classroom/ChatTab";
import DocumentsTab from "@/components/classroom/DocumentsTab";
import ToolsTab from "@/components/classroom/ToolsTab";

type ClassRoomProps = {};

const ClassRoom: React.FC<ClassRoomProps> = ({}) => {
  const [settings, setSettings] = useState<{
    board?: boolean;
    screen?: boolean;
    camera?: boolean;
    mic?: boolean;
    record?: boolean;
    play?: boolean;
    stop?: boolean;
  }>({
    board: false,
    screen: true,
    camera: false,
    mic: false,
    record: false,
    play: false,
    stop: false,
  });

  const [activeTab, setActiveTab] = useState<number>(0);

  return (
    <Layout className="layout layout-classroom">
      <Header className="header">
        <h4>Session Title</h4>
        <div className="header-actions">
          <Button
            variant="light"
            type="primary"
            icon={<LinkSimpleHorizontal />}
            className="me-1"
          />
          <Button variant="danger">
            End Session <Icon name="SignOut" />
          </Button>
        </div>
      </Header>
      <Layout>
        <Layout className="main-content">
          <Content className="site-layout-background page-content">
            <div className="board-pagination">
              <div className="board-pagination-buttons">
                <div className="board-pagination-numbers">
                  <Button
                    icon={<CaretLeft />}
                    type="text"
                    variant="dark"
                    size="small"
                  />
                  <div className="px-1">3/5</div>
                  <Button
                    icon={<CaretRight />}
                    type="text"
                    variant="dark"
                    size="small"
                  />
                </div>
                <Button
                  icon={<Plus />}
                  type="text"
                  variant="dark"
                  size="small"
                  className="me"
                />
                <Button
                  icon={<Trash />}
                  type="text"
                  variant="danger"
                  size="small"
                />
              </div>
              <div className="board-pages">
                <div></div>
                <div></div>
                <div className="active"></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <div className="board-controls">
              <Button icon={<ArrowArcLeft />} type="text" variant="dark" />
              <div className="board-controls-buttons">
                <Button icon={<ArrowUpLeft />} type="text" variant="dark" />
                <Button icon={<PencilSimple />} type="text" variant="dark" />
                <Button
                  icon={<PaintBucket />}
                  type="primary"
                  variant="primary"
                />
                <Button icon={<TextT />} type="text" variant="dark" />
                <Button icon={<Rectangle />} type="text" variant="dark" />
                <Button icon={<Image />} type="text" variant="dark" />
              </div>
              <Button icon={<ArrowArcRight />} type="text" variant="dark" />
            </div>
            <div className="board-files">
              <Button icon={<Folders />} type="text" variant="dark" />
            </div>
          </Content>
        </Layout>
        <Sider width={340} className="site-layout-background sidebar">
          <TeacherCam instructorName="Ayham Hazzouri" />
          {activeTab === 0 ? (
            <AttendanceTab />
          ) : activeTab === 1 ? (
            <ChatTab />
          ) : activeTab === 2 ? (
            <DocumentsTab />
          ) : (
            <ToolsTab />
          )}
        </Sider>
      </Layout>
      <Footer className="footer flex">
        <div className="flex justify-center content-actions">
          <Space align="center" size={40}>
            <Space direction="vertical" align="center">
              <Space size={0}>
                <Button
                  icon={<Chalkboard />}
                  onClick={() =>
                    setSettings((s) => ({
                      ...s,
                      board: !settings.board,
                      screen: !settings.screen,
                    }))
                  }
                  variant={`${settings.board ? "primary" : "light"}`}
                />
                <Button
                  icon={<Monitor />}
                  onClick={() =>
                    setSettings((s) => ({
                      ...s,
                      board: !settings.board,
                      screen: !settings.screen,
                    }))
                  }
                  variant={`${settings.screen ? "primary" : "light"}`}
                />
              </Space>
              Board / Screen
            </Space>
            <Space align="center" size={20}>
              <Space direction="vertical" align="center">
                <Button
                  icon={
                    settings.camera ? <VideoCamera /> : <VideoCameraSlash />
                  }
                  onClick={() =>
                    setSettings((s) => ({ ...s, camera: !settings.camera }))
                  }
                  variant={`${settings.camera ? "primary" : "light"}`}
                />
                Camera
              </Space>
              <Space direction="vertical" align="center">
                <Button
                  icon={settings.mic ? <Microphone /> : <MicrophoneSlash />}
                  onClick={() =>
                    setSettings((s) => ({ ...s, mic: !settings.mic }))
                  }
                  variant={`${settings.mic ? "primary" : "light"}`}
                />
                Camera
              </Space>
            </Space>
            <Space align="center" size={20}>
              <Space direction="vertical" align="center">
                <Button
                  icon={<Record />}
                  onClick={() =>
                    setSettings((s) => ({ ...s, record: !settings.record }))
                  }
                  variant={`${settings.record ? "primary" : "light"}`}
                />
                Record
              </Space>
              {settings.record && (
                <>
                  <Space direction="vertical" align="center">
                    <Button
                      icon={settings.play ? <Play /> : <Pause />}
                      onClick={() =>
                        setSettings((s) => ({ ...s, play: !settings.play }))
                      }
                      variant={`${settings.play ? "primary" : "light"}`}
                    />
                    {settings.play ? "Play" : "Pause"}
                  </Space>
                  <Space direction="vertical" align="center">
                    <Button
                      icon={<Stop />}
                      onClick={() =>
                        setSettings((s) => ({ ...s, record: !settings.record }))
                      }
                      variant="light"
                    />
                    Stop
                  </Space>
                </>
              )}
            </Space>
          </Space>
        </div>
        <div className="flex justify-center sider-actions">
          <Space align="center" size={20}>
            <Space direction="vertical" align="center">
              <Badge count={5}>
                <Button
                  icon={<Users />}
                  onClick={() => setActiveTab(0)}
                  variant={`${activeTab === 0 ? "primary" : "light"}`}
                />
              </Badge>
              Participants
            </Space>
            <Space direction="vertical" align="center">
              <Badge count={5}>
                <Button
                  icon={<Chats />}
                  onClick={() => setActiveTab(1)}
                  variant={`${activeTab === 1 ? "primary" : "light"}`}
                />
              </Badge>
              Chat
            </Space>
            <Space direction="vertical" align="center">
              <Badge count={5}>
                <Button
                  icon={<Link />}
                  onClick={() => setActiveTab(2)}
                  variant={`${activeTab === 2 ? "primary" : "light"}`}
                />
              </Badge>
              Shared Docs
            </Space>
            <Space direction="vertical" align="center">
              <Button
                icon={<FadersHorizontal />}
                onClick={() => setActiveTab(3)}
                variant={`${activeTab === 3 ? "primary" : "light"}`}
              />
              Tools
            </Space>
          </Space>
        </div>
      </Footer>
    </Layout>
  );
};

export default ClassRoom;
