import { useState } from "react";
import { useUtiles } from "./useUtiles";

export const useServiceWorker = () => {
  const [token, setTokenState] = useState("");
  const { consoleLog } = useUtiles();
  const setToken = (t: string) => {
    const SW = navigator?.serviceWorker?.controller;
    SW?.postMessage({
      type: "SET_TOKEN",
      token: t,
    });

    setTokenState(t);
  };

  const sendMessage = (message: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      const messageChannel = new MessageChannel();
      messageChannel.port1.onmessage = (event) => {
        if (event.data.error) {
          return reject(event.data.error);
        } else {
          return resolve(event.data);
        }
      };

      navigator.serviceWorker.controller?.postMessage(message, [
        messageChannel.port2,
      ]);

      // navigator.serviceWorker.controller?.postMessage(message, [
      //   messageChannel.port2,
      // ]);
    });
  };

  const getToken = async (): Promise<string> => {
    return new Promise((resolve, reject) => {
      sendMessage("GET_TOKEN")
        .then((result) => {
          consoleLog("GET_TOKEN", result);

          const token = result.token ? result.token : "";
          return resolve(token);
        })
        .catch((e) => reject(e));
    });
  };

  return { token, getToken, setToken };
};
