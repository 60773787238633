import { Avatar, List, Skeleton } from 'antd';


export const Loading = () => {
    return   <List
    itemLayout="vertical"
    size="large"
    dataSource={[1, 2, 3, 4, 5]}
    renderItem={(item, index) => (
      <List.Item key={`skelaton-${index}`}>
        <Skeleton loading={true} active avatar>
          <List.Item.Meta
            avatar={<Avatar src={""} />}
            title={<a>{""}</a>}
            description={""}
          />
          {null}
        </Skeleton>
      </List.Item>
    )}
  />
}