import { config as Settings } from "@/config";
import { useRedirect } from "@/hooks/useRedirect";
import { getValFromArray } from "@/utils";
import { UploadOutlined } from "@ant-design/icons";
import { Card } from "@components/dynamic/Card";
import Button from "@components/ui/buttons/Button";
import { useAction } from "@hooks/useAction";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import {
  Col,
  Form,
  FormInstance,
  message,
  Modal,
  Row,
  Space,
  Tooltip,
  Upload,
  UploadProps,
} from "antd";
import ImgCrop from "antd-img-crop";
import { Rule } from "antd/lib/form";
import { RcFile } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import classNames from "classnames";
import update from "immutability-helper";
import { NotePencil, Plus, X, Check } from "phosphor-react";
import React, { useEffect, useMemo } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import Preview from "../ui/preview";

const RegexParser = require("regex-parser");

const getBase64 = (file: Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export interface IFile {
  uid?: string;
  name?: string;
  url?: string;
  type?: string;
}

interface IState {
  files: Array<UploadFile>;
  previewVisible: boolean;
  uploading: boolean;
  fitImages: boolean;
  previewImage: string;
  results: any[];
  changeFilesKey: number;

  loading: boolean;
  isValid: boolean;
  editMode: boolean;
  value: UploadFile<any>[];
  editedValue: UploadFile<any>[];
  file?: IFile;
}

interface IProps {
  block: IBlock;
  scope: IScope[];
  loading: boolean;
  form: FormInstance<any>;
  editInline?: boolean;
  formState: any;
  setFormState: any;

  onChange?: (value: any, variable: string) => void;
  visible?: boolean;
  disabled?: string;
}

export const FieldFileUploader: React.FC<IProps> = ({
  block,
  scope = [],
  form,
  setFormState,
  formState,
  onChange,
  editInline,
  visible,
  disabled,
  ...props
}) => {
  const { checkRedirect } = useRedirect();
  const { t } = useTranslation();
  const type = "add";

  const { doQuery, doMutateQraphQl, replaceFromScope } = useAction();

  const config: IScope[] = block?.config || [];

  const variable = config.find((e) => e.name === "variable")?.value || "";
  const required = config.find((e) => e.name === "required")?.value === "1";
  const pattern = config.find((e) => e.name === "pattern")?.value;
  const validation_message = config.find(
    (e) => e.name === "validation_message"
  )?.value;
  const view_only = config.find((e) => e.name === "view_only")?.value || false;
  const accept = config.find((e) => e.name === "accept")?.value;
  const endpoint = config.find((e) => e.name === "endpoint")?.value;
  const max_size = config.find((e) => e.name === "max_size")?.value
    ? parseFloat(config.find((e) => e.name === "max_size")?.value + "")
    : Settings.defaultMaxSizeFile;
  const maxCount = config.find((e) => e.name === "maxCount")?.value
    ? parseFloat(config.find((e) => e.name === "maxCount")?.value + "")
    : Settings.defaultMaxCountFile;

  const use_crop = config.find((e) => e.name === "use_crop")?.value === "1";
  const use_dragger =
    config.find((e) => e.name === "use_dragger")?.value === "1";
  const listType = config.find((e) => e.name === "listType")?.value as
    | UploadProps<any>["listType"]
    | undefined;
  const load_query = config.find((e) => e.name === "load_query")?.value;
  const crop_config = config.find((e) => e.name === "crop_config")?.array || [];

  const aspect = crop_config?.find((e) => e.name === "aspect")?.value;
  const shape = crop_config?.find((e) => e.name === "shape")?.value as
    | "rect"
    | "round"
    | undefined;
  const quality = crop_config?.find((e) => e.name === "quality")?.value;
  const fillColor = crop_config?.find((e) => e.name === "fillColor")?.value;
  const zoom = crop_config?.find((e) => e.name === "zoom")?.value === "1";
  const minZoom = crop_config?.find((e) => e.name === "minZoom")?.value;
  const maxZoom = crop_config?.find((e) => e.name === "maxZoom")?.value;

  const expanded = config.find((e) => e.name === "expanded")?.value === "1";
  const expandable = config.find((e) => e.name === "expandable")?.value === "1";
  const can_do = config.find((e) => e.name === "can_do")?.value === "1";
  const defaults = config.find((e) => e.name === "default")?.value;
  const urls = config.find((e) => e.name === "urls")?.array || [];
  const graphql = block?.config?.find((e: any) => e.name === "graphql")?.value;

  const { rules } = useMemo(() => {
    const rules: Rule[] = [];

    if (pattern) {
      rules.push({
        pattern: new RegExp(RegexParser(pattern)),
        message: validation_message,
      });
    }
    if (required) {
      rules.push({
        required: required,
        message: `${block?.title || block?.label} is required.`,
      });
    }

    return { rules };
  }, [required, pattern]);

  const [state, setState] = React.useState<IState>({
    files: [],
    previewVisible: false,
    previewImage: "",
    uploading: false,
    results: [],
    fitImages: false,
    changeFilesKey: Math.random(),

    loading: false,
    isValid: false,
    editMode: false,
    value: [],
    editedValue: [],
    file: {},
  });

  useEffect(() => {
    if (load_query) {
      loadQueryData();
    }
  }, [load_query]);

  const loadQueryData = () => {
    if (load_query) {
      doQuery({ scope, item: undefined, query: load_query }).then(
        (data: any) => {
          if (data?.data) {
            const files = data?.data?.getFiles?.map((e: any) => ({
              uid: e.id,
              status: "success",
              url: e.url,
              name: e.name,
              type: e.type,
              thumbUrl: e.url,
            }));
            setState((s) => ({
              ...s,
              files,
              value: files,
              editedValue: files,
            }));

            form.setFieldsValue({
              [variable + ""]: files.map((f: any) => f.uid).join(","),
            });
            setFormState((s: any) => ({
              ...s,
              [variable + ""]: files.map((f: any) => f.uid).join(","),
            }));

            onChange &&
              onChange(files.map((f: any) => f.uid).join(","), variable + "");
          }
        }
      );
    }
  };

  useEffect(() => {
    if (defaults && variable) {
      const files = urls.map((e: any, index: number) => ({
        uid: defaults[index],
        status: "success",
        url: getValFromArray(e.array, "url"),
        name: getValFromArray(e.array, "name"),
        type: getValFromArray(e.array, "type"),
        thumbUrl: getValFromArray(e.array, "url"),
      }));
      setState((s: any) => ({
        ...s,
        files,
        value: files,
        editedValue: files,
      }));

      form.setFieldsValue({
        [variable + ""]: files.map((f: any) => f.uid).join(","),
      });
      setFormState((s: any) => ({
        ...s,
        [variable + ""]: files.map((f: any) => f.uid).join(","),
      }));

      onChange &&
        onChange(files.map((f: any) => f.uid).join(","), variable + "");
    }
  }, [urls, defaults, variable]);

  const handleCancel = () =>
    setState((s) => ({
      ...s,
      previewVisible: false,
      file: {},
      previewImage: "",
    }));

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setState((s) => ({
      ...s,
      previewImage: file.url || file.preview,
      file: file,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    }));
  };

  const onRemove = (event: UploadFile) => {
    if (type == "add") {
      const upFiles = state.files.filter((f) => f.uid != event.uid);
      setState((s) => ({
        ...s,
        files: upFiles,
        editedValue: upFiles,
        changeFilesKey: Math.random(),
      }));
      form.setFieldsValue({
        [variable + ""]:
          upFiles.length === 0
            ? ""
            : upFiles
                .filter((f) => f.status)
                .map((f) => f?.response?.id || f.uid)
                .join(","),
      });
      setFormState((s: any) => ({
        ...s,
        [variable + ""]:
          upFiles.length === 0
            ? ""
            : upFiles
                .filter((f) => f.status)
                .map((f) => f?.response?.id || f.uid)
                .join(","),
      }));

      onChange &&
        onChange(
          upFiles.length === 0
            ? ""
            : upFiles
                .filter((f) => f.status)
                .map((f) => f?.response?.id || f.uid)
                .join(","),
          variable + ""
        );
      return false;
    } else {
      setState((s) => ({
        ...s,
        files: s.files.map((f) => ({
          ...f,
          status: f.uid == event.uid ? "error" : f.status,
        })),
        editedValue: s.files.map((f) => ({
          ...f,
          status: f.uid == event.uid ? "error" : f.status,
        })),

        changeFilesKey: Math.random(),
      }));
    }
  };

  const save = async () => {
    await form.validateFields();
    const errors = form.getFieldError(variable);

    if (errors.length === 0) {
      let query = "";

      query = replaceFromScope({
        scope: [{ name: variable, value: formState[variable] }],
        str: graphql + "",
        withQuate: false,
      });

      setState((s) => ({ ...s, loading: true }));

      doMutateQraphQl(query)
        .then(() => {
          setState((s) => ({ ...s, editMode: false }));
          checkRedirect(block?.config);
          loadQueryData();
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setState((s) => ({ ...s, loading: false }));
        });
    }
  };

  const removeImages = async (files: UploadFile[]) => {
    // removeFiles(files.map((e) => e.fileName + ""))
    //   .then((data) => {
    //     setState((s) => ({
    //       ...s,
    //       files: s.files.filter(
    //         (f) => !files.map((e) => e.uid).includes(f.uid)
    //       ),
    //       changeFilesKey: Math.random(),
    //     }));
    //   })
    //   .catch((e) => {});
  };

  const uploadImage = async ({ onProgress, onError, onSuccess, file }: any) => {
    const fmData = new FormData();

    fmData.append("file", file);
    const uid = Math.random().toString();
    const url = (await getBase64(file)) as string;

    setState((s) => ({
      ...s,
      uploading: true,
      files: [
        ...s.files,
        {
          uid,
          status: "uploading",
          url,
          name: file.name,
          percent: 0,
        },
      ],
    }));

    // uploadFiles(fmData, (event: any) => {
    //   const percent = Math.floor((event.loaded / event.total) * 100);

    //   setState((s) => ({
    //     ...s,
    //     files: s.files.filter((_file) => {
    //       if (_file.uid == uid) {
    //         _file.percent = percent;
    //       }
    //       return _file;
    //     }),
    //   }));

    //   if (percent === 100) {
    //     // setTimeout(() => setProgress(0), 1000);
    //   }
    //   onProgress({ percent: (event.loaded / event.total) * 100 });
    // })
    //   .then((res) => {
    //     setState((s) => ({
    //       ...s,
    //       // results: [...s.results, ...res?.map((e: any) => ({ ...e, uid }))],
    //       files: s.files.filter((_file) => {
    //         if (_file.uid == uid) {
    //           _file.percent = 0;
    //           _file.status = "done";
    //           _file.url = config.baseUrl + "/upload/file/" + res[0]?.filename;
    //           _file.name = res[0]?.path;
    //           _file.fileName = res[0]?.filename;
    //           _file.type = res[0]?.type;
    //           _file.size = res[0]?.size;
    //         }
    //         return _file;
    //       }),
    //       changeFilesKey: Math.random(),
    //     }));
    //     onSuccess("Ok");
    //   })
    //   .catch((err) => {
    //     onError({ err });

    //     setState((s) => ({
    //       ...s,
    //       files: s.files.filter((_file) => {
    //         if (_file.uid == uid) {
    //           _file.percent = 0;
    //           _file.status = "error";
    //         }
    //         return _file;
    //       }),
    //     }));
    //   })
    //   .finally(() => {
    //     setState((s) => ({
    //       ...s,
    //       uploading: false,
    //     }));
    //   });
  };

  const moveRow = React.useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = state.files[dragIndex];
      setState((s) => ({
        ...s,
        files: update(state.files, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      }));
    },
    [state.files]
  );

  const DragableUploadListItem = React.memo(
    ({ originNode, moveRow, file, fileList }: any) => {
      const ref = React.useRef(null);
      const index = fileList.indexOf(file);
      const [{ isOver, dropClassName }, drop] = useDrop({
        accept: "DragableUploadList",
        collect: (monitor) => {
          const { index: dragIndex } = (monitor.getItem() || {}) as any;
          if (dragIndex === index) {
            return {};
          }
          return {
            isOver: monitor.isOver(),
            dropClassName:
              dragIndex < index ? " drop-over-downward" : " drop-over-upward",
          };
        },
        drop: (item: any) => {
          moveRow(item.index, index);
        },
      });
      const [, drag] = useDrag({
        type: "DragableUploadList",
        item: { index },
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
      });
      drop(drag(ref));
      const errorNode = (
        <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>
      );
      return (
        <div
          ref={ref}
          className={`ant-upload-draggable-list-item ${
            isOver ? dropClassName : ""
          }`}
          style={{ cursor: "move" }}
        >
          {file.status === "error" ? (
            errorNode
          ) : (
            <div>
              {/* <Button
                type="primary"
                size="small"
                block
                onClick={() => copyToClipboard(file.url)}
              >
                copy
              </Button> */}

              {originNode}
            </div>
          )}
        </div>
      );
    }
  );

  const uploadButton =
    state.files.length < maxCount &&
    (listType === "picture-card" ? (
      <div>
        <Plus />
        <div style={{ marginTop: 8 }}>{t("upload")}</div>
      </div>
    ) : (
      <Button disabled={disabled === "1"} icon={<UploadOutlined />}>
        {t("upload")}
      </Button>
    ));

  const beforeUpload = (file: RcFile) => {
    // const isJpgOrPng = file.type === accept;
    // if (!isJpgOrPng) {
    //   message.error('this type is not accepted !');
    // }

    if (max_size) {
      const isAcceptableSize = file.size / 1024 / 1024 < max_size;

      if (!isAcceptableSize) {
        message.error(`Image must smaller than ${isAcceptableSize}MB!`);
        return false;
      }
    }
    return true;
  };

  const itemRender = (
    originNode: React.ReactElement<
      any,
      string | React.JSXElementConstructor<any>
    >,
    file: UploadFile<any>,
    currFileList: UploadFile<any>[]
  ) => {
    return false ? (
      // return sort ? (
      <DragableUploadListItem
        originNode={originNode}
        file={file}
        fileList={currFileList}
        moveRow={moveRow}
      />
    ) : (
      originNode
    );
  };

  const UploadBody: UploadProps<any> = {
    accept: accept,
    multiple: true,
    beforeUpload: beforeUpload,
    disabled: disabled === "1",
    onDownload: (file) => {},
    onDrop: (e) => {},
    // customRequest={uploadImage}
    action: endpoint,
    onChange: ({ file, fileList, event }) => {
      setState((s) => ({ ...s, files: fileList }));

      const upFiles = fileList.filter(
        (f) =>
          f.status === "success" ||
          f.status === "uploading" ||
          f.status === "done"
      );

      if (upFiles.length > 0) {
        if (editInline) {
          if (!upFiles.find((e) => e.status === "uploading")) {
            setState((s) => ({
              ...s,
              // editMode: false,
              editedValue: upFiles,
              value: upFiles,
            }));
          } else {
            setState((s) => ({ ...s, editedValue: upFiles }));
          }
          form.setFieldsValue({
            [variable + ""]:
              upFiles.length === 0
                ? ""
                : upFiles
                    .filter((f) => f.status)
                    .map((f) => f?.response?.id || f.uid)
                    .join(","),
          });
          setFormState((s: any) => ({
            ...s,
            [variable + ""]:
              upFiles.length === 0
                ? ""
                : upFiles
                    .filter((f) => f.status)
                    .map((f) => f?.response?.id || f.uid)
                    .join(","),
          }));

          onChange &&
            onChange(
              upFiles.length === 0
                ? ""
                : upFiles
                    .filter((f) => f.status)
                    .map((f) => f?.response?.id || f.uid)
                    .join(","),
              variable + ""
            );
        } else {
          const upFiles = fileList.filter(
            (f) => f.status === "success" || f.status === "done"
          );

          form.setFieldsValue({
            [variable + ""]:
              upFiles.length === 0
                ? ""
                : upFiles
                    .filter((f) => f.status)
                    .map((f) => f?.response?.id || f.uid)
                    .join(","),
          });
          setFormState((s: any) => ({
            ...s,
            [variable + ""]:
              upFiles.length === 0
                ? ""
                : upFiles
                    .filter((f) => f.status)
                    .map((f) => f?.response?.id || f.uid)
                    .join(","),
          }));

          onChange &&
            onChange(
              upFiles.length === 0
                ? ""
                : upFiles
                    .filter((f) => f.status)
                    .map((f) => f?.response?.id || f.uid)
                    .join(","),
              variable + ""
            );
        }
      } else {
        form.setFieldsValue({
          [variable + ""]: "",
        });
        setFormState((s: any) => ({
          ...s,
          [variable + ""]: "",
        }));

        onChange && onChange("", variable + "");
      }
    },
    listType: listType,
    fileList: editInline ? state.editedValue : state.files,
    onPreview: handlePreview,
    onRemove: onRemove,
    maxCount: maxCount || 1,

    showUploadList: {
      showDownloadIcon: false,
      // downloadIcon: <DownloadOutlined  />,
      showRemoveIcon: view_only ? false : true,
      // showPreviewIcon:true,
      // removeIcon: <DeleteOutlined color={"red"}  />,
    },
  };

  const AcceptComponent = () => (
    <>{!!accept && <span className="">{t("uploadAccept", { accept })}</span>}</>
  );

  const CardComponent = (
    <Card
      body={""}
      title={block?.title}
      expanded={expanded}
      classes={block?.classes}
      expandable={expandable}
      visible={visible}
      config={config}
    >
      <Row wrap={false} gutter={[16, 16]}>
        <Col flex="auto">
          <Upload
            {...{
              onChange: ({ file, fileList, event }) => {
                return;
              },
              listType: listType,
              fileList: state.value,
              onPreview: handlePreview,

              showUploadList: {
                // showDownloadIcon: true,
                showRemoveIcon: false,
                showPreviewIcon: true,
              },
            }}
          />
        </Col>
        {can_do && (
          <Col flex="none">
            <Space direction="horizontal" style={{ width: "20%" }}>
              <Button
                key="card-edit-action-edit-inline"
                // type="button"
                loading={state.loading}
                variant="primary"
                onClick={() => setState((s) => ({ ...s, editMode: true }))}
                icon={<NotePencil />}
              />
            </Space>
          </Col>
        )}
      </Row>
      <AcceptComponent />
    </Card>
  );

  const cancel = () => {
    setState((s) => ({ ...s, editMode: false, editedValue: s.value }));
  };

  const Required = () => {
    return (
      !state.editedValue && (
        <div className="ant-form-item-explain ">
          <div role="alert" className="ant-form-item-explain-error">
            {variable} is required.
          </div>
        </div>
      )
    );
  };
  // const Pattern = () => {
  //   if (pattern) {
  //     const expression = new RegExp(RegexParser(pattern));

  //     // const isMatch = expression.test(state.editedValue);

  //     return (
  //       !isMatch && (
  //         <div className="ant-form-item-explain ">
  //           <div role="alert" className="ant-form-item-explain-error">
  //             {validation_message}
  //           </div>
  //         </div>
  //       )
  //     );
  //   } else return null;
  // };

  const Uploader = (
    <Upload {...UploadBody} itemRender={itemRender}>
      {state.files.length >= maxCount || view_only ? null : uploadButton}
    </Upload>
  );
  const Dragger = (
    <Upload.Dragger {...UploadBody}>
      <p className="ant-upload-drag-icon">
        <UploadOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload
      </p>
      <p className="ant-upload-hint"></p>
      {/* {state.files.length >= maxCount ? null : uploadButton} */}
    </Upload.Dragger>
  );
  const Up = use_dragger ? Dragger : Uploader;

  return (
    <React.Fragment>
      {editInline ? (
        state.editMode ? (
          <Card
            body={""}
            title={block?.title}
            expanded={expanded}
            classes={block?.classes}
            expandable={expandable}
            visible={visible}
            config={config}
          >
            <Row wrap={false} gutter={[16, 16]}>
              <Col flex="auto">
                <DndProvider backend={HTML5Backend}>
                  {use_crop ? (
                    <ImgCrop
                      quality={quality ? parseFloat(quality) : undefined}
                      aspect={aspect ? parseFloat(aspect) : undefined}
                      shape={shape} // rect
                      fillColor={fillColor}
                      zoom={zoom}
                      minZoom={minZoom ? parseFloat(minZoom) : undefined}
                      maxZoom={maxZoom ? parseFloat(maxZoom) : undefined}
                      modalTitle={t("cropImage")}
                      modalOk={t("ok")}
                      modalCancel={t("cancel")}
                    >
                      {Up}
                    </ImgCrop>
                  ) : (
                    Up
                  )}
                </DndProvider>
                <AcceptComponent />
              </Col>
              <Col flex="none">
                <Space direction="horizontal" style={{ width: "20%" }}>
                  <Button
                    variant="danger"
                    type="primary"
                    icon={<X />}
                    // width={10}
                    disabled={state.loading}
                    onClick={cancel}
                  />
                  <Button
                    type="primary"
                    icon={<Check />}
                    // width={10}
                    disabled={state.loading}
                    onClick={save}
                  />
                </Space>
              </Col>
            </Row>
            {Required()}
          </Card>
        ) : (
          CardComponent
        )
      ) : (
        <React.Fragment>
          <Form.Item
            className={classNames(visible === false && "d-none")}
            label={block?.title || block?.label}
            name={variable}
            rules={[
              ...(rules ? rules : []),
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  // if (value?.length < min) {
                  //   return Promise.reject(`Min length is ${minLengeth} !`);
                  // } else {
                  //   return Promise.resolve();
                  // }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <DndProvider backend={HTML5Backend}>
              {use_crop ? (
                <ImgCrop
                  quality={quality ? parseFloat(quality) : undefined}
                  aspect={aspect ? parseFloat(aspect) : undefined}
                  shape={shape} // rect
                  fillColor={fillColor}
                  zoom={zoom}
                  minZoom={minZoom ? parseFloat(minZoom) : undefined}
                  maxZoom={maxZoom ? parseFloat(maxZoom) : undefined}
                  modalTitle={t("cropImage")}
                  modalOk={t("ok")}
                  modalCancel={t("cancel")}
                >
                  {Up}
                </ImgCrop>
              ) : (
                Up
              )}
            </DndProvider>
            <AcceptComponent />
          </Form.Item>
        </React.Fragment>
      )}
      <Preview
        file={state.file}
        previewImage={state.previewImage}
        visible={state.previewVisible}
        handleCancel={handleCancel}
      />
    </React.Fragment>
  );
};
