import { useParams } from "react-router-dom";
import { RenderPage } from "@components/RenderPage";

export const Page = ({ name: nameProp }: { name?: string }) => {
  const { name } = useParams();

  const pageName = nameProp || name;

  return !!pageName ? <RenderPage name={pageName} /> : null;
};
