import { Input } from "antd";
import { File, ImageSquare, Smiley } from "phosphor-react";
import SimpleBar from "simplebar-react";
import Button from "../ui/buttons/Button";
import ChatItem from "./ChatItem";

const ChatTab = () => {
  return (
    <div className="tab-item chat-tab">
      <div className="tab-title">
        <span>Chat room</span>
      </div>
      <div className="tab-content">
        <SimpleBar style={{ maxHeight: "100%" }}>
          <ChatItem
            name="Ayham Hazzouri"
            time="1 hour ago"
            content={
              <>
                <span className="chat-item-message">Hello Everyone</span>
                <span className="chat-item-message">
                  i'm the new teacher nice to meet you all
                </span>
              </>
            }
          />
          <ChatItem
            name="Mohammed Ali"
            time="30 minute ago"
            content={
              <>
                <span className="chat-item-message">Hello Teacher</span>
              </>
            }
          />
          <ChatItem
            name="Ayham Hazzouri"
            time="1 hour ago"
            content={
              <>
                <span className="chat-item-message">Hello Everyone</span>
                <span className="chat-item-message">
                  i'm the new teacher nice to meet you all
                </span>
              </>
            }
          />
          <ChatItem
            name="Mohammed Ali"
            time="30 minute ago"
            content={
              <>
                <span className="chat-item-message">Hello Teacher</span>
              </>
            }
          />
          <ChatItem
            name="Ayham Hazzouri"
            time="1 hour ago"
            content={
              <>
                <span className="chat-item-message">Hello Everyone</span>
                <span className="chat-item-message">
                  i'm the new teacher nice to meet you all
                </span>
              </>
            }
          />
          <ChatItem
            name="Mohammed Ali"
            time="30 minute ago"
            content={
              <>
                <span className="chat-item-message">Hello Teacher</span>
              </>
            }
          />
          <ChatItem
            time="30 minute ago"
            side="right"
            content={
              <>
                <span className="chat-item-message">
                  Welcome Teacher Nice to meet you. i'm Khalil Mahmoud from your
                  english class.
                </span>
              </>
            }
          />
        </SimpleBar>
      </div>
      <div className="chat-form">
        <Button
          icon={<ImageSquare />}
          type="text"
          variant="primary"
          size="small"
        />
        <Button
          icon={<File />}
          type="text"
          variant="primary"
          size="small"
          className="me"
        />
        <div className="chat-input">
          <Input placeholder="Your message..." />
          <Button
            icon={<Smiley />}
            type="text"
            variant="primary"
            size="small"
            className="ms"
          />
        </div>
      </div>
    </div>
  );
};

export default ChatTab;
