import SimpleBar from "simplebar-react";
import Attendance from "./Attendance";
import AttendanceItem from "./AttendanceItem";

const AttendanceTab = () => {
  return (
    <div className="tab-item attendance-tab">
      <SimpleBar style={{ height: "100%" }}>
        <Attendance header="Instructor">
          <AttendanceItem
            name="Ayham Hazzouri"
            isOnline={true}
            hasCam={false}
            hasMic={true}
            hasChat={true}
            last
          />
        </Attendance>
        <Attendance header="Co-Instructors (2)">
          <AttendanceItem
            name="Ayham Hazzouri"
            isOnline={true}
            hasCam={false}
            hasMic={true}
            hasChat={true}
          />
          <AttendanceItem
            name="Ayham Hazzouri"
            isOnline={true}
            hasCam={false}
            hasMic={true}
            hasChat={true}
            last
          />
        </Attendance>
        <Attendance header="Students (20)" hasSettings>
          <AttendanceItem
            name="Ayham Hazzouri"
            isOnline={true}
            hasCam={false}
            hasMic={true}
            hasChat={true}
          />
          <AttendanceItem
            name="Ayham Hazzouri"
            isOnline={true}
            hasCam={false}
            hasMic={true}
            hasChat={true}
            hasHand={true}
          />
          <AttendanceItem
            name="Ayham Hazzouri"
            isOnline={true}
            hasCam={false}
            hasMic={true}
            hasChat={true}
          />
          <AttendanceItem
            name="Ayham Hazzouri"
            isOnline={true}
            hasCam={false}
            hasMic={true}
            hasChat={true}
          />
          <AttendanceItem
            name="Ayham Hazzouri"
            isOnline={true}
            hasCam={false}
            hasMic={true}
            hasChat={true}
            hasHand={true}
          />
          <AttendanceItem
            name="Ayham Hazzouri"
            isOnline={true}
            hasCam={false}
            hasMic={true}
            hasChat={true}
          />
          <AttendanceItem
            name="Ayham Hazzouri"
            isOnline={true}
            hasCam={false}
            hasMic={true}
            hasChat={true}
          />
          <AttendanceItem
            name="Ayham Hazzouri"
            isOnline={true}
            hasCam={false}
            hasMic={true}
            hasChat={true}
            hasHand={true}
          />
          <AttendanceItem
            name="Ayham Hazzouri"
            isOnline={true}
            hasCam={false}
            hasMic={true}
            hasChat={true}
            last
          />
        </Attendance>
      </SimpleBar>
    </div>
  );
};

export default AttendanceTab;
