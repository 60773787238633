// const url = process.env.REACT_APP_URL || "http://erp3.inlinez.net:8888/page/form2";
// const url = "https://app1.exapp.net/graphql?token=test123";
// const url = "https://api22.exapp.net";
// const url = "https://api.sham.exapp.net";
// const url = "https://app1.exapp.net";
// const url = "http://192.168.196.89";
// const url = "https://staging.api.sis.shamuniversity.com";

// const url = "https://erp2.inlinez.net";
// const url = 'http://localhost:3000'

export const url = process.env.REACT_APP_API_URL;

export const MAIN = process.env.REACT_APP_MAIN;
export const SOCKET_ENABLED = process.env.REACT_APP_SOCKET_ENABLED === "true";

export const config = {
  dashboardUrlApi: `${url}/graphql`,
  portalUrlApi: `${url}/portal`,
  // BaseUrlApi: `${url}/api/v1`,
  //   baseUrl: `${url}/api`,
  // baseUrl : `${url}/ajax`,
  pageSize: 10,
  specialChar: "@@",
  defaultMaxCountFile: 10,
  defaultMaxSizeFile: 10,

  googleClientId:
    "658977310896-knrl3gka66fldh83dao2rhgbblmd4un9.apps.googleusercontent.com",
  facebookClientId: "2078689248910144",
  facebookFields: "name,email,picture",
  captchaKey: "6LdoKWwgAAAAAKS_ELvlTVuhSgnZzYA_uXk_qkz2",

  page_size_options: [10, 50, 100, 200],

  //   graphQlUri : 'https://app1.exapp.net/graphql'
};
