import { ModalActions, modalSelector } from "@/store/reducers/modal.reducer";
import { getValFromArray } from "@/utils";
import PubSub from "pubsub-js";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAction } from "./useAction";
import { useRedirect } from "./useRedirect";
import { useHotkeys } from "react-hotkeys-hook";

type IConfig = {
  array?: [] | null;
  name: string;
  type: null | string;
  value: string;
};

export type KeyboardAction = {
  name: string;
  type: string;
  href: string;
  target: string;
  action_type: string;
  config: IConfig[];
};

type IPageForm = {
  [key: string]: any;
};

export const useKeyboardActions = () => {
  const { page_name, stack } = useSelector(modalSelector);
  const [actions, setActions] = useState<any>([]);
  const [pages, setPages] = useState<any>({});
  const pagesRef = useRef<any>({});
  const formState = useRef<any>({});
  const { replaceFromScope, convertItemToScope, doMutateQraphQl } = useAction();
  const { checkRedirect } = useRedirect();
  const dispatch = useDispatch();

  useEffect(() => {
    document.addEventListener("keyup", handleKeyDown, false);
    const add_action = PubSub.subscribe("add_action", addAction);
    const add_page = PubSub.subscribe("add_page", addPage);
    const add_submit = PubSub.subscribe("add_submit", addSubmit);
    const add_form_state = PubSub.subscribe("add_form_state", addFormState);
    const add_submit_btns = PubSub.subscribe("add_submit_btns", addSubmitBtns);

    return () => {
      document.removeEventListener("keyup", handleKeyDown);
      PubSub.unsubscribe(add_action);
      PubSub.unsubscribe(add_page);
      PubSub.unsubscribe(add_submit);
      PubSub.unsubscribe(add_form_state);
      PubSub.unsubscribe(add_submit_btns);
    };
  }, [page_name]);

  const addAction = async (msg: string, a: any) => {
    setActions((prev: any) => [...prev, a]);
  };
  const addPage = async (msg: string, a: any) => {
    pagesRef.current[a.pageName] = a.ref;
    pagesRef.current[a.pageName].submitBtns = [];
    if (a.pageName) {
      setPages((pages[a.pageName] = a.ref));
    }
  };

  const addSubmit = async (msg: string, a: any) => {
    if (pagesRef.current[a.page_name]) {
      pagesRef.current[a.page_name].fieldSubmit = a.config;
      pagesRef.current[a.page_name].submit_uuid = a.uuid;
    }
  };

  const addSubmitBtns = async (msg: string, a: any) => {
    if (pagesRef.current[a.page_name]) {
      pagesRef.current[a.page_name].submitBtns.push(a);
    }
  };

  const addFormState = async (msg: string, a: any) => {
    formState.current = a;
  };

  const handleKeyDown = async (e: globalThis.KeyboardEvent) => {
    actions.forEach((action: KeyboardAction) => {
      if (action?.config) {
        const actionTarget = getValFromArray(action.config, "target");
        const shortcut = getValFromArray(action.config, "shortcut");
        const type = action.type;
        const href = action.href;

        if (shortcut) {
          useHotkeys(
            shortcut,
            () => {
              e.preventDefault();
              if (actionTarget === "blank") {
                window.open(href, "_blank");
              }
            },
            [],
            {
              preventDefault: true,
              enableOnFormTags: ["INPUT", "SELECT", "TEXTAREA"],
            }
          );
        }
        // if (shortcut.includes("ctrl") && e.ctrlKey) {
        //   if (shortcut.split("+")[1]?.toLowerCase() === e.key) {
        //     e.preventDefault();
        //     if (actionTarget === "blank") {
        //       window.open(href, "_blank");
        //     }
        //   }
        // }
      }
    });
    // if (e.key === "Enter") {
    //   if (e.ctrlKey) {
    //     if (page_name) {
    //       const ref = pagesRef.current[page_name];
    //       if (ref) {
    //         const config = ref.fieldSubmit || [];
    //         const graphql =
    //           config.find((e: any) => e.name === "graphql")?.value || "";

    //         if (graphql) {
    //           ref
    //             ?.validateFields()
    //             .then(() => {
    //               dispatch(ModalActions.startLoading(ref.submit_uuid));
    //               const _query = replaceFromScope({
    //                 str: graphql,
    //                 scope: convertItemToScope(formState.current),
    //                 withQuate: true,
    //               });

    //               doMutateQraphQl(_query).then(async (res) => {
    //                 if (!res.errors) {
    //                   checkRedirect(config);
    //                 }
    //               });
    //             })
    //             .catch((e: any) => {
    //               console.log(e);
    //             })
    //             .finally(() => {
    //               dispatch(ModalActions.endLoading());
    //             });
    //         }
    //       }
    //     }
    //   } else {
    //     const event: any = e;
    //     if (e.key === "Enter" && event.target.nodeName === "INPUT") {
    //       var form = event.target.form;
    //       var index = Array.prototype.indexOf.call(form, event.target);
    //       form.elements[index + 1].focus();
    //       event.preventDefault();
    //     }
    //   }
    // }
  };

  return <div />;
};
