import { Carousel, Image } from "antd";
import { CarouselRef } from "antd/lib/carousel";
import React, { useEffect, useRef } from "react";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import classNames from "classnames";

export const StaticSlider: React.FC<{
  block: IBlock;
  scope: IScope[];
  loading: boolean;
  visible?: boolean;
}> = ({ block, scope = [], loading, visible }) => {
  const carouselRef = useRef<CarouselRef>(null);

  const config: IScope[] = block?.config ? block?.config : [];

  const slides = config.find((e: any) => e.name === "slides")?.array || [];
  const dots = config.find((e: any) => e.name === "dots")?.value || "0";
  const autoplay =
    config.find((e: any) => e.name === "autoplay")?.value === "1";
  const timeout = parseInt(
    config.find((e: any) => e.name === "timeout")?.value || "4"
  );

  useEffect(() => {
    let timer: any;
    if (timeout > 0) {
      timer = setInterval(() => {
        carouselRef.current?.next();
      }, timeout * 1000);

      return () => timer && clearInterval(timer);
    }
  }, [timeout]);

  return (
    <Carousel
      className={classNames(visible === false && "d-none")}
      autoplay={timeout ? false : autoplay}
      dots={dots === "1"}
      ref={carouselRef}
    >
      {slides.map((slide, index) => (
        <Image
          key={`slider-${index}-${slide.name}`}
          width={"100%"}
          src={slide.value}
          preview={false}
        />
      ))}
    </Carousel>
  );
};
