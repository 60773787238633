// import { Provider } from "react-redux";
// import { PersistGate } from "redux-persist/integration/react";
// import App from "./App";
// import "./index.css";
// import reportWebVitals from "./reportWebVitals";
// import { persistor, Store } from "./store/store";
// import { createRoot } from "react-dom/client";
// import * as React from "react";

// // const root = createRoot(document.getElementById("root") as Element);

// // root.render(
// //   <React.StrictMode>
// //     <Provider store={Store}>
// //       <PersistGate persistor={persistor} loading={<div></div>}>
// //         <App />
// //       </PersistGate>
// //     </Provider>
// //   </React.StrictMode>
// // );
// ReactDOM.render(
//   // <React.StrictMode>
//   <Provider store={Store}>
//     <PersistGate persistor={persistor} loading={<div></div>}>
//       <App />
//     </PersistGate>
//   </Provider>,
//   // </React.StrictMode>,
//   document.getElementById("root")
// );

// reportWebVitals();

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, Store } from "./store/store";

import "./index.css";
// replace console.* for disable log on production
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={Store}>
    <PersistGate persistor={persistor} loading={<div></div>}>
      <App />
    </PersistGate>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
