import { useLogoSrc } from "@/hooks/useLogoSrc";
import { FC, Fragment } from "react";
import { Link } from "react-router-dom";

const AuthLogo: FC<{ withLink?: boolean }> = ({ withLink = true }) => {
  const logoSrc = useLogoSrc();

  if (!withLink) {
    return (
      <Fragment>
        <img width={220} src={logoSrc} />
      </Fragment>
    );
  }

  return (
    <Link to="/">
      <img width={220} src={logoSrc} />
    </Link>
  );
};

export default AuthLogo;
