import { ModalActions, modalSelector } from "@/store/reducers/modal.reducer";
import { getValFromArray } from "@/utils";
import { Action } from "@components/dynamic/Action";
import { Card } from "@components/dynamic/Card";
import { useAction } from "@hooks/useAction";
import { IAction } from "@interfaces/action.interface";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import { FormInstance, Space } from "antd";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const Simple: React.FC<{
  block: IBlock;
  scope: IScope[];
  form: FormInstance<any>;
  formState: any;
  setFormState: any;
  loading: boolean;
  visible?: boolean;
}> = ({
  block,
  scope = [],
  loading,
  formState,
  setFormState,
  form,
  visible,
}) => {
  const [state, setState] = useState({
    body: block.body ? block.body : "",
    isFetched: false,
  });

  const { replaceFromScope } = useAction();
  const config: IScope[] = block?.config || [];
  const { page_name } = useSelector(modalSelector);
  const dispatch = useDispatch();

  const expandable =
    config.find((e: any) => e.name === "expandable")?.value === "1";
  const expanded =
    config.find((e: any) => e.name === "expanded")?.value === "1";
  const listen_to =
    config.find((e: any) => e.name === "listen_to")?.array || [];

  const actions = (block?.actions || []) as IAction[];

  useEffect(() => {
    if (listen_to.length > 0) {
      const _scope: IScope[] = [];

      listen_to.forEach((listenKey) => {
        _scope.push({
          name: listenKey.value,
          value: formState[listenKey.value] ? formState[listenKey.value] : "",
        });
      });

      setState((s) => ({
        ...s,
        body: replaceFromScope({
          str: block.body,
          // item: params,
          scope: _scope,
          withQuate: false,
        }),
      }));
    }
  }, [formState]);

  useEffect(() => {
    let params: any = {};

    scope.forEach((s) => {
      params[s.name] = s.value;
    });

    form.setFieldsValue(params);
    setFormState((s: any) => ({ ...s, ...params }));
  }, []);

  return (
    <Card
      body={listen_to.length > 0 ? state.body : block.body}
      title={block?.title}
      expanded={expanded}
      classes={classNames(block?.classes)}
      expandable={expandable}
      loading={loading}
      visible={visible}
      config={config}
    >
      <Space>
        {actions.map((action, index) => {
          return (
            <Action
              item={undefined}
              action={action}
              key={`simple-action-${index}`}
            />
          );
        })}
      </Space>
    </Card>
  );
};
