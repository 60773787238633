import { IScope } from "@/interfaces/scope.interface";
import { GET_PAGE, GET_PORTAL_PAGE } from "@/queries";
import {
  SideMenuActions,
  sideMenuSelector,
} from "@/store/reducers/side-menu.reducer";
import { useLazyQuery } from "@apollo/client";
import { Loading } from "@components/dynamic/Loading";
import { Panel } from "@components/Panel";
import { Col, Row } from "antd";
import classNames from "classnames";
import { Question, ArrowsHorizontal } from "phosphor-react";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { usePageTracker } from "../hooks/usePageTracker";
import { ModalActions, modalSelector } from "../store/reducers/modal.reducer";
import { PageComponent } from "./PageComponent";
import AuthLogo from "./AuthLogo";
import { useTranslation } from "react-i18next";

type Props = {
  name: string;
  style?: React.CSSProperties;
  page_params?: IScope[];
  className?: string;
};

export const RenderPortalPage: React.FC<Props> = ({
  name,
  style,
  page_params = [],
  className,
}) => {
  const [searchParams] = useSearchParams();

  const query = React.useMemo(() => {
    let q: any[] = [];

    for (const entry of searchParams.keys()) {
      q.push({
        name: entry,
        value: searchParams.get(entry),
      });
    }

    return q;
  }, [searchParams.keys()]);

  const { t } = useTranslation();

  const {
    page_name,
    tabs_opened,
    stack,
    refreshSelf,
    refreshParent,
    showModal,
    currentPage,
    refreshCurrent,
  } = useSelector(modalSelector);
  const {
    hasSideMenu,
    hasSideMenuOpen,
    page_name: sidePageName,
    tab_page_name,
    tabs,
    size,
  } = useSelector(sideMenuSelector);

  const dispatch = useDispatch();

  const tabs_open_ref = React.useRef(false);

  React.useEffect(() => {
    dispatch(
      ModalActions.toggleModal({
        page_name: name,
      })
    );
    return () => {
      dispatch(ModalActions.removeStack(name));
    };
  }, [name]);

  React.useEffect(() => {
    if (!tabs_opened) {
      dispatch(
        ModalActions.toggleModal({
          page_name: name,
          previous_page_name: page_name,
        })
      );
    }
  }, [name, page_name]);

  const [data, setData] = React.useState<any>({});
  const [loading, setLoading] = React.useState(true);
  const { registerActions } = usePageTracker();

  React.useEffect(() => {
    if (data?.getPortalPage) {
      if (tabs_opened) {
        dispatch(
          SideMenuActions.setTabPageName(data?.getPortalPage?.side_menu)
        );
        dispatch(
          SideMenuActions.setTabs({
            page_name: name,
            side_page_name: data?.getPortalPage?.side_menu,
          })
        );
      } else {
        dispatch(SideMenuActions.setPageName(data?.getPortalPage?.side_menu));
      }
    }
  }, [data, tabs_opened]);

  React.useEffect(() => {
    if (!tabs_opened && !showModal) {
      document.title = data?.getPortalPage?.title || "";
    }
  }, [tabs_opened, showModal, data?.getPortalPage?.title]);

  const [selectQuery] = useLazyQuery(GET_PORTAL_PAGE, {
    variables: {
      name,
      params: [
        ...query,
        ...page_params.map((e) => ({ name: e.name, value: e.value })),
      ],
    },

    // context: { headers: { "x-extra-data": search } },
  });

  const getData = React.useCallback(async () => {
    try {
      setLoading(true);
      const res = await selectQuery();
      if (res.data) {
        setData(res.data);
        registerActions(res?.data?.getPortalPage?.kb_actions);
        if (showModal) {
          dispatch(ModalActions.setTitle(res?.data?.getPortalPage?.title));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    if (stack && refreshSelf && name === stack[stack.length - 1]) {
      dispatch(ModalActions.toggleModal({ refreshSelf: false }));
      setTimeout(() => {
        getData();
      }, 200);
    }
    if (stack && refreshParent && name === stack[stack.length - 2]) {
      dispatch(ModalActions.toggleModal({ refreshParent: false }));
      setTimeout(() => {
        getData();
      }, 200);
    }
  }, [refreshSelf, refreshParent, stack, name]);

  React.useEffect(() => {
    if (refreshCurrent) {
      if (currentPage === name) {
        dispatch(
          ModalActions.toggleModal({ refreshParent: false, currentPage: "" })
        );
        setTimeout(() => {
          getData();
        }, 200);
      }
    }
  }, [refreshCurrent, currentPage]);

  // React.useEffect(() => {
  //   if (reload && previous_page_name !== name) {
  //     dispatch(ModalActions.toggleModal({ reload: false }));
  //     setTimeout(() => {
  //       getData();
  //     }, 200);
  //   }
  // }, [reload, previous_page_name, name]);

  // React.useEffect(() => {
  //   if (refresh && page_name === name) {
  //     dispatch(ModalActions.toggleModal({ refresh: false }));
  //     setTimeout(() => {
  //       getData();
  //     }, 200);
  //   }
  // }, [refresh, page_name, name]);

  React.useEffect(() => {
    if (sidePageName) {
      dispatch(SideMenuActions.hasSideMenu({ hasSideMenu: true }));
    } else {
      dispatch(SideMenuActions.hasSideMenu({ hasSideMenu: false }));
    }
  }, [sidePageName]);

  React.useEffect(() => {
    if (name) {
      getData();
    }
  }, [name]);

  return (
    <div
      className={classNames(
        "protal-page-content-wrapper",
        hasSideMenu &&
          (tab_page_name || sidePageName) &&
          hasSideMenuOpen &&
          "is-open",
        size,
        className
      )}
    >
      {loading && <Loading />}
      {hasSideMenu && (tab_page_name || sidePageName) && (
        <div
          className={classNames(
            "side-page-drawer",
            hasSideMenuOpen && "is-opened"
          )}
        >
          <div className="handler-icon">
            <Question
              onClick={() => {
                dispatch(SideMenuActions.toggle());
              }}
              className="icon"
            />
            {hasSideMenuOpen && (
              <ArrowsHorizontal
                onClick={() => {
                  dispatch(SideMenuActions.toggleSize());
                }}
                className="icon"
              />
            )}
          </div>
          <div className="content-side-wrapper">
            <PageComponent name={tab_page_name || sidePageName} />
          </div>
        </div>
      )}
      <Row
        style={{ marginTop: 20, marginBottom: 20, ...style }}
        gutter={[16, 16]}
        className={"main-row"}
      >
        {data?.getPortalPage?.panels?.map((panel: any, index: number) => {
          return (
            <Col
              span={24}
              key={`col-panel-${index}-${panel?.name}`}
              id={panel?.name}
            >
              <Panel
                loading={loading}
                scope={
                  data?.getPortalPage?.scope ? data?.getPortalPage?.scope : []
                }
                panel={panel}
                pageName={name}
              />
            </Col>
          );
        })}
      </Row>
      <p className="text-center mb-4">
        <Link to={"/"}>{t("backToHome")}</Link>
      </p>
    </div>
  );
};
