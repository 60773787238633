import { useAction } from "@/hooks/useAction";
import { replaceJsonDoubleQuotes } from "@/utils";
import { Card } from "@components/dynamic/Card";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import { Checkbox, Row, Switch } from "antd";
import classNames from "classnames";
import { map } from "lodash";
import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface ICourse {
  course_id: number;
  credit: number;
  description1: string;
  description2: string;
  name: string;
  practical: boolean;
  practical_text: string;
  status: boolean;
  selected?: boolean;
  practical_selected?: boolean;
  pacticalSelected?: boolean;
  force_selected?: boolean;
  category: string;
  ects_total: number;
  is_mandatory: boolean;
  __typename: string;
}

type ICourseSelection = ICourse & { practical_selected?: boolean };

export const CourseSelector: React.FC<{
  block: IBlock;
  scope: IScope[];
  loading: boolean;
  setFormState: any;
}> = memo(({ block, scope = [], loading, setFormState }) => {
  const { t } = useTranslation();
  const config: IScope[] = block?.config ? block?.config : [];
  const [courses, setCourses] = useState<ICourse[]>([]);
  const [selectedCourses, setSelectedCourses] = useState<ICourseSelection[]>(
    []
  );

  const visible = config.find((e: any) => e.name === "visible")?.value === "1";

  const expandable =
    config.find((e: any) => e.name === "expandable")?.value === "1";
  const expanded =
    config.find((e: any) => e.name === "expanded")?.value === "1";
  const show_credit_counter =
    config.find((e: any) => e.name === "show_credit_counter")?.value === "1";
  const show_course_counter =
    config.find((e: any) => e.name === "show_course_counter")?.value === "1";

  const course_query = config.find((e) => e.name === "course_query")?.value;
  const variable = config.find((e: any) => e.name === "variable")?.value;
  const max_credit = Number(
    config.find((e: any) => e.name === "max_credit")?.value
  );
  const min_credit = Number(
    config.find((e: any) => e.name === "min_credit")?.value
  );
  const min_count = Number(
    config.find((e: any) => e.name === "min_count")?.value
  );
  const max_count = Number(
    config.find((e: any) => e.name === "max_count")?.value
  );
  const min_ects = Number(
    config.find((e: any) => e.name === "min_ects")?.value
  );
  const max_ects = Number(
    config.find((e: any) => e.name === "max_ects")?.value
  );

  const min_mandatory = Number(
    config.find((e: any) => e.name === "min_mandatory")?.value
  );
  const max_mandatory = Number(
    config.find((e: any) => e.name === "max_mandatory")?.value
  );

  const { doQuery } = useAction();

  const getData = async () => {
    if (course_query) {
      try {
        const res: any = await doQuery({
          query: course_query,
          scope: block?.config,
        });

        const courses = res.data.getAvailableCourseSelections.map(
          (one: ICourse) => {
            return {
              ...one,
              pacticalSelected:
                typeof one.practical_selected === "undefined"
                  ? false
                  : one.practical_selected,
            };
          }
        );

        setCourses(courses);
        setSelectedCourses(
          courses.filter((one: ICourse) => one.selected || one.force_selected)
        );
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    getData();
  }, [course_query]);

  useEffect(() => {
    setFormState((s: any) => ({
      ...s,
      [variable as string]: replaceJsonDoubleQuotes(
        JSON.stringify(
          selectedCourses.map((e) => ({
            course_id: e.course_id,
            practical: e.pacticalSelected,
          }))
        )
      ),
    }));
  }, [selectedCourses]);

  const handleChange = (course: ICourse) => {
    if (course.force_selected) return;
    if (selectedCourses.find((e) => e.course_id === course.course_id)) {
      setSelectedCourses(
        selectedCourses.filter((e) => e.course_id !== course.course_id)
      );
    } else {
      setSelectedCourses([...selectedCourses, course]);
    }
  };

  const handlePracticalChange = (course: ICourse) => {
    setSelectedCourses((s) =>
      s.map((e) => {
        if (e.course_id === course.course_id) {
          return {
            ...e,
            pacticalSelected: !e.pacticalSelected,
          };
        }
        return e;
      })
    );
  };

  const checkIfDisabled = (course: ICourse) => {
    if (course.status === false) {
      return true;
    }
    if (Number(min_count) === 0 && Number(min_credit) === 0) {
      return false;
    }
    const find = selectedCourses.find((e) => e.course_id === course.course_id);

    const selectedLength = selectedCourses.length;
    const credit = calcCredit();

    if (
      (selectedLength >= Number(min_count) &&
        selectedLength >= Number(max_count)) ||
      (credit >= Number(min_credit) && credit >= Number(max_credit))
    ) {
      if (find) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  };

  const calcCredit = () => {
    let credit = 0;
    selectedCourses.forEach((e) => {
      credit += e.ects_total;
    });
    return credit;
  };

  const calcMandatoryCredit = () => {
    let credit = 0;
    selectedCourses.forEach((e) => {
      credit += e.is_mandatory ? e.ects_total : 0;
    });
    return credit;
  };

  const coursesWithCategory = courses.reduce(
    (curr: { [key: string]: ICourse[] }, prev) => {
      if (curr[prev.category]) {
        curr[prev.category].push(prev);
      } else {
        curr[prev.category] = [prev];
      }
      return curr;
    },
    {}
  );

  return (
    <Card
      scope={scope}
      body={block.body}
      title={block?.title}
      expanded={expanded}
      classes={block?.classes}
      expandable={expandable}
      loading={loading}
      visible={visible}
      config={config}
    >
      <div className="course-selector">
        <div className="flex-1 course-area">
          {map(coursesWithCategory, (courses, key) => {
            return (
              <Card title={key} body={""} key={key} className="mb-1">
                {courses.map((one) => (
                  <Row
                    key={one.course_id}
                    className={classNames(
                      "course-row align-items-center",
                      checkIfDisabled(one) && "disabled"
                    )}
                  >
                    <div className="d-flex gap-2 flex-1">
                      <Checkbox
                        id={`course-${one.course_id}`}
                        onChange={() => handleChange(one)}
                        disabled={checkIfDisabled(one)}
                        className="course-checkbox"
                        checked={
                          !!selectedCourses.find(
                            (course) => course.course_id === one.course_id
                          )
                        }
                      />
                      <label htmlFor={`course-${one.course_id}`}>
                        <div className="d-flex gap-2">
                          <p className="mb-0 course-title">{one.name} </p>
                          <span>{one.description2}</span>
                        </div>
                        <span>{one.description1}</span>
                      </label>
                    </div>
                    <div className="d-flex flex-col justify-end">
                      {one.practical && (
                        <div className="d-flex gap-2">
                          <span>{one.practical_text}</span>
                          <Switch
                            disabled={
                              !selectedCourses.find(
                                (course) => course.course_id === one.course_id
                              ) || checkIfDisabled(one)
                            }
                            checked={
                              selectedCourses.find(
                                (course) => course.course_id === one.course_id
                              )?.pacticalSelected
                            }
                            onChange={() => handlePracticalChange(one)}
                          />
                        </div>
                      )}
                    </div>
                  </Row>
                ))}
              </Card>
            );
          })}
        </div>
        <div className="grade">
          {show_course_counter && (
            <div>
              <p className="title">{t("subjectCount")}</p>
              <div className="d-flex gap-2">
                <p className="sub-count">{selectedCourses.length}</p>
                {(min_count || max_count) && (
                  <div className="d-flex flex-col">
                    {!!min_count && (
                      <span
                        style={{
                          color:
                            selectedCourses.length < min_count
                              ? "red"
                              : "inherit",
                        }}
                      >
                        {t("min")}: {min_count}
                      </span>
                    )}
                    {!!max_count && (
                      <span
                        style={{
                          color:
                            selectedCourses.length > max_count
                              ? "red"
                              : "inherit",
                        }}
                      >
                        {t("max")}: {max_count}
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          {show_credit_counter && (
            <div className="credit-count">
              <p className="title">{t("creditCount")}</p>
              <div className="d-flex gap-2">
                <p className="sub-count">{calcCredit()}</p>
                <div className="d-flex flex-col">
                  <span
                    style={{
                      color: calcCredit() < min_ects ? "red" : "inherit",
                    }}
                  >
                    {t("min")}: {min_ects}
                  </span>
                  <span
                    style={{
                      color: calcCredit() > max_ects ? "red" : "inherit",
                    }}
                  >
                    {t("max")}: {max_ects}
                  </span>
                </div>
              </div>
            </div>
          )}

          <div className="credit-count">
            <p className="title">{t("mandatoryCreditCount")}</p>
            <div className="d-flex gap-2">
              <p className="sub-count">{calcMandatoryCredit()}</p>
              <div className="d-flex flex-col">
                <span
                  style={{
                    color:
                      calcMandatoryCredit() < min_mandatory ? "red" : "inherit",
                  }}
                >
                  {t("min")}: {min_mandatory}
                </span>
                <span
                  style={{
                    color:
                      calcMandatoryCredit() > max_mandatory ? "red" : "inherit",
                  }}
                >
                  {t("max")}: {max_mandatory}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
});
