import React, { useState } from "react";
import Button from "./components/ui/buttons/Button";
import Table from "./components/ui/tables/Table";
import {
  Space,
  Tabs,
  Badge,
  Menu,
  PageHeader,
  Pagination,
  Radio,
  Checkbox,
  Switch,
  Rate,
  Upload,
  Row,
  Col,
  Input,
  Select,
  Divider,
  Typography,
  Dropdown,
  Avatar,
  DatePicker,
  TimePicker,
  Tag,
  Tooltip,
  Popover,
  List,
} from "antd";
//import "./less/main.less";
import {
  CirclesFour,
  Sun,
  Moon,
  CheckCircle,
  Activity,
  Users,
  Intersect,
  UploadSimple,
  Tray,
  Plus,
  MagnifyingGlass,
  CaretDown,
  FolderUser,
  User,
  PencilSimple,
  Trash,
} from "phosphor-react";

/* Tabs */
const { TabPane } = Tabs;
/* // Tabs */

/* Menu */
const { SubMenu } = Menu;
/* // Menu */

/* Input */
const { Search } = Input;
const { Option } = Select;
/* // Input */

/* Breadcrumb */
const routes = [
  {
    path: "index",
    breadcrumbName: "First-level Menu",
  },
  {
    path: "first",
    breadcrumbName: "Second-level Menu",
  },
  {
    path: "second",
    breadcrumbName: "Third-level Menu",
  },
];
/* // Breadcrumb */

/* Upload  */
const fileList: any = [
  {
    uid: "-1",
    name: "xxx.png",
    status: "done",
    url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    thumbUrl:
      "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
  },
  {
    uid: "-2",
    name: "yyy.png",
    status: "error",
  },
  {
    uid: "-5",
    name: "yyy.png",
    percent: 50,
    status: "uploading",
  },
];

const { Dragger } = Upload;
/* // Upload  */

let index = 0;

/* Dropdown */
const menuDropdown1 = (
  <Menu>
    <Menu.Item>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.antgroup.com"
      >
        1st menu item
      </a>
    </Menu.Item>
    <Menu.Item>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.aliyun.com"
      >
        2nd menu item
      </a>
    </Menu.Item>
    <Menu.Item>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.luohanacademy.com"
      >
        3rd menu item
      </a>
    </Menu.Item>
    <Menu.Item>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.luohanacademy.com"
      >
        4th menu item
      </a>
    </Menu.Item>
  </Menu>
);
const menuDropdown2 = (
  <Menu>
    <Menu.Item icon={<FolderUser />}>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.antgroup.com"
      >
        1st menu item
      </a>
    </Menu.Item>
    <Menu.Item
      icon={<Avatar size="small" src="https://joeschmoe.io/api/v1/random" />}
    >
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.aliyun.com"
      >
        2nd menu item
      </a>
    </Menu.Item>
    <Menu.Item
      icon={
        <Avatar size="small" src="https://joeschmoe.io/api/v1/female/random" />
      }
    >
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.luohanacademy.com"
      >
        3rd menu item
      </a>
    </Menu.Item>
    <Menu.Item
      icon={
        <Avatar
          size="small"
          style={{ backgroundColor: "#87d068" }}
          src="https://joeschmoe.io/api/v1/male/random"
        />
      }
    >
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.luohanacademy.com"
      >
        4th menu item
      </a>
    </Menu.Item>
  </Menu>
);
/* // Dropdown */

/* Popover */
const popoverContent = <div>this is popover long content text</div>;
/* // Popover */

/* List */
const listData = [
  "Racing car sprays burning fuel into crowd.",
  "Japanese princess to wed commoner.",
  "Australian walks 100km after outback crash.",
  "Man charged over missing wedding girl.",
  "Los Angeles battles huge wildfires.",
];
/* // List */

/* Pickers */
const { RangePicker } = DatePicker;
/* // Pickers */

const AllComponents = () => {
  /* Radio */
  const [value, setValue] = React.useState(1);

  const onChange = (e: any) => {
    setValue(e.target.value);
  };
  /* // Radio */
  /* Select */
  const [items, setItems] = useState(["jack", "lucy"]);
  const [name, setName] = useState("");

  const onNameChange = (event: any) => {
    setName(event.target.value);
  };

  const addItem = (e: any) => {
    e.preventDefault();
    setItems([...items, name || `New item ${index++}`]);
    setName("");
  };

  const children = [];
  for (let i = 10; i < 36; i++) {
    children.push(
      <Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>
    );
  }

  function handleChange(value: any) {}
  /* // Select */
  return (
    <div className="App">
      <div style={{ padding: "70px 0" }}>
        <h1>Buttons</h1>
        <h4 style={{ margin: "40px 0 20px" }}>Normal Buttons</h4>
        <Space>
          <Button variant="primary" type="primary">
            Primary
          </Button>
          <Button variant="secondary" type="primary">
            Secondary
          </Button>
          <Button variant="success" type="primary">
            Success
          </Button>
          <Button variant="warning" type="primary">
            Warning
          </Button>
          <Button variant="danger" type="primary">
            Error
          </Button>
          <Button variant="info" type="primary">
            Info
          </Button>
          <Button variant="light" type="primary">
            Light
          </Button>
          <Button variant="dark" type="primary">
            Dark
          </Button>
          <Button variant="primary" type="link">
            Link
          </Button>
        </Space>

        <h4 style={{ margin: "40px 0 20px" }}>Flush Buttons</h4>
        <Space>
          <Button variant="primary" types="flush">
            Primary
          </Button>
          <Button variant="secondary" types="flush">
            Secondary
          </Button>
          <Button variant="success" types="flush">
            Success
          </Button>
          <Button variant="warning" types="flush">
            Warning
          </Button>
          <Button variant="danger" types="flush">
            Error
          </Button>
          <Button variant="info" types="flush">
            Info
          </Button>
          <Button variant="light" types="flush">
            Light
          </Button>
          <Button variant="dark" types="flush">
            Dark
          </Button>
          <Button variant="primary" type="link">
            Link
          </Button>
        </Space>

        <h4 style={{ margin: "40px 0 20px" }}>Borderd Buttons</h4>
        <Space>
          <Button variant="primary" types="bordered">
            Primary
          </Button>
          <Button variant="secondary" types="bordered">
            Secondary
          </Button>
          <Button variant="success" types="bordered">
            Success
          </Button>
          <Button variant="warning" types="bordered">
            Warning
          </Button>
          <Button variant="danger" types="bordered">
            Error
          </Button>
          <Button variant="info" types="bordered">
            Info
          </Button>
          <Button variant="light" types="bordered">
            Light
          </Button>
          <Button variant="dark" types="bordered">
            Dark
          </Button>
          <Button variant="primary" type="link">
            Link
          </Button>
        </Space>

        <h4 style={{ margin: "40px 0 20px" }}>Icon Buttons</h4>
        <Space>
          <Button
            variant="primary"
            type="primary"
            icon={<CirclesFour />}
          ></Button>
          <Button
            variant="secondary"
            type="primary"
            icon={<CirclesFour />}
          ></Button>
          <Button
            variant="success"
            type="primary"
            icon={<CirclesFour />}
          ></Button>
          <Button
            variant="warning"
            type="primary"
            icon={<CirclesFour />}
          ></Button>
          <Button
            variant="danger"
            type="primary"
            icon={<CirclesFour />}
          ></Button>
          <Button variant="info" type="primary" icon={<CirclesFour />}></Button>
          <Button
            variant="light"
            type="primary"
            icon={<CirclesFour />}
          ></Button>
          <Button variant="dark" type="primary" icon={<CirclesFour />}></Button>
          <Button variant="primary" type="link" icon={<CirclesFour />}></Button>
        </Space>
      </div>

      <div style={{ borderTop: "3px solid #eee", padding: "70px 0" }}>
        <h1>Navigation</h1>
        <h2 style={{ margin: "40px 0 20px" }}>Tabs</h2>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <h4 style={{ margin: "40px 0 20px" }}>Line Tabs</h4>
            <Tabs defaultActiveKey="1">
              <TabPane tab="All users" key="1"></TabPane>
              <TabPane tab="Active users" key="2"></TabPane>
              <TabPane tab="Deleted users" key="3"></TabPane>
            </Tabs>
          </Col>
          <Col span={8}>
            <h4 style={{ margin: "40px 0 20px" }}>Solid Tabs</h4>
            <Tabs defaultActiveKey="1" type="card">
              <TabPane tab="All users" key="1"></TabPane>
              <TabPane tab="Active users" key="2"></TabPane>
              <TabPane tab="Deleted users" key="3"></TabPane>
            </Tabs>
          </Col>
          <Col span={8}>
            <h4 style={{ margin: "40px 0 20px" }}>Icon Tabs</h4>
            <Tabs defaultActiveKey="1" type="card" size="small">
              <TabPane tab={<Sun />} key="1"></TabPane>
              <TabPane tab={<Moon />} key="2"></TabPane>
            </Tabs>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={8}>
            <h2 style={{ margin: "40px 0 20px" }}>Menu</h2>
            <Menu
              defaultSelectedKeys={["1"]}
              style={{ width: 256 }}
              mode="inline"
            >
              <Menu.Item icon={<CirclesFour />} key="1">
                Dashboard
              </Menu.Item>
              <Menu.Item icon={<CheckCircle />} key="2">
                Tasks
              </Menu.Item>
              <SubMenu key="sub1" icon={<Activity />} title="Activities">
                <Menu.ItemGroup key="g1">
                  <Menu.Item key="3">Option 1</Menu.Item>
                  <Menu.Item key="4">Option 2</Menu.Item>
                  <Menu.Item key="5">Option 3</Menu.Item>
                  <Menu.Item key="6">Option 4</Menu.Item>
                </Menu.ItemGroup>
              </SubMenu>
              <SubMenu key="sub2" icon={<Users />} title="Users">
                <Menu.Item key="7">Option 5</Menu.Item>
                <Menu.Item key="8">Option 6</Menu.Item>
              </SubMenu>
              <Menu.Item icon={<Intersect />} key="9">
                Roles
              </Menu.Item>
            </Menu>
          </Col>
          <Col span={8}>
            <h2 style={{ margin: "40px 0 20px" }}>Page Header</h2>
            <PageHeader
              title="Users List"
              breadcrumb={{ routes }}
              extra={[
                <Button variant="success" types="flush">
                  Action 1
                </Button>,
                <Button variant="danger" types="flush">
                  Action 2
                </Button>,
              ]}
            ></PageHeader>
          </Col>
          <Col span={8}>
            <h2 style={{ margin: "40px 0 20px" }}>Pagination</h2>
            <Pagination defaultCurrent={1} total={50} showSizeChanger />
          </Col>
        </Row>
      </div>

      <div style={{ borderTop: "3px solid #eee", padding: "70px 0" }}>
        <h1>Data Entry</h1>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <h2 style={{ margin: "40px 0 20px" }}>Radio</h2>
            <Radio.Group onChange={onChange} value={value}>
              <Radio value={1}>Radio 1</Radio>
              <Radio value={2}>Radio 2</Radio>
              <Radio value={3}>Radio 3</Radio>
              <Radio value={4}>Radio 4</Radio>
            </Radio.Group>
          </Col>
          <Col span={12}>
            <h2 style={{ margin: "40px 0 20px" }}>Checkbox</h2>
            <Checkbox.Group>
              <Checkbox value={1}>Checkbox 1</Checkbox>
              <Checkbox value={2}>Checkbox 2</Checkbox>
              <Checkbox value={3}>Checkbox 3</Checkbox>
              <Checkbox value={4}>Checkbox 4</Checkbox>
            </Checkbox.Group>
          </Col>
          <Col span={12}>
            <h2 style={{ margin: "40px 0 20px" }}>Switch</h2>
            <Switch />
          </Col>
          <Col span={12}>
            <h2 style={{ margin: "40px 0 20px" }}>Rate</h2>
            <Rate defaultValue={3} />
            <span className="ant-rate-text">34 Rate</span>
          </Col>
        </Row>

        <h2 style={{ margin: "40px 0 20px" }}>Upload</h2>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <h4 style={{ margin: "0 0 20px" }}>Upload list</h4>
            <Upload defaultFileList={[...fileList]}>
              <Button variant="light" icon={<UploadSimple />}>
                Upload
              </Button>
            </Upload>
          </Col>
          <Col span={8}>
            <h4 style={{ margin: "0 0 20px" }}>Upload area</h4>
            <Dragger>
              <p className="ant-upload-drag-icon">
                <Tray />
              </p>
              <p className="ant-upload-text">Drag and Drop files</p>
              <p className="ant-upload-hint">
                Accepted files: .rar .zip .doc .docx .pdf .jpg
              </p>
            </Dragger>
          </Col>
          <Col span={8}>
            <h4 style={{ margin: "0 0 20px" }}>Upload area</h4>
            <Upload
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              listType="picture-card"
              fileList={fileList}
            >
              {fileList.length >= 8 ? null : (
                <div>
                  <Plus />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )}
            </Upload>
          </Col>
        </Row>

        <h2 style={{ margin: "40px 0 20px" }}>Input</h2>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <h4 style={{ margin: "0 0 20px" }}>Normal</h4>
            <Input placeholder="Placeholder" />
          </Col>
          <Col span={8}>
            <h4 style={{ margin: "0 0 20px" }}>With icon</h4>
            <Input placeholder="Placeholder" prefix={<MagnifyingGlass />} />
          </Col>
          <Col span={8}>
            <h4 style={{ margin: "0 0 20px" }}>With button</h4>
            <Search
              placeholder="Placeholder"
              enterButton={<MagnifyingGlass />}
            />
          </Col>
          <Col span={8}>
            <h4 style={{ margin: "0 0 20px" }}>Disabled</h4>
            <Input placeholder="Placeholder" disabled />
          </Col>
          <Col span={8}>
            <h4 style={{ margin: "0 0 20px" }}>With select</h4>
            <Input.Group compact>
              <Select defaultValue="Zhejiang">
                <Option value="Zhejiang">Zhejiang</Option>
                <Option value="Jiangsu">Jiangsu</Option>
              </Select>
              <Input
                style={{ width: "50%" }}
                defaultValue="Xihu District, Hangzhou"
              />
            </Input.Group>
          </Col>
        </Row>

        <h2 style={{ margin: "40px 0 20px" }}>Select</h2>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <h4 style={{ margin: "0 0 20px" }}>Normal</h4>
            <Select defaultValue="lucy" style={{ width: "100%" }}>
              <Option value="jack">Jack</Option>
              <Option value="lucy">Lucy</Option>
              <Option value="Yiminghe">yiminghe</Option>
            </Select>
          </Col>
          <Col span={8}>
            <h4 style={{ margin: "0 0 20px" }}>With search field</h4>
            <Select showSearch defaultValue="lucy" style={{ width: "100%" }}>
              <Option value="jack">Jack</Option>
              <Option value="lucy">Lucy</Option>
              <Option value="Yiminghe">yiminghe</Option>
            </Select>
          </Col>
          <Col span={8}>
            <h4 style={{ margin: "0 0 20px" }}>With add item</h4>
            <Select
              style={{ width: 300 }}
              placeholder="Option"
              dropdownRender={(menu) => (
                <React.Fragment>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space align="center" style={{ padding: "0 8px 4px" }}>
                    <Input
                      placeholder="Please enter item"
                      value={name}
                      onChange={onNameChange}
                    />
                    <Typography.Link
                      onClick={addItem}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <Plus /> Add item
                    </Typography.Link>
                  </Space>
                </React.Fragment>
              )}
            >
              {items.map((item) => (
                <Option key={item}>{item}</Option>
              ))}
            </Select>
          </Col>
          <Col span={8}>
            <h4 style={{ margin: "0 0 20px" }}>Multi select - Tags</h4>
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select"
              defaultValue={["a10", "c12"]}
              onChange={handleChange}
            >
              {children}
            </Select>
          </Col>
        </Row>

        <h2 style={{ margin: "40px 0 20px" }}>Dropdown</h2>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <h4 style={{ margin: "0 0 20px" }}>Normal</h4>
            <Dropdown overlay={menuDropdown1}>
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Hover me <CaretDown />
              </a>
            </Dropdown>
          </Col>
          <Col span={8}>
            <h4 style={{ margin: "0 0 20px" }}>With icon / image</h4>
            <Dropdown overlay={menuDropdown2}>
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Hover me <CaretDown />
              </a>
            </Dropdown>
          </Col>
          <Col span={8}>
            <h4 style={{ margin: "0 0 20px" }}>Button</h4>
            <Dropdown overlay={menuDropdown1}>
              <Button
                variant="primary"
                type="primary"
                className="ant-btn-primary"
              >
                Primary <CaretDown />
              </Button>
            </Dropdown>
          </Col>
          <Col span={8}>
            <h4 style={{ margin: "0 0 20px" }}>Icon</h4>
            <Dropdown overlay={menuDropdown1}>
              <Button
                variant="primary"
                type="primary"
                className="ant-btn-light"
                icon={<Plus />}
              ></Button>
            </Dropdown>
          </Col>
        </Row>

        <h2 style={{ margin: "40px 0 20px" }}>Datepicker</h2>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <h4 style={{ margin: "0 0 20px" }}>Normal</h4>
            <DatePicker style={{ width: "50%" }} />
          </Col>
          <Col span={12}>
            <h4 style={{ margin: "0 0 20px" }}>Range</h4>
            <RangePicker style={{ width: "50%" }} />
          </Col>
        </Row>

        <h2 style={{ margin: "40px 0 20px" }}>Timepicker</h2>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <h4 style={{ margin: "0 0 20px" }}>Normal</h4>
            <TimePicker style={{ width: "50%" }} />
          </Col>
          <Col span={12}>
            <h4 style={{ margin: "0 0 20px" }}>Range</h4>
            <TimePicker.RangePicker style={{ width: "50%" }} />
          </Col>
        </Row>
      </div>

      <div style={{ borderTop: "3px solid #eee", padding: "70px 0" }}>
        <h1>Data Display</h1>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <h2 style={{ margin: "40px 0 20px" }}>Avatar</h2>
            <Avatar icon={<User />} style={{ marginRight: "10px" }} />
            <Avatar
              style={{
                marginRight: "10px",
                backgroundColor: "blue",
              }}
            >
              U
            </Avatar>
            <Avatar
              style={{
                marginRight: "10px",
                backgroundColor: "yellow",
              }}
              src="https://joeschmoe.io/api/v1/random"
            />
            <Avatar
              shape="square"
              icon={<User />}
              style={{ marginRight: "10px" }}
            />
            <Avatar
              shape="square"
              style={{
                marginRight: "10px",
                backgroundColor: "blue",
              }}
            >
              U
            </Avatar>
            <Avatar
              shape="square"
              style={{
                marginRight: "10px",
                backgroundColor: "yellow",
              }}
              src="https://joeschmoe.io/api/v1/random"
            />
          </Col>
          <Col span={8}>
            <h2 style={{ margin: "40px 0 20px" }}>Badge</h2>
            <span style={{ marginRight: "20px" }}>
              <Badge count={5}>
                <Avatar shape="square" icon={<User />} />
              </Badge>
            </span>
            <span style={{ marginRight: "10px" }}>
              <Badge dot>
                <Avatar shape="square" style={{ backgroundColor: "blue" }}>
                  U
                </Avatar>
              </Badge>
            </span>
          </Col>
          <Col span={8}>
            <h2 style={{ margin: "40px 0 20px" }}>Tag</h2>
            <Tag>First tag</Tag>
            <Tag closable>Second tag</Tag>
            <Tag icon={<CheckCircle />} color="success">
              success
            </Tag>
            <Tag color="processing">processing</Tag>
            <Tag color="error">error</Tag>
            <Tag color="warning">warning</Tag>
          </Col>
          <Col span={8}>
            <h2 style={{ margin: "40px 0 20px" }}>Status</h2>
            <Badge
              status="success"
              text="online"
              style={{ marginRight: "30px" }}
            />
            <Badge status="success" />
            <Badge status="error" />
            <Badge status="default" />
            <Badge status="processing" />
            <Badge status="warning" />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <h2 style={{ margin: "40px 0 20px" }}>Tooltip</h2>
            <Tooltip title="prompt text">
              <span style={{ marginRight: "30px" }}>Normal</span>
            </Tooltip>
            <Tooltip color="yellow" title="prompt text">
              <span style={{ marginRight: "30px" }}>Colored</span>
            </Tooltip>
          </Col>
          <Col span={12}>
            <h2 style={{ margin: "40px 0 20px" }}>Popover</h2>
            <Popover content={popoverContent} title="Heading">
              <Button variant="primary" type="primary">
                Hover me
              </Button>
            </Popover>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={16}>
            <h2 style={{ margin: "40px 0 20px" }}>Table</h2>
            <Table></Table>
          </Col>
          <Col span={8}>
            <h2 style={{ margin: "40px 0 20px" }}>Table actions</h2>
          </Col>
        </Row>

        <h2 style={{ margin: "40px 0 20px" }}>Lists</h2>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <h4 style={{ margin: "0 0 20px" }}>Normal</h4>
            <List
              dataSource={listData}
              renderItem={(item) => <List.Item>{item}</List.Item>}
            />
          </Col>
          <Col span={8}>
            <h4 style={{ margin: "0 0 20px" }}>Flush</h4>
            <List
              split={false}
              dataSource={listData}
              renderItem={(item) => <List.Item>{item}</List.Item>}
            />
          </Col>
          <Col span={8}>
            <h4 style={{ margin: "0 0 20px" }}>With action</h4>
            <List
              dataSource={listData}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <a href="#" key="list-loadmore-edit">
                      <PencilSimple />
                    </a>,
                    <a href="#" key="list-loadmore-more">
                      <Trash />
                    </a>,
                  ]}
                >
                  {item}
                </List.Item>
              )}
            />
          </Col>
          <Col span={8}>
            <h4 style={{ margin: "0 0 20px" }}>With image / icon</h4>
            <List
              dataSource={listData}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<FolderUser />}
                    title={<a href="https://ant.design">{item}</a>}
                  />
                </List.Item>
              )}
            />
          </Col>
          <Col span={8}>
            <h4 style={{ margin: "0 0 20px" }}>With image / icon</h4>
            <List
              dataSource={listData}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                    title={<a href="https://ant.design">{item}</a>}
                    description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                  />
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AllComponents;
