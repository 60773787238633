import { useRedirect } from "@/hooks/useRedirect";
import { Card } from "@components/dynamic/Card";
import Button from "@components/ui/buttons/Button";
import { useAction } from "@hooks/useAction";
import { IScope } from "@interfaces/scope.interface";
import { Col, DatePicker, Form, FormInstance, Row, Space } from "antd";
import { Rule } from "antd/lib/form";
import classNames from "classnames";
import moment from "moment";
import { Check, NotePencil, X } from "phosphor-react";
import React, { useEffect, useMemo, useState } from "react";

const RegexParser = require("regex-parser");

interface IProps {
  block: any;
  scope: IScope[];
  loading: boolean;
  form: FormInstance<any>;

  formState: any;
  setFormState: any;
  editInline?: boolean;

  onChange?: (value: any, variable: string) => void;
  visible?: boolean;
}

export const FieldDatePicker: React.FC<IProps> = ({
  block,
  setFormState,
  formState,
  scope = [],
  onChange,
  editInline,
  form,
  visible,
}) => {
  const config: IScope[] = block?.config || [];
  const { checkRedirect } = useRedirect();

  const variable = config.find((e) => e.name === "variable")?.value || "";
  const picker = config.find((e) => e.name === "picker")?.value as
    | "time"
    | "date"
    | "week"
    | "month"
    | "quarter"
    | "year"
    | undefined;
  const placeholder = config.find((e) => e.name === "placeholder")?.value || "";
  const required = config.find((e) => e.name === "required")?.value === "1";
  const allowClear = config.find((e) => e.name === "allowClear")?.value === "1";
  const disabled = config.find((e) => e.name === "disabled")?.value === "1";
  const pattern = config.find((e) => e.name === "pattern")?.value;
  const validation_message = config.find(
    (e) => e.name === "validation_message"
  )?.value;

  //

  const graphql = config.find((e) => e.name === "graphql")?.value;
  const expanded = config.find((e) => e.name === "expanded")?.value === "1";
  const expandable = config.find((e) => e.name === "expandable")?.value === "1";
  const can_do = config.find((e) => e.name === "can_do")?.value === "1";
  const auto_save = config.find((e) => e.name === "auto_save")?.value || "";
  const query = config.find((e) => e.name === "query")?.value;
  const defaultValue = config.find((e) => e.name === "default")?.value || "";

  const inline =
    config.find((e) => e.name === "inline")?.value === "1" || false;
  const { doMutateQraphQl, replaceFromScope } = useAction();

  const [state, setState] = useState({
    loading: false,
    isValid: false,
    editMode: false,
    value: block?.body || "",
    editedValue: block?.body || "",
  });

  const { rules } = useMemo(() => {
    const rules: Rule[] = [];

    if (pattern) {
      rules.push({
        pattern: new RegExp(RegexParser(pattern)),
        message: validation_message,
      });
    }
    if (required) {
      rules.push({
        required: required,
        message: `${block?.title || block?.label} is required.`,
      });
    }

    return { rules };
  }, [required, pattern]);

  const Wrapper = inline ? "span" : Card;

  const CardComponent = (
    <Card
      body={state.value}
      title={block?.title}
      expanded={expanded}
      classes={block?.classes}
      expandable={expandable}
      visible={visible}
      config={config}
      editBodyButton={
        can_do ? (
          <Button
            key="card-edit-action-edit-inline"
            // type="button"
            loading={state.loading}
            variant="primary"
            onClick={() => setState((s) => ({ ...s, editMode: true }))}
            icon={<NotePencil />}
          />
        ) : undefined
      }
    />
  );

  const InlineCardComponent = (
    <Card
      body={
        <Space>
          <Form.Item
            label={block?.title}
            prefixCls=""
            required={required}
            className="inline-form-item"
          >
            {state.value}
          </Form.Item>
        </Space>
      }
      bordered={false}
      cardProps={{
        bodyStyle: { padding: 0 },
      }}
      expanded={expanded}
      classes={block?.classes}
      expandable={expandable}
      visible={visible}
      config={config}
      editBodyButton={
        can_do ? (
          <Button
            key="card-edit-action-edit-inline"
            // type="button"
            loading={state.loading}
            variant="primary"
            // size="small"
            onClick={() => setState((s) => ({ ...s, editMode: true }))}
            icon={<NotePencil />}
          />
        ) : undefined
      }
    />
  );

  const save = () => {
    let query = "";

    if (required && !state.editedValue) return;

    if (pattern) {
      const expression = new RegExp(RegexParser(pattern));

      const isMatch = expression.test(state.editedValue);
      if (!isMatch) return;
    }

    query = replaceFromScope({
      scope: [{ name: variable, value: state.editedValue }],
      str: graphql + "",
      withQuate: false,
    });

    setState((s) => ({ ...s, loading: true }));

    doMutateQraphQl(query)
      .then(() => {
        checkRedirect(config);
        setState((s) => ({
          ...s,
          loading: false,
          editMode: false,
          value: s.editedValue,
        }));

        onChange && onChange(state.editedValue, variable);
      })
      .catch((e) => {
        setState((s) => ({ ...s, loading: false, editMode: false }));
      });
  };
  const cancel = () => {
    setState((s) => ({ ...s, editMode: false, editedValue: s.value }));
  };

  const Required = () => {
    return (
      !state.editedValue && (
        <div className="ant-form-item-explain ">
          <div role="alert" className="ant-form-item-explain-error">
            {variable} is required.
          </div>
        </div>
      )
    );
  };
  const Pattern = () => {
    if (pattern) {
      const expression = new RegExp(RegexParser(pattern));

      const isMatch = expression.test(state.editedValue);

      return (
        !isMatch && (
          <div className="ant-form-item-explain ">
            <div role="alert" className="ant-form-item-explain-error">
              {validation_message}
            </div>
          </div>
        )
      );
    } else return null;
  };

  useEffect(() => {
    if (defaultValue) {
      const momentValue = moment(defaultValue);
      if (momentValue.isValid()) {
        const result = moment(momentValue)?.format("YYYY-MM-DD HH:mm:ss");
        setFormState((s: any) => ({
          ...s,
          [variable]: result,
        }));
        form.setFieldsValue({ [variable]: momentValue });
        onChange && onChange(result, variable);
      } else if (picker === "time") {
        const momentValue = moment(defaultValue, "HH:mm:ss");
        if (momentValue.isValid()) {
          const result = moment(momentValue)?.format("YYYY-MM-DD HH:mm:ss");
          setFormState((s: any) => ({
            ...s,
            [variable]: result,
          }));
          form.setFieldsValue({ [variable]: momentValue });
          onChange && onChange(result, variable);
        }
      }
    }
  }, []);

  const onFieldChange = (val: string) => {
    if (auto_save && auto_save === "on_change") {
      let q = "";

      if (pattern) {
        const expression = new RegExp(RegexParser(pattern));

        const isMatch = expression.test(val);
        if (!isMatch) return;
      }

      q = replaceFromScope({
        scope: [{ name: variable, value: val }],
        str: query + "",
        withQuate: false,
      });

      doMutateQraphQl(q)
        .then(() => {
          checkRedirect(config);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return editInline ? (
    state.editMode ? (
      <Wrapper
        body={""}
        title={inline ? block?.title : ""}
        expanded={expanded}
        classes={block?.classes}
        expandable={expandable}
        visible={visible}
        config={config}
      >
        <Row wrap={false} gutter={[16, 16]}>
          <Col flex="auto">
            <Form.Item
              label={block.title || block.label}
              name={variable}
              rules={rules}
              className={classNames(
                visible === false && "d-none",
                inline && "inline-form-item"
              )}
            >
              <DatePicker
                id={block?.name}
                style={{ width: "100%" }}
                placeholder={placeholder}
                disabled={disabled || state.loading}
                picker={picker}
                allowClear={allowClear}
                value={
                  state.editedValue ? moment(state.editedValue) : undefined
                }
                onChange={(value) => {
                  const result = value?.format("YYYY-MM-DD HH:mm:ss");
                  setState((s) => ({ ...s, editedValue: result }));
                }}
              />
            </Form.Item>
          </Col>
          <Col flex="none">
            <Space direction="horizontal">
              <Button
                variant="primary"
                type="primary"
                icon={<Check />}
                width={10}
                onClick={save}
                loading={state.loading}
              ></Button>
              <Button
                variant="danger"
                type="primary"
                icon={<X />}
                width={10}
                disabled={state.loading}
                onClick={cancel}
              ></Button>
            </Space>
          </Col>
        </Row>

        {Required()}
        {Pattern()}
      </Wrapper>
    ) : inline ? (
      InlineCardComponent
    ) : (
      CardComponent
    )
  ) : (
    <Form.Item
      label={block?.title || block?.label}
      name={variable}
      rules={rules}
      className={classNames(
        visible === false && "d-none",
        inline && "inline-form-item"
      )}
    >
      <DatePicker
        id={block?.name}
        style={{ width: "100%" }}
        placeholder={placeholder}
        disabled={disabled}
        picker={picker}
        allowClear={allowClear}
        onChange={(value) => {
          if (value) {
            const result = value?.format("YYYY-MM-DD HH:mm:ss");

            form.setFieldsValue({ [variable]: value });
            setFormState((s: any) => ({ ...s, [variable]: result }));
            onFieldChange(result);
            onChange && onChange(result, variable);
          } else {
            form.setFieldsValue({ [variable]: null });
            setFormState((s: any) => ({ ...s, [variable]: null }));

            onChange && onChange(null, variable);
          }
        }}
      />
    </Form.Item>
  );
};
