import { usePageTracker } from "@/hooks/usePageTracker";
import { useRedirect } from "@/hooks/useRedirect";
import { useAction } from "@hooks/useAction";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import { FormInstance, Popconfirm } from "antd";
import classNames from "classnames";
import PubSub from "pubsub-js";
import React, { useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import short from "short-uuid";
import {
  ModalActions,
  modalSelector,
} from "../../store/reducers/modal.reducer";
import Button, { ButtonVariant } from "../ui/buttons/Button";
import { Lightbulb } from "phosphor-react";
import { formSelector } from "@/store/reducers/form.reducer";

interface IProps {
  block: IBlock;
  scope: IScope[];
  loading: boolean;
  form: FormInstance<any>;
  formState: any;
  setFormState: any;
  visible?: boolean;
}

interface IState {
  loading: boolean;
}

export const FieldSubmit: React.FC<IProps> = ({
  block,
  form,
  formState,
  visible,
  setFormState,
}) => {
  const { replaceFromScope, convertItemToScope, doMutateQraphQl } = useAction();

  const { showModal, showDrawer } = useSelector(modalSelector);
  const { lastType } = useSelector(formSelector);

  const uuid = useRef(short.generate()).current;
  const { t } = useTranslation();
  const config = block?.config || [];
  const label = config.find((e) => e.name === "label")?.value;
  const graphql = config.find((e) => e.name === "graphql")?.value || "";
  const variant = config.find((e) => e.name === "variant")?.value || "primary";
  const kb_shortcut = config.find((e) => e.name === "kb_shortcut")?.value;
  const query_accessor = config.find((e) => e.name === "query_accessor")?.value;
  const is_portal = config?.find((e) => e.name === "is_portal")?.value === "1";
  const { page_name, submitLoading, btn_uuid } = useSelector(modalSelector);
  const need_confirm =
    config.find((e) => e.name === "need_confirm")?.value === "1";
  const confirm_message = config.find(
    (e) => e.name === "confirm_message"
  )?.value;
  const confirm_button = config.find((e) => e.name === "confirm_button")?.value;

  const dispatch = useDispatch();

  if (kb_shortcut) {
    useHotkeys(
      kb_shortcut,
      () => {
        submit();
      },
      [],
      {
        preventDefault: true,
        enableOnFormTags: ["INPUT", "SELECT", "TEXTAREA"],
      }
    );
  }

  const [state, setState] = useState<IState>({
    loading: false,
  });

  const { checkRedirect } = useRedirect();

  const { registerSubmit } = usePageTracker();

  useEffect(() => {
    if (page_name && config) {
      PubSub.publish("add_submit_btns", {
        config,
        page_name,
        uuid,
        kb_shortcut,
      });
      registerSubmit({ page_name, config, uuid });
    }
  }, [page_name, config]);

  const submit = async () => {
    if (block.type === "form_cancel") {
      if (lastType === "modal" && showModal) {
        dispatch(
          ModalActions.toggleModal({
            showModal: false,
            modalPageName: "",
            title: "",
          })
        );
      }
      if (lastType === "drawer" && showDrawer) {
        dispatch(
          ModalActions.toggleModal({
            showDrawer: false,
            drawerPageName: "",
          })
        );
      }
    }
    if (graphql) {
      form
        ?.validateFields()
        .then(() => {
          const _query = replaceFromScope({
            str: graphql,
            scope: convertItemToScope(formState),
            withQuate: true,
          });

          dispatch(ModalActions.startLoading(uuid));
          doMutateQraphQl(_query, is_portal)
            .then(async (res) => {
              setState((s) => ({ ...s, loading: false }));

              if (!res.errors) {
                if (query_accessor) {
                  checkRedirect(
                    config,
                    res.data[query_accessor],
                    form,
                    setFormState
                  );
                }
              }
            })
            .catch(() => {
              setState((s) => ({ ...s, loading: false }));
            })
            .finally(() => {
              dispatch(ModalActions.endLoading());
            });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  if (need_confirm) {
    return (
      <Popconfirm
        title={replaceFromScope({
          str: confirm_message || "",
          scope: [],
          withQuate: false,
        })}
        okText={replaceFromScope({
          str: confirm_button || "",
          scope: [],
          withQuate: false,
        })}
        cancelText={t("cancel")}
        onConfirm={(e) => {
          submit();
        }}
      >
        <Button
          type={"primary"}
          variant={variant as ButtonVariant}
          style={{ marginRight: 15, marginLeft: 15 }}
          htmlType="button"
          loading={submitLoading && btn_uuid === uuid}
          disabled={submitLoading && btn_uuid === uuid}
          className={classNames(visible === false && "d-none")}
        >
          {label}
        </Button>
      </Popconfirm>
    );
  }

  return (
    <Button
      type={"primary"}
      variant={variant as ButtonVariant}
      style={{ marginRight: 15, marginLeft: 15 }}
      htmlType="button"
      loading={submitLoading && btn_uuid === uuid}
      disabled={submitLoading && btn_uuid === uuid}
      className={classNames(visible === false && "d-none")}
      onClick={submit}
    >
      {label}
    </Button>
  );
};
