import Chart from "react-apexcharts";
import React, { useEffect, useRef } from "react";
import { useAction } from "@hooks/useAction";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import { Card } from "@components/dynamic/Card";
import { useSelector } from "react-redux";
import { themeSelector } from "@/store/reducers/theme.reducer";

export const StaticLineChartBlock: React.FC<{
  block: IBlock;
  scope: IScope[];
  loading?: boolean;
}> = ({ block, scope = [], loading }) => {
  const { replaceFromScope, getCSS } = useAction();

  const config: IScope[] = block?.config ? block?.config : [];

  const chartRef = useRef<HTMLDivElement | null>(null);
  const expandable =
    config.find((e: any) => e.name === "expandable")?.value === "1";
  const expanded =
    config.find((e: any) => e.name === "expanded")?.value === "1";
  const data = config.find((e: any) => e.name === "data")?.array || [];
  const xaxis = config.find((e: any) => e.name === "xaxis")?.array || [];

  const theme = useSelector(themeSelector).theme;

  return (
    <Card
      scope={scope}
      body={block.body}
      title={block?.title}
      expanded={expanded}
      classes={block?.classes}
      expandable={expandable}
      loading={loading}
      config={config}
    >
      {!!block?.body && (
        <p
          dangerouslySetInnerHTML={{
            __html: replaceFromScope({
              str: block?.body,
              scope,
              withQuate: false,
            }),
          }}
        ></p>
      )}

      <Chart
        options={{
          chart: {
            height: 350,
            type: "line",
            zoom: {
              enabled: false,
            },
            background: "transparent",
          },
          theme: {
            mode: theme,
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
          },
          title: {
            text: "Product Trends by Month",
            align: "left",
          },
          grid: {
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: xaxis.map((one) => one.value),
          },
        }}
        series={
          data.map((one) => ({
            name: one.name,
            data: one?.array && one?.array.map((two) => Number(two.value)),
          })) as ApexAxisChartSeries
        }
        type="line"
      />
    </Card>
  );
};
