import { Avatar, Comment } from "antd";
import { User } from "phosphor-react";
import React from "react";

export type ChatItemProps = {
  side?: string;
  name?: string;
  image?: string;
  time?: string;
  content: JSX.Element;
};
const ChatItem: React.FC<ChatItemProps> = ({
  side = "left",
  name,
  image,
  time,
  content,
}) => {
  return (
    <div className={`chat-item ${side === "right" ? "right" : "left"}`}>
      <Comment
        author={name}
        avatar={<Avatar src={image} icon={<User />} />}
        content={content}
        datetime={time}
      />
    </div>
  );
};

export default ChatItem;
