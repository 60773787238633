import { Card } from "@components/dynamic/Card";
import { useAction } from "@hooks/useAction";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import { Progress } from "antd";
import { ProgressSize, ProgressType } from "antd/lib/progress/progress";
import React, { memo } from "react";

export const StaticProgressbar: React.FC<{
  block: IBlock;
  scope: IScope[];
  loading: boolean;
  visible?: boolean;
}> = memo(({ block, scope = [], loading, visible }) => {
  const { replaceFromScope } = useAction();

  const config: IScope[] = block?.config ? block?.config : [];
  const percent = parseInt(
    config.find((e: any) => e.name === "percent")?.value || "0"
  );
  const size =
    (config.find((e: any) => e.name === "size")?.value as ProgressSize) ||
    "default";
  const type = (config.find((e: any) => e.name === "type")?.value ||
    undefined) as ProgressType;
  const status = (config.find((e: any) => e.name === "status")?.value ||
    undefined) as "success" | "normal" | "exception" | "active" | undefined;
  const showInfo =
    config.find((e: any) => e.name === "showInfo")?.value === "1";
  const success_percent = config.find(
    (e: any) => e.name === "success_percent"
  )?.value;
  const steps = config.find((e: any) => e.name === "steps")?.value;
  const expandable =
    config.find((e: any) => e.name === "expandable")?.value === "1";
  const expanded =
    config.find((e: any) => e.name === "expanded")?.value === "1";

  return (
    <Card
      scope={scope}
      body={block.body}
      title={block?.title}
      expanded={expanded}
      classes={block?.classes}
      expandable={expandable}
      loading={loading}
      visible={visible}
      config={config}
    >
      <Progress
        showInfo={showInfo}
        size={size}
        format={(percent) => (
          <div>
            <span>asdasd</span>
          </div>
        )}
        type={type}
        percent={percent}
        status={status}
        {...(success_percent
          ? { success: { percent: parseInt(success_percent) } }
          : {})}
        steps={steps ? parseInt(steps) : undefined}
      />
    </Card>
  );
});
