import { config } from "@/config";
import { FormActions } from "@/store/reducers/form.reducer";
import { ApolloQueryResult, FetchPolicy } from "@apollo/client";
import { IActionState } from "@components/dynamic/Action";
import { useApollo } from "@hooks/useApollo";
import { IAction } from "@interfaces/action.interface";
import { IScope } from "@interfaces/scope.interface";
import { ModalActions } from "@store/reducers/modal.reducer";
import { dispatch } from "@store/store";
import gql from "graphql-tag";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { modalSelector } from "../store/reducers/modal.reducer";
import { useRedirect } from "./useRedirect";
import { useUtiles } from "./useUtiles";
import { replaceJsonDoubleQuotes } from "@/utils";

export interface IDoAction {
  item: any;
  action: IAction;
}
export interface IDoReplaceString {
  item: any;
  str: string;
  withQuate?: boolean;
  filters?: any[];
}
export interface IDoMutate {
  item: any;
  action?: IAction;
  graphql: string;
}

export interface IGetForm {
  action: IAction;
  userID: number;
}
export interface IGoRoute {
  item: any;
  url?: string;
  target?: string;
  action: IAction;
  scope?: IScope[];
}

export interface IClickAction {
  action: IAction;
  setState?: React.Dispatch<React.SetStateAction<IActionState>>;
  item?: any;
  scope: IScope[];
  extraParams?: IScope["array"];
  actionClicked?: (res: any) => void;
  filters?: IScope[];
}

export const useAction = () => {
  const navigate = useNavigate();
  const { apolloDashboardClient: apolloClient, apolloPortalClient } =
    useApollo();
  const { consoleLog } = useUtiles();

  const { page_name: modalSelectorPageName } = useSelector(modalSelector);

  function getCSS(el: HTMLElement, styleProp: string) {
    const defaultView = (el.ownerDocument || document).defaultView;

    if (!defaultView) {
      return "";
    }

    // sanitize property name to css notation
    // (hyphen separated words eg. font-Size)
    styleProp = styleProp.replace(/([A-Z])/g, "-$1").toLowerCase();

    return defaultView.getComputedStyle(el, null).getPropertyValue(styleProp);
  }

  const extractTextBetween = (text: string, start: string, end: string) => {
    const regex = new RegExp(`${start}(.*?)${end}`, "g");
    const matches = text.match(regex);
    return matches;
  };

  const doAction = ({ item, action }: IDoAction) =>
    new Promise((resolve, reject) => {
      if (!action.query) resolve(undefined);

      let newQuery = action.query?.replaceAll(config.specialChar, "'") + "";
      const matches = extractTextBetween(newQuery, "'", "'");

      matches?.map((key) => {
        const itemKey = key.replaceAll("'", "");
        newQuery = newQuery.replaceAll(key, item[itemKey]);
      });

      apolloClient
        .query({
          query: gql`
            ${newQuery}
          `,
          // variables: {},
        })
        .then((data) => {
          // if(action.)
          // openNotificationWithIcon(data.data?.message, "", "success");
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });

  const doQuery = ({
    scope = [],
    item = undefined,
    query,
    fetchPolicy = "no-cache",
    variables = {},
    withQuate = true,
    portal = false,
  }: {
    scope?: IScope[];
    item?: any;
    query: string;
    variables?: any;
    fetchPolicy?: FetchPolicy | undefined;
    withQuate?: boolean;
    portal?: boolean;
  }) =>
    new Promise((resolve, reject) => {
      if (!query) resolve(undefined);

      if (item) {
        scope = [...scope, ...convertItemToScope(item)];
      }

      const newQuery = replaceFromScope({
        str: query,
        scope,
        withQuate,
      });

      if (portal) {
        return apolloPortalClient
          .query({
            query: gql`
              ${newQuery}
            `,
            variables,
            // notifyOnNetworkStatusChange : true
            fetchPolicy,
          })
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      }

      return apolloClient
        .query({
          query: gql`
            ${newQuery}
          `,
          variables,
          // notifyOnNetworkStatusChange : true
          fetchPolicy,
        })
        .then((data) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });

  const doReplaceString = ({
    item,
    str,
    withQuate = false,
    filters = [],
  }: IDoReplaceString) => {
    if (!str || !item) return "";
    let newStr = (str + "").replaceAll(config.specialChar, "'") + "";
    const matches = extractTextBetween(newStr, "'", "'");
    filters.forEach((one) => {
      if (!item[one.name]) {
        item[one.name] = one.val
          ? one.val
          : one.vals.length
          ? one.vals.join(",")
          : one.val1 && one.val2
          ? `${one.val1},${one.val2}`
          : null;
      }
    });

    matches?.map((key) => {
      const itemKey = key.replaceAll("'", "");
      const value = item[itemKey] ? item[itemKey] : "";
      newStr = newStr.replaceAll(
        key,
        withQuate ? `${value ? `"${value}"` : null}` : `${value ? value : null}`
      );
    });

    return newStr ? newStr : "";
  };

  const doMutateQraphQl = (graphql: string, is_portal?: boolean) => {
    if (is_portal) {
      return apolloPortalClient.mutate({
        mutation: gql`
          ${graphql}
        `,
      });
    }
    return apolloClient.mutate({
      mutation: gql`
        ${graphql}
      `,
    });
  };

  const convertItemToScope = (item: any) => {
    const scope = [] as IScope[];

    Object.keys(item).map((key) => {
      scope.push({
        name: key,
        value: item[key],
      });
    });

    return scope;
  };

  const replaceFromScope = ({
    str,
    scope,
    withQuate = false,
  }: {
    str: string;
    scope: IScope[];
    withQuate: boolean;
  }) => {
    if (!str) return "";
    let newStr = str?.replaceAll(config.specialChar, "'") + "";
    const matches = extractTextBetween(newStr, "'", "'");

    matches?.map((key) => {
      const itemKey = key.replaceAll("'", "");
      const item = scope.find((e) => e.name === itemKey);

      newStr = newStr.replaceAll(
        key,
        item
          ? typeof item.value === "string" ||
            typeof item.value === "number" ||
            typeof item.value === "object"
            ? `${withQuate ? '"' : ""}${
                typeof item?.value === "string"
                  ? item?.value?.replace(/(?:\r\n|\r|\n)/g, "_NL_")
                  : item?.value
              }${withQuate ? '"' : ""}`
            : " null "
          : withQuate
          ? " null "
          : ""
      );
    });

    return newStr ? newStr : "";
  };

  const doMutate = ({ item, action, graphql }: IDoMutate) =>
    new Promise((resolve, reject) => {
      let newQuery = graphql?.replaceAll(config.specialChar, "'") + "";

      const matches = extractTextBetween(newQuery, "'", "'");

      matches?.map((key) => {
        const itemKey = key.replaceAll("'", "");
        newQuery = newQuery.replaceAll(key, `"${item[itemKey]}"`);
      });

      // mutation{createUser(email:"aw111111111d@awd.awd",username:"12113",firstname:"123",lastname:"123"){id}}

      apolloClient
        .mutate({
          mutation: gql`
            ${newQuery}
          `,
        })
        .then((data) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });

  const getForm = ({ action, userID }: IGetForm) =>
    new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`
          query {
            getForm( userID :${userID} , name : "${action.form_name}") {
              name
              graphql


              rows {
                grid
                span
                fields{
                  name
                  type
                  name

                  label
                  span
                  placeholder
                  rules{
                    message
                    required
                    pattern
                  }
                }
                childs{
                  grid
                  span
                  fields{
                    name
                    type
                    name

                    label
                    span
                    placeholder
                    rules{
                      message
                      required
                      pattern
                    }


              }
                  childs{
                    grid
                    span
                    fields{
                      name
                      type
                       name
                type
                label
                span
                placeholder
                rules{
                  message
                  required
                  pattern
                }
                    }
                  }
                }
              }
            }
          }
          `,
          variables: {},
        })
        .then((data) => {
          // if(action.)
          // openNotificationWithIcon(data.data?.message, "", "success");
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });

  const goRoute = ({ item, action, url, target, scope = [] }: IGoRoute) => {
    let newRoute = url?.replaceAll(config.specialChar, "'") + "";
    const matches = extractTextBetween(newRoute, "'", "'");

    if (scope.length) {
      url = replaceFromScope({ str: newRoute, scope, withQuate: true });
    }

    if (item) {
      matches?.map((key) => {
        const itemKey = key.replaceAll("'", "");

        newRoute = newRoute.replaceAll(key, item[itemKey]);
      });
    }
    if (target === "blank") {
      window.open(newRoute, "_blank");
    } else {
      navigate(newRoute);
    }
  };

  const { checkRedirect } = useRedirect();

  const clickAction = ({
    action,
    setState,
    item,
    scope,
    actionClicked,
    extraParams = [],
    filters,
  }: IClickAction) => {
    const query = action.config?.find((e) => e.name === "query")?.value;
    const page_name =
      action.config?.find((e) => e.name === "page_name")?.value || "";
    const page_params =
      action.config?.find((e) => e.name === "page_params")?.array || [];
    const page_config =
      action.config?.find((e) => e.name === "page_config")?.array || [];
    const page_width =
      page_config?.find((e) => e.name === "page_width")?.value || "60";

    if (setState) {
      setState((s) => ({
        ...s,
        page_name,
        page_params,
        page_width,
      }));
    }

    if (action) {
      if (setState) {
        setState((s) => ({
          ...s,
          action,
          item: item,
        }));
      }
    }

    const params: typeof page_params = [];
    [...page_params]
      .concat(extraParams.filter((one) => one.value?.includes("@@")))
      .forEach((e) => {
        let _item = { ...e };
        if (_item.value?.includes("@@")) {
          const key = _item.value?.replaceAll("@@", "");
          if (item && item[key]) {
            _item.value = item[key];
          }
        }

        params.push(_item);
      });

    if (action.type === "gqlquery") {
      if (query) {
        let _query = doReplaceString({
          item,
          str: query,
          withQuate: true,
          filters,
        });

        doQuery({ query: _query, scope })
          .then((res) => {
            actionClicked && actionClicked(res);
            if (action?.config) {
              checkRedirect(action?.config);
            }
          })
          .catch((e) => {
            consoleLog("error action click", e);
          });
      }
    } else if (action.type === "link" && action.href) {
      if (action.target !== "blank") {
        dispatch(
          ModalActions.toggleModal({
            showDrawer: false,
          })
        );
      }
      goRoute({
        item: item,
        url: action.href,
        target: action.target,
        action,
      });
    } else if (action.type === "download" && action.href) {
      goRoute({
        item: item,
        url: action.href,
        target: "blank",
        action,
      });
    } else if (action.type === "open_drawer") {
      dispatch(
        ModalActions.toggleModal({
          drawerPageName: page_name,
          drawerPageParams: params,
          drawerWidth: page_width,
          showDrawer: true,
        })
      );
      dispatch(FormActions.setLastType("drawer"));
    } else if (action.type === "open_modal") {
      dispatch(
        ModalActions.toggleModal({
          modalPageName: page_name,
          modalePageParams: params,
          modalWidth: page_width,
          showModal: true,
          previous_page_name: modalSelectorPageName,
        })
      );
      dispatch(FormActions.setLastType("modal"));
    } else {
    }
  };

  return {
    doAction,
    goRoute,
    extractTextBetween,
    doMutate,
    getForm,
    doReplaceString,
    replaceFromScope,
    convertItemToScope,
    doMutateQraphQl,
    doQuery,
    getCSS,
    clickAction,
  };
};
