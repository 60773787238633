import { useMediaQuery } from "@/hooks/useMediaQuery";
import { Button as AntButton, ButtonProps } from "antd";
import classNames from "classnames";
import React, { PropsWithRef } from "react";

export type ButtonVariant =
  | "primary"
  | "secondary"
  | "success"
  | "warning"
  | "danger"
  | "info"
  | "light"
  | "dark";

export type ButtonTypes =
  | "link"
  | "text"
  | "ghost"
  | "default"
  | "primary"
  | "dashed"
  | "primary"
  | "bordered"
  | "flush"
  | undefined;

export interface CustomButtonProps extends ButtonProps {
  variant?: ButtonVariant;

  types?: ButtonTypes;
  width?: number;
  // type?:string

  // type :  'flush' | 'bordered' | 'flush'
}

// const Button: React.FC<ButtonProps & Props> = ({
const Button = React.forwardRef<ButtonProps, CustomButtonProps>(
  ({ variant, types, children, className, size, ...props }, ref) => {
    const isMobile = useMediaQuery("(max-width: 1080px)");
    const isDesktop = useMediaQuery("(min-width: 1080px)");

    return (
      <AntButton
        ref={ref as any}
        className={classNames(`ant-btn-${variant}`, types, className)}
        type={
          (types === "primary"
            ? ""
            : types === undefined
            ? "primary"
            : types) as any
        }
        size={
          size ? size : isDesktop ? "middle" : isMobile ? "small" : "middle"
        }
        {...props}
      >
        {children}
      </AntButton>
    );
  }
);

export default Button;
