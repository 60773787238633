import Button from "@components/ui/buttons/Button";
import { Badge, Col, Input, Radio, Row, Select, Table as AntTable } from "antd";
import { MagnifyingGlass, Sliders } from "phosphor-react";
import React from "react";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    render: (text: string) => <a>{text}</a>,
  },
  {
    title: "Age",
    dataIndex: "age",
  },
  {
    title: "Address",
    dataIndex: "address",
  },
];

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const data: DataType[] = [
  {
    key: "1",
    name: "John Brown",
    age: 32,
    address: "New York No. 1 Lake Park",
  },
  {
    key: "2",
    name: "Jim Green",
    age: 42,
    address: "London No. 1 Lake Park",
  },
  {
    key: "3",
    name: "Joe Black",
    age: 32,
    address: "Sidney No. 1 Lake Park",
  },
  {
    key: "4",
    name: "Disabled User",
    age: 99,
    address: "Sidney No. 1 Lake Park",
  },
];

const rowSelection = {
  onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {},
  getCheckboxProps: (record: DataType) => ({
    disabled: record.name === "Disabled User", // Column configuration not to be checked
    name: record.name,
  }),
};

function itemRender(current: any, type: any, originalElement: any) {
  if (type === "prev") {
    return <a>Previous</a>;
  }
  if (type === "next") {
    return <a>Next</a>;
  }
  return originalElement;
}

const Table: React.FC<any> = () => {
  const [openFilters, setOpenFilters] = React.useState(false);
  return (
    <div className="table">
      <div className="table-filters">
        <div className="table-filters-main">
          <Input
            placeholder="Find a user..."
            bordered={true}
            prefix={<MagnifyingGlass />}
          />
          <Select defaultValue="10 per page" bordered={false}>
            <Select.Option value="10">10 per page</Select.Option>
            <Select.Option value="50">50 per page</Select.Option>
            <Select.Option value="100">100 per page</Select.Option>
          </Select>
          <Badge count={3}>
            <Button
              size="small"
              variant="light"
              types="bordered"
              onClick={() => setOpenFilters(!openFilters)}
            >
              <Sliders />
              Light
            </Button>
          </Badge>
        </div>
        <div
          className={`table-filters-collapse ${openFilters ? "opened" : ""}`}
        >
          <div className="table-filters-collapse-container">
            <Row gutter={[16, 16]} align="middle">
              <Col span={8}>
                <Input placeholder="Job Title" />
              </Col>
              <Col span={8}>
                <Input placeholder="Company" />
              </Col>
              <Col span={4}>
                <Input placeholder="Lead Score" />
              </Col>
              <Col span={4}>
                <Radio.Group>
                  <Radio value={1}>Disabled</Radio>
                  <Radio value={2}>Active</Radio>
                </Radio.Group>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <AntTable
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        pagination={{
          position: ["bottomCenter"],
          itemRender: itemRender,
          total: 50,
          showSizeChanger: false,
        }}
      />
    </div>
  );
};

export default Table;
