import { IBlock } from "@/interfaces/block.interface";
import { IScope } from "@/interfaces/scope.interface";
import { replaceJsonDoubleQuotes } from "@/utils";
import { Badge, Col, DatePicker, Input, Row, Select, Skeleton } from "antd";
import { Plus, X } from "phosphor-react";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import short from "short-uuid";
import Button from "../ui/buttons/Button";
import { Card } from "./Card";
import { useAction } from "@/hooks/useAction";
import moment from "moment";

interface IProductAmountEditor {
  block: IBlock;
  formState: any;
  visible?: boolean;
  setFormState: any;
}

interface InvoiceRow {
  id: string | undefined;
  product: {
    id: string;
    name: string;
    __typename: string;
  };
  qty: number;
  max_qty?: number;
}

export const ProductAmountEditor: FC<IProductAmountEditor> = ({
  block,
  visible = true,
  setFormState,
  formState,
}) => {
  const config: IScope[] = block?.config || [];

  const { doQuery, replaceFromScope } = useAction();

  const { t } = useTranslation();

  const [rows, setRows] = useState<InvoiceRow[]>([]);
  const [loading, setLoading] = useState(false);

  const variable = config.find((e) => e.name === "variable")?.value;
  const enable_editing =
    config.find((e) => e.name === "enable_editing")?.value === "1";

  const items_query = config.find((e) => e.name === "items_query")?.value;
  const items_accessor = config.find((e) => e.name === "items_accessor")?.value;
  const scope_variables =
    config.find((e) => e.name === "scope_variables")?.array || [];

  const vars: string[] = useMemo(() => {
    return scope_variables.map((one) => one.name);
  }, [scope_variables]);

  const lazyDataLoaded = useRef(false);
  const prevFormState = useRef<{ [key: string]: string | boolean | number }>();

  const getItems = useCallback(async () => {
    if (items_query) {
      lazyDataLoaded.current = true;
      const newVars: any = {};
      vars.forEach((one) => {
        if (formState[one]) {
          newVars[one] = formState[one];
        }
      });

      let hasVariableState = true;

      Object.keys(newVars).forEach((key) => {
        if (!formState[key]) {
          hasVariableState = false;
        }
      });

      if (!hasVariableState) {
        return;
      }

      const _query = replaceFromScope({
        scope: Object.keys(newVars).map((key) => ({
          name: key,
          value: newVars[key],
        })),
        str: items_query + "",
        withQuate: false,
      });

      setLoading(true);
      doQuery({
        query: _query,
      })
        .then((data: any) => {
          if (data?.data) {
            if (items_accessor) {
              const newRows = [...data?.data[items_accessor || ""]] || [];
              newRows.forEach((one: any) => {
                one.max_qty = one.qty;
              });
              setRows(newRows);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [items_query, formState, items_accessor, vars, doQuery]);

  useEffect(() => {
    if (lazyDataLoaded.current === false) {
      getItems();
    }

    let get = false;
    vars.map((one) => {
      if (
        typeof formState[one] !== "undefined" &&
        !!formState[one] &&
        formState[one] !== prevFormState?.current?.[one]
      ) {
        get = true;
      }
    });

    prevFormState.current = formState;
    if (get) {
      getItems();
    }
  }, [items_query, formState]);

  useEffect(() => {
    const newRows: InvoiceRow[] = JSON.parse(JSON.stringify(rows));
    setFormState((s: any) => ({
      ...s,
      [variable as string]: replaceJsonDoubleQuotes(
        JSON.stringify(
          newRows.map((one) => {
            return one;
          })
        )
      ),
    }));
  }, [rows, setFormState, variable]);

  return visible && rows.length ? (
    <>
      <Card title="" body="">
        <Row
          style={{
            gap: "10px",
          }}
          align="middle"
        >
          <Col span={10}>
            <p>{t("productAmountEditor.product")}</p>
          </Col>
          <Col span={10}>
            <p>{t("productAmountEditor.qty")}</p>
          </Col>
          <Col span={2} />
        </Row>

        {loading ? (
          <Skeleton />
        ) : (
          rows.map((one, index) => (
            <Row
              key={index}
              style={{
                marginBottom: "10px",
                gap: "10px",
              }}
              align="middle"
            >
              <Col className="d-flex" span={10}>
                <Input
                  disabled={!enable_editing}
                  className="flex-1"
                  type={"text"}
                  readOnly
                  placeholder={t("productAmountEditor.product")}
                  value={one.product.name}
                  onChange={(event) => {
                    setRows(
                      rows.map((e) => {
                        if (e.id === one.id) {
                          e.product = {
                            ...e.product,
                            name: event.target.value,
                          };
                        }
                        return e;
                      })
                    );
                  }}
                />
              </Col>
              <Col className="d-flex" span={10}>
                <Input
                  disabled={!enable_editing}
                  className="flex-1"
                  placeholder={t("productAmountEditor.qty")}
                  type="number"
                  max={one.max_qty}
                  min={0}
                  value={one.qty}
                  onChange={(event) => {
                    setRows(
                      rows.map((e) => {
                        if (e.id === one.id) {
                          if (
                            Number(event.target.value) > Number(one.max_qty)
                          ) {
                            e.qty = Number(one.max_qty);
                          } else {
                            e.qty = Number(event.target.value);
                          }
                        }
                        return e;
                      })
                    );
                  }}
                />
              </Col>
              {enable_editing && (
                <Col span={1}>
                  <Button
                    onClick={() => {
                      setRows(rows.filter((e) => e.id !== one.id));
                    }}
                    variant="danger"
                    type="link"
                    size="small"
                  >
                    <X />
                  </Button>
                </Col>
              )}
            </Row>
          ))
        )}
      </Card>
    </>
  ) : (
    <></>
  );
};
