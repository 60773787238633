import ApexCharts from "apexcharts";
import React, { useEffect, useRef } from "react";
import { useAction } from "@hooks/useAction";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import { Card } from "@components/dynamic/Card";
import { useSelector } from "react-redux";
import { themeSelector } from "@/store/reducers/theme.reducer";

export const StaticLineChart: React.FC<{
  block: IBlock;
  scope: IScope[];
  loading: boolean;
}> = ({ block, scope = [], loading }) => {
  const { replaceFromScope, getCSS } = useAction();

  const config: IScope[] = block?.config ? block?.config : [];

  const chartRef = useRef<HTMLDivElement | null>(null);
  const expandable =
    config.find((e: any) => e.name === "expandable")?.value === "1";
  const expanded =
    config.find((e: any) => e.name === "expanded")?.value === "1";

  const data = config.find((e: any) => e.name === "data")?.array || [];
  const theme = useSelector(themeSelector).theme;

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    const height = parseInt(getCSS(chartRef.current, "height"));

    let series: any[] = [];
    let categories: any[] = [];

    const line_names = config.find((e: any) => e.name === "line_names");
    const x_axis_label =
      config.find((e: any) => e.name === "x_axis_label")?.value || "";
    const y_axis_label =
      config.find((e: any) => e.name === "y_axis_label")?.value || "";

    line_names?.array?.forEach((e, i) => {
      const line_name = config.find((ee: any) => ee.name === e.name);
      const line_value = e.value;
      if (i === 0) {
        categories = line_name?.pairs?.map((e) => e.a) || [];
        // categories
      }

      series.push({
        name: line_value,
        data: line_name?.pairs?.map((e) => e.b),
      });
    });

    const chart = new ApexCharts(chartRef.current, {
      series,
      //   : [
      //     {
      //       name: x_axis_label,
      //       data: line_1?.pairs?.map((e) => e.b),
      //     },
      //     {
      //       name: y_axis_label,
      //       data: line_2?.pairs?.map((e) => e.b),
      //     },
      //   ]
      chart: {
        height: height,
        type: "area",
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        // type: "datetime",
        // categories: line_1?.pairs?.map((e) => e.a),
        categories,
      },
      //   tooltip: {
      //     x: {
      //       format: "dd/MM/yy HH:mm",
      //     },
      //   },
    });

    if (chart) {
      chart.render();
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef]);

  return (
    <Card
      scope={scope}
      body={block.body}
      title={block?.title}
      expanded={expanded}
      classes={block?.classes}
      expandable={expandable}
      loading={loading}
      config={config}
    >
      {!!block?.body && (
        <p
          dangerouslySetInnerHTML={{
            __html: replaceFromScope({
              str: block?.body,
              scope,
              withQuate: false,
            }),
          }}
        ></p>
      )}

      <div ref={chartRef} style={{ height: "350px" }}></div>
    </Card>
  );
};
