import { Steps, StepsProps } from "antd";
import React from "react";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import { Card } from "@components/dynamic/Card";

const { Step } = Steps;

export const StaticStepbar: React.FC<{
  block: IBlock;
  scope: IScope[];
  loading: boolean;
  visible?: boolean;
}> = ({ block, scope = [], loading, visible }) => {
  const config: IScope[] = block?.config ? block?.config : [];

  const steps =
    config.find((e: any) => e.name === "steps")?.array || ([] as any[]);
  const expandable =
    config.find((e: any) => e.name === "expandable")?.value === "1";
  const expanded =
    config.find((e: any) => e.name === "expanded")?.value === "1";
  const size = (config.find((e: any) => e.name === "size")?.value ||
    "default") as StepsProps["size"];

  const direction = config.find((e: any) => e.name === "direction")?.value as
    | "horizontal"
    | "vertical"
    | undefined;

  const current = steps.find(
    (e: any) => e.name === config.find((e: any) => e.name === "current")?.value
  ) as any;

  return (
    <Card
      scope={scope}
      body={block.body}
      title={block?.title}
      expanded={expanded}
      classes={block?.classes}
      expandable={expandable}
      loading={loading}
      visible={visible}
      config={config}
    >
      <Steps
        size={size}
        current={current ? steps.indexOf(current) : 1}
        direction={direction}
      >
        {steps.map((step, index) => {
          const title =
            step?.array?.find((e: any) => e.name === "title")?.value || "";
          const subtitle =
            step?.array?.find((e: any) => e.name === "subtitle")?.value || "";
          const description =
            step?.array?.find((e: any) => e.name === "description")?.value ||
            "";
          return (
            <Step
              key={`step-${step.name}-${index}`}
              title={title}
              description={
                <div className="d-flex flex-col">
                  <span>{subtitle}</span>
                  <span>{description}</span>
                </div>
              }
            />
          );
        })}
      </Steps>
    </Card>
  );
};
