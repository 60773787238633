import { useRender } from "@hooks/useRender";
import { IFilter } from "@interfaces/filter.interface";
import { IScope } from "@interfaces/scope.interface";
import { Col, Form, Row } from "antd";
import React, { useEffect, useState } from "react";

interface IProps {
  scope: IScope[];
  panel?: any;
  loading?: boolean;
  filters: any[];
  onChangeFilters: (filters: IFilter[]) => void;
}

export const Filters: React.FC<IProps> = ({
  scope,
  filters,
  loading = false,
  onChangeFilters,
}) => {
  const [form] = Form.useForm();
  const [formState, setFormState] = useState<any>({});
  const [filterState, setFilterState] = useState<IFilter[]>([]);
  const { RenderBlocks } = useRender();
  // const { sendMessage } = useSocket();

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChangeFilters(filterState);

      // sendMessage("filters_changes", { filters: filterState });
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [filterState]);

  return (
    <Form autoComplete={"off"} layout="vertical" form={form} disabled={loading}>
      <Row style={{ marginTop: 20, marginBottom: 20 }} gutter={[16, 16]}>
        {filters?.map((block: any, index: number) => {
          return (
            <Col
              span={block?.width || 6}
              key={`col-block-${index}-${block?.name}`}
              id={block?.name}
            >
              {RenderBlocks({
                loading: loading,
                block,
                scope,
                form,
                formState,
                setFormState,
                setFilterState,
                filterState,
              })}
            </Col>
          );
        })}
      </Row>
    </Form>
  );
};
