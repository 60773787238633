import { Image } from "antd";
import React from "react";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import classNames from "classnames";

export const StaticImage: React.FC<{
  block: IBlock;
  scope: IScope[];
  loading: boolean;
  visible?: boolean;
}> = ({ block, scope = [], loading, visible }) => {
  const config: IScope[] = block?.config ? block?.config : [];

  const url = config.find((e: any) => e.name === "url")?.value;
  const alt = config.find((e: any) => e.name === "alt")?.value;
  const onclick = config.find((e: any) => e.name === "onclick")?.value;
  const target = config.find((e: any) => e.name === "target")?.value;
  const preview = config.find((e: any) => e.name === "preview")?.value === "1";

  const onClickImage = () => {
    if (onclick) {
      window.open(onclick, target === "blank" ? "_blank" : "");
    }
  };
  return (
    <Image
      width={"100%"}
      src={url}
      alt={alt}
      onClick={onClickImage}
      preview={preview}
      className={classNames(visible === false && "d-none")}
    />
  );
};
