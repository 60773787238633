import { IBlock } from "@/interfaces/block.interface";
import { IScope } from "@/interfaces/scope.interface";
import { replaceJsonDoubleQuotes } from "@/utils";
import { Col, Input, Row, Select } from "antd";
import { Plus, X } from "phosphor-react";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import short from "short-uuid";
import Button from "../ui/buttons/Button";
import { Card } from "./Card";
import { useAction } from "@/hooks/useAction";

interface IDueTermsEditor {
  block: IBlock;
  formState: any;
  visible?: boolean;
  setFormState: any;
}

interface InvoiceRow {
  due: number | undefined;
  type: string | undefined;
  period: number | undefined;
  plan: string | undefined;
  id: string | undefined;
}

export const DueTermsEditor: FC<IDueTermsEditor> = ({
  block,
  visible = true,
  setFormState,
}) => {
  const config: IScope[] = block?.config || [];

  const type_options =
    config.find((e) => e.name === "type_options")?.array || [];
  const plan_options =
    config.find((e) => e.name === "plan_options")?.array || [];
  const typeOptions = useMemo(() => {
    return type_options.map((one) => ({
      label: one.name,
      value: one.value,
      option: one,
    }));
  }, [type_options]);

  const planOptions = useMemo(() => {
    return plan_options.map((one) => ({
      label: one.name,
      value: one.value,
      option: one,
    }));
  }, [plan_options]);

  const defaultOption: InvoiceRow = {
    id: short.generate(),
    due: 0,
    type: typeOptions[0]?.value,
    period: 0,
    plan: planOptions[0]?.value,
  };
  const { doQuery } = useAction();

  const { t } = useTranslation();

  const [rows, setRows] = useState<InvoiceRow[]>([defaultOption]);

  const variable = config.find((e) => e.name === "variable")?.value;
  const enable_editing =
    config.find((e) => e.name === "enable_editing")?.value === "1";

  const items_query = config.find((e) => e.name === "items_query")?.value;
  const items_accessor = config.find((e) => e.name === "items_accessor")?.value;

  const getItems = () => {
    if (items_query) {
      doQuery({
        query: items_query,
      }).then((data: any) => {
        if (data?.data) {
          setRows([...data?.data[items_accessor || ""], defaultOption] || []);
        }
      });
    }
  };

  useEffect(() => {
    getItems();
  }, [items_query]);

  useEffect(() => {
    const newRows: InvoiceRow[] = JSON.parse(JSON.stringify(rows));
    setFormState((s: any) => ({
      ...s,
      [variable as string]: replaceJsonDoubleQuotes(
        JSON.stringify(
          newRows.map((one) => {
            return one;
          })
        )
      ),
    }));
  }, [rows, setFormState, variable]);

  return visible ? (
    <>
      <Card title="" body="">
        <Row
          style={{
            gap: "10px",
          }}
          align="middle"
        >
          <Col span={11}>
            <p>{t("dueTerms.due")}</p>
          </Col>
          <Col span={11}>
            <p>{t("dueTerms.after")}</p>
          </Col>
          <Col span={2} />
        </Row>

        {rows.map((one, index) => (
          <Row
            key={index}
            style={{ marginBottom: "10px", gap: "10px" }}
            align="middle"
          >
            <Col className="d-flex" span={11}>
              <Input
                disabled={!enable_editing}
                className="flex-1"
                type={"number"}
                placeholder={t("dueTerms.due")}
                value={one.due}
                onChange={(event) => {
                  setRows(
                    rows.map((e) => {
                      if (e.id === one.id) {
                        e.due = Number(event.target.value);
                      }
                      return e;
                    })
                  );
                }}
              />

              <Select
                disabled={!enable_editing}
                className="flex-1"
                value={
                  one.type
                    ? typeOptions.find((e) => e.value == one.type)?.value
                    : undefined
                }
                filterOption={false}
                options={typeOptions}
                onChange={(value) => {
                  setRows(
                    rows.map((e) => {
                      if (e.id === one.id) {
                        e.type = value;
                      }
                      return e;
                    })
                  );
                }}
                placeholder={t("dueTerms.type")}
              />
            </Col>
            <Col className="d-flex" span={11}>
              <Input
                disabled={!enable_editing}
                className="flex-1"
                type={"number"}
                placeholder={t("dueTerms.period")}
                value={one.period}
                onChange={(event) => {
                  setRows(
                    rows.map((e) => {
                      if (e.id === one.id) {
                        e.period = Number(event.target.value);
                      }
                      return e;
                    })
                  );
                }}
              />

              <Select
                disabled={!enable_editing}
                className="flex-1"
                value={
                  one.plan
                    ? planOptions.find((e) => e.value == one.plan)?.value
                    : undefined
                }
                filterOption={false}
                options={planOptions}
                onChange={(value) => {
                  setRows(
                    rows.map((e) => {
                      if (e.id === one.id) {
                        e.plan = value;
                      }
                      return e;
                    })
                  );
                }}
                placeholder={t("dueTerms.plan")}
              />
            </Col>

            {enable_editing && (
              <Col span={1}>
                {index === rows.length - 1 ? (
                  <Button
                    onClick={() => {
                      setRows((s) => [...s, defaultOption]);
                    }}
                    variant="primary"
                    type="link"
                    size="small"
                  >
                    <Plus />
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setRows(rows.filter((e) => e.id !== one.id));
                    }}
                    variant="danger"
                    type="link"
                    size="small"
                  >
                    <X />
                  </Button>
                )}
              </Col>
            )}
          </Row>
        ))}
      </Card>
    </>
  ) : (
    <></>
  );
};
