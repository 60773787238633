import { useRedirect } from "@/hooks/useRedirect";
import { Card } from "@components/dynamic/Card";
import Button from "@components/ui/buttons/Button";
import { useAction } from "@hooks/useAction";
import { IScope } from "@interfaces/scope.interface";
import { Form, FormInstance, Input, InputNumber } from "antd";
import { Rule } from "antd/lib/form";
import classNames from "classnames";
import { NotePencil } from "phosphor-react";
import React, { useEffect, useMemo, useState } from "react";

const RegexParser = require("regex-parser");

interface IProps {
  block: any;
  scope: IScope[];
  loading: boolean;
  form: FormInstance<any>;

  formState: any;
  setFormState: any;

  onChange?: (value: any, variable: string) => void;
  visible?: boolean;
}

export const EditInlineField: React.FC<IProps> = ({
  block,
  scope = [],
  form,
  setFormState,
  onChange,
  formState,
  visible,
  ...props
}) => {
  const [state, setState] = useState({
    loading: false,
    isValid: false,
    editMode: false,
  });

  const variable = block?.config?.find(
    (e: any) => e.name === "variable"
  )?.value;

  const placeholder =
    block?.config?.find((e: any) => e.name === "placeholder")?.value || "";
  const required =
    block?.config?.find((e: any) => e.name === "required")?.value === "1";
  const disabled =
    block?.config?.find((e: any) => e.name === "disabled")?.value === "1";
  const pattern = block?.config?.find((e: any) => e.name === "pattern")?.value;
  const validation_message = block?.config?.find(
    (e: any) => e.name === "validation_message"
  )?.value;

  const graphql = block?.config?.find((e: any) => e.name === "graphql")?.value;
  const min = block?.config?.find((e: any) => e.name === "min")?.value;
  const max = block?.config?.find((e: any) => e.name === "max")?.value;
  const prefix = block?.config?.find((e: any) => e.name === "prefix")?.value;
  const addonAfter = block?.config?.find(
    (e: any) => e.name === "addonAfter"
  )?.value;
  const addonBefore = block?.config?.find(
    (e: any) => e.name === "addonBefore"
  )?.value;
  const step = block?.config?.find((e: any) => e.name === "step")?.value;
  const defaultValue = block?.config?.find(
    (e: any) => e.name === "default"
  )?.value;

  const can_do =
    block?.config?.find((e: any) => e.name === "can_do")?.value === "1";
  const expanded =
    block?.config?.find((e: any) => e.name === "expanded")?.value === "1";
  const expandable =
    block?.config?.find((e: any) => e.name === "expandable")?.value === "1";
  const input_type = block?.config?.find(
    (e: any) => e.name === "input_type"
  )?.value;
  const rows = block?.config?.find((e: any) => e.name === "rows")?.value;

  const { rules } = useMemo(() => {
    const rules: Rule[] = [];

    if (pattern) {
      rules.push({
        pattern: new RegExp(RegexParser(pattern)),
        message: validation_message,
      });
    }
    if (required) {
      rules.push({
        required: required,
        message: `${block?.title || block?.label} is required.`,
      });
    }

    return { rules };
  }, [required, pattern]);

  useEffect(() => {
    changeValue(block.body);
  }, [block.body]);

  const changeValue = (value: string) => {
    form.setFieldsValue({ [variable]: value });
    setFormState((s: any) => ({
      ...s,
      [variable]: value,
    }));

    onChange && onChange(value, variable);
  };

  const { doMutateQraphQl, replaceFromScope, doReplaceString } = useAction();

  const { checkRedirect } = useRedirect();

  const save = async () => {
    await form.validateFields();
    const errors = form.getFieldError(variable);

    if (errors.length === 0) {
      let query = "";

      query = replaceFromScope({
        scope: [{ name: variable, value: formState[variable] }],
        str: graphql + "",
        withQuate: false,
      });

      setState((s) => ({ ...s, loading: true }));

      doMutateQraphQl(query)
        .then(() => {
          setState((s) => ({ ...s, loading: false, editMode: false }));
          checkRedirect(block?.config);
        })
        .catch((e) => {
          setState((s) => ({ ...s, loading: false, editMode: false }));
        });
    }
  };

  const CardComponent = (
    <Card
      body={formState[variable]}
      title={block?.title}
      expanded={expanded}
      classes={block?.classes}
      expandable={expandable}
      visible={visible}
      config={block?.config}
      cardProps={{
        extra: can_do
          ? [
              <Button
                key="card-edit-action-edit-inline"
                // type="button"
                loading={state.loading}
                size="small"
                onClick={() => setState((s) => ({ ...s, editMode: true }))}
                icon={<NotePencil />}
              />,
            ]
          : [],
      }}
    />
  );
  const FormItemComponent = (
    <Form.Item
      className={classNames(visible === false && "d-none")}
      label={
        <React.Fragment>
          <Button
            className="me-5"
            // type="button"
            loading={state.loading}
            size="small"
            onClick={save}
            icon={<NotePencil />}
          >
            {block?.title || block?.label}
          </Button>
        </React.Fragment>
      }
      name={variable}
      rules={[
        ...(rules ? rules : []),
        ({ getFieldValue }) => ({
          validator(rule, value) {
            if (value?.length < 0) {
              return Promise.reject(
                `${block?.title || block?.label} is required.`
              );
            } else {
              return Promise.resolve();
            }
          },
        }),
      ]}
    >
      {input_type === "textarea" && (
        <React.Fragment>
          <Input.TextArea
            placeholder={placeholder}
            rows={rows ? parseInt(rows) : 5}
            value={formState[variable]}
            size="small"
            disabled={disabled || state.loading}
            onChange={(event) => {
              form.setFieldsValue({ [variable]: event.target.value });
              setFormState((s: any) => ({
                ...s,
                [variable]: event.target.value,
              }));

              onChange && onChange(event.target.value, variable);
            }}
            {...props}
          />
        </React.Fragment>
      )}
      {input_type === "text" && (
        <Input
          value={formState[variable]}
          size="small"
          suffix={
            <Button
              // type="button"
              loading={state.loading}
              size="small"
              onClick={save}
              icon={<NotePencil />}
            />
          }
          placeholder={placeholder}
          disabled={disabled || state.loading}
          onChange={(event) => {
            form.setFieldsValue({ [variable]: event.target.value });
            setFormState((s: any) => ({
              ...s,
              [variable]: event.target.value,
            }));

            onChange && onChange(event.target.value, variable);
          }}
          {...props}
        />
      )}
      {input_type === "number" && (
        <InputNumber
          // suffix={can_do && <Button onClick={save} icon={<CirclesFour />} />}
          placeholder={placeholder}
          disabled={disabled}
          defaultValue={defaultValue}
          step={step}
          addonAfter={addonAfter}
          addonBefore={addonBefore}
          prefix={prefix}
          max={max}
          min={min}
          onChange={(value) => {
            form.setFieldsValue({ [variable]: value });
            setFormState((s: any) => ({ ...s, [variable]: value }));
          }}
        />
      )}
    </Form.Item>
  );

  return can_do
    ? state.editMode
      ? FormItemComponent
      : CardComponent
    : CardComponent;

  return can_do && !state.editMode ? (
    <Card
      body={formState[variable]}
      title={block?.title}
      expanded={expanded}
      classes={block?.classes}
      expandable={expandable}
      cardProps={{
        extra: [
          <Button
            key="card-edit-action-edit-inline"
            // type="button"
            loading={state.loading}
            size="small"
            onClick={() => setState((s) => ({ ...s, editMode: true }))}
            icon={<NotePencil />}
          />,
        ],
      }}
    />
  ) : (
    <Form.Item
      label={block?.title || block?.label}
      name={variable}
      rules={[
        ...(rules ? rules : []),
        ({ getFieldValue }) => ({
          validator(rule, value) {
            if (value?.length < 0) {
              return Promise.reject(
                `${block?.title || block?.label} is required.`
              );
            } else {
              return Promise.resolve();
            }
          },
        }),
      ]}
    >
      {input_type === "text" && (
        <Input
          value={formState[variable]}
          size="small"
          suffix={
            <Button
              // type="button"
              loading={state.loading}
              size="small"
              onClick={save}
              icon={<NotePencil />}
            />
          }
          placeholder={placeholder}
          disabled={disabled || state.loading}
          onChange={(event) => {
            form.setFieldsValue({ [variable]: event.target.value });
            setFormState((s: any) => ({
              ...s,
              [variable]: event.target.value,
            }));

            onChange && onChange(event.target.value, variable);
          }}
          {...props}
        />
      )}
      {input_type === "number" && (
        <InputNumber
          // suffix={can_do && <Button onClick={save} icon={<CirclesFour />} />}
          placeholder={placeholder}
          disabled={disabled}
          defaultValue={defaultValue}
          step={step}
          addonAfter={addonAfter}
          addonBefore={addonBefore}
          prefix={prefix}
          max={max}
          min={min}
          onChange={(value) => {
            form.setFieldsValue({ [variable]: value });
            setFormState((s: any) => ({ ...s, [variable]: value }));
          }}
        />
      )}
    </Form.Item>
  );
};
