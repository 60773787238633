import ClassRoom from "@/pages/ClassRoom";
import { PortalPage } from "@/pages/PortalPage";
import { authSelector } from "@/store/reducers/auth.reducer";
import { ForgetPassword } from "@pages/ForgetPassword";
import { Privacy } from "@pages/Privacy";
import { ResetPasssword } from "@pages/ResetPassword";
import { Signin } from "@pages/Signin";
import { SigninOtp } from "@pages/SigninOtp";
import { Signup } from "@pages/Signup";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router";

export const AuthRouters: React.FC<{}> = ({}) => {
  const { authConfig, accessToken, sessionToken } = useSelector(authSelector);

  return (
    <React.Fragment>
      <Routes>
        {authConfig?.login?.enabled && (
          <>
            <Route path="/signin" element={<Signin />} />
            <Route path="/signin-otp" element={<SigninOtp />} />
          </>
        )}
        {authConfig?.signup?.enabled && (
          <Route path="/signup" element={<Signup />} />
        )}
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/classroom" element={<ClassRoom />} />
        <Route
          path="/reset-password/:reset_token"
          element={<ResetPasssword />}
        />
        <Route path="/portal/page/:name" element={<PortalPage />} />
        <Route
          path="*"
          element={
            <Navigate
              to={
                accessToken || sessionToken
                  ? authConfig?.default_page || "/"
                  : "/signin"
              }
              replace
            />
          }
        />
      </Routes>
    </React.Fragment>
  );
};
