import { IUser } from "@interfaces/user.interface";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/reducers/root.reducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const NAME = "config";
type IConfigState = {
  google_maps_api_key?: string;
};
const initialState: IConfigState = {
  google_maps_api_key: "",
};
export const ConfigSlice = createSlice({
  name: NAME,
  initialState: initialState,
  reducers: {
    setGoogleMapsKey: (state, action) => {
      return { ...state, google_maps_api_key: action.payload?.key };
    },
  },
});

// Action creators are generated for each case reducer function
export const ConfigActions = ConfigSlice.actions;
export const configSelector = (state: RootState) => state.config;

export const ConfigReducer = persistReducer(
  {
    storage: storage,
    key: NAME,
    debug: false,
    whitelist: ["user", "accessToken"],
  },
  ConfigSlice.reducer
);
