import { FacebookLogo } from "phosphor-react";
import React, { useState } from "react";
// import FacebookLogin from "react-facebook-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { config } from "@/config";
import Button from "@components/ui/buttons/Button";

type Props = { buttonText: string };
export const FacebookButton: React.FC<Props> = ({ buttonText }) => {
  const responseFacebook = (response: any) => {
    // if (!response.error) {
    //   fetch(
    //     `https://graph.facebook.com/v14.0/${response.userID}/accounts?access_token=${response.accessToken}`,
    //     {}
    //   )
    //     .then((res) => res.json())
    //     .then((data) => {
    //       // setState({ ...state, data: data.data, visibleDrawer: true });
    //     });
    // }
  };
  const componentClicked = (
    response: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {};

  const [state, setState] = useState({
    visibleDrawer: false,
    data: [],
  });

  const closeDrawer = () => {
    setState((s) => ({ ...s, visibleDrawer: false }));
  };
  const loginToSystem = () => {};

  return (
    <React.Fragment>
      <FacebookLogin
        appId={config.facebookClientId}
        autoLoad={false}
        fields={config.facebookFields}
        onClick={componentClicked}
        callback={responseFacebook}
        scope="public_profile,email,pages_show_list"
        render={(renderProps) => (
          <Button
            onClick={renderProps.onClick}
            icon={<FacebookLogo />}
            variant="primary"
            types="flush"
            block
          >
            {buttonText}
          </Button>
        )}
      />
      {/* <Drawer
        title={undefined}
        closeIcon={""}
        placement={"right"}
        width={600}
        onClose={closeDrawer}
        visible={state.visibleDrawer}
        bodyStyle={{}}
      >
        <List
          itemLayout="horizontal"
          className="p-4"
          dataSource={state.data}
          renderItem={(item: any) => (
            <List.Item onClick={loginToSystem}>
              <List.Item.Meta
                title={<a>{item.name}</a>}
                description={item.category}
              />
            </List.Item>
          )}
        />
      </Drawer> */}
    </React.Fragment>
  );
};
