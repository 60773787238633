import { useLazyloadQuery } from "@hooks/useLazyloadQuery";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import React, { memo } from "react";

import { Card } from "./Card";

export const Lazyload: React.FC<{
  block: IBlock;
  scope: IScope[];
  loading: boolean;
  visible?: boolean;
}> = memo(({ block, scope = [], visible }) => {
  const config: IScope[] = block?.config ? block?.config : [];
  const refresh_after = parseInt(
    config.find((e) => e.name === "refresh_after")?.value || "0"
  );
  const expandable = config.find((e) => e.name === "expandable")?.value === "1";
  const expanded = config.find((e) => e.name === "expanded")?.value === "1";
  const graphql = config.find((e) => e.name === "graphql")?.value || "";

  const {
    allResult: result,
    loading,
    refetch,
  } = useLazyloadQuery({
    query: graphql,
    scope: config,
    variables: {},
  });

  return (
    <Card
      scope={scope}
      params={result}
      body={block.body}
      title={block?.title}
      expanded={expanded}
      classes={block?.classes}
      expandable={expandable}
      loading={loading}
      visible={visible}
      config={config}
    />
  );
});
