// https://erp2.inlinez.net/ajax/login

import { ADD_USER, QUERY_HI } from "@/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useAction } from "@hooks/useAction";
import { IScope } from "@interfaces/scope.interface";
import gql from "graphql-tag";
import React, { useEffect } from "react";
import { DATA_SELECTOR } from "../queries";

interface IState {
  query: string;
  query_accessor: string;
  value: string;
  refresh_after: number;
  result?: any;
  allResult?: any;
}

type Props = {
  variables?: any;
  query: string;
  scope?: IScope[];
};

export const useLazyloadQuery = ({
  variables = {},
  query,
  scope = [],
}: Props) => {
  const { doAction, goRoute, doReplaceString, replaceFromScope } = useAction();

  const [state, setState] = React.useState<IState>({
    query: "",
    query_accessor: "",
    value: "",
    refresh_after: 0,
    result: undefined,
    allResult: undefined,
  });

  const [getQuery, { loading, error, data, fetchMore, refetch }] = useLazyQuery(
    state.query ? gql(state.query) : QUERY_HI,
    {
      variables,
      notifyOnNetworkStatusChange: true,
    }
  );

  useEffect(() => {
    let timer: any;
    if (state.refresh_after > 0) {
      timer = setInterval(() => {
        refetch({});
      }, state.refresh_after * 1000);

      return () => timer && clearInterval(timer);
    }
  }, [state.refresh_after]);

  useEffect(() => {
    if (query) {
      const qr = replaceFromScope({ str: query, scope, withQuate: true });

      const query_accessor =
        scope.find((s) => s.name === "query_accessor")?.value || "";

      const value = scope.find((s) => s.name === "value")?.value || "";
      const refresh_after = parseInt(
        scope.find((s) => s.name === "refresh_after")?.value || "0"
      );
      setState((s) => ({
        ...s,
        query: qr,
        query_accessor,
        value,
        refresh_after,
      }));
    }
  }, [query]);

  useEffect(() => {
    if (state.query) {
      getQuery({});
    }
  }, [state.query]);

  useEffect(() => {
    if (data) {
      let stateKeys = {};

      if (data[state.query_accessor]) {
        stateKeys = {
          ...stateKeys,
          allResult: data[state.query_accessor],
        };
        if (data[state.query_accessor]) {
          stateKeys = {
            ...stateKeys,
            result: data[state.query_accessor][state.value],
          };
        }
        setState((s) => ({ ...s, ...stateKeys }));
      }
    }
  }, [data]);

  return { getQuery, loading, error, data, fetchMore, refetch, ...state };
};

export const useLazyloadMutation = ({
  variables = {},
  query,
  scope = [],
}: Props) => {
  const { doAction, goRoute, doReplaceString, replaceFromScope } = useAction();

  const [state, setState] = React.useState<IState>({
    query: "",
    query_accessor: "",
    value: "",
    refresh_after: 0,
    result: undefined,
    allResult: undefined,
  });

  const [doMutation, { data, loading, error }] = useMutation(
    state.query ? gql(state.query) : ADD_USER
  );

  useEffect(() => {
    let timer: any;
    if (state.refresh_after > 0) {
      timer = setInterval(() => {
        doMutation({});
      }, state.refresh_after * 1000);

      return () => timer && clearInterval(timer);
    }
  }, [state.refresh_after]);

  useEffect(() => {
    if (query) {
      const qr = replaceFromScope({ str: query, scope, withQuate: false });

      const query_accessor =
        scope.find((s) => s.name === "query_accessor")?.value || "";

      const value = scope.find((s) => s.name === "value")?.value || "";
      const refresh_after = parseInt(
        scope.find((s) => s.name === "refresh_after")?.value || "0"
      );
      setState((s) => ({
        ...s,
        query: qr,
        query_accessor,
        value,
        refresh_after,
      }));
    }
  }, [query]);

  // useEffect(() => {
  //   if (state.query) {
  //     doMutation({});
  //   }
  // }, [state.query]);

  useEffect(() => {
    if (data) {
      let stateKeys = {};

      if (data[state.query_accessor]) {
        stateKeys = {
          ...stateKeys,
          allResult: data[state.query_accessor],
        };
        if (data[state.query_accessor]) {
          stateKeys = {
            ...stateKeys,
            result: data[state.query_accessor][state.value],
          };
        }
        setState((s) => ({ ...s, ...stateKeys }));
      }
    }
  }, [data]);

  return { doMutation, loading, error, data, ...state };
};
