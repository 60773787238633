import { Action } from "@components/dynamic/Action";
import { useAction } from "@hooks/useAction";
import { IAction } from "@interfaces/action.interface";
import { IBlock } from "@interfaces/block.interface";
import { IScope, IScopeArray } from "@interfaces/scope.interface";
import {
  Card as AntCard,
  Col,
  Divider,
  FormInstance,
  Progress,
  Rate,
  Row,
  Space,
} from "antd";
import React from "react";
import { BadgeItem } from "@components/dynamic/Badge";
import { Card } from "@components/dynamic/Card";

export const GridItem: React.FC<{
  block: IBlock;
  scope: IScope[];
  form: FormInstance<any>;
  formState: any;
  setFormState: any;
  loading: boolean;
  show?: "grid" | "list";
  visible?: boolean;
}> = ({
  block,
  scope = [],
  loading,
  formState,
  setFormState,
  form,
  show = "grid",
  visible,
}) => {
  const { clickAction, doReplaceString } = useAction();

  const onClickAction = block?.onClickAction as IAction;
  const config: IScope[] = block?.config || [];
  const expandable = config.find((e) => e.name === "expandable")?.value === "1";
  const expanded = config.find((e) => e.name === "expanded")?.value === "1";
  const has_divider =
    config.find((e) => e.name === "has_divider")?.value === "1";
  const id = config.find((e) => e.name === "id")?.value;
  const image = config.find((e) => e.name === "image")?.value;
  const short_description = config.find(
    (e) => e.name === "short_description"
  )?.value;
  const section1 = config.find((e) => e.name === "section1")?.array || [];
  const section2 = config.find((e) => e.name === "section2")?.array || [];
  const section3 = config.find((e) => e.name === "section3")?.array || [];
  const badge = config.find((e) => e.name === "badge")?.array || [];

  const actions = (block?.actions || []) as IAction[];

  const onClickItem = () => {
    if (onClickAction) {
      clickAction({ action: onClickAction, scope: scope, item: undefined });
    }
  };

  return (
    <Card
      body={""}
      title={""}
      expanded={expanded}
      classes={
        block?.classes +
        ` ${onClickAction ? " pointer " : ""} fixed-card ${
          show === "list" ? "list-item" : ""
        }`
      }
      visible={visible}
      config={config}
      expandable={expandable}
      loading={loading}
      cardProps={{
        headStyle: {
          position: "absolute",
          border: 0,
          padding: 0,
          paddingRight: 2,
          paddingLeft: 10,
          zIndex: 10,
        },
        cover: <img alt="example" src={image} />,
        extra: (
          <>
            {badge.map((b, index) => (
              <BadgeItem
                key={`badge-item-${index}`}
                style={{}}
                badge={b.array || []}
                type="tag"
              ></BadgeItem>
            ))}
          </>
        ),
        onClick: onClickItem,
      }}
    >
      <AntCard.Meta title={block.title} />
      <p className="text-truncate-1 mt-1 fixed-card-short-description">
        {short_description}
      </p>
      <p
        className="text-truncate-3 my-1 fixed-card-description"
        dangerouslySetInnerHTML={{
          __html: doReplaceString({
            str: block.body,
            item: {},
          }),
        }}
      >
        {/* {block.body} */}
      </p>
      <Divider className="my-1" />
      <Row justify="space-between" className="fixed-card-section-1-2">
        <Col span={12} flex={1}>
          <Section1 section={section1} />
        </Col>
        <Col span={12} flex="none">
          <Space align="end" direction="horizontal">
            <Section2 section={section2} />
          </Space>
        </Col>
      </Row>

      <Divider style={has_divider ? {} : { border: 0 }} className="my-1" />

      <Row
        justify="space-between"
        align="middle"
        className="fixed-card-section-3-actions"
      >
        <Col span={12} flex={1}>
          <Space align="center" direction="horizontal">
            <Section3 section={section3} />
          </Space>
        </Col>
        <Col span={12} flex="none">
          <Space align="end" direction="horizontal">
            {actions.map((action, index) => {
              return (
                <Action
                  item={undefined}
                  action={action}
                  key={`simple-action-${index}`}
                />
              );
            })}
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

const Section1: React.FC<{ section: IScopeArray[] }> = ({ section }) => {
  const typeRate = section.find((e) => e.name === "type")?.value === "rate";
  const typeProgress =
    section.find((e) => e.name === "type")?.value === "progressbar";
  const rate = section.find((e) => e.name === "rate")?.value;
  const count = section.find((e) => e.name === "count")?.value || "";
  const text = section.find((e) => e.name === "text")?.value;
  const percent = section.find((e) => e.name === "percent")?.value;

  return (
    <span className="text-truncate-1">
      {typeRate && (
        <>
          <Rate
            allowHalf
            disabled
            count={count ? parseInt(count) : 5}
            defaultValue={rate ? parseInt(rate) : 0}
            style={{ fontSize: 13 }}
          />
          {text ? <span className="ant-rate-text">{text}</span> : ""}
        </>
      )}
      {typeProgress && (
        <>
          <Progress percent={percent ? parseInt(percent) : 0} />
        </>
      )}
    </span>
  );
};

const Section2: React.FC<{ section: IScopeArray[] }> = ({ section }) => {
  const text = section.find((e) => e.name === "text")?.value || "";

  return <p className="text-truncate-1">{text}</p>;
};

const Section3: React.FC<{ section: IScopeArray[] }> = ({ section }) => {
  const text = section.find((e) => e.name === "text")?.value || "";

  return (
    <h2 className="my-0 mx-0" style={{ marginBottom: 0 }}>
      {text}
    </h2>
  );
};
