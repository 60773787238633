import { Modal } from "antd";
import { FC, useEffect, useMemo, useState } from "react";
import { IFile } from "../dynamic/FieldFileUploader";
import { useTranslation } from "react-i18next";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

interface IPreview {
  visible: boolean;
  file: IFile | undefined;
  handleCancel: () => void;
  previewImage: string;
}

const Preview: FC<IPreview> = ({
  visible,
  file,
  handleCancel,
  previewImage,
}) => {
  const { t } = useTranslation();
  const [mounted, setMounted] = useState<boolean>(false);
  const [fileUrl, setFileUrl] = useState<string>("");

  useEffect(() => {
    if (visible && file?.url) {
      setFileUrl(file?.url);
    } else {
      setFileUrl("");
    }
  }, [visible, file]);

  useEffect(() => {
    if (visible) {
      setMounted(true);
    } else {
      setMounted(false);
    }
  }, [visible]);

  if (!visible) {
    return null;
  }

  return (
    <Modal
      visible={visible}
      title={t("viewImage")}
      footer={null}
      onCancel={handleCancel}
      width={
        file?.type === "jpg" || file?.type === "png" || file?.type === "jpeg"
          ? 500
          : 800
      }
      centered
    >
      {visible ? (
        file?.type === "jpg" ||
        file?.type === "png" ||
        file?.type === "jpeg" ? (
          <img alt="" style={{ width: "100%" }} src={previewImage} />
        ) : (
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={[
              {
                uri: fileUrl,
              },
            ]}
          />
        )
      ) : null}
    </Modal>
  );
};

export default Preview;
