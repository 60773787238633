import { useAction } from "@/hooks/useAction";
import { IBlock } from "@/interfaces/block.interface";
import { IScope } from "@/interfaces/scope.interface";
import { Col, Input, Row, Select, TreeSelect } from "antd";
import debounce from "lodash/debounce";
import { Plus, X } from "phosphor-react";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import short from "short-uuid";
import Button from "../ui/buttons/Button";
import { Card } from "./Card";
import { Loading } from "./Loading";
import { replaceJsonDoubleQuotes } from "@/utils";

interface IExpenseItemEditor {
  block: IBlock;
  formState: any;
  visible?: boolean;
  setFormState: any;
}

interface InvoiceRow {
  account: IOption | string;
  price: number;
  description: string;
  id: string;
  fetchAccounts: boolean;
  accountQuery: string;
  accountsOptions: IOption[];
  accountOption?: IOption;
}

interface IOption {
  label: string;
  value: string;
  id?: string;
  option?: any;
}

export const ExpenseItemEditor: FC<IExpenseItemEditor> = ({
  block,
  visible = true,
  formState,
  setFormState,
}) => {
  const defaultOption: InvoiceRow = {
    account: "",
    price: 0,
    description: "",
    id: short.generate(),
    fetchAccounts: false,
    accountQuery: "",
    accountsOptions: [],
  };
  const config: IScope[] = block?.config || [];

  const { doQuery } = useAction();

  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<IOption[]>([]);
  const [rows, setRows] = useState<InvoiceRow[]>([defaultOption]);

  const accounts_query = config.find((e) => e.name === "accounts_query")?.value;
  const accounts_accessor = config.find(
    (e) => e.name === "accounts_accessor"
  )?.value;
  const items_query = config.find((e) => e.name === "items_query")?.value;
  const items_accessor = config.find((e) => e.name === "items_accessor")?.value;
  const variable = config.find((e) => e.name === "variable")?.value;
  const enable_editing =
    config.find((e) => e.name === "enable_editing")?.value === "1";

  const multi_level =
    config.find((e) => e.name === "multi_level")?.value === "1";

  const getData = () => {
    if (accounts_query) {
      doQuery({
        query: accounts_query,
        scope: [{ name: "account_name", value: "" }],
        withQuate: false,
      }).then((data: any) => {
        if (data?.data) {
          if (multi_level) {
            const optionsWithGroups =
              data?.data[accounts_accessor || ""].options.map(
                (one: any, index: number) => {
                  return {
                    title: one.title,
                    value: one.name,
                    children: renderChilds(one),
                  };
                }
              ) || [];
            setOptions(optionsWithGroups);
          } else {
            const optionsWithGroups =
              data?.data[accounts_accessor || ""].options.map(
                (one: any, index: number) => {
                  if (one?.options?.length) {
                    return {
                      label: one.title,
                      options: one?.options?.map((o: any) => ({
                        label: o.title,
                        value: o.name,
                      })),
                    };
                  }
                  return {
                    label: one.title,
                    value: one.name,
                    option: one,
                  };
                }
              ) || [];
            setOptions(optionsWithGroups);
          }
        }
      });
    }
  };

  useEffect(() => {
    const newRows: InvoiceRow[] = JSON.parse(JSON.stringify(rows));
    setFormState((s: any) => ({
      ...s,
      [variable as string]: replaceJsonDoubleQuotes(
        JSON.stringify(
          newRows.map((one) => {
            return one;
          })
        )
      ),
    }));
  }, [rows, setFormState, variable]);

  const renderChilds = (data: any) => {
    return data?.options?.map((one: any) => {
      if (one?.options?.length) {
        return {
          title: one.title,
          value: one.name,
          children: renderChilds(one),
        };
      }
      return {
        title: one.title,
        value: one.name,
        option: one,
      };
    });
  };

  const searchAccounts = useMemo(() => {
    if (!accounts_query) return;
    const loadOptions = (val: string, one: InvoiceRow) => {
      if (val === "") {
        return;
      }
      setRows((s) =>
        s.map((e) => {
          if (e.id === one.id) {
            e.fetchAccounts = true;
            e.accountQuery = val;
          }
          return e;
        })
      );
      doQuery({
        query: accounts_query,
        scope: [{ name: "account_name", value: val.toString() }],
        withQuate: false,
      })
        .then((data: any) => {
          if (data?.data) {
            setRows((s) =>
              s.map((e) => {
                if (e.id === one.id) {
                  if (multi_level) {
                    e.accountsOptions =
                      data?.data[accounts_accessor || ""].options.map(
                        (one: any) => ({
                          title: one.title,
                          value: one.name,
                          children: renderChilds(one),
                        })
                      ) || [];
                  } else {
                    e.accountsOptions =
                      data?.data[accounts_accessor || ""].options.map(
                        (one: any) => ({
                          label: one.title,
                          value: one.name,
                          options:
                            one?.options?.map((o: any) => ({
                              label: o.title,
                              value: o.name,
                              id: o.id,
                            })) || [],
                        })
                      ) || [];
                  }
                }
                return e;
              })
            );
          }
        })
        .finally(() => {
          setRows((s) =>
            s.map((e) => {
              if (e.id === one.id) {
                e.fetchAccounts = false;
              }
              return e;
            })
          );
        });
    };

    return debounce(loadOptions, 1000);
  }, [accounts_query, rows, accounts_accessor]);

  const getItems = () => {
    if (items_query) {
      setLoading(true);
      doQuery({
        query: items_query,
      })
        .then((data: any) => {
          if (data?.data) {
            setRows([...data?.data[items_accessor || ""], defaultOption] || []);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getData();
  }, [accounts_query]);

  useEffect(() => {
    getItems();
  }, [items_query]);

  return visible ? (
    <>
      <Card title="" body="">
        {loading && <Loading />}
        {!loading && (
          <Row
            style={{
              gap: "10px",
              justifyContent: "space-between",
            }}
            align="middle"
          >
            <Col>
              <p>{t("description")}</p>
            </Col>
            <Col>
              <p>{t("price")}</p>
            </Col>
            <Col>
              <p>{t("account")}</p>
            </Col>
            <Col style={{ minWidth: 35 }} />
          </Row>
        )}
        {!loading &&
          rows.map((one, index) => (
            <Row
              key={index}
              style={{ marginBottom: "10px", gap: "10px" }}
              align="middle"
            >
              <Input
                disabled={!enable_editing}
                className="flex-1"
                placeholder={t("description")}
                value={one.description}
                onChange={(event) => {
                  setRows(
                    rows.map((e) => {
                      if (e.id === one.id) {
                        e.description = event.target.value;
                      }
                      return e;
                    })
                  );
                }}
              />

              <Input
                disabled={!enable_editing}
                className="flex-1"
                placeholder={t("price")}
                type="number"
                value={one.price}
                onChange={(event) => {
                  setRows(
                    rows.map((e) => {
                      if (e.id === one.id) {
                        e.price = Number(event.target.value);
                      }
                      return e;
                    })
                  );
                }}
              />
              {multi_level ? (
                <TreeSelect
                  showSearch
                  className="flex-1"
                  value={
                    typeof one.account !== "string"
                      ? one.account?.id
                      : one.account || undefined
                  }
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  placeholder={t("selectProduct")}
                  allowClear
                  treeDefaultExpandAll
                  onBlur={() => {
                    setRows(
                      rows.map((e) => {
                        if (e.id === one.id) {
                          e.fetchAccounts = false;
                          e.accountQuery = "";
                        }
                        return e;
                      })
                    );
                  }}
                  treeData={one.accountQuery ? one.accountsOptions : options}
                  loading={one.fetchAccounts}
                  onChange={(value) => {
                    setRows(
                      rows.map((e) => {
                        if (e.id === one.id) {
                          const dt = options || one.accountsOptions;
                          e.accountOption = dt.find((e) => e.value === value);
                          e.account = value;
                        }
                        return e;
                      })
                    );
                  }}
                />
              ) : (
                <Select
                  disabled={!enable_editing}
                  className="flex-1"
                  showSearch
                  onSearch={(val) => searchAccounts?.(val, one)}
                  value={
                    typeof one.account !== "string"
                      ? one.account?.id
                      : one.account || undefined
                  }
                  filterOption={false}
                  onBlur={() => {
                    setRows(
                      rows.map((e) => {
                        if (e.id === one.id) {
                          e.fetchAccounts = false;
                          e.accountQuery = "";
                        }
                        return e;
                      })
                    );
                  }}
                  options={one.accountQuery ? one.accountsOptions : options}
                  loading={one.fetchAccounts}
                  onChange={(value) => {
                    setRows(
                      rows.map((e) => {
                        if (e.id === one.id) {
                          const dt = options || one.accountsOptions;
                          e.accountOption = dt.find((e) => e.value === value);
                          e.account = value;
                        }
                        return e;
                      })
                    );
                  }}
                  placeholder={t("selectProduct")}
                />
              )}
              {enable_editing && (
                <>
                  {index === rows.length - 1 ? (
                    <Button
                      onClick={() => {
                        setRows((s) => [...s, defaultOption]);
                      }}
                      variant="primary"
                      type="link"
                      size="small"
                    >
                      <Plus />
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        setRows(rows.filter((e) => e.id !== one.id));
                      }}
                      variant="danger"
                      type="link"
                      size="small"
                    >
                      <X />
                    </Button>
                  )}
                </>
              )}
            </Row>
          ))}
      </Card>
    </>
  ) : (
    <></>
  );
};
