import AuthLogo from "@/components/AuthLogo";
import { Spin } from "antd";
import React from "react";

export const AppLoading: React.FC<{
  withLogo?: boolean;
  style?: React.CSSProperties;
}> = ({ withLogo = false, style }) => {
  return (
    <div
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        flexDirection: "column",
        zIndex: 1000,
        ...style,
      }}
    >
      {withLogo && <AuthLogo withLink={false} />}
      <Spin size="large" style={{ marginTop: "2rem" }} />
    </div>
  );
};
