import { Card } from "@components/dynamic/Card";
import { useAction } from "@hooks/useAction";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import React, { useRef } from "react";
import Chart from "react-apexcharts";

export const StaticPercentageBlock: React.FC<{
  block: IBlock;
  scope: IScope[];
  loading?: boolean;
}> = ({ block, scope = [], loading }) => {
  const { replaceFromScope, getCSS } = useAction();

  const config: IScope[] = block?.config ? block?.config : [];

  const expandable =
    config.find((e: any) => e.name === "expandable")?.value === "1";
  const expanded =
    config.find((e: any) => e.name === "expanded")?.value === "1";

  return (
    <Card
      scope={scope}
      body={block.body}
      title={block?.title}
      expanded={expanded}
      classes={block?.classes}
      expandable={expandable}
      loading={loading}
      config={config}
    >
      {!!block?.body && (
        <p
          dangerouslySetInnerHTML={{
            __html: replaceFromScope({
              str: block?.body,
              scope,
              withQuate: false,
            }),
          }}
        ></p>
      )}

      <Chart
        options={{
          chart: {
            type: "donut",
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: true,
                  },
                  value: {
                    show: true,
                  },
                  total: {
                    show: true,
                    showAlways: true,
                    label: "Total",
                  },
                },
              },
            },
          },
        }}
        series={[50, 20, 30]}
        type="donut"
      />
    </Card>
  );
};
