import { Collapse, Space } from "antd";
import React, { useState } from "react";
import { presetPrimaryColors } from "@ant-design/colors";
import {
  Microphone,
  MicrophoneSlash,
  VideoCamera,
  VideoCameraSlash,
} from "phosphor-react";

export type AttendanceProps = {
  header?: React.ReactNode;
  hasSettings?: boolean;
  onChange?: () => void;
};

const Attendance: React.FC<AttendanceProps> = ({
  header,
  children,
  hasSettings,
  onChange,
}) => {
  const [settings, setSettings] = useState<{
    cam?: boolean;
    mic?: boolean;
  }>({
    cam: false,
    mic: false,
  });

  const genExtra = () => (
    <div className="flex">
      <div className="flex me-1">
        <Microphone
          className="cursor-pointer"
          color={presetPrimaryColors.lime}
          size={18}
        />
        <MicrophoneSlash
          className="cursor-pointer"
          color={presetPrimaryColors.red}
          size={18}
        />
      </div>
      <div className="flex">
        <VideoCamera
          onClick={() => setSettings((s) => ({ ...s, cam: !settings.cam }))}
          className="me cursor-pointer"
          color={presetPrimaryColors.lime}
          size={18}
        />
        <VideoCameraSlash
          onClick={() => setSettings((s) => ({ ...s, cam: !settings.cam }))}
          className="cursor-pointer"
          color={presetPrimaryColors.red}
          size={18}
        />
      </div>
    </div>
  );

  return (
    <Collapse
      defaultActiveKey={["1"]}
      onChange={onChange}
      expandIconPosition="end"
      className="users-collapse"
      ghost
    >
      <Collapse.Panel
        header={header}
        key="1"
        extra={hasSettings ? genExtra() : <></>}
      >
        {children}
      </Collapse.Panel>
    </Collapse>
  );
};

export default Attendance;
