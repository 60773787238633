import { useAction } from "@/hooks/useAction";
import { IBlock } from "@/interfaces/block.interface";
import { IScope } from "@/interfaces/scope.interface";
import { FC, useEffect, useMemo, useState } from "react";
import { Card } from "./Card";
import { Radio, Row, Select, Space, Input } from "antd";
import Button from "../ui/buttons/Button";
import { ClosedCaptioning, Plus, X } from "phosphor-react";
import { useTranslation } from "react-i18next";
import short from "short-uuid";
import { Loading } from "./Loading";
import debounce from "lodash/debounce";
import { replaceJsonDoubleQuotes } from "@/utils";
import Big from "big.js";

interface IJournalEditor {
  block: IBlock;
  formState: any;
  visible?: boolean;
  setFormState: any;
}

interface InvoiceRow {
  account: IOption | string;
  type: "credit" | "debit";
  amount: number;
  description: string;
  id?: string;
  fetchAccounts?: boolean;
  accountsOptions?: IOption[];
  query?: string;
  disabled?: boolean;
}

interface IOption {
  label: string;
  value: string;
  id?: string;
}

export const JournalEditor: FC<IJournalEditor> = ({
  block,
  visible = true,
  formState,
  setFormState,
}) => {
  const defaultOption: InvoiceRow = {
    account: "",
    type: "credit",
    amount: 0,
    description: "",
    id: `empty-${short.generate()}`,
    disabled: false,
  };
  const config: IScope[] = block?.config || [];

  const { doQuery } = useAction();

  const { t } = useTranslation();

  const radioOptions = [
    { label: t("creditor"), value: "credit" },
    { label: t("debtor"), value: "debit" },
  ];

  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<IOption[]>([]);
  const [rows, setRows] = useState<InvoiceRow[]>([]);

  const accounts_query = config.find((e) => e.name === "accounts_query")?.value;
  const variable = config.find((e) => e.name === "variable")?.value;
  const lines_query = config.find((e) => e.name === "lines_query")?.value;
  const lines_accessor = config.find((e) => e.name === "lines_accessor")?.value;
  const enable_editing =
    config.find((e) => e.name === "enable_editing")?.value === "0";
  const accounts_accessor = config.find(
    (e) => e.name === "accounts_accessor"
  )?.value;

  const getData = () => {
    if (accounts_query) {
      doQuery({
        query: accounts_query,
        scope: [{ name: "account_name", value: "" }],
        withQuate: false,
      }).then((data: any) => {
        if (data?.data) {
          setOptions(
            data?.data[accounts_accessor || ""].options.map((one: any) => ({
              label: one.title,
              value: one.name,
            })) || []
          );
        }
      });
    }
  };

  useEffect(() => {
    const newRows: InvoiceRow[] = JSON.parse(JSON.stringify(rows));
    setFormState((s: any) => ({
      ...s,
      [variable as string]: replaceJsonDoubleQuotes(
        JSON.stringify(
          newRows.map((one) => {
            delete one.accountsOptions;
            delete one.fetchAccounts;
            delete one.query;
            if (one?.id?.includes("empty")) {
              delete one.id;
            }
            return one;
          })
        )
      ),
    }));
  }, [rows, setFormState, variable]);

  const searchAccount = useMemo(() => {
    if (!accounts_query) return;
    const loadOptions = (val: string, one: InvoiceRow) => {
      if (val === "") {
        return;
      }
      setRows((s) =>
        s.map((e) => {
          if (e.id === one.id) {
            e.fetchAccounts = true;
            e.query = val;
          }
          return e;
        })
      );
      doQuery({
        query: accounts_query,
        scope: [{ name: "account_name", value: val.toString() }],
        withQuate: false,
      })
        .then((data: any) => {
          if (data?.data) {
            setRows((s) =>
              s.map((e) => {
                if (e.id === one.id) {
                  e.accountsOptions =
                    data?.data[accounts_accessor || ""].options.map(
                      (one: any) => ({
                        label: one.title,
                        value: one.name,
                      })
                    ) || [];
                }
                return e;
              })
            );
          }
        })
        .finally(() => {
          setRows((s) =>
            s.map((e) => {
              if (e.id === one.id) {
                e.fetchAccounts = false;
              }
              return e;
            })
          );
        });
    };

    return debounce(loadOptions, 1000);
  }, [accounts_query, rows, accounts_accessor]);

  const getLines = () => {
    if (lines_query) {
      setLoading(true);
      doQuery({
        query: lines_query,
      })
        .then((data: any) => {
          if (data?.data) {
            setRows([...data?.data[lines_accessor || ""], defaultOption] || []);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getData();
  }, [accounts_query]);

  useEffect(() => {
    getLines();
  }, [lines_query]);

  const calcDebit = () => {
    return rows.reduce((a, b) => {
      if (b.type === "debit") {
        const bigA = Big(a);
        const bigB = Big(b.amount);
        return bigA.plus(bigB).toNumber();
      }
      return a;
    }, 0);
  };

  const calcCredit = () => {
    return rows.reduce((a, b) => {
      if (b.type === "credit") {
        const bigA = Big(a);
        const bigB = Big(b.amount);
        return bigA.plus(bigB).toNumber();
      }
      return a;
    }, 0);
  };

  return visible ? (
    <>
      <Card title="" body="">
        {loading && <Loading />}
        {!loading &&
          rows.map((one, index) => (
            <Row
              key={index}
              style={{ marginBottom: "10px", gap: "10px" }}
              align="middle"
            >
              <Radio.Group
                disabled={!enable_editing || one.disabled}
                optionType="button"
                buttonStyle="solid"
                options={radioOptions}
                onChange={(event) => {
                  setRows(
                    rows.map((e) => {
                      if (e.id === one.id) {
                        e.type = e.type === "credit" ? "debit" : "credit";
                      }
                      return e;
                    })
                  );
                }}
                value={one.type}
              />
              <Select
                disabled={!enable_editing || one.disabled}
                className="flex-1"
                showSearch
                onSearch={(val) => searchAccount?.(val, one)}
                value={
                  typeof one.account !== "string"
                    ? one.account.id
                    : one.account || undefined
                }
                filterOption={false}
                onBlur={() => {
                  setRows(
                    rows.map((e) => {
                      if (e.id === one.id) {
                        e.fetchAccounts = false;
                        e.query = "";
                      }
                      return e;
                    })
                  );
                }}
                options={one.query ? one.accountsOptions : options}
                loading={one.fetchAccounts}
                onChange={(value) => {
                  console.log(value);
                  setRows(
                    rows.map((e) => {
                      if (e.id === one.id) {
                        e.account = value;
                      }
                      return e;
                    })
                  );
                }}
                placeholder={t("selectAccount")}
              />
              <Input
                disabled={!enable_editing || one.disabled}
                className="flex-1"
                placeholder={t("amount")}
                type="number"
                value={one.amount}
                onChange={(event) => {
                  setRows(
                    rows.map((e) => {
                      if (e.id === one.id) {
                        e.amount = Number(event.target.value);
                      }
                      return e;
                    })
                  );
                }}
              />
              <Input
                disabled={!enable_editing || one.disabled}
                className="flex-1"
                placeholder={t("description")}
                value={one.description}
                onChange={(event) => {
                  setRows(
                    rows.map((e) => {
                      if (e.id === one.id) {
                        e.description = event.target.value;
                      }
                      return e;
                    })
                  );
                }}
              />
              {enable_editing && (
                <>
                  {index === rows.length - 1 ? (
                    <Button
                      onClick={() => {
                        setRows((s) => [...s, defaultOption]);
                      }}
                      variant="primary"
                      type="link"
                      size="small"
                    >
                      <Plus />
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        setRows(rows.filter((e) => e.id !== one.id));
                      }}
                      disabled={one.disabled}
                      variant="danger"
                      type="link"
                      size="small"
                    >
                      <X />
                    </Button>
                  )}
                </>
              )}
            </Row>
          ))}
        <Row justify="end">
          <Space>
            <div className="total-result">
              <p>{t("totalCredit")}</p>
              <span>{calcCredit()}</span>
            </div>
            |
            <div className="total-result">
              <p>{t("totalDebit")}</p>
              <span>{calcDebit()}</span>
            </div>
          </Space>
        </Row>
      </Card>
    </>
  ) : (
    <></>
  );
};
