import { Action } from "@components/dynamic/Action";
import { useAction } from "@hooks/useAction";
import { IAction } from "@interfaces/action.interface";
import { IBlock } from "@interfaces/block.interface";
import { IScope, IScopeArray } from "@interfaces/scope.interface";
import {
  Card as AntCard,
  Col,
  Divider,
  FormInstance,
  Rate,
  Row,
  Space,
} from "antd";
import React from "react";
import { BadgeItem } from "@components/dynamic/Badge";
import { Card } from "@components/dynamic/Card";
import Button from "../ui/buttons/Button";
import { useTranslation } from "react-i18next";
import { detectSafari } from "@/utils";

export const PrintPreview: React.FC<{
  block: IBlock;
  scope: IScope[];
  visible?: boolean;
}> = ({ block, scope = [], visible }) => {
  const { t } = useTranslation();
  const config: IScope[] = block?.config || [];
  const expandable = config.find((e) => e.name === "expandable")?.value === "1";
  const expanded = config.find((e) => e.name === "expanded")?.value === "1";
  const url = config.find((e) => e.name === "url")?.value;

  const openWin = async () => {
    if (url) {
      try {
        const res = await fetch(url);
        const text = await res.text();

        const myWindow = window.open("", "", "width=1200,height=800") as Window;
        myWindow?.document?.write(text);

        myWindow?.document?.close();
        myWindow?.focus();
        if (detectSafari()) {
          myWindow?.print();
          myWindow?.close();
        } else {
          myWindow.onload = () => {
            myWindow?.print();
            myWindow?.close();
          };
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <Card
      body={""}
      title={block.title}
      expanded={expanded}
      expandable={expandable}
      visible={visible}
      config={config}
      cardProps={{
        bodyStyle: { height: "100%" },
        extra: <Button onClick={openWin}>{t("print")}</Button>,
      }}
    >
      <iframe
        id="print-iframe"
        src={url}
        className="w-100 h-container b-none"
      />
    </Card>
  );
};
