import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { RootState } from "./root.reducer";

const NAME = "theme";

type ITheme = "dark" | "light";

type IThemeState = {
  theme?: ITheme;
};
const initialState: IThemeState = {
  theme: "light",
};

export const ThemeSlice = createSlice({
  name: NAME,
  initialState: initialState,
  reducers: {
    setTheme: (state, action) => {
      const theme = action.payload as ITheme;
      return { ...state, theme };
    },
  },
});

export const ThemeActions = ThemeSlice.actions;
export const themeSelector = (state: RootState) => state.theme;
export const ThemeReducer = persistReducer(
  {
    storage: storage,
    key: NAME,
    debug: false,
    whitelist: ["theme"],
  },
  ThemeSlice.reducer
);
