import { useSocket } from "@/hooks/useSocket";
import { ModalActions } from "@/store/reducers/modal.reducer";
import { socketSelector } from "@/store/reducers/socket.reducer";
import { getValFromArray, replaceVariables } from "@/utils";
import { useAction } from "@hooks/useAction";
import { IScope } from "@interfaces/scope.interface";
import {
  Card as AntCard,
  CardProps,
  Col,
  Collapse as AntCollapse,
  CollapseProps,
  Row,
  Skeleton,
} from "antd";
import classNames from "classnames";
import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const { Panel } = AntCollapse;

interface Props {
  expanded?: boolean;
  expandable?: boolean;
  title?: string | JSX.Element;
  editBodyButton?: JSX.Element;
  classes?: string;
  body: string | JSX.Element;
  loading?: boolean;
  params?: any;
  scope?: IScope[];
  className?: string;
  cardProps?: CardProps;
  collapseProps?: CollapseProps;
  visible?: boolean;
  config?: IScope[];
  bordered?: boolean;
}

export const Card: React.FC<PropsWithChildren<Props>> = ({
  expanded,
  expandable,
  title,
  classes,
  body,
  children,
  loading,
  params = {},
  scope = [],
  className = "",
  cardProps,
  collapseProps,
  editBodyButton,
  visible,
  config: configProps,
  bordered: borderedProp,
  ...props
}) => {
  const { isConnected, socket } = useSelector(socketSelector);

  const [append, setAppend] = React.useState<any[]>([]);
  const [prepend, setPrepend] = React.useState<any[]>([]);
  const [replace, setReplace] = React.useState<any>({});
  const navigate = useNavigate();

  const config: IScope[] = configProps || [];

  const variant = config.find((e: any) => e.name === "variant")?.value;
  const border = config.find((e: any) => e.name === "border")?.value;
  const bordered = config.find((e: any) => e.name === "bordered")?.value;

  const head_style =
    config.find((e: any) => e.name === "head_style")?.array || [];
  let headStyle: { [key: string]: string | number } = {};
  if (head_style.length) {
    head_style.forEach((e) => {
      headStyle[e.name] = e.value;
    });
  }
  const body_style =
    config.find((e: any) => e.name === "body_style")?.array || [];
  let bodyStyle: { [key: string]: string | number } = {};
  if (body_style.length) {
    body_style.forEach((e) => {
      bodyStyle[e.name] = e.value;
    });
  }
  const rooms = config.find((e: any) => e.name === "rooms")?.array || [];
  const ws_events =
    config.find((e: any) => e.name === "ws_events")?.array || [];

  const { doReplaceString } = useAction();
  const key = useRef(Math.random()).current;

  const dispatch = useDispatch();

  const { listenOnRoom, joinRoom, leaveRoom, removeListner } = useSocket();

  const handleSocketData = useCallback(() => {
    if (rooms.length) {
      if (rooms.length) {
        rooms.forEach((room) => {
          joinRoom(room.value);
        });
      }
    }
    if (ws_events) {
      ws_events.forEach((event) => {
        listenOnRoom(event.name, (data: any) => {
          const type = getValFromArray(event.array as [], "type");
          const msgData = JSON.parse(data.message);
          if (type === "append") {
            const body = getValFromArray(event.array as [], "body");
            setAppend((prev) => [
              ...prev,
              replaceVariables({ str: body, object: msgData }),
            ]);
          }
          if (type === "prepend") {
            const body = getValFromArray(event.array as [], "body");
            setPrepend((prev) => [
              ...prev,
              replaceVariables({ str: body, object: msgData }),
            ]);
          }
          if (type === "replace") {
            const body = getValFromArray(event.array as [], "body");
            setPrepend([]);
            setAppend([]);
            setReplace(replaceVariables({ str: body, object: msgData }));
          }
          if (type === "refresh_page") {
            dispatch(ModalActions.toggleModal({ refreshSelf: true }));
          }
        });
      });
    }
  }, [rooms, ws_events, socket]);

  useEffect(() => {
    if (isConnected) {
      handleSocketData();
    }
    return () => {
      if (isConnected) {
        if (rooms.length) {
          rooms.forEach((room) => {
            leaveRoom(room.value);
          });
        }
        if (ws_events) {
          ws_events.forEach((event) => {
            removeListner(event.name);
          });
        }
      }
    };
  }, [rooms, ws_events, isConnected]);

  const { allParams } = useMemo(() => {
    let allParams: any = {};

    scope.forEach((s) => {
      allParams[s.name] = s.value;
    });

    allParams = {
      ...allParams,
      ...params,
    };

    return { allParams };
  }, [params, scope]);

  return expandable ? (
    <Skeleton
      className={classNames(visible === false && "d-none")}
      loading={loading}
    >
      <AntCollapse
        defaultActiveKey={expanded ? [`collaps-key-${key}`] : []}
        expandIconPosition="right"
        style={{ width: "100%" }}
        className={classNames(classes, className)}
        {...collapseProps}
        {...props}
      >
        <Panel
          extra={cardProps?.extra}
          header={<span style={headStyle}>{title}</span>}
          key={`collaps-key-${key}`}
        >
          {body !== undefined && typeof body === "string" && (
            <p
              className={classes}
              dangerouslySetInnerHTML={{
                __html: doReplaceString({
                  str: body,
                  item: allParams,
                }),
              }}
            />
          )}
          {React.isValidElement(body) && body}
          {children}
        </Panel>
      </AntCollapse>
    </Skeleton>
  ) : (
    <AntCard
      title={title}
      bordered={
        typeof borderedProp !== "undefined"
          ? borderedProp
          : !bordered
          ? true
          : bordered === "1"
      }
      style={{ width: "100%" }}
      className={classNames(
        classes,
        className,
        visible === false && "d-none",
        variant ? `ant-card-${variant}` : "",
        border ? `ant-card-border-${border}` : ""
      )}
      loading={loading}
      headStyle={headStyle}
      bodyStyle={bodyStyle}
      {...cardProps}
    >
      {body !== undefined && typeof body === "string" && (
        <Row wrap={false} gutter={[16, 16]}>
          <Col flex="auto">
            {Object.keys(replace).length === 0 && (
              <div
                className={classNames("card-body-wrapper", classes)}
                dangerouslySetInnerHTML={{
                  __html: doReplaceString({
                    str: body,
                    item: allParams,
                  }),
                }}
              />
            )}
          </Col>
          {editBodyButton && <Col flex="none">{editBodyButton}</Col>}
        </Row>
      )}

      {React.isValidElement(body) && (
        <>
          <Row wrap={false} gutter={[16, 16]}>
            <Col flex="auto">{body}</Col>
            {editBodyButton && <Col flex="none">{editBodyButton}</Col>}
          </Row>
        </>
      )}

      {prepend.map((one, index) => (
        <div
          key={`prepend-${index}`}
          dangerouslySetInnerHTML={{
            __html: one,
          }}
        />
      ))}

      {Object.keys(replace).length > 0 ? (
        <div
          dangerouslySetInnerHTML={{
            __html: replace,
          }}
        />
      ) : (
        children
      )}
      {append.map((one, index) => (
        <div
          key={`append-${index}`}
          dangerouslySetInnerHTML={{
            __html: one,
          }}
        />
      ))}
    </AntCard>
  );
};
