import { Avatar, Badge, Space } from "antd";
import { presetPrimaryColors } from "@ant-design/colors";
import {
  Chat,
  HandPalm,
  Microphone,
  MicrophoneSlash,
  User,
  VideoCamera,
  VideoCameraSlash,
} from "phosphor-react";
import React, { useEffect, useState } from "react";

export type AttendanceItemProps = {
  name?: string;
  image?: string;
  last?: boolean;
  isOnline?: boolean;
  hasChat?: boolean;
  hasHand?: boolean;
  hasCam?: boolean;
  hasMic?: boolean;
};
const AttendanceItem: React.FC<AttendanceItemProps> = ({
  name,
  image,
  last,
  isOnline,
  hasChat,
  hasHand,
  hasCam,
  hasMic,
}) => {
  const [settings, setSettings] = useState<{
    cam?: boolean;
    mic?: boolean;
  }>({
    cam: false,
    mic: false,
  });

  useEffect(() => {
    setSettings({
      cam: hasCam,
      mic: hasMic,
    });
  }, []);

  return (
    <div className={`flex align-items-center ${last ? "mb-0" : "mb-1"}`}>
      {settings.mic ? (
        <Microphone
          onClick={() => setSettings((s) => ({ ...s, mic: !settings.mic }))}
          className="me cursor-pointer"
          color={presetPrimaryColors.lime}
          size={18}
        />
      ) : (
        <MicrophoneSlash
          onClick={() => setSettings((s) => ({ ...s, mic: !settings.mic }))}
          className="me cursor-pointer"
          color={presetPrimaryColors.red}
          size={18}
        />
      )}
      {settings.cam ? (
        <VideoCamera
          onClick={() => setSettings((s) => ({ ...s, cam: !settings.cam }))}
          className="me cursor-pointer"
          color={presetPrimaryColors.lime}
          size={18}
        />
      ) : (
        <VideoCameraSlash
          onClick={() => setSettings((s) => ({ ...s, cam: !settings.cam }))}
          className="me cursor-pointer"
          color={presetPrimaryColors.red}
          size={18}
        />
      )}
      <Badge dot={isOnline} color="lime" offset={[-5, 5]} className="me">
        <Avatar src={image} icon={<User />} />
      </Badge>
      <h4 className="mb-0">{name}</h4>
      {hasHand && (
        <HandPalm
          color={presetPrimaryColors.gold}
          className="ms cursor-pointer"
          size={18}
        />
      )}
      {hasChat && (
        <Chat
          className="ms-auto cursor-pointer"
          color={presetPrimaryColors.grey}
          size={18}
        />
      )}
    </div>
  );
};

export default AttendanceItem;
